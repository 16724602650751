import './auth-callback.scss';
var htmlContent = require('./auth-callback.html');
import { Component } from '../../shared/components/base-component';
import $ from 'jquery';
import { Spinner } from '../../shared/components/spinner/spinner';
import { ViewsLoader } from '../../core/views/views-loader';
import { HttpService } from '../../core/http/http';
import { AuthenticationService } from '../../shared/services/authenticationService';
import { Router } from '../../core/router/router';
import { ToastService } from '../../shared/services/toastService';
import { TranslationService } from '../../core/translation/translation';

const renderMarkup = () => htmlContent;

export class AuthCallbackPage extends Component {
  constructor(params) {
    super(renderMarkup());
    this.viewsLoader = new ViewsLoader();
    this.httpService = new HttpService();
    this.toastService = new ToastService();
    this.authenticationService = new AuthenticationService();

    $(document).ready(() => {
      $('#loader-container').removeClass('hidden');
      this.viewsLoader.loadView(new Spinner('big'), document.getElementById('loader-container'));
      this.authenticate();
    });
  }

  generateAntiForgeryTokens() {
    return this.httpService.get(this.httpService.config.getAntiForgeryTokens);
  }
  completeAuthentication() {
    return this.httpService.get(this.httpService.config.getLoggedUser);
  }
  authenticate() {
    if (this.authenticationService.isAuthenticated()) {
      this.authenticationService.obtainToken()
      .then(() => {
        this.generateAntiForgeryTokens()
          .then(() => {
            this.completeAuthentication()
            .then(user => {
              this.authenticationService.setAuthenticatedUser(user);
              Router.inst.reload();
            })
            .catch(err => {
              this.toastService.info(
                TranslationService.instance.translate('info-title'),
                TranslationService.instance.translate('msg-user-not-found')
              );
              setTimeout(() => {
                this.authenticationService.logout();
              }, 2000);
              console.log(err);
            });
        });
      });
    } else {
      this.authenticationService.startAuthentication();
      // para que en IOS recarge la página después de la validación por PopUp
      setTimeout(() => {
        Router.inst.reload();
      }, 10000);
    }
  }
}
