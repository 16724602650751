import './configMailNotifications.scss';
var htmlContent = require('./configMailNotifications.html');
import { Component } from '../../shared/components/base-component';
import $ from '../../core/jquery/jquery-global';
import { ModalComponent } from '../../shared/components/modal';
import { TranslationService } from '../../core/translation/translation';
import { configureAlertsComponent } from '../../components/configure-alerts/configure-alerts';
import { HttpService } from '../../core/http/http';
import { ToastService } from '../../shared/services/toastService';
import { ViewsLoader } from '../../core/views/views-loader';
import { Spinner } from '../../shared/components/spinner/spinner';
import {
  ConfirmationDeleteOptions,
  ConfirmationDeleteComponent
} from '../../components/confirmation-delete/confirmationDelete';
import { ListOptions, List } from '../../shared/components/list/list';
import { AuthenticationService } from '../../shared/services/authenticationService';

const renderMarkup = () => htmlContent;

export class ConfigMailNotificationsPage extends Component {
  constructor() {
    super(renderMarkup());
    $(document).ready(() => {
      let selectNav = document.querySelectorAll('.links-item');
      let i = 0;
      selectNav.forEach(e => {
        if (i == 6) {
          $(e).addClass('active');
          i++;
        } else {
          $(e).removeClass('active');
          i++;
        }
      });
      this.httpService = new HttpService();
      this.toastService = new ToastService();
      this.authenticationService = new AuthenticationService();
      this.viewsLoader = new ViewsLoader();
      this.init();
      this._notificationsConfig = null;
      this._selectedNotificationConfig = null;
    });
  }

  _loadNotificationsConfig() {
    $('#loader-container').removeClass('hidden');
    this.viewsLoader.loadView(new Spinner('big'), document.getElementById('loader-container'));
    this.httpService
      .get(
        `${this.httpService.config.loadNotificationsConfig}?loginUsuario=${
          this.authenticationService.getAuthenticatedUser().login
        }`
      )
      .then(response => {
        $('#loader-container').addClass('hidden');
        this._notificationsConfig = response;
        this.buildNotificationsConfigTable();
        this.enableButtons();
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }

  init() {
    this._loadNotificationsConfig();
    this.bindButtons();
  }

  buildNotificationsConfigTable() {
    let options = new ListOptions();
    options.listSelector = '#users-notifications';
    options.searchSelector = '.search-input';
    options.textShowingSelector = '.text-showing-results';
    options.textShowingValue = TranslationService.instance.translate('common-showing-results');
    options.includeFieldsSearch = ['idUsuarioNavigationNombre'];

    options.onRenderRow = function(config) {
      let notificationCheckHtml = '<span class="circle-state green">';
      return `<div class="table-row" data-id="${config.idAvisoCorreo}">
      <div class="cell left user">${config.idUsuarioNavigationNombre}</div>
      <div class="cell left company">${config.idEmpresaNavigationNombre}</div>
      <div class="cell left end-section investmentArea">${
        config.idAreaInversionNavigationNombre
      }</div>
      <div class="cell center budget1">${
        config.borradorPresupuesto == true ? notificationCheckHtml : ''
      }</div>
      <div class="cell center budget2">${
        config.estadoPresupuesto == true ? notificationCheckHtml : ''
      }</div>
      <div class="cell center budget3">${
        config.responsablePresupuesto == true ? notificationCheckHtml : ''
      }</div>
      <div class="cell center end-section budget4">${
        config.aprobarPresupuesto == true ? notificationCheckHtml : ''
      }</div>
      <div class="cell center request1">${
        config.borradorSolicitud == true ? notificationCheckHtml : ''
      }</div>
      <div class="cell center request2">${
        config.estadoSolicitud == true ? notificationCheckHtml : ''
      }</div>
      <div class="cell center request3">${
        config.responsableSolicitud == true ? notificationCheckHtml : ''
      }</div>
      <div class="cell center request4">${
        config.aprobarSolicitud == true ? notificationCheckHtml : ''
      }</div>
      <div class="cell center request5">${
        config.aprobarRechazarEnFlujoSolicitud == true ? notificationCheckHtml : ''
      }</div>
    </div>`;
    };
    options.onRenderFinish = () => {
      $('#users-notifications')
        .find('.table-row')
        .click(e => this.selectTableRow(e));
    };

    this._list = new List(options);
    this._list.updateData(this._notificationsConfig);
  }

  selectTableRow(event) {
    $('#users-notifications')
      .find('.table-row')
      .removeClass('selected');
    event.currentTarget.classList.add('selected');
    this._selectedNotificationConfig = this._notificationsConfig.filter(
      nc => nc.idAvisoCorreo == parseInt(event.currentTarget.getAttribute('data-id'))
    )[0];
    this.enableButtons();
  }

  bindButtons() {
    $('#btn-create-notification').click(() => this.createConfigurationModal());
    $('#btn-edit-notification').click(() => this.editConfigurationModal());
    $('#btn-delete-notification').click(() => this.deleteConfigurationModal());
  }
  enableButtons() {
    $('#btn-create-notification').attr('disabled', this._notificationsConfig == null);
    $('#btn-edit-notification').attr('disabled', this._selectedNotificationConfig == null);
    $('#btn-delete-notification').attr('disabled', this._selectedNotificationConfig == null);
  }

  createConfigurationModal() {
    var modal = new ModalComponent(
      TranslationService.instance.translate('config-mail-new-modal-title'),
      new configureAlertsComponent()
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._selectedNotificationConfig = null;
      this.enableButtons();
      this._loadNotificationsConfig();
    });
  }

  editConfigurationModal() {
    var modal = new ModalComponent(
      TranslationService.instance.translate('config-mail-edit-modal-title'),
      new configureAlertsComponent(this._selectedNotificationConfig)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._selectedNotificationConfig = null;
      this.enableButtons();
      this._loadNotificationsConfig();
    });
  }

  deleteConfigurationModal() {
    let options = new ConfirmationDeleteOptions();
    options.itemName = `${this._selectedNotificationConfig.idUsuarioNavigationNombre} 
    - ${this._selectedNotificationConfig.idEmpresaNavigationNombre} - 
    ${this._selectedNotificationConfig.idAreaInversionNavigationNombre}`;
    options.actionUrl = `${this.httpService.config.deleteMailAlert}?idConfiguracion=${
      this._selectedNotificationConfig.idAvisoCorreo
    }&loginUsuario=${this.authenticationService.getAuthenticatedUser().login}`;
    let modal = new ModalComponent(
      TranslationService.instance.translate('common-modal-confirmation'),
      new ConfirmationDeleteComponent(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._selectedNotificationConfig = null;
      this.enableButtons();
      this._loadNotificationsConfig();
    });
  }
}
