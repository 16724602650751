import './generic-confirm.scss';
var htmlContent = require('./generic-confirm.html');
import { BaseModalContent } from '../../shared/components/base-modal-content';

const renderMarkup = () => htmlContent;

export class GenericConfirmComponent extends BaseModalContent {
  constructor(subtitle) {
    super(renderMarkup());
    this.value = null;
    this._subtitle = subtitle;
  }

  onCreated() {
    this.init();
  }

  init() {
    this.parentModal.container.find('#cancel').click(() => this.cancel());
    this.parentModal.container.find('#confirm').click(() => this.confirm());
    this.parentModal.container.find('.subtitle').text(this._subtitle);
  }

  cancel() {
    this.value = null;
    this.parentModal.close();
  }

  confirm() {
    this.value = {
      confirm: true
    };
    this.parentModal.close();
  }

  get result() {
    return this.value;
  }
}
