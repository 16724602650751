import './requests.scss';
var htmlContent = require('./requests.html');
import { Component } from '../../shared/components/base-component';
import $ from '../../core/jquery/jquery-global';
import {
  UnitsEnums,
  RequestTypeEnum,
  RequestStatusNameEnum,
  InvestmentTypeEnum,
  RequestStatusEnum,
  RequestFlowStatusClassesEnum,
  RequestStatusClassesEnum,
  RequestTypeEnumTranslation
} from '../../config/enums';
import { HttpService } from '../../core/http/http';
import { ToastService } from '../../shared/services/toastService';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import { ViewsLoader } from '../../core/views/views-loader';
import { Spinner } from '../../shared/components/spinner/spinner';
import { DatePicker } from '../../components/date-picker/datePicker';
import { ModalComponent } from '../../shared/components/modal';
import { ImportRequestComponent } from '../../components/import-request/importRequest';
import { Router } from '../../core/router/router';
import { CreateRequestComponent } from '../../components/create-request/createRequest';
import { InputNumeric } from '../../components/input-numeric/inputNumeric';
import { TranslationService, TranslationServiceOptions } from '../../core/translation/translation';
import { ChooseBudgetComponent } from '../../components/choose-budget/choose-budget';
import { ListOptions, List } from '../../shared/components/list/list';
import {
  NavigationCardOptions,
  NavigationCardComponent
} from '../../shared/components/navigation-card/navigation-card';
import {
  CardBalanceBudget,
  CardBalanceBudgetOptions
} from '../../shared/components/card-balance-budget/card-balance-budget';
import {
  VisualizationOptionsParams,
  VisualizationOptionsComponent
} from '../../components/visualization-options/visualization-options';
import { AuthenticationService } from '../../shared/services/authenticationService';
import { SessionStorageService } from '../../core/sessionStorage/sessionStorage';
import { SessionKeys } from '../../config/keys';
import { LocalStorageService } from '../../core/localStorage/localStorage';

const renderMarkup = () => htmlContent;

export class RequestsPage extends Component {
  constructor() {
    super(renderMarkup());
    $(document).ready(() => {
      let selectNav = document.querySelectorAll('.links-item');
      let i = 0;
      selectNav.forEach(e => {
        if (i == 2) {
          $(e).addClass('active');
          i++;
        } else {
          $(e).removeClass('active');
          i++;
        }
      });
      this.httpService = new HttpService();
      this.toastService = new ToastService();
      this.authenticationService = new AuthenticationService();
      this.viewsLoader = new ViewsLoader();
      this.sessionStorage = new SessionStorageService();
      this.localStorageService = new LocalStorageService();

      this.viewsLoader.loadView(
        new Spinner('big'),
        document.getElementById('main-container-loader')
      );

      var screenWidth = window.innerWidth;
      if (screenWidth >= 768) {
        this.device = 'desktop';
        this.buildSelectors();
      } else {
        this.device = 'mobile';
        this.buildSelectors();
      }

      this.enableButtons(this.device);

      this._myPendingRequests = null;
      this._budgetRequests = [];
      this._balance = null;
      this.localCurrency = true;
      this.unit = UnitsEnums.Unit;

      this._cardBalanceOptions = new CardBalanceBudgetOptions();
      this._cardBalanceOptions.localCurrency = this.localCurrency;
      this._cardBalanceOptions.unit = this.unit;

      this.selectedYear = null;
      this.selectedCompany = null;
      this.selectedInvestmentArea = null;
    });
  }

  buildSelectors() {
    Promise.all([this._loadYears(), this._loadCompanies(), this._loadCurrencies(), this.loadMyRequests()]).then(
      response => {
        this.loadingFromSession =
          this.existsInSession(SessionKeys.RequestYear) ||
          this.existsInSession(SessionKeys.RequestCompany) ||
          this.existsInSession(SessionKeys.RequestInvestmentArea);
        let willLoadInvestmentAreas =
          this.loadingFromSession && this.existsInSession(SessionKeys.RequestCompany); //This is required to prevent double loads when page contains investment area, as the investment area is loaded after company loads.

        this.yearSelector = new DropDownComponent(document.getElementById('exercises-selector'));
        this.yearSelector.loadData(this._years, 'id', 'label');

        this.yearSelector.onChanges.listen(year => {
          this.setSelectedYear(year);

          if (this.loadingFromSession) return;
          this.filterTriggered();
        });

        this.companySelector = new DropDownComponent(document.getElementById('company-selector'));
        this.companySelector.loadData(this._companies, 'idEmpresa', 'nombre');

        this.investmentAreaSelector = new DropDownComponent(
          document.getElementById('investment-area-selector')
        );
        this.investmentAreaSelector.setRule('disabled', 'disabled');

        this.investmentAreaSelector.onChanges.listen(investmentArea => {
          this.setSelectedInvestmentArea(investmentArea);

          if (willLoadInvestmentAreas) {
            this.loadingFromSession = false;
            willLoadInvestmentAreas = false;
            $('#main-container-loader').addClass('hidden');
          }

          this.filterTriggered();
        });

        this.companySelector.onChanges.listen(company => {
          this.setSelectedCompany(company);

          if (company && company.idEmpresa) {
            this._loadInvestmentAreas(company.idEmpresa);
          } else {
            this.investmentAreaSelector.clearSelection();
            this.investmentAreaSelector.setRule('disabled', 'disabled');
          }

          if (this.loadedDefaultValues) {
            this.investmentAreaSelector.clearSelection();
          }

          if (company && company.idMoneda) {
            this.rebuildCurrencySelector(company.idMoneda);
          }
        });

        this._setDefaultTab();

        //Load default values
        //If year exists load it, as is the first parameter if it's null it will load all the parameters
        const year = this.existsInSession(SessionKeys.RequestYear)
          ? parseInt(this.sessionStorage.getItem(SessionKeys.RequestYear))
          : new Date().getFullYear();
        if(this._years.some(x => x.id === year)) {
          this.yearSelector.setSelected(year, 'id', 'label');
        }

        if (this.existsInSession(SessionKeys.RequestCompany)) {
          this.companySelector.setSelected(
            parseInt(this.sessionStorage.getItem(SessionKeys.RequestCompany)), 'idEmpresa', 'nombre');
        }

        if (!willLoadInvestmentAreas) {
          this.loadingFromSession = false;
          this.filterTriggered();
          $('#main-container-loader').addClass('hidden');
        }

        this.configureSearchList();
      }
    );
  }

  configureSearchList() {
    let options = new ListOptions();
    options.listSelector =
      this.device == 'desktop' ? '#my-requests-table-content' : '#my-request-cards-content';
    options.searchSelector = '.search-input';
    options.textShowingSelector = '.text-showing-results';
    options.textShowingValue = TranslationService.instance.translate('common-showing-results');
    options.includeFieldsSearch = [
      'referencia',
      'fecha',
      'areaInversion',
      'nombreSolicitud',
      'importeLocal',
      'solicitante',
      'pendiente',
      'tipoText',
      'enPlanText',
      'noEnPlanText',
      'excedidoText',
      'estadoText'
    ];

    options.onRenderRow = data => this._buildMyRequest(data);
    options.onRenderFinish = () => {
      //contents.find('.budget-input.decimal').each((i, e) => InputNumeric.createDecimal(e));
    };
    options.onRenderEmpty = () => this._buildMyRequestEmpty();

    options.data = this._myPendingRequests.map(x => {
      x.tipoText = TranslationService.instance.translate(RequestTypeEnumTranslation[x.idTipo]);
      x.enPlanText = x.enPlan ? TranslationService.instance.translate(InvestmentTypeEnum[1]) : '';
      x.noEnPlanText =
        x.noEnPlan && !x.enPlan ? TranslationService.instance.translate(InvestmentTypeEnum[2]) : '';
      x.excedidoText = x.excedido ? TranslationService.instance.translate('exceded') : '';
      x.estadoText = TranslationService.instance.translate(RequestStatusEnum[x.idEstado]);

      return x;
    });
    this._list = new List(options);
  }

  existsInSession(val) {
    return (
      this.sessionStorage.hasItem(val) &&
      this.sessionStorage.getItem(val) !== '' &&
      this.sessionStorage.getItem(val) !== 'undefined'
    );
  }

  mobileChooseBudgetModal() {
    let options = {
      year: this.getSelectedYearValue(),
      company: this.getSelectedCompanyValue(),
      investmentArea: this.getSelectedInvestmentAreaValue()
    };
    let modal = new ModalComponent(
      TranslationService.instance.translate('choose-budget-modal-title'),
      new ChooseBudgetComponent(options)
    );
    modal.onClosed.listen(() => {
      let res = modal.result;

      if (res == null) {
        return;
      }

      this.setSelectedYear(res.year != '' ? res.year : null);
      this.setSelectedCompany(res.company != '' ? res.company : null);
      this.setSelectedInvestmentArea(res.investmentArea != '' ? res.investmentArea : null);

      this.fillBreadcrumb();
      this.filterTriggered();
    });
  }

  fillBreadcrumb() {
    if (
      this.selectedYear !== null &&
      this.selectedCompany !== null &&
      this.selectedInvestmentArea !== null
    ) {
      document.getElementById('filter-results').innerText = [
        this.getSelectedYearLabel(),
        this.getSelectedCompanyLabel(),
        this.getSelectedInvestmentAreaLabel()
      ].join(' / ');
    }
  }

  fillInvestmentArea() {
    if (
      typeof this._investmentAreaToFill !== 'undefined' &&
      this.investmentAreaSelector.contains(this._investmentAreaToFill, 'idAreaInversion')
    ) {
      this.investmentAreaSelector.setSelected(
        this._investmentAreaToFill,
        'idAreaInversion',
        'nombre'
      );
      // Removes it because it has already been filled
      this._investmentAreaToFill = undefined;
    }
  }

  rebuildCurrencySelector(companyCurrencyId) {
    let companyCurrency = this._currencies.filter(c => c.idTipoMoneda == companyCurrencyId)[0];
    $('.company-currency').html(`${companyCurrency.nombreLargo} (${companyCurrency.simbolo})`);
    $('.euro-selector').toggleClass('hidden', companyCurrency.simbolo == '€');
    $('.euro-selector').toggleClass('active', !companyCurrency.simbolo == '€');
    this.localCurrency = this.localCurrency || companyCurrency.simbolo == '€';
    $('.company-currency').toggleClass('active', this.localCurrency);
  }

  filterTriggered() {
    const exercise = this.getSelectedYearValue();
    const companyId = this.getSelectedCompanyValue();
    const investmentAreaId = this.getSelectedInvestmentAreaValue();

    $('#case-exercises').html = exercise;

    if (exercise != null && companyId != null && investmentAreaId != null) {
      $('#new-request').removeAttr('disabled');
      $('#import-request').removeAttr('disabled');
    } else {
      $('#new-request').attr('disabled', 'disabled');
      $('#import-request').attr('disabled', 'disabled');
    }

    let breadcrumb = '';
    if (exercise != null) {
      breadcrumb += `${TranslationService.instance.translate('common-exercises')} ${exercise} /`;
    }

    if (companyId != null) {
      var company = this._companies.filter(c => c.idEmpresa == companyId)[0];
      breadcrumb += ` ${company.nombre} /`;
    }

    if (investmentAreaId != null) {
      var investmentArea = this._investmentAreas.filter(ia => ia.idAreaInversion == investmentAreaId)[0];
      breadcrumb += ` ${investmentArea.nombre}`;
    }

    if (breadcrumb.lastIndexOf('/') == breadcrumb.length - 1) {
      breadcrumb = breadcrumb.substr(0, breadcrumb.length - 1);
    }

    breadcrumb = breadcrumb.trim();
    $('#main-container-budget-requests')
      .find('.title-case')
      .html(breadcrumb);
    $('#main-container-balance')
      .find('.title-case')
      .html(breadcrumb);

    this._reloadTables();
  }

  _loadYears () {
    return this.httpService.get(this.httpService.config.loadBudgetsYears +
      this.authenticationService.getAuthenticatedUser().login
    ).then(response => {
      this._years = response.reverse().map(year => ({id: year, label: year.toString()}));
    }).catch(error => {
      this._toasts.error(error.title, error.message);
      console.error(error);
    });
  }

  _loadCompanies() {
    return this.httpService
      .get(
        this.httpService.config.loadCompaniesPerUser +
        this.authenticationService.getAuthenticatedUser().login
      )
      .then(response => {
        this._companies = response;
      })
      .catch(error => {
        $('#main-container-loader').addClass('hidden');
        this.toastService.error(error.title, error.message);
        console.error(error);
      });
  }

  _loadInvestmentAreas(idEmpresa) {
    return this.httpService
      .get(
        this.httpService.config.loadInvestmentAreasPerUserAndCompany +
        '?loginUsuario=' +
        this.authenticationService.getAuthenticatedUser().login +
        '&idEmpresa=' +
        idEmpresa +
        '&tieneQueSerAdmin=false'
      )
      .then(response => {
        this._investmentAreas = response;
        if (this._investmentAreas && this._investmentAreas.length > 0) {
          this.investmentAreaSelector.loadData(this._investmentAreas, 'idAreaInversion', 'nombre');
          this.investmentAreaSelector.removeRule('disabled');

          if (
            this.existsInSession(SessionKeys.RequestInvestmentArea) &&
            !this.loadedDefaultValues
          ) {
            this.loadedDefaultValues = true;

            this.investmentAreaSelector.setSelected(
              parseInt(this.sessionStorage.getItem(SessionKeys.RequestInvestmentArea)),
              'idAreaInversion',
              'nombre'
            );
          } else {
            this.loadedDefaultValues = true;

            this.investmentAreaSelector.clearSelection();
            this.fillInvestmentArea();
          }
        } else {
          this.investmentAreaSelector.setRule('disabled', 'disabled');
        }
      })
      .catch(error => {
        $('#main-container-loader').addClass('hidden');
        this.toastService.error(error.title, error.message);
        console.error(error);
      });
  }

  _loadCurrencies() {
    return this.httpService
      .get(this.httpService.config.loadCurrencies)
      .then(response => {
        this._currencies = response;
      })
      .catch(error => {
        $('#main-container-loader').addClass('hidden');
        this.toastService.error(error.title, error.message);
        console.error(error);
      });
  }

  enableButtons(device) {
    if (device == 'desktop') {
      $('#btn-toogle-menu').click(() => this._toggleMenu());
      $('#my-pending-requests').click(() => this.myPendingRequest());
      $('#work-budget-requests').click(() => this.budgetRequests());
      $('#balance').click(() => this.balance());
      $('#exercises').click(() => this.selectExercises());
      $('#company').click(() => this.selectCompany());
      $('#investment-area').click(() => this.selectInvestmentArea());
      $('.company-currency').click(() => this.enableLocalCurrencyView());
      $('.euro-selector').click(() => this.enableEurosView());
      $('.euro-selector').addClass('hidden');
      $('.unit-units').click(() => this.switchUnit(UnitsEnums.Unit));
      $('.unit-thousands').click(() => this.switchUnit(UnitsEnums.Thousand));
      $('.unit-millions').click(() => this.switchUnit(UnitsEnums.Millions));
      $('#import-request').click(() => this.importRequestModal());
      $('#new-request').click(() => this.newRequestModal());
    } else if (device == 'mobile') {
      $('#my-pending-requests').click(() => this.myPendingRequest());
      $('#work-budget-requests').click(() => this.budgetRequests());
      $('#balance').click(() => this.balance());
      $('#btn-toogle-menu').click(() => this._toggleMenu());
      $('#btn-filter').click(() => this.mobileChooseBudgetModal());
      $('#import-request').click(() => this.importRequestModal());
      $('#new-request').click(() => this.newRequestModal());
      $('#btn-filters').click(() => this.visualizationOptions());

      let htmlTemplate = `<div>
           ${TranslationService.instance.translate('requests-mpr-title')}
          </div>
        `;

      $('#title-requests').html(htmlTemplate);
    }
  }

  importRequestModal() {
    let references;

    Promise.all([
      this.getReferenceId()
    ])
      .then(() => {
        if (typeof this._references !== 'undefined') {
          references = this._references;
          this._references = undefined;
        }
      })
      .catch(error => {
        $('#main-container-loader').addClass('hidden');
        this.toastService.exception(error.title);
        console.error(error);
      })
      .finally(() => {
        let selectedFilter = {
          year: this.getSelectedYearValue(),
          company: this.getSelectedCompanyValue(),
          investment: this.getSelectedInvestmentAreaValue()
        };

        let modal = new ModalComponent(
          TranslationService.instance.translate('requests-modal-import-request-title'),
          new ImportRequestComponent(100, references, selectedFilter)
        ); //Possibly will change in a future, the client is not clear in what to do with UTE
        modal.onClosed.listen(() => {
          if (modal.result != null) {
            this.importRequest(modal.result);
          }
        });
      });
  }

  importRequest(result) {
    $('#loader-container').removeClass('hidden');

    let body = {
      usuarioSolicitante: this.authenticationService.getAuthenticatedUser().login,
      areaTrabajo: {
        idEmpresa: this.getSelectedCompanyValue(),
        idAreaInversion: this.getSelectedInvestmentAreaValue(),
        ejercicio: this.getSelectedYearValue()
      },
      idSolicitud: result.requestOriginId,
      referencia: result.reference
    };

    this.httpService
      .post(this.httpService.config.importRequest, body)
      .then(response => {
        if (response !== null) {
          Router.inst.navigate('requests/create', true, { data: response });
        }
      })
      .catch(error => {
        $('#main-container-loader').addClass('hidden');
        this.toastService.exception(error.title);
        console.error(error);
      });
  }

  newRequestModal() {
    $('#main-container-loader').removeClass('hidden');
    let year = this.getSelectedYearValue();

    Promise.all([this.getBudgetId(), this.getReferenceId(), this.getSegmented()]).then(() => {
        if (typeof this._budgetId !== 'undefined') {
          let budgetId = this._budgetId;
          this._budgetId = undefined;
          let references, isSegmented;

          if (typeof this._references !== 'undefined') {
            references = this._references;
            this._references = undefined;
          }

          isSegmented = this._isSegmented;

          Promise.all([this.getBudgetWorks(budgetId)]).then(() => {
            if (typeof this._works !== 'undefined' && this._works.length > 0) {
              $('#main-container-loader').addClass('hidden');
              this._works = undefined;
              let modal = new ModalComponent(
                TranslationService.instance.translate('requests-modal-new-request-title'),
                new CreateRequestComponent(
                  this.getSelectedCompanyValue(),
                  this.getSelectedInvestmentAreaValue(),
                  references,
                  isSegmented
                )
              );
              modal.onClosed.listen(() => {
                if (modal.result != null) {
                  let params = this._createEmptyRequestModel(
                    budgetId,
                    modal.result.reference,
                    modal.result.requestName,
                    parseInt(modal.result.requestType),
                    year,
                    parseInt(modal.result.requestTypology),
                  );
                  Router.inst.navigate('requests/create', true, { data: params });
                }
              });
            } else {
              $('#main-container-loader').addClass('hidden');
              this.toastService.error(
                'Error',
                TranslationService.instance.translate('req-no-works-for-selected-budget')
              );
              console.error('No works for selected budget');
            }
          });
        }
      })
      .catch(error => {
        $('#main-container-loader').addClass('hidden');
        this.toastService.exception(error.title);
        console.error(error);
      });
  }

  enableEurosView() {
    $('.company-currency').removeClass('active');
    $('.euro-selector').addClass('active');
    this.localCurrency = false;
    this._rebuildTables();
  }
  enableLocalCurrencyView() {
    $('.company-currency').addClass('active');
    $('.euro-selector').removeClass('active');
    this.localCurrency = true;
    this._rebuildTables();
  }
  switchUnit(unit) {
    $('.unit').removeClass('active');
    switch (unit) {
      case UnitsEnums.Unit:
        $('.unit-units').addClass('active');
        this.unit = UnitsEnums.Unit;
        break;
      case UnitsEnums.Thousand:
        $('.unit-thousands').addClass('active');
        this.unit = UnitsEnums.Thousand;
        break;
      case UnitsEnums.Millions:
        $('.unit-millions').addClass('active');
        this.unit = UnitsEnums.Millions;
        break;
    }
    this._rebuildTables();
  }

  myPendingRequest() {
    this.sessionStorage.setItem(SessionKeys.RequestLastTab, 1);

    if (!$('#my-pending-requests').hasClass('active')) {
      $('.header-container').attr('data-active-tab', 'pending-requests');

      $('.nav-item').each((i, e) => {
        if (e.classList.contains('active')) {
          e.classList.remove('active');
        }
      });
      $('#main-container-pending-requests').removeClass('not-select');
      $('#main-container-budget-requests').addClass('not-select');
      $('#main-container-balance').addClass('not-select');
      $('#my-pending-requests').addClass('active');
    }
  }

  budgetRequests() {
    this.sessionStorage.setItem(SessionKeys.RequestLastTab, 2);

    if (!$('#work-budget-requests').hasClass('active')) {
      $('.header-container').attr('data-active-tab', 'requests');

      $('.nav-item').each((i, e) => {
        if (e.classList.contains('active')) {
          e.classList.remove('active');
        }
      });
      $('#main-container-pending-requests').addClass('not-select');
      $('#main-container-budget-requests').removeClass('not-select');
      $('#main-container-balance').addClass('not-select');
      $('#work-budget-requests').addClass('active');

      if (!this.loadingFromSession) {
        this.loadBudgetRequests();
      }
    }
  }
  balance() {
    this.sessionStorage.setItem(SessionKeys.RequestLastTab, 3);

    if (!$('#balance').hasClass('active')) {
      $('.header-container').attr('data-active-tab', 'balance');

      $('.nav-item').each((i, e) => {
        if (e.classList.contains('active')) {
          e.classList.remove('active');
        }
      });
      $('#main-container-pending-requests').addClass('not-select');
      $('#main-container-budget-requests').addClass('not-select');
      $('#main-container-balance').removeClass('not-select');
      $('#balance').addClass('active');

      if (!this.loadingFromSession) {
        this.loadBalance();
      }
    }
  }

  _setDefaultTab() {
    let defaultPageIndex = this.sessionStorage.getItem(SessionKeys.RequestLastTab) || '0';
    //this.myPendingRequest();
    switch (parseInt(defaultPageIndex)) {
      case 2:
        this.budgetRequests();
        break;
      case 3:
        this.balance();
        break;
      default:
        this.myPendingRequest();
        break;
    }
  }
  _reloadTables() {
    if ($('#work-budget-requests').hasClass('active')) {
      this.loadBudgetRequests();
    }

    if ($('#balance').hasClass('active')) {
      this.loadBalance();
    }
  }
  _rebuildTables() {
    //MyPending tables no need to rebuild because it's only loaded once

    if ($('#work-budget-requests').hasClass('active')) this._buildBudgetRequestsTable();

    if ($('#balance').hasClass('active')) this._buildBalanceTable();
  }

  _hideMyPendingRequests() {
    $('#requests-tabs').addClass('no-pending-requests');
    this.budgetRequests();
  }

  loadMyRequests() {
    return this.httpService
      .get(
        `${this.httpService.config.loadMyRequests}/${this.authenticationService.getAuthenticatedUser().login
        }`
      )
      .then(response => {
        if (response !== null) {
          this._myPendingRequests = response;

          if (this._myPendingRequests.length == 0) {
            this._hideMyPendingRequests();
            return;
          }
        }
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }

  _buildMyRequestEmpty() {
    return `<div class="request all">
    <span>${TranslationService.instance.translate('requests-empty')}</span>
  </div>`;
  }

  _buildMyRequest(data) {
    if (this.device == 'desktop') {
      return this._buildMyRequestsTable(data);
    } else if (this.device == 'mobile') {
      return this._buildMyRequestCard(data);
    }
  }

  openClick() {
    this.localStorageService.setItem('lastPageRequest', "requests/");
  }

  _buildMyRequestsTable(data) {
    let txtState = '';

    if (data.esRevision) {
      txtState = TranslationService.instance.translate('review');
    } else {
      txtState = TranslationService.instance.translate(RequestStatusEnum[data.idEstado]);
    }
    let circleState = RequestFlowStatusClassesEnum[data.idEstado];

    return `<div class="request">
              <div class="data left open">
                <a href="/#requests/${data.idSolicitud}" onclick="${this.openClick()}"><span class="icon-abrir" title="${TranslationService.instance.translate('common-table-open')}"></span></a>
              </div>
              <div class="data left reference">${data.referencia}</div>
              <div class="data left date">${DatePicker.toLocaleDateString(
      DatePicker.parseDate(data.fecha)
    )}</div>
              <div class="data left investmentArea">${data.areaInversion}</div>
              <div class="data left requestTitle">${data.nombreSolicitud}</div>
              <div class="data left type">${TranslationService.instance.translate(
      RequestTypeEnumTranslation[data.idTipo]
    )}</div>
              <div class="data left planArea">
              ${data.noEnPlan === true && data.enPlan === false
        ? '<div class="plan no">' +
        TranslationService.instance.translate(InvestmentTypeEnum[2]) +
        '</div>'
        : ''
      }
              ${data.enPlan === true
        ? '<div class="plan">' +
        TranslationService.instance.translate(InvestmentTypeEnum[1]) +
        '</div>'
        : ''
      }
              ${data.excedido === true
        ? '<div class="plan exceded">' +
        TranslationService.instance.translate('exceded') +
        '</div>'
        : ''
      }
              </div>
              <div class="data right amount">
              <input type="text" disabled class="budget-input decimal" value="${InputNumeric.formatWithoutComponent(
        data.importe
      )}"/>
              </div>
              <div class="data left requestedBy">${data.solicitante}</div>
              <div class="data left status">
                <div class="state">
                  <div class="circle-state ${circleState}"></div>
                  <div class="txt">${txtState}</div>
                </div>
              </div>
            </div>
            `;
  }

  _buildMyRequestCard(data) {
    return `<div class="card">
              <div class="card-row">
                <div class="card-row-element all">
                  <span class="title with-link">${data.nombreSolicitud}</span>
                  <a href="/#requests/${data.idSolicitud}" onclick="${this.openClick()}" class="icon-abrir" title="${TranslationService.instance.translate('common-table-open')}"></a>
                </div>
              </div>

              <div class="card-row">
                <div class="card-row-element half">
                  <div class="label">${TranslationService.instance.translate(
      'common-reference'
    )}</div>
                  <div class="description">
                    ${data.referencia}
                  </div>
                </div>
    
                <div class="card-row-element half">
                  <div class="label">${TranslationService.instance.translate('common-date')}</div>
                  <div class="description">
                    ${DatePicker.toLocaleDateString(DatePicker.parseDate(data.fecha))}
                  </div>
                </div>
              </div>

              <div class="card-row">
                <div class="card-row-element half">
                  <div class="label">${TranslationService.instance.translate(
      'common-invtm-area'
    )}</div>
                  <div class="description">
                    ${data.areaInversion}
                  </div>
                </div>
              </div>

              <div class="card-row">
                <div class="card-row-element half">
                  <div class="label">${TranslationService.instance.translate('common-type')}</div>
                  <div class="description">
                    ${data.tipoSolicitud}
                  </div>
                </div>

                <div class="card-row-element half">
                  <div class="label">${TranslationService.instance.translate(
      'requests-in-plan'
    )}</div>
                  <div class="description">
                  ${data.noEnPlan === true && data.enPlan === false
        ? '<div class="plan no">' +
        TranslationService.instance.translate(InvestmentTypeEnum[2]) +
        '</div>'
        : ''
      }
                  ${data.enPlan === true
        ? '<div class="plan">' +
        TranslationService.instance.translate(InvestmentTypeEnum[1]) +
        '</div>'
        : ''
      }
                  ${data.excedido === true
        ? '<div class="plan exceded">' +
        TranslationService.instance.translate('exceded') +
        '</div>'
        : ''
      }
                  </div>
                </div>
              </div>

            <div class="card-row">
              <div class="card-row-element half">
                <div class="label">${TranslationService.instance.translate(
        'bd-modal-disbur-dates-amount'
      )}</div>
                <div class="description">
                  <input type="text" disabled class="budget-input decimal" value="${InputNumeric.formatWithoutComponent(
        data.importeLocal
      )}"/>
                </div>
              </div>

              <div class="card-row-element half">
                <div class="label">${TranslationService.instance.translate(
        'petitions-table-applicant'
      )}</div>
                <div class="description">
                  ${data.solicitante}
                </div>
              </div>
            </div>

            <div class="card-row">
              <div class="card-row-element all">
                <div class="label">${TranslationService.instance.translate('common-state')}</div>
                <div class="description">
                  <div class="circle-state ${RequestStatusClassesEnum[data.idEstado]}"></div>
                  <span>${TranslationService.instance.translate(
        RequestStatusEnum[data.idEstado]
      )}</span>
                </div>
              </div>
            </div>
          </div>
      `;
  }

  async getBudgetId() {
    var body = {};
    body.areaTrabajo = {
      idEmpresa: this.getSelectedCompanyValue(),
      idAreaInversion: this.getSelectedInvestmentAreaValue(),
      ejercicio: this.getSelectedYearValue()
    };

    body.loginUsuario = this.authenticationService.getAuthenticatedUser().login;

    return this.httpService
      .post(this.httpService.config.budgetIdByWorkArea, body)
      .then(response => {
        this._budgetId = response;
      })
      .catch(error => {
        $('#main-container-loader').addClass('hidden');
        this.toastService.exception(error.title);
        console.error(error);
      });
  }

  async getSegmented() {
    return this.httpService
      .get(
        this.httpService.config.loadSegmented +
        '?loginUsuario=' + this.authenticationService.getAuthenticatedUser().login +
        '&idEmpresa=' + this.getSelectedCompanyValue() +
        '&idAreaInversion=' + this.getSelectedInvestmentAreaValue()
      )
      .then(response => {
        this._isSegmented = response;
      })
      .catch(error => {
        $('#main-container-loader').addClass('hidden');

        this.toastService.error(
          'Error',
          TranslationService.instance.translate('common-error-reference')
        );

        console.error(error);
        this._isSegmented = undefined;
      });

  }

  async getReferenceId() {
    var body = {};
    body.areaTrabajo = {
      idEmpresa: this.getSelectedCompanyValue(),
      idAreaInversion: this.getSelectedInvestmentAreaValue(),
      ejercicio: this.getSelectedYearValue()
    };

    body.usuarioSolicitante = this.authenticationService.getAuthenticatedUser().login;

    return this.httpService
      .post(this.httpService.config.loadRequestsReference, body)
      .then(response => {
        this._references = response;
      })
      .catch(error => {
        $('#main-container-loader').addClass('hidden');

        this.toastService.error(
          'Error',
          TranslationService.instance.translate('common-error-reference')
        );

        console.error(error);
        this._references = undefined;
      });
  }

  async getBudgetWorks(budgetId) {
    return this.httpService
      .get(
        `${this.httpService.config.loadBudgetWorks}/${budgetId}/${this.authenticationService.getAuthenticatedUser().login
        }`
      )
      .then(response => {
        this._works = response;
      })
      .catch(error => {
        $('#main-container-loader').addClass('hidden');
        this.toastService.error(error.title, error.message);
        console.error(error);
      });
  }

  loadBudgetRequests() {
    const exercise = this.getSelectedYearValue();
    const companyId = this.getSelectedCompanyValue();
    const investmentAreaId = this.getSelectedInvestmentAreaValue();
    
    if (exercise == null || companyId == null || investmentAreaId == null) {
      var htmlTemplate = `<div class="request all">
              <span>${TranslationService.instance.translate('requests-complete-filters')}</span>
            </div>`;
      $('#budgets-requests-table-content').html(htmlTemplate);
      $('#budgets-requests-cards-content').html(`<div class="empty">
      <span class="msg">${TranslationService.instance.translate('requests-complete-filters')}</span>
    </div>`);
      //$('.buttons-container .filter-required').addClass('disabled'); TODO REMOVE comment
    } else {
      this.fillBreadcrumb();

      $('#main-container-loader').removeClass('hidden');
      $('.buttons-container .filter-required').removeClass('disabled');

      var body = {
        idEmpresa: companyId,
        idAreaInversion: investmentAreaId,
        ejercicio: exercise
      };
      this.httpService
        .post(this.httpService.config.loadBudgetRequests, body)
        .then(response => {
          this._budgetRequests = response;

          if (this.device == 'desktop') {
            this._buildBudgetRequestsTable();
          } else if (this.device == 'mobile') {
            this._buildBudgetRequestsCardList();
          }

          $('#main-container-loader').addClass('hidden');
        })
        .catch(error => {
          $('#main-container-loader').addClass('hidden');
          this.toastService.exception(error.title);
          console.error(error);
        });
    }
  }
  _buildBudgetRequestsTable() {
    let htmlTemplate = '';
    this._budgetRequests.forEach(request => {
      let imprt = this.localCurrency == true ? request.importeLocal : request.importe;
      switch (this.unit) {
        case UnitsEnums.Thousand:
          imprt = imprt / 1000;
          break;
        case UnitsEnums.Millions:
          imprt = imprt / 1000000;
          break;
      }
      let txtState = '';

      if (request.esRevision) {
        txtState = TranslationService.instance.translate('review');
      } else {
        txtState = TranslationService.instance.translate(RequestStatusEnum[request.idEstado]);
      }

      let circleState = RequestFlowStatusClassesEnum[request.idEstado];
      htmlTemplate += `<div class="request">
      <div class="data left open">
      <a href="/#requests/${request.idSolicitud}" onclick="${this.openClick()}" ><span class="icon-abrir" title="${TranslationService.instance.translate('common-table-open')}"></span></a>
      </div>
      <div class="data left reference">${request.referencia}</div>
      <div class="data left date">${DatePicker.toLocaleDateString(
        DatePicker.parseDate(request.fecha)
      )}</div>
      <div class="data left investmentArea">${request.areaInversion}</div>
      <div class="data left requestTitle">${request.nombreSolicitud}</div>
      <div class="data left type">${TranslationService.instance.translate(
        RequestTypeEnumTranslation[request.idTipo]
      )}</div>
      <div class="data left planArea">
      ${request.noEnPlan === true && request.enPlan === false
          ? '<div class="plan no">' +
          TranslationService.instance.translate(InvestmentTypeEnum[2]) +
          '</div>'
          : ''
        }
      ${request.enPlan === true
          ? '<div class="plan">' +
          TranslationService.instance.translate(InvestmentTypeEnum[1]) +
          '</div>'
          : ''
        }
      ${request.excedido === true
          ? '<div class="plan exceded">' +
          TranslationService.instance.translate('exceded') +
          '</div>'
          : ''
        }
      </div>
      <div class="data right amount">
      <input type="text" disabled class="budget-input decimal"" value="${imprt}"/>
      </div>
      <div class="data left requestedBy">${request.solicitante}</div>
      <div class="data left pending">${request.pendiente != null ? request.pendiente : ''}</div>
      <div class="data left status">
        <div class="state">
          <div class="circle-state ${circleState}"></div>
          <div class="txt">${txtState}</div>
        </div>
      </div>
    </div>`;
    });
    if (htmlTemplate.length == 0) {
      htmlTemplate = `<div class="request all">
              <span>${TranslationService.instance.translate('requests-empty')}</span>
            </div>`;
    }
    $('#budgets-requests-table-content').html(htmlTemplate);
    let contents = $('#budgets-requests-table-content').find('.request');
    contents.find('.budget-input.decimal').each((i, e) => InputNumeric.createDecimal(e));
  }

  _buildBudgetRequestsCardList() {
    let options = new ListOptions();
    options.listSelector = '#budgets-requests-cards-content';
    options.searchSelector = '.search-input';
    options.includeFieldsSearch = ['nombreSolicitud'];
    options.onRenderRow = request => {
      let imprt = this.localCurrency == true ? request.importeLocal : request.importe;
      switch (this.unit) {
        case UnitsEnums.Thousand:
          imprt = imprt / 1000;
          break;
        case UnitsEnums.Millions:
          imprt = imprt / 1000000;
          break;
      }

      return `<div class="card">
      <div class="card-row">
        <div class="card-row-element all">
          <span class="title with-link">${request.nombreSolicitud}</span>
          <a href="/#requests/${request.idSolicitud}" onclick="${this.openClick()}" class="icon-abrir" title="${TranslationService.instance.translate('common-table-open')}"></a>
        </div>
      </div>

      <div class="card-row">
        <div class="card-row-element half">
          <div class="label">${TranslationService.instance.translate('common-reference')}</div>
          <div class="description">
            ${request.referencia}
          </div>
        </div>

        <div class="card-row-element half">
          <div class="label">${TranslationService.instance.translate('common-date')}</div>
          <div class="description">
            ${DatePicker.toLocaleDateString(DatePicker.parseDate(request.fecha))}
          </div>
        </div>
      </div>

      <div class="card-row">
        <div class="card-row-element half">
          <div class="label">${TranslationService.instance.translate('common-type')}</div>
          <div class="description">
            ${TranslationService.instance.translate(RequestTypeEnumTranslation[request.idTipo])}
          </div>
        </div>

        <div class="card-row-element half">
          <div class="label">${TranslationService.instance.translate('requests-in-plan')}</div>
          <div class="description">
          ${request.noEnPlan === true && request.enPlan === false
          ? '<div class="plan no">' +
          TranslationService.instance.translate(InvestmentTypeEnum[2]) +
          '</div>'
          : ''
        }
          ${request.enPlan === true
          ? '<div class="plan">' +
          TranslationService.instance.translate(InvestmentTypeEnum[1]) +
          '</div>'
          : ''
        }
          ${request.excedido === true
          ? '<div class="plan exceded">' +
          TranslationService.instance.translate('exceded') +
          '</div>'
          : ''
        }
          </div>
        </div>
      </div>

      <div class="card-row">
        <div class="card-row-element half">
          <div class="label">${TranslationService.instance.translate(
          'bd-modal-disbur-dates-amount'
        )}</div>
          <div class="description">
            <input type="text" disabled class="budget-input decimal"" value="${InputNumeric.formatWithoutComponent(
          imprt
        )}"/>
          </div>
        </div>
      </div>

      <div class="card-row">
        <div class="card-row-element half">
          <div class="label">${TranslationService.instance.translate(
          'petitions-table-applicant'
        )}</div>
          <div class="description">
            ${request.solicitante}
          </div>
        </div>

        <div class="card-row-element half">
          <div class="label">${TranslationService.instance.translate('requests-pending-of')}</div>
          <div class="description">
            ${request.pendiente != null ? request.pendiente : ''}
          </div>
        </div>
      </div>

      <div class="card-row">
        <div class="card-row-element half">
          <div class="label">${TranslationService.instance.translate(
          'budgets-table-state-budget'
        )}</div>
          <div class="description">
            <div class="circle-state ${RequestStatusClassesEnum[request.idEstado]}"></div>
            <span>${TranslationService.instance.translate(
          RequestStatusEnum[request.idEstado]
        )}</span>
          </div>
        </div>
      </div>
    </div>`;
    };

    this._budgetCards = new List(options);
    this._budgetCards.updateData(this._budgetRequests);
    if (this._budgetRequests.length == 0) {
      $('#budgets-requests-cards-content').html(`<div class="empty">
      <span class="msg">${TranslationService.instance.translate('requests-empty')}</span>
    </div>`);
    }
  }

  loadBalance() {
    const exercise = this.getSelectedYearValue();
    const companyId = this.getSelectedCompanyValue();
    const investmentAreaId = this.getSelectedInvestmentAreaValue();    

    if (exercise == null || companyId == null || investmentAreaId == null) {
      var htmlTemplate = `<div class="table-row all">
              <span>${TranslationService.instance.translate('balance-complete-filters')}</span>
            </div>`;
      $('#balance-table-content').html(htmlTemplate);
      $('#balance-cards-container').html(`<div class="empty">
      <span class="msg">${TranslationService.instance.translate('balance-complete-filters')}</span>
      </div>`);
    } else {
      this.fillBreadcrumb();
      $('#main-container-loader').removeClass('hidden');

      var body = {
        usuarioSolicitante: this.authenticationService.getAuthenticatedUser().login,
        idioma: this.authenticationService.getAuthenticatedUser().idIdioma.substr(0, 2),
        areaTrabajo: {
          idEmpresa: companyId,
          idAreaInversion: investmentAreaId,
          ejercicio: exercise
        }
      };
      this.httpService
        .post(this.httpService.config.loadBalance, body)
        .then(response => {
          this._balance = this.importJson(response);
        })
        .catch(error => {
          this.toastService.exception(error.title);
          console.error(error);

          this._balance = null;
        })
        .finally(() => {
          $('#main-container-loader').addClass('hidden');

          if (this.device == 'desktop') {
            this._buildBalanceTable();
          } else if (this.device == 'mobile') {
            this._buildBalanceCardList();
          }
        });
    }
  }
  _buildBalanceTable() {
    var html = '';
    if (this._balance != null) {
      let collapseIcon = '';
      if (this._balance.lineTypeId == 2 && !this._balance.isCollapsed) {
        collapseIcon = '<span class="icon icon-plegar" data-id="' + this._balance.id + '"></span>';
      } else if (this._balance.lineTypeId == 2 && this._balance.isCollapsed) {
        collapseIcon =
          '<span class="icon icon-desplegar" data-id="' + this._balance.id + '"></span>';
      }
      html += `<div class="table-row ${this._balance.lineTypeId == 1 || this._balance.lineTypeId == 2 ? 'bolder' : ''
        }">
               <div class="cell border-right name ${this._balance.level != null ? 'lvl-' + this._balance.level : ''
        }">
                <div class="wrapper">
                  ${collapseIcon}
                  <span>${this._balance.description}</span>
                </div>
               </div>
               <div class="cell budget">
                  <input type="text" disabled
                  class="balance-input decimal ${this._balance.lineTypeId == 1 || this._balance.lineTypeId == 2 ? 'bolder' : ''
        }"" value="${this._balance.getBudget(this.localCurrency, this.unit)}"/>
               </div>
               <div class="cell approvedRequests">
                  <input type="text" disabled
                  class="balance-input decimal ${this._balance.lineTypeId == 1 || this._balance.lineTypeId == 2 ? 'bolder' : ''
        }"" value="${this._balance.getApprovedRequests(this.localCurrency, this.unit)}"/>
               </div>
               <div class="cell pendingRequests">
                  <input type="text" disabled
                  class="balance-input decimal ${this._balance.lineTypeId == 1 || this._balance.lineTypeId == 2 ? 'bolder' : ''
        }"" value="${this._balance.getPendingRequests(this.localCurrency, this.unit)}"/>
               </div>
               <div class="cell balance">
                  <input type="text" disabled
                  class="balance-input decimal ${this._balance.lineTypeId == 1 || this._balance.lineTypeId == 2 ? 'bolder' : ''
        }"" value="${this._balance.getBalance(this.localCurrency, this.unit)}"/>
               </div>
               <div class="cell predictedBalance">
                  <input type="text" disabled
                  class="balance-input decimal ${this._balance.lineTypeId == 1 || this._balance.lineTypeId == 2 ? 'bolder' : ''
        }"" value="${this._balance.getPredictedBalance(this.localCurrency, this.unit)}"/>
               </div>
              </div>`;
      html = this._buildChildBalanceLinesHtml(this._balance.childLines, html);
    } else {
      html = `<div class="table-row all">
              <span>${TranslationService.instance.translate('requests-empty-balance')}</span>
            </div>`;
    }
    $('#balance-table-content').html(html);
    let contents = $('#balance-table-content').find('.table-row');
    contents.find('.balance-input.decimal').each((i, e) => InputNumeric.createDecimal(e));
    $('#main-container-balance')
      .find('.icon-plegar')
      .click(e => this._toggleCollapsed(e));
    $('#main-container-balance')
      .find('.icon-desplegar')
      .click(e => this._toggleCollapsed(e));
  }

  _buildBalanceCardList() {
    let navigationCardOptions = new NavigationCardOptions();
    navigationCardOptions.data = this._balance;
    navigationCardOptions.navigationProperty = 'childLines';
    navigationCardOptions.selector = '.requests-container #balance-cards-container';
    navigationCardOptions.cardTemplate = CardBalanceBudget.template;
    navigationCardOptions.componentTemplate = navigationCardOptions.componentTemplate = `<div class="navigation-card">
    <div class="menu">
      <div class="navigation-up"></div>
      <div id="btn-visualization-options" class="button primary-button with-icon no-text">
        <span class="icon icon-opciones-visualizacion"></span>
      </div>
    </div>
    <div class="card-container"></div>
  </div>`;
    navigationCardOptions.onRenderCard = (card, data, breadcrumbs) =>
      this._onRenderCard(card, data, breadcrumbs);
    navigationCardOptions.onRenderBreadcrumbs = (container, breadcrumbs) =>
      this._onRenderBreadcrumbs(container, breadcrumbs);
    navigationCardOptions.comparerProperty = 'id';
    navigationCardOptions.sort = (a, b) => {
      return a.order - b.order;
    };

    this._navigationCard = new NavigationCardComponent(navigationCardOptions);
    this._navigationCard.visualizationOptions.listen(() => this.visualizationOptions()); //Open the modal to select the currency and the unit to see the data
    this._navigationCard.init();

    if (this._balance == null) {
      $('#balance-cards-container').html(`<div class="empty">
      <span class="msg">${TranslationService.instance.translate('requests-empty-balance')}</span>
    </div>`);
    }
  }

  /**
   * Open modal to select visualization options and rebuild the cards on confirm close.
   */
  visualizationOptions() {
    let options = new VisualizationOptionsParams();
    options.selectedUnit = this.unit;
    options.localCurrency = 'Local'; //TODO: review
    options.isLocal = this.localCurrency;
    let modal = new ModalComponent(
      TranslationService.instance.translate('visualization-options-modal-title'),
      new VisualizationOptionsComponent(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this.localCurrency = modal.result.isLocal;
      this.unit = modal.result.selectedUnit;
      this._cardBalanceOptions.localCurrency = modal.result.isLocal;
      this._cardBalanceOptions.unit = modal.result.selectedUnit;
      if (this._navigationCard) this._navigationCard.draw();
      this._buildBudgetRequestsCardList();
    });
  }

  _onRenderBreadcrumbs(container, breadcrumbs) {
    let text = breadcrumbs.map(x => x.description).join(' / ');

    container.innerHTML = `<span class="icon-chevron"></span><span class="text">${text}</span>`;
  }

  _onRenderCard(card, data, breadcrumbs) {
    let cardLineBudget = new CardBalanceBudget(this._cardBalanceOptions);
    cardLineBudget.render(card, data, breadcrumbs);
  }

  _buildChildBalanceLinesHtml(childLines, html) {
    childLines.forEach(childLine => {
      let collapseIcon = '';
      if (childLine.lineTypeId == 2 && !childLine.isCollapsed) {
        collapseIcon = '<span class="icon icon-plegar" data-id="' + childLine.id + '"></span>';
      } else if (childLine.lineTypeId == 2 && childLine.isCollapsed) {
        collapseIcon = '<span class="icon icon-desplegar" data-id="' + childLine.id + '"></span>';
      }
      html += `<div class="table-row ${childLine.lineTypeId == 1 || childLine.lineTypeId == 2 ? 'bolder' : ''
        }">
               <div class="cell name border-right ${childLine.level != null ? 'lvl-' + childLine.level : ''
        }">
                <div class="wrapper">
                  ${collapseIcon}
                  <span>${childLine.description}</span>
                </div>
               </div>
               <div class="cell budget">
                  <input type="text" disabled
                  class="balance-input decimal ${childLine.lineTypeId == 1 || childLine.lineTypeId == 2 ? 'bolder' : ''
        }"" value="${childLine.getBudget(this.localCurrency, this.unit)}"/>
               </div>
               <div class="cell approvedRequests">
               <input type="text" disabled
                  class="balance-input decimal ${childLine.lineTypeId == 1 || childLine.lineTypeId == 2 ? 'bolder' : ''
        }"" value="${childLine.getApprovedRequests(this.localCurrency, this.unit)}"/>
               </div>
               <div class="cell pendingRequests">
               <input type="text" disabled
                  class="balance-input decimal ${childLine.lineTypeId == 1 || childLine.lineTypeId == 2 ? 'bolder' : ''
        }"" value="${childLine.getPendingRequests(this.localCurrency, this.unit)}"/>
               </div>
               <div class="cell balance">
               <input type="text" disabled
                  class="balance-input decimal ${childLine.lineTypeId == 1 || childLine.lineTypeId == 2 ? 'bolder' : ''
        }"" value="${childLine.getBalance(this.localCurrency, this.unit)}"/>
               </div>
               <div class="cell predictedBalance">
               <input type="text" disabled
                  class="balance-input decimal ${childLine.lineTypeId == 1 || childLine.lineTypeId == 2 ? 'bolder' : ''
        }"" value="${childLine.getPredictedBalance(this.localCurrency, this.unit)}"/>
               </div>
              </div>`;
      if (childLine.childLines.length > 0 && !childLine.isCollapsed) {
        html = this._buildChildBalanceLinesHtml(childLine.childLines, html);
      }
    });
    return html;
  }

  _buildChildBalanceCardsHtml(parentNode, childLines, html) {
    let htmlTemplate = '';
    childLines.forEach(childLine => {
      htmlTemplate += html;
    });
    html = htmlTemplate;

    $('#balance-cards-content').html(html);
  }
  _toggleCollapsed(event) {
    let line = this._balance.childLines.filter(
      cl => cl.id == parseInt(event.target.getAttribute('data-id'))
    )[0];
    line.isCollapsed = !line.isCollapsed;
    this._rebuildTables();
  }

  importJson(apiBalanceResponse) {
    let apiRootLine = apiBalanceResponse.lineaRaiz;
    let rootLine = new BalanceLine(
      apiRootLine.idLineaPresupuesto,
      apiRootLine.infoLineaBalance.descripcion,
      apiRootLine.importeBalance.presupuesto,
      apiRootLine.importeBalance.solicitudesAprobadas,
      apiRootLine.importeBalance.solicitudesPendientes,
      apiRootLine.importeBalance.balance,
      apiRootLine.importeBalance.balancePrevisto,
      apiRootLine.importeBalance.presupuestoLocal,
      apiRootLine.importeBalance.solicitudesAprobadasLocal,
      apiRootLine.importeBalance.solicitudesPendientesLocal,
      apiRootLine.importeBalance.balanceLocal,
      apiRootLine.importeBalance.balancePrevistoLocal,
      apiRootLine.infoLineaBalance.idTipoLineaPresupuesto
    );
    rootLine = this.buildChildrenLines(apiRootLine.lineasBalance, rootLine, 1);
    return rootLine;
  }
  buildChildrenLines(apiChildrenLines, line, level) {
    apiChildrenLines.forEach(apiLine => {
      let childLine = new BalanceLine(
        apiLine.idLineaPresupuesto,
        apiLine.infoLineaBalance.descripcion,
        apiLine.importeBalance.presupuesto,
        apiLine.importeBalance.solicitudesAprobadas,
        apiLine.importeBalance.solicitudesPendientes,
        apiLine.importeBalance.balance,
        apiLine.importeBalance.balancePrevisto,
        apiLine.importeBalance.presupuestoLocal,
        apiLine.importeBalance.solicitudesAprobadasLocal,
        apiLine.importeBalance.solicitudesPendientesLocal,
        apiLine.importeBalance.balanceLocal,
        apiLine.importeBalance.balancePrevistoLocal,
        apiLine.infoLineaBalance.idTipoLineaPresupuesto,
        level
      );
      if (apiLine.lineasBalance.length > 0) {
        childLine = this.buildChildrenLines(apiLine.lineasBalance, childLine, level + 1);
      }
      line.childLines.push(childLine);
    });
    return line;
  }

  _createEmptyRequestModel(budgetId, reference, name, requestTypeId, year, requestTypologyId) {
    let request = {
      idPresupuesto: budgetId,
      idSolicitud: null,
      idFlujoAprobacion: null,
      idEstado: RequestStatusNameEnum.Draft,
      ejercicio: year,
      referencia: reference,
      fecha: null,
      nombre: name,
      idTipo: requestTypeId,
      idTipologiaObra: requestTypologyId,
      enPlan: false,
      noEnPlan: false,
      excedido: false,
      importe: null,
      importeLocal: null,
      comentarios: null,
      idMoneda: null,
      detalleSolicitud: {
        idDetalleSolicitud: null,
        detalleInversion: null,
        detalleDesinversion: null,
        idSolicitudNavigationAdjuntoSolicitud: [],
        flujoCaja: [],
        costeAdicional: [],
        concepto: [],
        adjunto: [],
        idGrupo: null,
        grupo: null,
        porcentajeUte: null,
        impuestosLocal: null,
        impuestos: null,
        porcImpuestos: null,
        plazoEntrega: null,
        posicionEntrega: null,
        formaPago: null,
        observaciones: null
      },
      monedasSolicitud: []
    };
    if (requestTypeId == RequestTypeEnum.Investment) {
      request.detalleSolicitud.detalleInversion = {
        idDetalleSolicitudInversion: null,
        proveedor: null,
        opcionRecompra: false,
        porcentajeRecompra: null
      };
    } else {
      request.detalleSolicitud.detalleDesinversion = {
        idDetalleSolicitudDesinversion: null,
        destinatarioVenta: null,
        ofertaRecibida: []
      };
    }
    return request;
  }

  _toggleMenu() {
    $('.sidenav').toggleClass('menuComplete');
  }

  setSelectedYear(val) {
    this.selectedYear = val;
    this.sessionStorage.setItem(SessionKeys.RequestYear, val.id);
  }

  setSelectedCompany(val) {
    this.selectedCompany = val;
    this.sessionStorage.setItem(SessionKeys.RequestCompany, val.idEmpresa);
  }

  setSelectedInvestmentArea(val) {
    this.selectedInvestmentArea = val;
    this.sessionStorage.setItem(SessionKeys.RequestInvestmentArea, val.idAreaInversion);
  }

  getSelectedYearLabel() {
    return this.selectedYear ? this.selectedYear.label : null;
  }

  getSelectedCompanyLabel() {
    return this.selectedCompany ? this.selectedCompany.nombre : null;
  }

  getSelectedInvestmentAreaLabel() {
    return this.selectedInvestmentArea ? this.selectedInvestmentArea.nombre : null;
  }

  getSelectedYearValue() {
    return this.selectedYear ? this.selectedYear.id : null;
  }

  getSelectedCompanyValue() {
    return this.selectedCompany ? this.selectedCompany.idEmpresa : null;
  }

  getSelectedInvestmentAreaValue() {
    return this.selectedInvestmentArea ? this.selectedInvestmentArea.idAreaInversion : null;
  }
}

class BalanceLine {
  constructor(
    id,
    description,
    budget,
    approvedRequests,
    pendingRequests,
    balance,
    predictedBalance,
    localBudget,
    localApprovedRequests,
    localPendingRequests,
    localBalance,
    localPredictedBalance,
    lineTypeId = 4,
    level = null,
    isCollapsed = false
  ) {
    this.id = id;
    this.description = description;
    this.budget = budget;
    this.approvedRequests = approvedRequests;
    this.pendingRequests = pendingRequests;
    this.balance = balance;
    this.predictedBalance = predictedBalance;
    this.lineTypeId = lineTypeId;
    this.level = level;
    this.childLines = [];
    this.isCollapsed = isCollapsed;
    this.localBudget = localBudget;
    this.localApprovedRequests = localApprovedRequests;
    this.localPendingRequests = localPendingRequests;
    this.localBalance = localBalance;
    this.localPredictedBalance = localPredictedBalance;
  }

  getBudget(isLocal, unit) {
    if ((isLocal == true && this.localBudget == null) || (isLocal == false && this.budget == null))
      return '';

    switch (unit) {
      case UnitsEnums.Unit:
        return isLocal == true ? this.localBudget : this.budget;
      case UnitsEnums.Thousand:
        return isLocal == true ? this.localBudget / 1000 : this.budget / 1000;
      case UnitsEnums.Millions:
        return isLocal == true ? this.localBudget / 1000000 : this.budget / 1000000;
    }
  }

  getApprovedRequests(isLocal, unit) {
    if (
      (isLocal == true && this.localApprovedRequests == null) ||
      (isLocal == false && this.approvedRequests == null)
    )
      return '';

    switch (unit) {
      case UnitsEnums.Unit:
        return isLocal == true ? this.localApprovedRequests : this.approvedRequests;
      case UnitsEnums.Thousand:
        return isLocal == true ? this.localApprovedRequests / 1000 : this.approvedRequests / 1000;
      case UnitsEnums.Millions:
        return isLocal == true
          ? this.localApprovedRequests / 1000000
          : this.approvedRequests / 1000000;
    }
  }

  getPendingRequests(isLocal, unit) {
    if (
      (isLocal == true && this.localPendingRequests == null) ||
      (isLocal == false && this.pendingRequests == null)
    )
      return '';

    switch (unit) {
      case UnitsEnums.Unit:
        return isLocal == true ? this.localPendingRequests : this.pendingRequests;
      case UnitsEnums.Thousand:
        return isLocal == true ? this.localPendingRequests / 1000 : this.pendingRequests / 1000;
      case UnitsEnums.Millions:
        return isLocal == true
          ? this.localPendingRequests / 1000000
          : this.pendingRequests / 1000000;
    }
  }

  getBalance(isLocal, unit) {
    if (
      (isLocal == true && this.localBalance == null) ||
      (isLocal == false && this.balance == null)
    )
      return '';

    switch (unit) {
      case UnitsEnums.Unit:
        return isLocal == true ? this.localBalance : this.balance;
      case UnitsEnums.Thousand:
        return isLocal == true ? this.localBalance / 1000 : this.balance / 1000;
      case UnitsEnums.Millions:
        return isLocal == true ? this.localBalance / 1000000 : this.balance / 1000000;
    }
  }

  getPredictedBalance(isLocal, unit) {
    if (
      (isLocal == true && this.localPredictedBalance == null) ||
      (isLocal == false && this.predictedBalance == null)
    )
      return '';

    switch (unit) {
      case UnitsEnums.Unit:
        return isLocal == true ? this.localPredictedBalance : this.predictedBalance;
      case UnitsEnums.Thousand:
        return isLocal == true ? this.localPredictedBalance / 1000 : this.predictedBalance / 1000;
      case UnitsEnums.Millions:
        return isLocal == true
          ? this.localPredictedBalance / 1000000
          : this.predictedBalance / 1000000;
    }
  }

}
