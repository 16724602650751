import './edit-offer.scss';
var htmlContent = require('./edit-offer.html');
import { HttpService } from '../../core/http/http';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { ToastService } from '../../shared/services/toastService';

const renderMarkup = () => htmlContent;

export class EditOfferComponent extends BaseModalContent {
  constructor(options) {
    super(renderMarkup());
    this.toastService = new ToastService();
    this.httpService = new HttpService();
    this._options = options;
  }

  onOpened() {
    this.init();
  }

  init() {
    this.name = this.parentModal.container.find('#modal-offer-name');
    this.offer = this.parentModal.container.find('#modal-offer-offer');
    this.payment = this.parentModal.container.find('#modal-offer-payment');

    this.fillFields();
    this.parentModal.container.find('#btn-add-offer').click(() => this.save());
    this.parentModal.container.find('#btn-cancel').click(() => {
      this.value = false;
      this.parentModal.close();
    });
  }

  fillFields() {
    this.name.val(this._options ? this._options.nombre : '');
    this.offer.val(this._options ? this._options.valorLocal : '');
    this.payment.val(this._options ? this._options.formaPago : '');
  }

  save() {
    this.value = {
      nombre: this.name.val(),
      valor: Number(this.offer.val()),
      valorLocal: Number(this.offer.val()),
      formaPago: this.payment.val()
    };
    this.parentModal.close();
  }

  get result() {
    return this.value;
  }
}
