export let SessionKeys = {
  RequestYear: 'request-year',
  RequestCompany: 'request-company',
  RequestInvestmentArea: 'request-investment-area',
  RequestLastTab: 'request-last-tab',
  RequestQueryYear: 'request-query-year',
  RequestQueryCompany: 'request-query-company',
  RequestQueryInvestmentArea: 'request-query-investment-area',
  RequestQueryStatus: 'request-query-status',
  RequestQueryRequestType: 'request-query-request-type',
  RequestQueryInvestmentType: 'request-query-investment-type',
  BudgetQueryYear: 'budget-query-year',
  BudgetQueryCompany: 'budget-query-company',
  BudgetQueryInvestmentArea: 'budget-query-investment-area',
  BudgetQueryStatus: 'budget-query-status',
  BudgetYear: 'budget-year',
  BudgetCompany: 'budget-company',
  BudgetInvestmentArea: 'budget-investment-area',
  BudgetStatus: 'budget-status',
};
export let AppConfigKeys = {
  SupportEmail: 'support-email',
  ToastOpenTime: 'toast-open-time'
};
