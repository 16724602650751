import './configPhysicSign.scss';
var htmlContent = require('./configPhysicSign.html');
import { Component } from '../../shared/components/base-component';
import $ from 'jquery';
import { SessionStorageService } from '../../core/sessionStorage/sessionStorage';
import { ViewsLoader } from '../../core/views/views-loader';
import { Spinner } from '../../shared/components/spinner/spinner';
import { ConfigService } from '../../shared/services/config';

const renderMarkup = () => htmlContent;

export class ConfigPhysicSignPage extends Component {
  constructor() {
    super(renderMarkup());

    this.viewsLoader = new ViewsLoader();
    this.configService = ConfigService.getInstance();
    this.config = null;

    $(() => {
      this._init();
    });
  }

  _init() {
    let selectNav = document.querySelectorAll('.links-item');
    let i = 0;
    selectNav.forEach(e => {
      if (i == 6) {
        $(e).addClass('active');
        i++;
      } else {
        $(e).removeClass('active');
        i++;
      }
    });
    this.viewsLoader.loadView(new Spinner('big'), document.querySelector('#loader-container'));
    $('#loader-container').removeClass('hidden');

    this.physicalSignCheckbox = $('.physical-sign');

    this.physicalSignCheckbox.change(() => {
      $('#loader-container').removeClass('hidden');

      this.config.permitirFirmasFisicas = this.physicalSignCheckbox.is(':checked');
      this.config.usuarioActualizacion = this.configService._auth.getAuthenticatedUser().login;

      this.configService
        .saveGlobalConfig(this.config)
        .then(() => {
          $('#loader-container').addClass('hidden');
        })
        .catch(() => {
          $('#loader-container').addClass('hidden');
          this.physicalSignCheckbox.prop('checked', !this.physicalSignCheckbox.is(':checked'));
        });
    });

    this.configService
      .getGlobalConfig()
      .then(response => {
        this.config = response;
        this.physicalSignCheckbox.prop('checked', response.permitirFirmasFisicas);
        $('#loader-container').addClass('hidden');
      })
      .catch(() => {
        $('#loader-container').addClass('hidden');
      });
  }
}
