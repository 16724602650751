/**
 * Class to handle events
 */
export class Event {
  constructor() {
    this.lastInternalId = 0;
    this.listeners = [];
  }

  /**
   * Listen to the event. It will return the internal id of the listener.
   */
  listen(fn) {
    this.lastInternalId++;
    this.listeners.push({ id: this.lastInternalId, fn: fn });
    return this.lastInternalId;
  }

  /**
   * Listen an event but only will be fired once
   * @param {*} fn
   */
  once(fn) {
    let internalId = 0;
    let _this = this;
    let onceEvent = function(args) {
      _this.remove(internalId);
      fn(...(args == null ? [] : args));
    };

    internalId = this.listen(onceEvent);
    return internalId;
  }

  /**
   * Remove from the event. It can be removed using the internal id or the original function.
   */
  remove(fn) {
    var index;

    if (isNaN(fn)) {
      index = this.listeners.findIndex(x => x.fn === fn);
    } else {
      index = this.listeners.findIndex(x => x.id === fn);
    }

    if (index == -1) {
      throw 'Listener not found';
    }

    this.listeners[index].fn = null;
  }

  /**
   * Destroy the event listener
   */
  destroy() {
    this.lastInternalId = 0;
    this.listeners = [];
  }

  /**
   * Execute the event calling all the listeners
   */
  execute(params) {
    this.listeners = this.listeners.filter(x => x.fn != null);

    for (var i = 0; i < this.listeners.length; i++) {
      this.listeners[i].fn(params);
    }
  }
}
