import $ from '../../../core/jquery/jquery-global';
import 'jquery-ui/ui/widgets/autocomplete';
import { Component } from '../base-component';
import './dropDown.scss';
import { HttpService } from '../../../core/http/http';
import { ViewsLoader } from '../../../core/views/views-loader';
import { Event } from '../../../core/events/event';
import { Router } from '../../../core/router/router';
import { TranslationService } from '../../../core/translation/translation';
var htmlContent = require('./dropDown.html');

const renderMarkup = () => htmlContent;

export class DropDownComponent extends Component {
  /**
   * Creates a dropdown component at [element].
   * [allowCustomValues] enables the custom dropdown values feature.
   * @param {HTMLElement} element
   * @param {Boolean} allowCustomValues
   * @param {Object} classes
   */
  constructor(element, allowCustomValues = false, classes = null) {
    super(renderMarkup());

    // Init the dropdown component
    this.allowCustomValues = allowCustomValues;
    this.classes = classes;
    this.currentValue = ''; // current dropdown value
    this.currentLabel = ''; // current dropdown label
    this.container = $('#' + element.id); // jquery object with the element container
    this._mobileMaxResolution = 768;
    this.containerId = element.id;

    // Loads the dropdown into the element
    this.viewsLoader = new ViewsLoader();
    this.viewsLoader.loadView(this, element);

    //Events
    this.onChanges = new Event();
    this.container
      .find('.dropdown')
      .attr('placeholder', TranslationService.instance.translate('common-placeholder-select'));
    Router.inst.onBeforeNavigation.once(() => {
      this.destroy();
    });
  }

  destroy() {
    $(window).off('resize.dropdown' + this.containerId);

    if (this.container && this.container.find('.dropdown')) {
      let dropdown = this.container.find('.dropdown');

      //Check for initalization
      if (dropdown.data('ui-autocomplete') != undefined) {
        dropdown.autocomplete('destroy');
      }
    }
  }

  /**
   * Loads remote data from [datasource] using [value] as value field in the api response
   * and [label] as label field.
   * If there is no difference between the value and the label, use the same field.
   * @param {String} dataSource
   * @param {String} value
   * @param {String} label
   */
  loadRemote(dataSource, value_field, label_field) {
    this.httpService = new HttpService();
    return this.httpService
      .get(dataSource)
      .then(response => {
        this.entries = response;
        var options = [];
        var availableValues = [];
        this.entries.forEach(entry => {
          availableValues.push(entry[label_field]);
          options.push({
            id: entry[value_field],
            value: entry[label_field],
            label: String(entry[label_field]),
            object: entry
          });
        });
        this._loadDropdown(options, availableValues, value_field, label_field);
      })
      .catch(error => {
        this.toastService.error(error);
        console.error(error);
      });
  }

  /**
   *
   * @param {Object[]} dataSource
   * @param {string} value_field
   * @param {string} label_field
   */
  loadData(dataSource, value_field, label_field) {
    var options = [];
    var availableValues = [];
    dataSource.forEach(entry => {
      availableValues.push(entry[label_field]);
      options.push({
        id: entry[value_field],
        value: entry[label_field],
        label: String(entry[label_field]),
        object: entry
      });
    });
    this.entries = dataSource;
    this._loadDropdown(options, availableValues, value_field, label_field);
  }

  /**
   * Loads the data from [labels] and [values]
   * [values] is an optional parameter. It allows to specify different
   * labels and values for the dropdown.
   * @param {Array.String} labels
   * @param {Array.String} values
   */
  loadLocal(labels, values) {
    if (labels && Array.isArray(labels)) {
      let options = [];
      let availableValues = [];
      let labelsAndValues = values && Array.isArray(values) && labels.length == values.length;

      for (let i = 0; i < labels.length; i++) {
        options.push({
          id: labelsAndValues ? values[i] : labels[i],
          value: labels[i],
          label: String(labels[i]),
          object: { label: labels[i], value: labelsAndValues ? values[i] : labels[i] }
        });
        availableValues.push(labels[i].toString());
      }
      this.entries = options;
      this._loadDropdown(options, availableValues, 'id', 'label');
    } else {
      throw 'labels content can not be loaded';
    }
  }

  /**
   * Sets the validation rules to the dropdown input field.
   * [rules] is the set of validation rules.
   * @param {Object} rules
   */
  setRules(rules) {
    let input = this.container.find('.dropdown');
    // Adds rules to the requirements of the dropdown
    if (Object.keys(rules).length > 0) {
      input.prop('required', true);
      for (let key in rules) {
        input.attr(key, rules[key]);
      }
    }
  }

  setRule(ruleName, active) {
    let input = this.container.find('.dropdown');
    input.attr(ruleName, active);
  }

  removeRule(name) {
    let input = this.container.find('.dropdown');
    input.removeAttr(name);
  }

  /**
   * Loads the dropdown from the labels and values contained in [options]
   * @param {Object} options
   * @param {Array.String} availableValues
   */
  _loadDropdown(options, availableValues, value_field, label_field) {
    this.screenWidth = window.innerWidth;
    this.filterContainer = this.container.find('.filter-container');
    this.widget = null;

    let input = this.container
      .find('.dropdown')
      .off('focus')
      .autocomplete({
        classes: this.classes,
        appendTo:
          this.screenWidth < this._mobileMaxResolution
            ? this.container.find('.filter-container')
            : '',
        source: options,
        minLength: 0, // Show options with 0 characters
        delay: 0, // No delay to show options
        select: (event, ui) => {
          // On select, it updates the input value and forces blur
          input.val(ui.item.label);
          // Force the keyup event to trigger valindation from 'Validator'
          input.keyup();
          this.currentValue = ui.item.id;
          this.currentLabel = ui.item.label;
          this.onChanges.execute(ui.item.object);
        },
        change: (event, ui) => {
          if (
            input.val() == '' ||
            (this.allowCustomValues && !availableValues.includes(input.val()))
          ) {
            // If the input contains an empty value or
            // the input contains a custom value and custom values are allowed:
            this.currentValue = input.val();
            this.currentLabel = input.val();
            this.onChanges.execute({ label: input.val(), value: input.val() });
          } else if (!availableValues.includes(input.val())) {
            this.onChanges.execute({ label: '', value: '' });
            this.currentValue = '';
            this.currentLabel = '';
            input.val('');
            input.keyup();
          } else if (availableValues.includes(input.val()) && this.currentLabel != input.val()) {
            let entry = options.filter(entry => String(entry['label']) === input.val())[0];
            this.setSelected(entry['id'], value_field, label_field);
          }
        },
        close: (event, ui) => {
          // If the suggestions are closed:
          input.removeClass('active');
          $(window).off('resize.dropdown' + this.containerId);
        },
        open: (event, ui) => {
          // If the suggestions are opened:
          input.addClass('active');
          this.resize();
          $(window).on('resize.dropdown' + this.containerId, () => {
            this.resize();
          });
        }
      })
      .focus(() => {
        // On input click, forces a search to show the options
        if (!input.hasClass('active') && options && options.length > 0) {
          input.addClass('active');
          input.autocomplete('search', '');
        }
      });
    this.widget = input.autocomplete('widget');

    // Chevron click resolver
    let chevronClick = () => {
      if (options && options.length > 0) {
        input.addClass('active');
      }
      input.autocomplete('search', '');
    };

    this.container.find('.icon-chevron-desplegable').click(() => {
      chevronClick();
    });

    this.container.find('.chevron').click(() => {
      chevronClick();
    });
  }

  resize() {
    this.widget.outerWidth(this.filterContainer.outerWidth(true), true);
    if (this.screenWidth >= this._mobileMaxResolution) {
      let filterPosition = this.filterContainer.offset();
      filterPosition.top += this.filterContainer.height();
      this.widget.offset(filterPosition);
    }
  }

  contains(id, value_field) {
    return (
      this.entries &&
      this.entries.length > 0 &&
      this.entries.filter(entry => entry[value_field] === id).length > 0
    );
  }

  setSelected(id, value_field, label_field) {
    if (this.entries && this.entries.length > 0) {
      let entry = this.entries.filter(entry => entry[value_field] === id)[0];
      this.container.find('.dropdown').val(entry[label_field]);
      this.container.find('.dropdown').keyup();
      this.currentValue = entry[value_field];
      this.currentLabel = entry[label_field];
      this.onChanges.execute(entry);
    }
  }

  clearSelection() {
    this.container.find('.dropdown').val('');
    this.container.find('.dropdown').keyup();
    this.currentValue = '';
    this.currentLabel = '';
    this.onChanges.execute({ label: '', value: '' });
  }

  /**
   * Return the selected value (not the label)
   */
  getValue() {
    return this.currentValue;
  }

  getLabel() {
    return this.currentLabel;
  }
}
