import './confirmationDelete.scss';
var htmlContent = require('./confirmationDelete.html');
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { HttpService } from '../../core/http/http';
import { Validator } from '../../core/validator/validator';
import { ToastService } from '../../shared/services/toastService';

const renderMarkup = () => htmlContent;

export class ConfirmationDeleteComponent extends BaseModalContent {
  constructor(options) {
    super(renderMarkup());
    this.httpService = new HttpService();
    this.toastService = new ToastService();
    this._options = options;
    this.value = null;
  }

  onOpened() {
    this.init();
  }

  init() {
    if (this._options.text != undefined) {
      this.parentModal.container.find('.important').text(this._options.text);
    }

    if (this._options.buttonText != undefined) {
      this.parentModal.container.find('#actionButton').text(this._options.buttonText);
    }

    this.parentModal.container.find('.cancel').click(() => this.cancel());
    this.parentModal.container.find('.delete').click(() => this.delete());
    this.parentModal.container.find('#element-name').html(this._options.itemName);
    this.validator = new Validator(this.parentModal.container);
    this.validator.onValidationChanges.listen(isValid => {
      let createButton = this.parentModal.container.find('.save');
      if (isValid) {
        createButton.removeAttr('disabled');
      } else {
        createButton.attr('disabled', '');
      }
    });
    this.validator.validate();
  }

  cancel() {
    this.value = null;
    this.parentModal.close();
  }

  delete() {
    this.parentModal.toggleLoadingIcon(true);
    let promise = null;

    if (this._options.isPut == true) {
      promise = this.httpService.put(this._options.actionUrl);
    } else if (this._options.body == null) {
      promise = this.httpService.del(this._options.actionUrl);
    } else {
      promise = this.httpService.delWithBody(this._options.actionUrl, this._options.body);
    }

    promise
      .then(response => {
        this.value = true;
        this.parentModal.close();
      })
      .catch(error => {
        this.toastService.error(error.title, error.message);
        console.error(error);
      })
      .finally(() => this.parentModal.toggleLoadingIcon(false));
  }

  get result() {
    return this.value;
  }
}

export class ConfirmationDeleteOptions {
  itemName = null;
  actionUrl = null;
  body = null;
}
