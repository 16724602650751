import './configUserPermissions.scss';
var htmlContent = require('./configUserPermissions.html');
import { Component } from '../../shared/components/base-component';
import $ from '../../core/jquery/jquery-global';
import { ModalComponent } from '../../shared/components/modal';
import { TranslationService } from '../../core/translation/translation';
import { permissionManagerComponent } from '../../components/manage-permission/manage-permission';
import { HttpService } from '../../core/http/http';
import { ToastService } from '../../shared/services/toastService';
import { ViewsLoader } from '../../core/views/views-loader';
import { Spinner } from '../../shared/components/spinner/spinner';
import {
  ConfirmationDeleteOptions,
  ConfirmationDeleteComponent
} from '../../components/confirmation-delete/confirmationDelete';
import { ListOptions, List } from '../../shared/components/list/list';
import { AuthenticationService } from '../../shared/services/authenticationService';

const renderMarkup = () => htmlContent;

export class ConfigUserPermissionsPage extends Component {
  constructor() {
    super(renderMarkup());
    $(document).ready(() => {
      let selectNav = document.querySelectorAll('.links-item');
      let i = 0;
      selectNav.forEach(e => {
        if (i == 6) {
          $(e).addClass('active');
          i++;
        } else {
          $(e).removeClass('active');
          i++;
        }
      });
      this.httpService = new HttpService();
      this.toastService = new ToastService();
      this.authenticationService = new AuthenticationService();
      this.viewsLoader = new ViewsLoader();
      this.init();
      this._permissionsConfig = null;
      this._selectedPermissionConfig = null;
    });
  }

  _loadPermissionsConfig() {
    $('#loader-container').removeClass('hidden');
    this.viewsLoader.loadView(new Spinner('big'), document.getElementById('loader-container'));
    this.httpService
      .get(
        `${this.httpService.config.getPermissionsConfig}?loginUsuario=${
          this.authenticationService.getAuthenticatedUser().login
        }`
      )
      .then(response => {
        $('#loader-container').addClass('hidden');
        this._permissionsConfig = response;
        this.buildPermissionsConfigTable();
        this.enableButtons();
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }

  init() {
    this._loadPermissionsConfig();
    this.bindButtons();
  }

  buildPermissionsConfigTable() {
    let options = new ListOptions();
    options.listSelector = '#users-permissions';
    options.searchSelector = '.search-input';
    options.textShowingSelector = '.text-showing-results';
    options.textShowingValue = TranslationService.instance.translate('common-showing-results');
    options.includeFieldsSearch = ['idUsuarioNavigationNombre'];

    options.onRenderRow = function(config) {
      let permissionCheckHtml = '<span class="circle-state green">';
      return `<div class="table-row" data-id="${config.idPermisoUsuario}">
            <div class="cell left user">${config.idUsuarioNavigationNombre}</div>
            <div class="cell center admin">${
              config.esAdmin == true ? permissionCheckHtml : ''
            }</div>
            <div class="cell left company">${config.idEmpresaNavigationNombre}</div>
            <div class="cell left end-section investmentArea">${
              config.idAreaInversionNavigationNombre
            }</div>
            <div class="cell center matrixApproval">${
              config.solicitaAprobacionMatricial == true ? permissionCheckHtml : ''
            }</div>
            <div class="cell center budRead">${
              config.leePresupuesto == true ? permissionCheckHtml : ''
            }</div>
            <div class="cell center budModify">${
              config.modificaPresupuesto == true ? permissionCheckHtml : ''
            }</div>
            <div class="cell center budValidate">${
              config.validaPresupuesto == true ? permissionCheckHtml : ''
            }</div>
            <div class="cell center end-section budApprove">${
              config.apruebaPresupuesto == true ? permissionCheckHtml : ''
            }</div>
            <div class="cell center reqRead">${
              config.leeSolicitud == true ? permissionCheckHtml : ''
            }</div>
            <div class="cell center reqModify">${
              config.modificaSolicitud == true ? permissionCheckHtml : ''
            }</div>
            <div class="cell center reqValidate">${
              config.validaSolicitud == true ? permissionCheckHtml : ''
            }</div>
            <div class="cell center reqApprove">${
              config.apruebaSolicitud == true ? permissionCheckHtml : ''
            }</div>
            <div class="cell center reqCancel">${
              config.anulaSolicitud == true ? permissionCheckHtml : ''
            }</div>
          </div>`;
    };
    options.onRenderFinish = () => {
      $('#users-permissions')
        .find('.table-row')
        .click(e => this.selectTableRow(e));
    };

    this._list = new List(options);
    this._list.updateData(this._permissionsConfig);
  }

  selectTableRow(event) {
    $('#users-permissions')
      .find('.table-row')
      .removeClass('selected');
    event.currentTarget.classList.add('selected');
    this._selectedPermissionConfig = this._permissionsConfig.filter(
      pc => pc.idPermisoUsuario == parseInt(event.currentTarget.getAttribute('data-id'))
    )[0];
    this.enableButtons();
  }

  bindButtons() {
    $('#btn-create-permission').click(() => this.createPermissionModal());
    $('#btn-edit-permission').click(() => this.editPermissionModal());
    $('#btn-delete-permission').click(() => this.deletePermissionModal());
  }
  enableButtons() {
    $('#btn-create-permission').attr('disabled', this._permissionsConfig == null);
    $('#btn-edit-permission').attr('disabled', this._selectedPermissionConfig == null);
    $('#btn-delete-permission').attr('disabled', this._selectedPermissionConfig == null);
  }

  createPermissionModal() {
    var modal = new ModalComponent(
      TranslationService.instance.translate('config-cp-title-modal'),
      new permissionManagerComponent()
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._selectedPermissionConfig = null;
      this.enableButtons();
      this._loadPermissionsConfig();
    });
  }

  editPermissionModal() {
    var modal = new ModalComponent(
      TranslationService.instance.translate('config-ep-title-modal'),
      new permissionManagerComponent(this._selectedPermissionConfig)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._selectedPermissionConfig = null;
      this.enableButtons();
      this._loadPermissionsConfig();
    });
  }

  deletePermissionModal() {
    let options = new ConfirmationDeleteOptions();
    options.itemName = `${this._selectedPermissionConfig.idUsuarioNavigationNombre} 
    - ${this._selectedPermissionConfig.idEmpresaNavigationNombre} - 
    ${this._selectedPermissionConfig.idAreaInversionNavigationNombre}`;
    options.actionUrl = `${this.httpService.config.deletePermission}?idConfiguracion=${
      this._selectedPermissionConfig.idPermisoUsuario
    }&loginUsuario=${this.authenticationService.getAuthenticatedUser().login}`;
    let modal = new ModalComponent(
      TranslationService.instance.translate('common-modal-confirmation'),
      new ConfirmationDeleteComponent(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._selectedPermissionConfig = null;
      this.enableButtons();
      this._loadPermissionsConfig();
    });
  }
}
