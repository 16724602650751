import './petition-detail.scss';
var htmlContent = require('./petition-detail.html');
import $ from '../../core/jquery/jquery-global';
import { Component } from '../../shared/components/base-component';
import { BudgetTree, BudgetTreeOptions } from '../../components/budget-tree/budgetTree';
import { ViewsLoader } from '../../core/views/views-loader';
import { CreateLineComponent } from '../../components/create-line/createLine';
import { BudgetAskInfoComponent } from '../../components/budget-ask-info/budgetAskInfo';
import { RemoveLineComponent } from '../../components/remove-line/removeLine';
import { ModalComponent } from '../../shared/components/modal';
import { HttpService } from '../../core/http/http';
import { BudgetManager, BudgetLine } from '../../components/budget-tree/budget-manager';
import {
  UnitsEnums,
  BudgetStatusEnum,
  BudgetLinesActionsEnums,
  PetitionStatusTextEnum,
  BudgetStatusNameEnum
} from '../../config/enums';
import { ToastService } from '../../shared/services/toastService';
import { Spinner } from '../../shared/components/spinner/spinner';
import { Router } from '../../core/router/router';
import { QuitWithoutSaveComponent } from '../../components/quit-without-save/quitWithoutSave';
import { Validator } from '../../core/validator/validator';
import {
  InputFileOptions,
  InputFileComponent
} from '../../shared/components/input-file/input-file';
import { TranslationService } from '../../core/translation/translation';
import { AuthenticationService } from '../../shared/services/authenticationService';

const renderMarkup = () => htmlContent;

export class PetitionDetailPage extends Component {
  constructor(params) {
    super(renderMarkup());

    $(document).ready(() => {
      let selectNav = document.querySelectorAll('.links-item');
      let i = 0;
      selectNav.forEach(e => {
        if (i == 1) {
          $(e).addClass('active');
          i++;
        } else {
          $(e).removeClass('active');
          i++;
        }
      });
      this.budgetTree = null;
      this.httpService = new HttpService();
      this.toastService = new ToastService();
      this.authenticationService = new AuthenticationService();
      this.viewsLoader = new ViewsLoader();
      this.router = new Router();
      this.isDirty = false;
      this._init();
      this.petitionId = params.id;
      this.loadPetition(params.id);
    });
  }

  _init() {
    this.router.userHasChange.listen(event => this.confirmQuitWithoutSave(event));
    $(window).on('beforeunload', () => {
      if (this.isDirty) {
        return TranslationService.instance.translate('msgLeavePageWithChages');
      }
    });
  }

  loadPetition(petitionId) {
    $('#loader-container').removeClass('hidden');
    this.viewsLoader.loadView(new Spinner('big'), document.getElementById('loader-container'));
    this.httpService
      .post(this.httpService.config.loadPetition, {
        idPeticion: parseInt(petitionId),
        loginUsuario: this.authenticationService.getAuthenticatedUser().login
      })
      .then(response => {
        this.buildPage(response, petitionId);
        $('#loader-container').addClass('hidden');
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }

  buildPage(petitionResponse) {
    this.buildTitle(petitionResponse);
    this.buildContent(petitionResponse);
    this.enableButtons(petitionResponse);
  }

  buildTitle(petitionResponse) {
    $('#view-title').html(
      TranslationService.instance.translate('common-exercises') + ' ' + petitionResponse.nombre
    );
  }

  buildContent(petitionResponse) {
    var options = new BudgetTreeOptions();
    this.budgetManager = new BudgetManager(
      petitionResponse.idPresupuesto,
      this.authenticationService.getAuthenticatedUser().login,
      5
    );
    this.budgetManager.loadJsonItems(
      petitionResponse.lineaPresupuesto,
      petitionResponse.areaTrabajo.ejercicio
    );
    options.budgetManager = this.budgetManager;
    options.allowDrag = true;
    options.userId = this.authenticationService.getAuthenticatedUser().login;
    options.readonly = petitionResponse.idEstado != BudgetStatusNameEnum.Draft;
    options.selector = '.budget-tree';
    options.companyId = petitionResponse.areaTrabajo.idEmpresa;
    options.investmentAreaId = petitionResponse.areaTrabajo.idAreaInversion;
    options.isPetition = true;
    options.petitionId = petitionResponse.idPeticion;

    this.budgetTree = new BudgetTree(options);
    this.budgetTree.editedValue.listen(() => this.petitionEditedCallback());
    this.budgetTree.budgetSaved.listen(() => this.reloadPetition(petitionResponse.idPeticion));
    this.budgetTree.askInfoDone.listen(() => this.reloadPetition(petitionResponse.idPeticion));

    $('.petition-from-username').html(
      TranslationService.instance.translate('pet-details-from') + petitionResponse.usuarioRemitente
    );
    $('.petition-from-comments').html(petitionResponse.mensaje);

    if (petitionResponse.urlArchivo) {
      $('.petition-from-attachment').html(
        '<a href="' +
          petitionResponse.urlArchivo +
          '" target="_blank"><span class="icon-documento"> ' +
          petitionResponse.nombreArchivo +
          '</span></a>'
      );
    } else {
      $('.petition-from-attachment').attr('disabled', true);
    }
  }

  petitionEditedCallback() {
    $('#btn-save').attr('disabled', false);
    $('#btn-save-mobile').attr('disabled', false);
    $('#btn-modal-ask-info').attr('disabled', true);
    $('#btn-modal-send-answer').attr('disabled', true);
    $('#reply-msg').attr('disabled', true);

    // In addition a listener for the location change is created
    this.router.preventDefault = true;
    this.isDirty = true;
  }

  reloadPetition(idPeticion) {
    $('#btn-save').attr('disabled', true);
    $('#btn-save-mobile').attr('disabled', true);
    $('#btn-modal-ask-info').attr('disabled', false);
    $('#btn-modal-send-answer').attr('disabled', false);
    $('#reply-msg').attr('disabled', false);

    $(window).off('beforeunload');
    this.isDirty = false;
    this.router.preventDefault = false;

    this.loadPetition(idPeticion);
  }

  /**
   * Open modal asking the user for confirmation before leave the page when a change has been done
   * @param {*} event
   */
  confirmQuitWithoutSave(event) {
    if (!this.isDirty) {
      this.router.preventDefault = false;
      return;
    }

    this.router.cancelNavigation = true;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');
    let desiredLocation = location.hash;
    //Assign current location to loction of this budget details
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
      location.assign('#petitions/' + this.petitionId);
    } else {
      location.assign(event.oldURL.substr(event.oldURL.indexOf('#')));
    }
    var modal = new ModalComponent(
      TranslationService.instance.translate('common-wout-continue'),
      new QuitWithoutSaveComponent()
    );
    modal.onClosed.listen(() => {
      if (modal.result != null && modal.result.save) {
        $(window).off('beforeunload');
        this.router.preventDefault = false;
        this.router.cancelNavigation = false;
        this.saveBudget(this.budgetManager.getBudgetId());
      } else if (modal.result != null && modal.result.continue) {
        //continue to target
        $(window).off('beforeunload');
        this.router.preventDefault = false;
        this.router.cancelNavigation = false;
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
          location.assign(desiredLocation);
        } else {
          location.assign(event.newURL.substr(event.newURL.indexOf('#')) + ' ');
        }
      }
    });
  }

  enableButtons(petitionResponse) {
    $('#btn-modal-create-line')
      .off()
      .click(() => this.budgetTree.createNewLine(petitionResponse));
    $('#btn-modal-create-line').attr('disabled', petitionResponse.idEstado != 1);
    $('#btn-modal-ask-info')
      .off()
      .click(() => this.budgetTree.askInfo());
    $('#btn-modal-ask-info').attr('disabled', petitionResponse.idEstado != 1);
    $('#btn-modal-delete-line')
      .off()
      .click(() => this.budgetTree.deleteLine());
    $('#btn-modal-delete-line').attr('disabled', petitionResponse.idEstado != 1);
    $('#btn-save')
      .off()
      .click(() =>
        this.budgetTree.saveBudget(petitionResponse.idPresupuesto, petitionResponse.idPeticion)
      );
    $('#btn-save').attr('disabled', true);

    $('#btn-toogle-menu').click(() => this._toggleMenu());
    $('#details-tab').click(() => this.detailsTab());
    $('#edit-tab').click(() => this.editTab());

    if (
      petitionResponse.idEstado == 1 &&
      petitionResponse.idUsuarioResponsable ==
        this.authenticationService.getAuthenticatedUser().idUsuario
    ) {
      $('#btn-modal-send-answer')
        .off()
        .click(() => this.sendResponse(petitionResponse.idPeticion));

      let attachmentOptions = new InputFileOptions();
      attachmentOptions.allowDelete = true;
      attachmentOptions.allowMultipleFiles = false;

      this._attachments = new InputFileComponent(attachmentOptions);
      this.viewsLoader.loadView(
        this._attachments,
        document.querySelector('.attach-file-container')
      );

      this.validator = new Validator(document.getElementById('petition-reply'));
      this.validator.validate();
    } else {
      let answerText = TranslationService.instance.translate('petition-details-response');
      let html = `<span class="petition-info-header">${answerText}</span>
      <span>${
        petitionResponse.idEstado == 2
          ? petitionResponse.respuesta || ''
          : PetitionStatusTextEnum[petitionResponse.idEstado]
      }</span>`;
      $('.petition-to').html(html);
      $('#btn-modal-send-answer').addClass('hidden');

      $('#btn-modal-create-line').attr('disabled', true);
      $('#btn-modal-ask-info').attr('disabled', true);
      $('#btn-modal-delete-line').attr('disabled', true);
      $('#btn-delete-budget').addClass('disabled', true);
    }
  }

  _buildBody(petitionId) {
    let body = new PetitionReply();
    body.idPeticion = petitionId;
    body.respuesta = $('#reply-msg').val();
    body.usuarioActualizacion = this.authenticationService.getAuthenticatedUser().login;

    let formData = HttpService.objectToFormData(body);
    for (const file of this._attachments.files.filter(x => x.isNewFile)) {
      //This will attach only one file
      formData.append('adjunto', file);
    }

    return formData;
  }

  sendResponse(petitionId) {
    if (!this.validator.isValid) {
      return;
    }

    let body = this._buildBody(petitionId);

    this.httpService
      .post(this.httpService.config.replyPetition, body)
      .then(response => {
        this.toastService.success(
          TranslationService.instance.translate('success-title'),
          TranslationService.instance.translate('msg-pet-reply-ok')
        );
        this.loadPetition(petitionId);
        this.isDirty = false;
        this.router.preventDefault = false;
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }

  _toggleMenu() {
    $('.sidenav').toggleClass('menuComplete');
  }
  detailsTab() {
    //Hide edition content
    $('.budget-tree').addClass('hidden');
    //Show details content
    $('.petition-info').removeClass('hidden');
    //Add active to details tab
    $('#details-tab').addClass('active');
    //Remove active from edition tab
    $('#edit-tab').removeClass('active');
    //Show buttons container
    $('.buttons-container').removeClass('hidden');
  }
  editTab() {
    //Hide details content
    $('.budget-tree').removeClass('hidden');
    //Show details content
    $('.petition-info').addClass('hidden');
    //Remove active from details tab
    $('#details-tab').removeClass('active');
    //Add active to approval flow tab
    $('#edit-tab').addClass('active');
    //Hide buttons container
    $('.buttons-container').addClass('hidden');
  }
}

class PetitionReply {
  idPeticion = null;
  respuesta = null;
  usuarioActualizacion = null;
}
