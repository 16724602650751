import './configure-flow-approval.scss';
var htmlContent = require('./configure-flow-approval.html');
import 'jquery-ui/ui/widgets/slider';
import $ from 'jquery';
import { HttpService } from '../../core/http/http';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { ToastService } from '../../shared/services/toastService';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import { Validator } from '../../core/validator/validator';
import { TranslationService } from '../../core/translation/translation';
import { AuthenticationService } from '../../shared/services/authenticationService';
import { InputNumeric, InputNumericOptions } from '../input-numeric/inputNumeric';

const renderMarkup = () => htmlContent;

export class ConfigureFlowApprovalComponent extends BaseModalContent {
  constructor(params) {
    super(renderMarkup());
    window._hasMatApprobalPermissions = false;
    this.toastService = new ToastService();
    this.httpService = new HttpService();
    this.authenticationService = new AuthenticationService();
    this._params = params;
  }

  onOpened() {
    this._init();
  }

  onLoading(callback) {
    Promise.all([this._loadUsers(), this._loadCompanies()]).then(response => {
      callback();
    });
    return true;
  }

  _loadUsers() {
    return this.httpService
      .get(this.httpService.config.loadUsers)
      .then(response => {
        this._users = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  _loadCompanies() {
    return this.httpService
      .get(
        this.httpService.config.loadCompaniesPerUser +
          this.authenticationService.getAuthenticatedUser().login
      )
      .then(response => {
        this._companies = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  _loadInvestmentAreas(idEmpresa) {
    return this.httpService
      .get(
        this.httpService.config.loadInvestmentAreasPerUserAndCompany +
          '?loginUsuario=' +
          this.authenticationService.getAuthenticatedUser().login +
          '&idEmpresa=' +
          idEmpresa +
          '&tieneQueSerAdmin=false'
      )
      .then(response => {
        this._investmentAreas = response;
        if (this._investmentAreas && this._investmentAreas.length > 0) {
          this.investmentAreaSelector.loadData(this._investmentAreas, 'idAreaInversion', 'nombre');
          this.investmentAreaSelector.clearSelection();
          this.fillInvestmentArea();
          this.investmentAreaSelector.removeRule('disabled');
        } else {
          this.investmentAreaSelector.setRule('disabled', 'disabled');
        }
        this.parentModal.toggleLoadingIcon(false);
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  _createNumberInput(element) {
    let options = new InputNumericOptions();
    options.decimals = 0;
    options.useThousandSeparator = true;
    options.allowNegatives = false;

    new InputNumeric(element, options);
  }
  _init() {
    window.loadingEditMode = this._params;
    this._loadDropdowns();
    //this._maxSliderValue = 1000000;
    //this._loadSlider();

    this.defaultFlowSelect = this.parentModal.container.find('#content-default-flow-select');
    this.workTypeSelect = this.parentModal.container.find('#content-work-type-select');
    this.requestTypeSelect = this.parentModal.container.find('#content-request-type-select');
    //this.importsSlider = this.parentModal.container.find('#imports-range');
    this.defaultBudgetAction = this.parentModal.container.find('#content-default-action-select');
    this.defaultRequestAction = this.parentModal.container.find(
      '#content-default-action-request-select'
    );
    this.actionValidateBudgetContainer = this.parentModal.container.find(
      '#action-validate-budget-container'
    );
    this.actionValidateRequestContainer = this.parentModal.container.find(
      '#action-validate-request-container'
    );

    this.budgetMatSwitch = this.parentModal.container.find(
      '#budget-mat-switch'
    );

    this.requestMatSwitch = this.parentModal.container.find(
      '#request-mat-switch'
    );

    this.physicalSignBudget = this.parentModal.container.find('#physical-sign-budget');
    this.physicalSignRequest = this.parentModal.container.find('#physical-sign-request');
    this.matrixBudget = this.parentModal.container.find('#physical-matrix-budget');
    this.matrixRequest = this.parentModal.container.find('#physical-matrix-request');

    this.signerBudgetContainer = this.parentModal.container.find('#signer-budget-container');
    this.signerRequestContainer = this.parentModal.container.find('#signer-request-container');
    this.matrixBudget1Container = this.parentModal.container.find('#signer-budget-mat1-container');
    this.matrixBudget2Container = this.parentModal.container.find('#signer-budget-mat2-container');
    this.matrixRequest1Container = this.parentModal.container.find('#signer-request-mat1-container');
    this.matrixRequest2Container = this.parentModal.container.find('#signer-request-mat2-container');


    this.minImport = this.parentModal.container.find('#min-amount');
    this.maxImport = this.parentModal.container.find('#max-amount');
    this._createNumberInput(this.minImport);
    this._createNumberInput(this.maxImport);
    this.chkMaxAmount = this.parentModal.container.find('#chk-max-amount');

    if (this._params) {
      this._editMode = true;      
      this._fillFields(this._params);      
    }
    this._loadStep1();
    this._loadStep2();
    this._loadStep3();
    this._switchStepView(1);
    this._getMatApprovalPermissions(this.investmentAreaSelector.getValue());
    window.loadingEditMode = false;
  }

  _loadDropdowns() {
    this.userSelector = new DropDownComponent(document.getElementById('user-selector'));
    this.userSelector.setRules({
      required: true
    });
    this.userSelector.loadData(this._users, 'idUsuario', 'nombre');

    this.companySelector = new DropDownComponent(document.getElementById('company-selector'));
    this.companySelector.setRules({
      required: true
    });
    this.companySelector.loadData(this._companies, 'idEmpresa', 'nombre');

    this.investmentAreaSelector = new DropDownComponent(
      document.getElementById('investment-area-selector')
    );
    this.investmentAreaSelector.setRules({
      required: true
    });

    this.investmentAreaSelector.setRule('disabled', 'disabled');

    this.userSelector.onChanges.listen(company => {
      this._getMatApprovalPermissions(this.investmentAreaSelector.getValue());
    })

    this.companySelector.onChanges.listen(company => {
      if (company && company.idEmpresa) {
        this.parentModal.toggleLoadingIcon(true);
        this._loadInvestmentAreas(company.idEmpresa);
      } else {
        this.investmentAreaSelector.clearSelection();
        this.investmentAreaSelector.setRule('disabled', 'disabled');
      }

      this._getMatApprovalPermissions(this.investmentAreaSelector.getValue());
    });

    this.investmentAreaSelector.onChanges.listen(company => {
      this._getMatApprovalPermissions(this.investmentAreaSelector.getValue());
    });

    this.responsibleSelector = new DropDownComponent(
      document.getElementById('responsible-selector')
    );
    this.responsibleSelector.setRules({
      required: true,
      'data-validator-ignore-invisible': true
    });
    this.responsibleSelector.loadData(this._users, 'idUsuario', 'nombre');
    this.responsibleRequestSelector = new DropDownComponent(
      document.getElementById('responsible-request-selector')
    );
    this.responsibleRequestSelector.setRules({
      required: true,
      'data-validator-ignore-invisible': true
    });
    this.responsibleRequestSelector.loadData(this._users, 'idUsuario', 'nombre');

    this.budgetMat1Selector = new DropDownComponent(document.getElementById('budget-mat1-selector'));
    this.budgetMat1Selector.setRules({
      required: true,
      'data-validator-ignore-invisible': true
    });
    this.budgetMat1Selector.loadData(this._users, 'idUsuario', 'nombre');

    this.budgetMat2Selector = new DropDownComponent(document.getElementById('budget-mat2-selector'));
    this.budgetMat2Selector.setRules({
      required: false,
      'data-validator-ignore-invisible': true
    });
    this.budgetMat2Selector.loadData(this._users, 'idUsuario', 'nombre');

    this.requestMat1Selector = new DropDownComponent(document.getElementById('request-mat1-selector'));
    this.requestMat1Selector.setRules({
      required: true,
      'data-validator-ignore-invisible': true
    });
    this.requestMat1Selector.loadData(this._users, 'idUsuario', 'nombre');

    this.requestMat2Selector = new DropDownComponent(document.getElementById('request-mat2-selector'));
    this.requestMat2Selector.setRules({
      required: false,
      'data-validator-ignore-invisible': true
    });
    this.requestMat2Selector.loadData(this._users, 'idUsuario', 'nombre');
  }

  _loadSlider() {
    $('#imports-range').slider({
      range: true,
      min: 0,
      max: this._maxSliderValue,
      step: 1000,
      values: [0, this._maxSliderValue],
      slide: function(event, ui) {
        let minValue = ui.values[0] >= 1000 ? ui.values[0] : ui.values[0];
        let maxValue = ui.values[1] >= 1000 ? ui.values[1] : ui.values[1];
        if (ui.values[1] == this._maxSliderValue) maxValue = 'Max';
        $('#range-result').val(minValue + ' - ' + maxValue);
      }
    });
    this._loadSliderView();
  }

  _loadSliderView() {
    let minValue =
      $('#imports-range').slider('values', 0) >= 1000
        ? $('#imports-range').slider('values', 0)
        : $('#imports-range').slider('values', 0);
    let maxValue =
      $('#imports-range').slider('values', 1) >= 1000
        ? $('#imports-range').slider('values', 1)
        : $('#imports-range').slider('values', 1);
    if ($('#imports-range').slider('values', 1) == this._maxSliderValue) maxValue = 'Max';
    $('#range-result').val(minValue + ' - ' + maxValue);
  }

  _fillFields(data) {
    if (data.idUsuario !== null && this.userSelector.contains(data.idUsuario, 'idUsuario'))
      this.userSelector.setSelected(data.idUsuario, 'idUsuario', 'nombre');
    if (data.idEmpresa !== null && this.companySelector.contains(data.idEmpresa, 'idEmpresa'))
      this.companySelector.setSelected(data.idEmpresa, 'idEmpresa', 'nombre');

    if (data.idAreaInversion !== null) {
      this._investmentAreaToFill = data.idAreaInversion;
    }    

    this._getMatApprovalPermissions(this._investmentAreaToFill);
    //this.importsSlider.slider('values', 0, data.importeMin);
    //this.importsSlider.slider('values', 1, data.importeMax);
    //this._loadSliderView();
    this.minImport.val(data.importeMin);
    if (data.importeMax !== null) {
      this.switchUseMaxAtRangeAmounts(false);
      this.maxImport.val(data.importeMax);
    } else {
      this.switchUseMaxAtRangeAmounts(true);
    }
    this._createNumberInput(this.minImport);
    this._createNumberInput(this.maxImport);

    this.defaultBudgetAction
      .find('input[value=' + data.idAccionPresupuesto + ']')
      .prop('checked', true);
    this.defaultRequestAction
      .find('input[value=' + data.idAccionSolicitud + ']')
      .prop('checked', true);
    this.defaultFlowSelect.find('input[value=' + data.enPlan + ']').prop('checked', true);
    this.workTypeSelect.find('input[value=' + data.tipologiaObra + ']').prop('checked', true);
    this.requestTypeSelect.find('input[value=' + data.tipoSolicitud + ']').prop('checked', true);
    this.physicalSignBudget.prop('checked', data.solicitarFirmaPresupuesto);
    this.physicalSignRequest.prop('checked', data.solicitarFirmaSolicitud);
    this.matrixBudget.prop('checked', data.idAprobadorMatricialPresupuesto1 != null && data.idAprobadorMatricialPresupuesto2 != null);
    this.matrixRequest.prop('checked', data.idAprobadorMatricialSolicitud1 != null && data.idAprobadorMatricialSolicitud2 != null);

    if (
      data.idResponsablePresupuesto !== null &&
      this.responsibleSelector.contains(data.idResponsablePresupuesto, 'idUsuario')
    )
      this.responsibleSelector.setSelected(data.idResponsablePresupuesto, 'idUsuario', 'nombre');
    if (
      data.idResponsableSolicitud !== null &&
      this.responsibleRequestSelector.contains(data.idResponsableSolicitud, 'idUsuario')
    )
      this.responsibleRequestSelector.setSelected(
        data.idResponsableSolicitud,
        'idUsuario',
        'nombre'
      );    
    if (
      data.idAprobadorMatricialPresupuesto1 !== null &&
      this.budgetMat1Selector.contains(data.idAprobadorMatricialPresupuesto1, 'idUsuario')
    )
      this.budgetMat1Selector.setSelected(
        data.idAprobadorMatricialPresupuesto1,
        'idUsuario',
        'nombre'
      );
    if (
      data.idAprobadorMatricialPresupuesto2 !== null &&
      this.budgetMat2Selector.contains(data.idAprobadorMatricialPresupuesto2, 'idUsuario')
    )
      this.budgetMat2Selector.setSelected(
        data.idAprobadorMatricialPresupuesto2,
        'idUsuario',
        'nombre'
      );
    if (
      data.idAprobadorMatricialSolicitud1 !== null &&
      this.requestMat1Selector.contains(data.idAprobadorMatricialSolicitud1, 'idUsuario')
    )
      this.requestMat1Selector.setSelected(
        data.idAprobadorMatricialSolicitud1,
        'idUsuario',
        'nombre'
      );
    if (
      data.idAprobadorMatricialSolicitud2 !== null &&
      this.requestMat2Selector.contains(data.idAprobadorMatricialSolicitud2, 'idUsuario')
    )
      this.requestMat2Selector.setSelected(
        data.idAprobadorMatricialSolicitud2,
        'idUsuario',
        'nombre'
      );
  }

  fillInvestmentArea() {
    if (
      typeof this._investmentAreaToFill !== 'undefined' &&
      this.investmentAreaSelector.contains(this._investmentAreaToFill, 'idAreaInversion')
    ) {
      this.investmentAreaSelector.setSelected(
        this._investmentAreaToFill,
        'idAreaInversion',
        'nombre'
      );
      // Removes it because it has already been filled
      this._investmentAreaToFill = undefined;
    }
  }

  switchUseMaxAtRangeAmounts(use) {
    if (use) {
      this.maxImport.val('');
      this.maxImport.attr('disabled', 'disabled');
      // chkMaxAmount now is not required
      this.maxImport.removeAttr('required', 'required');
      this.chkMaxAmount.prop('checked', true);
    } else {
      this.maxImport.removeAttr('disabled');
      this.maxImport.prop('disabled', false);
      this.chkMaxAmount.prop('checked', false);
      // chkMaxAmount now is required
      this.maxImport.attr('required', 'required');
    }
    if (typeof this.validatorStep3 !== 'undefined') this.validatorStep3.validate();
  }

  _switchStepView(step) {
    if (step == 1) {
      $('#step-2-container').addClass('hide');
      $('#step-3-container').addClass('hide');
      $('#step-1-container').removeClass('hide');
      this.validatorStep1.validate();
    } else if (step == 2) {
      $('#step-1-container').addClass('hide');
      $('#step-3-container').addClass('hide');
      $('#step-2-container').removeClass('hide');
      this.validatorStep2.validate();
    } else if (step == 3) {
      $('#step-1-container').addClass('hide');
      $('#step-2-container').addClass('hide');
      $('#step-3-container').removeClass('hide');
      this.validatorStep3.validate();
    }
  }

  _loadStep1() {
    this.validatorStep1 = new Validator(this.parentModal.container.find('#step-1-container'));
    this.parentModal.container.find('#btn-go-step2').click(() => {
      if (!this.validatorStep1.isValid) {
        return;
      }
      this._switchStepView(2);
    });
    this.parentModal.container.find('#btn-step1-cancel').click(() => {
      this.value = false;
      this.parentModal.close();
    });
  }

  _loadStep2() {
    this.validatorStep2 = new Validator(this.parentModal.container.find('#step-2-container'));

    this._switchDefaultActionBudget(
      this.defaultBudgetAction.find('input[name=default-action-budget]:checked').val()
    );
    this._switchSignBudget(this.physicalSignBudget.is(':checked'));
    this._switchMatBudget(this.matrixBudget.is(':checked'));

    $('#budget-validate').click(() => this._switchDefaultActionBudget(2));
    $('#budget-approve').click(() => this._switchDefaultActionBudget(1));
    $('#physical-sign-budget').change(() =>
      this._switchSignBudget(this.physicalSignBudget.is(':checked'))
    );
    $('#physical-matrix-budget').change(() =>
      this._switchMatBudget(this.matrixBudget.is(':checked'))
    );

    this.parentModal.container.find('#btn-go-step3').click(() => {
      if (!this.validatorStep2.isValid) {
        return;
      }
      this._switchStepView(3);
    });
    this.parentModal.container.find('#btn-back-step1').click(() => this._switchStepView(1));
    this.parentModal.container.find('#btn-step2-cancel').click(() => {
      this.value = false;
      this.parentModal.close();
    });
  }

  _switchDefaultActionBudget(actionId) {
    if (actionId == 2) {
      this.actionValidateBudgetContainer.removeClass('hide');      
    } else if (actionId == 1) {
      this.actionValidateBudgetContainer.addClass('hide');      
    }

    this._switchBudgetMatrixVisibility(actionId);
    this.validatorStep2.validate();
  }

  _switchSignBudget(checked) {
    if (checked) {
      this.signerBudgetContainer.removeClass('hide');
    } else {
      this.signerBudgetContainer.addClass('hide');
    }
    this.validatorStep2.validate();
  }

  _switchMatBudget(checked) {
    if (checked) {
      this.matrixBudget1Container.removeClass('hide');
      this.matrixBudget2Container.removeClass('hide');
    } else {
      this.matrixBudget1Container.addClass('hide');
      this.matrixBudget2Container.addClass('hide');
    }
    this.validatorStep2.validate();
  }

  _switchMatRequest(checked) {
    if (checked) {
      this.matrixRequest1Container.removeClass('hide');
      this.matrixRequest2Container.removeClass('hide');
    } else {
      this.matrixRequest1Container.addClass('hide');
      this.matrixRequest2Container.addClass('hide');
    }
    this.validatorStep3.validate();
  }

  _loadStep3() {
    this.validatorStep3 = new Validator(this.parentModal.container.find('#step-3-container'));
    this.minImport.attr(
      'placeholder',
      TranslationService.instance.translate('config-flow-min-amount-placeholder')
    );

    this.maxImport.attr(
      'placeholder',
      TranslationService.instance.translate('config-flow-max-amount-placeholder')
    );

    this.chkMaxAmount.change(() =>
      this.switchUseMaxAtRangeAmounts(this.chkMaxAmount.is(':checked'))
    );

    this._switchDefaultActionRequest(
      this.defaultRequestAction.find('input[name=default-action-request]:checked').val()
    );
    this._switchSignRequest(this.physicalSignRequest.is(':checked'));
    this._switchMatRequest(this.matrixRequest.is(':checked'));

    $('#request-validate').click(() => this._switchDefaultActionRequest(2));
    $('#request-approve').click(() => this._switchDefaultActionRequest(1));
    $('#physical-sign-request').change(() =>
      this._switchSignRequest(this.physicalSignRequest.is(':checked'))
    );
    $('#physical-matrix-request').change(() =>
      this._switchMatRequest(this.matrixRequest.is(':checked'))
    );

    this.parentModal.container.find('#btn-save').click(() => {
      if (!this.validatorStep3.isValid) {
        return;
      }
      this._save();
    });
    this.parentModal.container.find('#btn-back-step2').click(() => this._switchStepView(2));
    this.parentModal.container.find('#btn-step3-cancel').click(() => {
      this.value = false;
      this.parentModal.close();
    });
  }

  _switchDefaultActionRequest(actionId) {
    if (actionId == 2) {
      this.actionValidateRequestContainer.removeClass('hide');
    } else if (actionId == 1) {
      this.actionValidateRequestContainer.addClass('hide');
    }
    this._switchRequestMatrixVisibility(actionId);
    this.validatorStep3.validate();
  }

  _switchSignRequest(checked) {
    if (checked) {
      this.signerRequestContainer.removeClass('hide');
    } else {
      this.signerRequestContainer.addClass('hide');
    }
    this.validatorStep3.validate();
  }

  _save() {
    let idAccionPresupuesto = parseInt(
      this.defaultBudgetAction.find('input[name=default-action-budget]:checked').val()
    );
    let idAccionSolicitud = parseInt(
      this.defaultRequestAction.find('input[name=default-action-request]:checked').val()
    );
    let solicitarFirmaPresupuesto = this.physicalSignBudget.is(':checked');
    let solicitarFirmaSolicitud = this.physicalSignRequest.is(':checked');
    let solicitarPresupuestoMatricial = this.matrixBudget.is(':checked') && idAccionPresupuesto != 1;
    let solicitarSolicitudMatricial = this.matrixRequest.is(':checked') && idAccionSolicitud != 1;
    let importeMax = this.chkMaxAmount.is(':checked')
      ? null
      : parseInt(this.maxImport.attr('data-raw-value'));

    let responsibleSelector =
      this.responsibleSelector.getValue() != '' ? this.responsibleSelector.getValue() : null;
    let responsibleRequestSelector =
      this.responsibleRequestSelector.getValue() != ''
        ? this.responsibleRequestSelector.getValue()
        : null;
    let body = {
      idUsuario: this.userSelector.getValue(),
      idEmpresa: this.companySelector.getValue(),
      idAreaInversion: this.investmentAreaSelector.getValue(),
      importeMin: parseInt(this.minImport.attr('data-raw-value')),
      importeMax: importeMax,
      idAccionPresupuesto: idAccionPresupuesto,
      idAccionSolicitud: idAccionSolicitud,
      enPlan: parseInt(
        this.defaultFlowSelect.find('input[name=default-action-flow]:checked').val()
      ),
      tipologiaObra: parseInt(
        this.workTypeSelect.find('input[name=default-action-work-type]:checked').val()
      ),
      tipoSolicitud: parseInt(
        this.requestTypeSelect.find('input[name=default-action-request-type]:checked').val()
      ),
      solicitarFirmaPresupuesto: false,
      solicitarFirmaSolicitud: false,
      idResponsablePresupuesto: idAccionPresupuesto == 2 ? responsibleSelector : null,
      idResponsableFirmaPresupuesto: null,
      idResponsableSolicitud: idAccionSolicitud == 2 ? responsibleRequestSelector : null,
      idResponsableFirmaSolicitud: null,
      usuarioActualizacion: this.authenticationService.getAuthenticatedUser().login,
      idAprobadorMatricialPresupuesto1: solicitarPresupuestoMatricial
      ? this.budgetMat1Selector.getValue()
      : null,
      idAprobadorMatricialPresupuesto2: solicitarPresupuestoMatricial
      ? this.budgetMat2Selector.getValue()
      : null,
      idAprobadorMatricialSolicitud1: solicitarSolicitudMatricial
      ? this.requestMat1Selector.getValue()
      : null,
      idAprobadorMatricialSolicitud2: solicitarSolicitudMatricial
      ? this.requestMat2Selector.getValue()
      : null      
    };

    if (this._editMode) {
      this._editConfigFlowApproval(body);
    } else {
      this._createConfigFlowApproval(body);
    }
  }

  _createConfigFlowApproval(body) {
    this.parentModal.toggleLoadingIcon(true);

    this.httpService
      .post(this.httpService.config.configFlowCreate, body)
      .then(response => {
        this.value = true;
        this.parentModal.close();
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      })
      .finally(() => this.parentModal.toggleLoadingIcon(false));
  }

  _editConfigFlowApproval(body) {
    this.parentModal.toggleLoadingIcon(true);

    body.idFlujoAprobacionDefecto = this._params.idFlujoAprobacionDefecto;
    this.httpService
      .put(this.httpService.config.configFlowEdit, body)
      .then(response => {
        this.value = true;
        this.parentModal.close();
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      })
      .finally(() => this.parentModal.toggleLoadingIcon(false));
  }

  _getMatApprovalPermissions(idAreaInversion) {
    let idUsuario = this.userSelector.getValue();
    let idEmpresa = this.companySelector.getValue();    
    
    if (idUsuario != "" && idEmpresa != "" && idAreaInversion != "") {
      this.httpService
        .get(
          this.httpService.config.getMatrixApprovalPermissions +
          '?idUsuario=' +
          idUsuario +
          '&idEmpresa=' +
          idEmpresa +
          '&idAreaInversion=' +
          idAreaInversion
        )
        .then(response => {
          window._hasMatApprobalPermissions = response;
        })
        .catch(error => {
          this.toastService.exception(error.title);
          console.error(error);      
          window._hasMatApprobalPermissions = false;
        })
        .finally(f =>{
          let budgetActionId = this.defaultBudgetAction.find('input[name=default-action-budget]:checked').val();
          let requestActionId = this.defaultRequestAction.find('input[name=default-action-request]:checked').val();
          this._switchBudgetMatrixVisibility(budgetActionId);
          this._switchRequestMatrixVisibility(requestActionId);
        });
    }
  }

  _switchBudgetMatrixVisibility(actionId){
    if (actionId == 2 && window._hasMatApprobalPermissions) {
      this.budgetMatSwitch.removeClass('hide');
      this._switchMatBudget(this.matrixBudget.is(':checked'));     
    } else if (!window.loadingEditMode && (actionId == 1 || !window._hasMatApprobalPermissions)) {
      this.budgetMatSwitch.addClass('hide');
      this.matrixBudget1Container.addClass('hide');
      this.matrixBudget2Container.addClass('hide');
      $('#physical-matrix-budget').prop('checked', false);
      this.budgetMat1Selector.clearSelection();
      this.budgetMat2Selector.clearSelection();
    }
  }

  _switchRequestMatrixVisibility(actionId){
    if (actionId == 2 && window._hasMatApprobalPermissions) {
      this.requestMatSwitch.removeClass('hide');
      this._switchMatRequest(this.matrixRequest.is(':checked'));
    } else if (!window.loadingEditMode && (actionId == 1 || !window._hasMatApprobalPermissions)) {
      this.requestMatSwitch.addClass('hide');
      this.matrixRequest1Container.addClass('hide');
      this.matrixRequest2Container.addClass('hide');
      $('#physical-matrix-request').prop('checked', false);
      this.requestMat1Selector.clearSelection();
      this.requestMat2Selector.clearSelection();
    }
  }

  get result() {
    return this.value;
  }
}
