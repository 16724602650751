import './login.scss';
var htmlContent = require('./login.html');
import { Component } from '../../shared/components/base-component';
import $ from 'jquery';
import { HttpService } from '../../core/http/http';
import { ToastService } from '../../shared/services/toastService';
import { TranslationService } from '../../core/translation/translation';
import { AuthenticationService } from '../../shared/services/authenticationService';
import { Spinner } from '../../shared/components/spinner/spinner';
import { ViewsLoader } from '../../core/views/views-loader';
import { SessionStorageService } from '../../core/sessionStorage/sessionStorage';

const renderMarkup = () => htmlContent;

export class LoginPage extends Component {
  constructor() {
    super(renderMarkup());
    $(document).ready(() => {
      this.viewsLoader = new ViewsLoader();
      this.viewsLoader.loadView(new Spinner('big'), document.getElementById('loader-container'));
      $('#loader-container').addClass('hidden');
      this.httpService = new HttpService();
      this.toastService = new ToastService();
      this.authenticationService = new AuthenticationService();
      this.sessionStorageService = new SessionStorageService();
      this.init();
    });
  }

  init() {
    $('#username').keyup(() => this.sumbitAllowed());
    $('#login-submit').click(() => this.login());
    this.sumbitAllowed();
  }

  // Check if login button should be enabled or not
  sumbitAllowed() {
    var username = $('#username').val();
    if (username === '') {
      $('#login-submit').addClass('disabled');
    } else {
      $('#login-submit').removeClass('disabled');
    }
  }

  // Will trigger the login
  login() {
    var username = $('#username').val();
    $('#loader-container').removeClass('hidden');
    this.httpService
      .get(this.httpService.config.loadUserByLogin + username)
      .then(response => {
        this.sessionStorageService.clearData();
        TranslationService.instance.changeLanguage(response.idIdioma);
        this.authenticationService.setAuthenticatedUser(response, true);
        $('#loader-container').addClass('hidden');
        location.assign('/#home');
      })
      .catch(error => {
        $('#loader-container').addClass('hidden');
        this.toastService.exception(error.title);
        console.error(error);
      });
  }
}
