import '@babel/polyfill';
import 'jquery';
import 'popper.js';
import 'bootstrap';
import './app.scss';
import { Router } from './core/router/router';
import { RouterConfig } from './core/router/router-conf';
import { HttpService } from './core/http/http';
import { ToastService } from './shared/services/toastService';
import { TranslationService, TranslationServiceOptions } from './core/translation/translation';
import { AuthenticationService } from './shared/services/authenticationService';
import { AppConfig } from './core/app-config/app-config';

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

//This is used to prevent page to reload when token is begin refreshed
if (!inIframe()) {
  let router = new Router(
    new RouterConfig(),
    document.getElementById('view'),
    document.getElementById('side-nav')
  );

  //Instantiate for first time to load config
  let httpService = new HttpService();
  httpService.loadConfig().then(() => {
    let authenticationService = new AuthenticationService();
    let translationServiceOptions = new TranslationServiceOptions();
    translationServiceOptions.defaultLanguage = 'es_ES';

    let user = authenticationService.getAuthenticatedUser();
    if (user) {
      translationServiceOptions.currentLanguage = user.idIdioma;
    }
    TranslationService.init(translationServiceOptions);

    Promise.all([
      TranslationService.instance.loadResourceFile('es_ES', httpService.config.spanish),
      TranslationService.instance.loadResourceFile('en_US', httpService.config.english),
      TranslationService.instance.loadResourceFile('pl_PL', httpService.config.polish),
      httpService.loadMsalConfig(),
      AppConfig.instance.init()
    ]).then(() => router.initialize());
  });
}
