import './quitWithoutSave.scss';
var htmlContent = require('./quitWithoutSave.html');
import { BaseModalContent } from '../../shared/components/base-modal-content';

const renderMarkup = () => htmlContent;

export class QuitWithoutSaveComponent extends BaseModalContent {
  constructor(enableSave) {
    super(renderMarkup());
    this.value = null;
    this.enableSave = typeof enableSave !== 'undefined' && enableSave != null ? enableSave : true;
  }

  onOpened() {
    this.init();
  }

  init() {
    if (this.enableSave) this.parentModal.container.find('#btn-qws-save').click(() => this.save());
    else this.parentModal.container.find('#btn-qws-save').attr('disabled', '');
    this.parentModal.container.find('#btn-qws-cancel').click(() => this.cancel());
    this.parentModal.container.find('#btn-qws-continue').click(() => this.continue());
  }

  save() {
    this.value = {
      save: true,
      cancel: false,
      continue: false
    };
    this.parentModal.close();
  }

  cancel() {
    this.value = {
      save: false,
      cancel: true,
      continue: false
    };
    this.parentModal.close();
  }

  continue() {
    this.value = {
      save: false,
      cancel: false,
      continue: true
    };
    this.parentModal.close();
  }

  get result() {
    return this.value;
  }
}
