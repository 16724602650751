import { BudgetsPage } from '../../views/budgets/budgets.js';
import { PetitionsPage } from '../../views/petitions/petitions.js';
import { ReportsPage } from '../../views/reports/reports.js';
import { RequestsPage } from '../../views/requests/requests.js';
import { Err404Page } from '../../views/errors/404.js';
import { LoginPage } from '../../views/login/login.js';
import { HomePage } from '../../views/home/home.js';
import { BudgetDetailPage } from '../../views/budget-detail/budget-detail.js';
import { PetitionDetailPage } from '../../views/petition-detail/petition-detail.js';
import { ConfigurationPage } from '../../views/configuration/configuration.js';
import { RequestDetailPage } from '../../views/request-detail/request-detail.js';
import { RequestDetailEditorPage } from '../../views/request-detail-editor/request-detail-editor.js';
import { ConfigApprovalFlowPage } from '../../views/config-approval-flow/configApprovalFlow.js';
import { ConfigCurrencyChangePage } from '../../views/config-currency-change/configCurrencyChange.js';
import { ConfigMailNotificationsPage } from '../../views/config-mail-notifications/configMailNotifications.js';
import { ConfigPhysicSignPage } from '../../views/config-physic-sign/configPhysicSign.js';
import { ConfigUserPermissionsPage } from '../../views/config-user-permissions/configUserPermissions.js';
import { ConfigInvestmentAreasPage } from '../../views/config-investment-areas/configInvestmentAreas.js';
import { RequestsQueryPage } from '../../views/requests-query/requests-query.js';
import { BudgetsQueryPage } from '../../views/budgets-query/budgets-query.js';
import { AuthCallbackPage } from '../../views/auth-callback/auth-callback.js';

export class RouterConfig {
  constructor() {
    if (RouterConfig.inst) {
      return RouterConfig.inst;
    }

    this.routes = {
      budgets: this.budgets,
      'budgets/:id': this.budgetDetail,
      petitions: this.petitions,
      'petitions/:id': this.petitionDetails,
      reports: this.reports,
      requests: this.requests,
      'requests/create': this.requestCreate,
      'requests/:id/edit': this.requestEdit,
      'requests/:id': this.requestDetail,
      login: this.login,
      home: this.home,
      configuration: this.configuration,
      'configuration/approvalFlow': this.configApprovalFlow,
      'configuration/currencyChange': this.configCurrencyChange,
      'configuration/investmentAreas': this.configInvestmentAreas,
      'configuration/mailNotifications': this.configMailNotifications,
      'configuration/physicSign': this.configPhysicSign,
      'configuration/userPermissions': this.configUserPermissions,
      'reports/budgets-query': this.budgetsQuery,
      'reports/requests-query': this.requestQuery,
      auth_callback: this.authCallback,
      default: this.authCallback
    };

    return this;
  }

  getRoute = (routeName, additionalParams) => {
    let params = { ...additionalParams };
    for (let currRoute in this.routes) {
      // Reemplaza la barra inicial y final para que 'url/' sea igual de valido que 'url'
      routeName = routeName.toString().replace(/^\/|\/$/g, '');
      let matcher = '^' + currRoute.replace(/(:\w+)/g, '([\\w-]+)') + '$';
      if (routeName.match(matcher)) {
        let routeParts = currRoute.split('/');
        let urlParts = routeName.split('/');

        for (let i = 0; i < routeParts.length; i++) {
          if (urlParts[i] && ~routeParts[i].indexOf(':')) {
            params[routeParts[i].slice(1)] = urlParts[i];
          }
        }
        return params ? this.routes[currRoute](params) : this.routes[currRoute]();
      }
    }
    //return this.err404();
    return this.home();
  };

  budgets = () => {
    return {
      view: new BudgetsPage(),
      sideNav: true,
      isSecured: true,
      onlyAdmins: false,
      onlyDevs: false,
      onlyImpersonate: false
    };
  };

  budgetDetail = params => {
    return {
      view: new BudgetDetailPage(params),
      sideNav: true,
      isSecured: true,
      onlyAdmins: false,
      onlyDevs: false,
      onlyImpersonate: false
    };
  };

  petitions = () => {
    return {
      view: new PetitionsPage(),
      sideNav: true,
      isSecured: true,
      onlyAdmins: false,
      onlyDevs: false,
      onlyImpersonate: false
    };
  };

  petitionDetails = params => {
    return {
      view: new PetitionDetailPage(params),
      sideNav: true,
      isSecured: true,
      onlyAdmins: false,
      onlyDevs: false,
      onlyImpersonate: false
    };
  };

  reports = () => {
    return {
      view: new ReportsPage(),
      sideNav: true,
      isSecured: true,
      onlyAdmins: false,
      onlyDevs: false,
      onlyImpersonate: false
    };
  };

  requests = () => {
    return {
      view: new RequestsPage(),
      sideNav: true,
      isSecured: true,
      onlyAdmins: false,
      onlyDevs: false,
      onlyImpersonate: false
    };
  };

  requestDetail = params => {
    return {
      view: new RequestDetailPage(params),
      sideNav: true,
      isSecured: true,
      onlyAdmins: false,
      onlyDevs: false,
      onlyImpersonate: false
    };
  };

  requestEdit = params => {
    return {
      view: new RequestDetailEditorPage(params),
      sideNav: true,
      isSecured: true,
      onlyAdmins: false,
      onlyDevs: false,
      onlyImpersonate: false
    };
  };

  requestCreate = params => {
    if (typeof params === undefined || Object.entries(params).length === 0) {
      window.location.hash = 'requests';
    } else {
      return {
        view: new RequestDetailEditorPage(params),
        sideNav: true,
        isSecured: true,
        onlyAdmins: false,
        onlyDevs: false,
        onlyImpersonate: false
      };
    }
  };

  budgetsQuery = () => {
    return {
      view: new BudgetsQueryPage(),
      sideNav: true,
      isSecured: true,
      onlyAdmins: false,
      onlyDevs: false,
      onlyImpersonate: false
    };
  };

  login = () => {
    return {
      view: new LoginPage(),
      sideNav: false,
      isSecured: true,
      onlyAdmins: false,
      onlyDevs: false,
      onlyImpersonate: true
    };
  };

  home = () => {
    return {
      view: new HomePage(),
      sideNav: true,
      isSecured: true,
      onlyAdmins: false,
      onlyDevs: false,
      onlyImpersonate: false
    };
  };

  configuration = () => {
    return {
      view: new ConfigurationPage(),
      sideNav: true,
      isSecured: true,
      onlyAdmins: true,
      onlyDevs: false,
      onlyImpersonate: false
    };
  };

  configApprovalFlow = () => {
    return {
      view: new ConfigApprovalFlowPage(),
      sideNav: true,
      isSecured: true,
      onlyAdmins: true,
      onlyDevs: false,
      onlyImpersonate: false
    };
  };

  configCurrencyChange = () => {
    return {
      view: new ConfigCurrencyChangePage(),
      sideNav: true,
      isSecured: true,
      onlyAdmins: true,
      onlyDevs: false,
      onlyImpersonate: false
    };
  };

  configInvestmentAreas = () => {
    return {
      view: new ConfigInvestmentAreasPage(),
      sideNav: true,
      isSecured: true,
      onlyAdmins: true,
      onlyDevs: false,
      onlyImpersonate: false
    };
  };

  configMailNotifications = () => {
    return {
      view: new ConfigMailNotificationsPage(),
      sideNav: true,
      isSecured: true,
      onlyAdmins: true,
      onlyDevs: false,
      onlyImpersonate: false
    };
  };

  configPhysicSign = () => {
    return {
      view: new ConfigPhysicSignPage(),
      sideNav: true,
      isSecured: true,
      onlyAdmins: false,
      onlyDevs: true,
      onlyImpersonate: false
    };
  };

  configUserPermissions = () => {
    return {
      view: new ConfigUserPermissionsPage(),
      sideNav: true,
      isSecured: true,
      onlyAdmins: true,
      onlyDevs: false,
      onlyImpersonate: false
    };
  };

  requestQuery = () => {
    return {
      view: new RequestsQueryPage(),
      sideNav: true,
      isSecured: true,
      onlyAdmins: false,
      onlyDevs: false,
      onlyImpersonate: false
    };
  };

  authCallback = params => {
    return {
      view: new AuthCallbackPage(params),
      sideNav: false,
      isSecured: false,
      onlyAdmins: false,
      onlyDevs: false,
      onlyImpersonate: false
    };
  };

  err404 = () => {
    return { view: new Err404Page(), sideNav: true };
  };
}
