import './createAggregateBudget.scss';
var htmlContent = require('./createAggregateBudget.html');
import { HttpService } from '../../core/http/http';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { ToastService } from '../../shared/services/toastService';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import { Validator } from '../../core/validator/validator';
import { AuthenticationService } from '../../shared/services/authenticationService';

const renderMarkup = () => htmlContent;

export class CreateAggregateBudgetComponent extends BaseModalContent {
  constructor(selectedIds) {
    super(renderMarkup());
    this.toastService = new ToastService();
    this.httpService = new HttpService();
    this.authenticationService = new AuthenticationService();
    this.selectedIds = selectedIds || [];
  }

  onOpened() {
    this.init();
  }

  onLoading(callback) {
    Promise.all([this._loadCompanies()]).then(response => {
      callback();
    });
    return true;
  }

  _loadCompanies() {
    return this.httpService
      .get(
        this.httpService.config.loadCompaniesPerUser +
          this.authenticationService.getAuthenticatedUser().login
      )
      .then(response => {
        this._companies = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  _loadInvestmentAreas(idEmpresa) {
    return this.httpService
      .get(
        this.httpService.config.loadInvestmentAreasPerUserAndCompany +
          '?loginUsuario=' +
          this.authenticationService.getAuthenticatedUser().login +
          '&idEmpresa=' +
          idEmpresa +
          '&tieneQueSerAdmin=false'
      )
      .then(response => {
        this._investmentAreas = response;
        if (this._investmentAreas && this._investmentAreas.length > 0) {
          this.investmentAreaSelector.loadData(this._investmentAreas, 'idAreaInversion', 'nombre');
          this.investmentAreaSelector.clearSelection();
          this.investmentAreaSelector.removeRule('disabled');
        } else {
          this.investmentAreaSelector.setRule('disabled', 'disabled');
        }
        this.parentModal.toggleLoadingIcon(false);
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }


  init() {
    this.buildSelectors();
    this.chkSameStructure = this.parentModal.container.find('#chk-same-structure');
    this.chkTaxes = this.parentModal.container.find('#chk-taxes');
    this.parentModal.container.find('#btn-create-aggregate-budget').click(() => this.createAggregateBudget());
    this.parentModal.container.find('#btn-cancel').click(() => {
      this.parentModal.container.find('.icon-cerrar').click();
    });
  }

  buildSelectors() {
    this.companySelector = new DropDownComponent(document.getElementById('company-selector'));
    this.companySelector.setRules({
      required: true
    });
    this.companySelector.loadData(this._companies, 'idEmpresa', 'nombre');

    this.investmentAreaSelector = new DropDownComponent(
      document.getElementById('investment-area-selector')
    );
    this.investmentAreaSelector.setRules({
      required: true
    });

    this.investmentAreaSelector.setRule('disabled', 'disabled');

    this.companySelector.onChanges.listen(company => {
      if (company && company.idEmpresa) {
        this.parentModal.toggleLoadingIcon(true);
        this._loadInvestmentAreas(company.idEmpresa);
      } else {
        this.investmentAreaSelector.clearSelection();
        this.investmentAreaSelector.setRule('disabled', 'disabled');
      }
    });

    this.validator = new Validator(this.parentModal.container);
    this.validator.validate();
  }

  createAggregateBudget() {
    if (!this.validator.isValid) {
      return;
    }

    this.parentModal.toggleLoadingIcon(true);

    let body = {
      conImpuestos: this.chkTaxes.is(':checked'),
      copiaEstructuraAnoAnterior: this.chkSameStructure.is(':checked'),
      idEmpresa: this.companySelector.getValue(),
      idAreaInversion: this.investmentAreaSelector.getValue(),
      IdsPresupuesto: this.selectedIds,
      usuarioActualizacion: this.authenticationService.getAuthenticatedUser().login,
    };

    this.httpService
      .post(this.httpService.config.createNewAggregateBudget, body)
      .then(response => {
        this.value = response;
        this.parentModal.close();
      })
      .catch(error => {
        this.value = null;
        this.toastService.exception(error.title);
        console.error(error);
      })
      .finally(() => this.parentModal.toggleLoadingIcon(false));
  }

  get result() {
    return this.value;
  }
}
