import './configCurrencyChange.scss';
var htmlContent = require('./configCurrencyChange.html');
import { Component } from '../../shared/components/base-component';
import $ from 'jquery';
import { SessionStorageService } from '../../core/sessionStorage/sessionStorage';
import { ViewsLoader } from '../../core/views/views-loader';
import { Spinner } from '../../shared/components/spinner/spinner';
import { ConfigService } from '../../shared/services/config';
import { HttpService } from '../../core/http/http';
import { DatePicker } from '../../components/date-picker/datePicker';

const renderMarkup = () => htmlContent;

export class ConfigCurrencyChangePage extends Component {
  constructor() {
    super(renderMarkup());

    this.viewsLoader = new ViewsLoader();
    this.configService = ConfigService.getInstance();
    this.config = null;
    this.httpService = new HttpService();

    this._currencies = null;
    this._exchangeRate = null;
    this._table = null;

    $(() => {
      this._init();
    });
  }

  _init() {
    let selectNav = document.querySelectorAll('.links-item');
    let i = 0;
    selectNav.forEach(e => {
      if (i == 6) {
        $(e).addClass('active');
        i++;
      } else {
        $(e).removeClass('active');
        i++;
      }
    });
    this.viewsLoader.loadView(new Spinner('big'), document.querySelector('#loader-container'));
    $('#loader-container').removeClass('hidden');

    this._table = $('#table-content');
    Promise.all([this._loadCurrencies(), this._loadExchangeRate()]).then(() => {
      this._buildTable();
      $('#loader-container').addClass('hidden');
    });
  }

  _buildTable() {
    for (const exchange of this._exchangeRate) {
      let currency = this._currencies.find(x => x.idTipoMoneda == exchange.idTipoMonedaOrigen);
      this._table.append(
        `<div class="data">
          <div class="currency">
            ${currency.nombreLargo} (${currency.simbolo})
          </div>
          <div class="exchange-rate">
            ${exchange.valor.toString().replace('.', ',')}
          </div>
          <div class="date">
            ${DatePicker.toLocaleDateString(DatePicker.parseDate(exchange.fecValor))}
          </div>
        </div>`
      );
    }
  }

  _loadCurrencies() {
    return this.httpService
      .get(this.httpService.config.loadCurrencyTypes)
      .then(response => {
        this._currencies = response;
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }
  _loadExchangeRate() {
    return this.httpService
      .get(this.httpService.config.loadExchangeRates)
      .then(response => {
        this._exchangeRate = response;
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }
}
