import './concepts-mobile.scss';
var htmlContent = require('./concepts-mobile.html');
import $ from 'jquery';
import { HttpService } from '../../core/http/http';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { ToastService } from '../../shared/services/toastService';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import { RequestTypeEnum } from '../../config/enums';
import { TranslationService } from '../../core/translation/translation';
import { DatePicker } from '../date-picker/datePicker';

const renderMarkup = () => htmlContent;

export class ConceptsMobileComponent extends BaseModalContent {
  constructor(options) {
    super(renderMarkup());
    this._options = options || new ConceptsMobileOptions();
    this.toastService = new ToastService();
    this.httpService = new HttpService();
    this.value = null;
  }

  onOpened() {
    this.init();
  }

  init() {
    this._render();
    this.enableButtons();
  }

  _render() {
    if (this._options.requestType == RequestTypeEnum.Investment) {
      this._renderInvestmentConcepts();
    } else {
      this._renderDisinvestmentConcepts();
    }
    this.bindEvents();
  }
  _renderInvestmentConcepts() {
    let htmlContent = '';
    this._options.data.forEach((concept, index) => {
      let planHtml = concept.idLineaPresupuesto
        ? `<div class="plan"><span>${TranslationService.instance.translate('onplan')}</span></div>`
        : `<div class="plan no"><span>${TranslationService.instance.translate(
            'notonplan'
          )}</span></div>`;
      htmlContent += `
      <div class="card" data-id="${concept.idConcepto}">
        <div class="card-row">
          <div class="card-row-element all">
            <span class="title">${concept.descripcion ? concept.descripcion : ''}</span>
            <span class="icon icon-opciones ${this._options.readonly == true ? 'hidden' : ''}">
              <div id="context-menu" class="context-menu">
              <div class="context-menu-item edit-concept" data-pos="${index}">
                  <span class="icon-editar"></span>
                  <span class="text">${TranslationService.instance.translate(
                    'req-detail-btn-edit'
                  )}</span>
                </div>
                <div class="context-menu-item delete-concept" data-pos="${index}">
                  <span class="icon-eliminar"></span>
                  <span class="text">${TranslationService.instance.translate(
                    'cocepts-mobile-delete-concept'
                  )}</span>
                </div> 
              </div>
            </span>
          </div>
        </div>
        <div class="card-row">
          <div class="card-row-element third">
            <span class="label">${TranslationService.instance.translate(
              'req-detail-concept-title'
            )}</span>
            <span class="description">${index + 1}</span>
          </div>
          <div class="card-row-element third">
            <span class="label">${TranslationService.instance.translate('common-units')}</span>
            <span class="description">${concept.unidades ? concept.unidades : ''}</span>
          </div>
          <div class="card-row-element third">
            <span class="label">${TranslationService.instance.translate('onplan')}</span>
            <div class="plan-container">
            ${planHtml}
            </div>
          </div>
        </div>
        <div class="card-row">
          <div class="card-row-element half">
            <span class="label">${TranslationService.instance.translate(
              'req-detail-concept-aquisition-value'
            )}</span>
            <span class="description">${
              concept.conceptoInversion.importeLocal
                ? this._options.localCurrency == true
                  ? concept.conceptoInversion.importeLocal / this._options.unitDiv
                  : concept.conceptoInversion.importe / this._options.unitDiv
                : ''
            }</span>
          </div>
        </div>
        <div class="card-row">
          <div class="card-row-element all">
            <span class="label">${TranslationService.instance.translate(
              'req-detail-concept-budget-item'
            )}</span>
            <span class="description"
              >${concept.idLineaPresupuesto ? concept.partidaPresupuestaria : '-'}</span
            >
          </div>
        </div>
      </div>`;
    });
    this.parentModal.container.find('#concepts-cards-container').html(htmlContent);
  }
  _renderDisinvestmentConcepts() {
    let htmlContent = '';
    this._options.data.forEach((concept, index) => {
      let planHtml = concept.idLineaPresupuesto
        ? `<div class="plan"><span>${TranslationService.instance.translate('onplan')}</span></div>`
        : `<div class="plan no"><span>${TranslationService.instance.translate(
            'notonplan'
          )}</span></div>`;
      htmlContent += `
      <div class="card" data-id="${concept.idConcepto}">
        <div class="card-row">
          <div class="card-row-element all">
            <span class="title">${concept.descripcion ? concept.descripcion : ''}</span>
            <span class="icon icon-opciones ${this._options.readonly == true ? 'hidden' : ''}">
              <div id="context-menu" class="context-menu">
                <div class="context-menu-item edit-concept" data-pos="${index}">
                  <span class="icon-editar"></span>
                  <span class="text">${TranslationService.instance.translate(
                    'req-detail-btn-edit'
                  )}</span>
                </div>
                <div class="context-menu-item delete-concept" data-pos="${index}">
                  <span class="icon-eliminar"></span>
                  <span class="text">${TranslationService.instance.translate(
                    'cocepts-mobile-delete-concept'
                  )}</span>
                </div> 
              </div>
            </span>
          </div>
        </div>
        <div class="card-row">
          <div class="card-row-element third">
            <span class="label">${TranslationService.instance.translate(
              'req-detail-concept-title'
            )}</span>
            <span class="description">${index + 1}</span>
          </div>
          <div class="card-row-element third">
            <span class="label">${TranslationService.instance.translate('common-units')}</span>
            <span class="description">${concept.unidades ? concept.unidades : ''}</span>
          </div>
          <div class="card-row-element third">
            <span class="label">${TranslationService.instance.translate('onplan')}</span>
            <div class="plan-container">
              ${planHtml}
            </div>
          </div>
        </div>
        <div class="card-row">
          <div class="card-row-element half">
            <span class="label">${TranslationService.instance.translate(
              'req-detail-concept-acquisition-date'
            )}</span>
            <span class="description"> ${
              concept.conceptoDesinversion.fechaAdquisicion
                ? DatePicker.toLocaleDateString(
                    DatePicker.parseDate(concept.conceptoDesinversion.fechaAdquisicion)
                  )
                : ''
            }</span>
          </div>
          <div class="card-row-element half">
            <span class="label">${TranslationService.instance.translate(
              'req-detail-concept-aquisition-value'
            )}</span>
            <span class="description">${
              concept.conceptoDesinversion.valorAdquisicionLocal
                ? this._options.localCurrency == true
                  ? concept.conceptoDesinversion.valorAdquisicionLocal / this._options.unitDiv
                  : concept.conceptoDesinversion.valorAdquisicion / this._options.unitDiv
                : ''
            }</span>
          </div>
        </div>
        <div class="card-row">
          <div class="card-row-element half">
            <span class="label">${TranslationService.instance.translate(
              'req-detail-concept-contable-value'
            )}</span>
            <span class="description">${
              concept.conceptoDesinversion.valorContableLocal
                ? this._options.localCurrency == true
                  ? concept.conceptoDesinversion.valorContableLocal / this._options.unitDiv
                  : concept.conceptoDesinversion.valorContable / this._options.unitDiv
                : ''
            }</span>
          </div>
          <div class="card-row-element half">
            <span class="label">${TranslationService.instance.translate(
              'req-detail-concept-disinvestment'
            )}</span>
            <span class="description">${
              concept.conceptoDesinversion.desinversionEurosLocal
                ? this._options.localCurrency == true
                  ? concept.conceptoDesinversion.desinversionEurosLocal / this._options.unitDiv
                  : concept.conceptoDesinversion.desinversionEuros / this._options.unitDiv
                : ''
            }</span>
          </div>
        </div>
        <div class="card-row">
          <div class="card-row-element all">
            <span class="label">${TranslationService.instance.translate(
              'req-detail-concept-budget-item'
            )}</span>
            <span class="description"
              >${concept.idLineaPresupuesto ? concept.partidaPresupuestaria : '-'}</span
            >
          </div>
        </div>
      </div>`;
    });
    this.parentModal.container.find('#concepts-cards-container').html(htmlContent);
  }

  bindEvents() {
    //Floating menu
    this.parentModal.container
      .find('.icon-opciones')
      .off()
      .click(event => {
        this.parentModal.container.find('.context-menu.active').removeClass('active');
        var contextMenuElement = $(event.currentTarget).find('.context-menu')[0];
        contextMenuElement.classList.add('active');
      });
    this.parentModal.container.off().click(event => {
      if (!event.target.classList.contains('icon-opciones')) {
        this.parentModal.container.find('.context-menu.active').removeClass('active');
      }
    });
    //Edit concept
    this.parentModal.container
      .find('.context-menu-item.edit-concept')
      .off()
      .click(event => this.editConcept(event.currentTarget));
    //Remove concept
    this.parentModal.container
      .find('.context-menu-item.delete-concept')
      .off()
      .click(event => this.deleteConcept(event.currentTarget));
  }

  enableButtons() {
    $('#mob-btn-new-concept').click(() => this.newConcept());
    $('.buttons-area').toggleClass('hidden', this._options.readonly);
    $('.buttons').toggleClass('hidden', this._options.readonly);
    $('#mob-btn-save-concepts').click(() => this.save());
    $('#btn-cancel').click(() => this.cancel());
  }

  cancel() {
    this.parentModal.close();
  }

  save() {
    this.value = {
      action: 'save-concepts',
      concept: null,
      concepts: this._options.data
    };
    this.parentModal.close();
  }

  newConcept() {
    this.value = {
      action: 'new-concept',
      concept: null,
      concepts: this._options.data
    };
    this.parentModal.close();
  }

  editConcept(currentTarget) {
    let concept = this._options.data.filter(
      (c, i) => i == Number(currentTarget.getAttribute('data-pos'))
    )[0];
    this.value = {
      action: 'edit-concept',
      concept: concept,
      conceptPosition: Number(currentTarget.getAttribute('data-pos')),
      concepts: this._options.data
    };
    this.parentModal.close();
  }

  deleteConcept(currentTarget) {
    this._options.data = this._options.data.filter(
      (c, i) => i != Number(currentTarget.getAttribute('data-pos'))
    );
    this._render();
  }

  get result() {
    return this.value;
  }
}

export class ConceptsMobileOptions {
  data = null;
  readonly = true;
  localCurrency = true;
  unitDiv = 1;
  requestType = RequestTypeEnum.Investment;
}
