import { Rule } from '../rule';

export class RequiredRule extends Rule {
  get name() {
    return 'required';
  }

  getErrorMessage(element, lang) {
    return 'El campo es obligatorio';
  }

  isValid(element) {
    return element.val().trim() !== '';
  }
}
