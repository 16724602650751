import * as Msal from 'msal';
import { LocalStorageService } from '../../core/localStorage/localStorage';

export class AuthenticationService {
  constructor() {
    if (AuthenticationService.inst) {
      return AuthenticationService.inst;
    }
    AuthenticationService.inst = this;

    //MSAL config
    this.msalConfig = null;
    //Create MSAL instance
    this.msalInstance = null;

    //Login request scopes for MSAL
    this.msalAccessTokenRequest = null;

    this.localStorageService = new LocalStorageService();
    return this;
  }

  /**
   * Build all MSAL instance once MSAL config is loaded
   * @param {Object} config MSAL config
   */
  init(config) {
    this.msalConfig = config.config;
    this.msalAccessTokenRequest = {
      scopes: config.scopes
    };
    this.msalInstance = new Msal.UserAgentApplication(this.msalConfig);
    //Configure MSAL redirect callback
    this.msalInstance.handleRedirectCallback((error, response) => {});
  }

  /**
   * Manage the obtention of an access token with MSAL. MSAL search for a valid token in cache storage
   *  1. Valid token found => return from cache.
   *  2. Not valid token found => MSAL open an iFrame to renew the token.
   */
  async obtainToken() {
    await this.msalInstance
      .acquireTokenSilent(this.msalAccessTokenRequest)
      .then(accessTokenResponse => {
        let accessToken = accessTokenResponse.accessToken;
        this.localStorageService.setItem('token', accessToken);
      })
      .catch(error => {
        //Acquire token silent failure, and send an interactive request
        if (error.errorMessage.indexOf('interaction_required') !== -1) {
          this.msalInstance.acquireTokenRedirect(this.msalAccessTokenRequest)
          .catch(error => {
            console.log('error en acquireTokenRedirect: ' + error);
          });
        }
      });
  }

  /**
   * Start MSAL authentication
   */
  startAuthentication() {

    // En IOS no funciona bien el loginRedirect si tiene instalado el MS Authenticator
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/3003
    var is_chrome_ios = /CriOS\/[\d]+/.test(navigator.userAgent);
    var is_edge_ios = /EdgiOS\/[\d]+/.test(navigator.userAgent);
  

    if (is_chrome_ios || is_edge_ios){
      this.msalInstance.loginPopup(this.msalAccessTokenRequest)
      .then(() =>{
        this.msalInstance.acquireTokenSilent(this.msalAccessTokenRequest)
        .catch(error => {
          console.log('Error en acquireTokenSilent: ' + error);
        });
      })
      .catch(error => {
        // salta en IOS, por eso hay que refrescar la página sin cache
        console.log('Error en loginPopup: ' + error + "UA: " + navigator.userAgent);
      });
    }
    else
    {
      this.msalInstance.loginRedirect(this.msalAccessTokenRequest)
    }
  }

  /**
   * Set the user logged in the app
   * @param {object} user The logged user in the app
   */
  setAuthenticatedUser(user, isImpersonating) {
    this.localStorageService.setItem('user', JSON.stringify(user));
    if (!isImpersonating) {
      this.localStorageService.setItem('canImpersonate', user.indDesarrollador);
    }
  }

  /**
   * Return if the original user is allowed to impersonate
   */
  canImpersonate() {
    return this.localStorageService.getItem('canImpersonate') === 'true';
  }

  /**
   * Return if the current user is being impersonated
   */
  isImpersonating() {
    let msalLogin = this.getMsalAccount().userName.split('@')[0].trim();
    let userLogin = this.getAuthenticatedUser().login.trim();
    return  userLogin !== msalLogin;
  }

  /**
   * Return the authenticated user from SessionStorage or null.
   */
  getAuthenticatedUser() {
    return JSON.parse(this.localStorageService.getItem('user')) || null;
  }

  /**
   * Return the token from SessionStorage or null.
   */
  getToken() {
    return `Bearer ${this.localStorageService.getItem('token')}` || null;
  }

  /**
   * Return the account from MSAL
   */
  getMsalAccount() {
    return this.msalInstance.getAccount();
  }

  /**
   * Return true in case that user or token are stored on ¿SessionStorage? if not return false
   */
  isAuthenticated() {
    return this.msalInstance != null && this.msalInstance.getAccount() != null;
  }

  isAuthenticationCompleted() {
    return this.getAuthenticatedUser() != null;
  }

  /**
   * Redirects the user to the logout Microsoft URL.
   */
  logout() {
    this.localStorageService.clearData();
    this.msalInstance.logout();
  }

  /**
   * Redirects the user to the logout Microsoft URL without any prompt.
   */
  forceLogout() {
    this.localStorageService.clearData();
    this.msalInstance.clearCache();
    let redirectUri = this.msalInstance.getPostLogoutRedirectUri();
    window.location.href = `https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=${redirectUri}`;
  }
}
