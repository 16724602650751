import { HttpService } from '../../core/http/http';
import { ToastService } from './toastService';
import { AuthenticationService } from './authenticationService';

export class ConfigService {
  static _instance = null;
  static getInstance() {
    if (ConfigService._instance == null) {
      ConfigService._instance = new ConfigService();
    }
    return ConfigService._instance;
  }

  constructor() {
    this._http = new HttpService();
    this._toasts = new ToastService();
    this._auth = new AuthenticationService();
  }

  saveGlobalConfig(data) {
    return this._http.put(this._http.config.updateGlobalConfig, data).catch(error => {
      this._toasts.error(error.title, error.message);
      console.error(error);
    });
  }

  getGlobalConfig() {
    return this._http
      .get(
        `${this._http.config.loadGlobalConfig}?loginUsuario=${
          this._auth.getAuthenticatedUser().login
        }`
      )
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  getMatrixConfig(id, flowType) {
    if (flowType == 'budget') {
      return this._http
        .get(
          `${this._http.config.loadMatrixConfigBudget}?loginUsuario=${
            this._auth.getAuthenticatedUser().login
          }&idPresupuesto=${id}`
        )
        .catch(error => {
          this._toasts.error(error.title, error.message);
          console.error(error);
        });
    } else {
      return this._http
        .get(
          `${this._http.config.loadMatrixConfigRequest}?loginUsuario=${
            this._auth.getAuthenticatedUser().login
          }&idSolicitud=${id}`
        )
        .catch(error => {
          this._toasts.error(error.title, error.message);
          console.error(error);
        });
    }
  }

  getIsMatrixApproval(id, flowType) {
    if (flowType == 'budget') {
      return this._http
        .get(
          `${this._http.config.loadIsMatrixApprovalBudget}?loginUsuario=${
            this._auth.getAuthenticatedUser().login
          }&idPresupuesto=${id}`
        )
        .catch(error => {
          this._toasts.error(error.title, error.message);
          console.error(error);
        });
    } else {
      return this._http
        .get(
          `${this._http.config.loadIsMatrixApprovalRequest}?loginUsuario=${
            this._auth.getAuthenticatedUser().login
          }&idSolicitud=${id}`
        )
        .catch(error => {
          this._toasts.error(error.title, error.message);
          console.error(error);
        });
    }
  }

  getFlowConfig(id, flowType) {
    if (flowType == 'budget') {
      return this._http
        .get(
          `${this._http.config.loadNextStepConfigBudget}?loginUsuario=${
            this._auth.getAuthenticatedUser().login
          }&idPresupuesto=${id}`
        )
        .catch(error => {
          this._toasts.error(error.title, error.message);
          console.error(error);
        });
    } else {
      return this._http
        .get(
          `${this._http.config.loadNextStepConfigRequest}?loginUsuario=${
            this._auth.getAuthenticatedUser().login
          }&idSolicitud=${id}`
        )
        .catch(error => {
          this._toasts.error(error.title, error.message);
          console.error(error);
        });
    }
  }

}
