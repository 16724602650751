import './disbursementDates.scss';
var htmlContent = require('./disbursementDates.html');
import $ from '../../core/jquery/jquery-global';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { ToastService } from '../../shared/services/toastService';
import { HttpService } from '../../core/http/http';
import { DatePicker } from '../date-picker/datePicker';
import { InputNumeric, InputNumericOptions } from '../input-numeric/inputNumeric';
import { Validator } from '../../core/validator/validator';
import { BudgetAmount } from '../budget-tree/budget-manager';
import { TranslationService } from '../../core/translation/translation';
import { BudgetLinesActionsEnums } from '../../config/enums';
const renderMarkup = () => htmlContent;

export class DisbursementDates extends BaseModalContent {
  static MANUAL = 'manual';
  static AUTOMATIC = 'automatic';

  /**
   * BudgetLine reffered
   * @param {BudgetLine} budgetLine
   */
  constructor(budgetLine) {
    super(renderMarkup());
    this.httpService = new HttpService();
    this._toasts = new ToastService();
    this.view = DisbursementDates.MANUAL;
    this.budgetLine = budgetLine;
    this.isDirty = false;
  }

  /**
   * Event fired after the modal creation
   */
  onCreated() {
    this._init();
  }

  /**
   * Initialize the component
   */
  _init() {
    this._newDate = this.parentModal.container.find('.new-date').click(() => {
      this._addNewDate();
      this.isDirty = true;
    });
    this.parentModal.container.find('.cancel').click(() => this.cancel());
    this.parentModal.container.find('.save').click(() => this.save());
    this.parentModal.container.find('.calculate').click(() => this.calculate());
    this.parentModal.container.find('.automatic-complete').click(() => this._toggleView());

    this.automaticView = this.parentModal.container.find('.automatic-view');
    this.manualView = this.parentModal.container.find('.manual-view');
    this.listValues = this.manualView.find('.list-values');
    this.saveButton = this.parentModal.container.find('.save');

    $('.start-date, .end-date').each((i, e) =>
      DatePicker.createWithoutMaxDate(e, this.budgetLine.getBudgetAmountsExercise())
    );
    $('.amount').each((i, e) => InputNumeric.createDecimal(e, true));
    $('.quantity').each((i, e) => InputNumeric.createInteger(e));

    this.manualViewValidator = new Validator(this.manualView);
    this.manualViewValidator.onValidationChanges.listen(isValid =>
      this._newDate.toggleClass('disabled', !isValid)
    );
    this.manualViewValidator.validate();

    this.automaticViewValidator = new Validator(this.automaticView);
    this.automaticViewValidator.validate();

    this.manualViewTotal = InputNumeric.createDecimal(
      this.parentModal.container.find('.total-amount'),
      true
    );

    let quartersTotalInput = InputNumeric.createDecimal($('.total-amount-quarters'));
    let quartersTotal = this.budgetLine.getAnnualAmountsFromQuarters(true);
    quartersTotalInput.parse(quartersTotal != null && !isNaN(quartersTotal) ? quartersTotal : 0);

    this._loadData();
    this._updateTotal();
  }

  /**
   * Load the existing data into the modal, if no data is provided it will insert a new line
   */
  _loadData() {
    let amounts = this.budgetLine.getAmounts();

    if (amounts.length > 0) {
      for (let i = 0; i < amounts.length; i++) {
        this._addNewDate(amounts[i]);
      }
    } else {
      this._addNewDate();
    }
  }

  /**
   * Toggle between the automatic and manual views
   */
  _toggleView() {
    if (this.view == DisbursementDates.MANUAL) {
      this.view = DisbursementDates.AUTOMATIC;
      this.parentModal.container
        .find('#modal-title')
        .html(
          '<i class="icon-subir-nivel"></i> ' +
            TranslationService.instance.translate('bd-modal-disbur-dates-auto-complete-title')
        );
      this.parentModal.container.find('#modal-title i').click(() => this._toggleView());
    } else {
      this.view = DisbursementDates.MANUAL;
      this.parentModal.container
        .find('#modal-title')
        .html(TranslationService.instance.translate('bd-modal-disbur-dates-title'));
    }
    this.manualView.toggleClass('hidden');
    this.automaticView.toggleClass('hidden');
  }

  /**
   * Adds a new or existing date to the list
   */
  _addNewDate(budgetLineAmount) {
    let html = `<div class="item">
        <input type="text" readonly class="form-control date" required/>
        <input type="text" class="form-control amount" required/>
        <span class="icon-cerrar close"></span>
      </div>`;
    let temp = $(document.createElement('div'));
    temp.html(html);

    let inputNumeric = InputNumeric.createDecimal(temp.find('.amount'), true);
    inputNumeric.element.keyup(() => this._updateTotal());
    inputNumeric.element.change(() => (this.isDirty = true));
    if (budgetLineAmount) {
      inputNumeric.parse(budgetLineAmount.getLocalAmount());
    }

    let date = temp.find('.date');
    if (budgetLineAmount) {
      date.val(
        DatePicker.toLocaleDateString(DatePicker.parseDate(budgetLineAmount.getPaymentDate()))
      );
    }

    let inputDate = DatePicker.createWithoutMaxDate(
      date,
      this.budgetLine.getBudgetAmountsExercise()
    );

    inputDate.element.change(() => (this.isDirty = true));
    temp.find('.icon-cerrar').click(e => this._removeDate(e));

    $('.item.text.dates').show();
    this.listValues.append(temp.children());
    this.manualViewValidator.refreshFields();
  }

  /**
   * Remove a date from the list
   */
  _removeDate(event) {
    let qtyDates = $(event.target)
      .closest('.list-values')
      .children().length;

    $(event.target)
      .closest('.item')
      .remove();

    if (qtyDates == 1) {
      $('.item.text.dates').hide();
    }
    this.manualViewValidator.refreshFields();
    this.isDirty = true;
    this._updateTotal();
  }

  /**
   * Update the total value by adding all the amounts in the list
   */
  _updateTotal() {
    let total = 0;
    this.listValues.find('.amount').each((index, element) => {
      if (element.dataset.rawValue) {
        total += parseFloat(element.dataset.rawValue);
      }
    });
    this.manualViewTotal.parse(Math.round(total * 100) / 100);
  }

  /**
   * Cancel the modal
   */
  cancel() {
    this.isDirty = false;
    this.parentModal.close();
  }

  /**
   * Manual View
   */
  _getResult() {
    let result = [];

    this.listValues.children().each((i, e) => {
      let date = $(e)
        .find('.date')
        .val();

      let o = result.find(x => x.getPaymentDate() == date);

      if (o == null) {
        o = new BudgetAmount();
        o.setPaymentDate(DatePicker.parseDate(date).toISOString());
      }

      o.setLocalAmount(
        $(e)
          .find('.amount')
          .data('raw-value') + (o.getLocalAmount() ? o.getLocalAmount() : 0)
      );

      result.push(o);
    });

    return result;
  }

  _clear() {
    this.listValues.html('');
  }

  /**
   * Automatic view
   */
  calculate() {
    if (!this.automaticViewValidator.isValid) {
      return;
    }

    this._clear();

    let startDate = DatePicker.parseDate(this.automaticView.find('.start-date').val());
    let endDate = DatePicker.parseDate(this.automaticView.find('.end-date').val());

    let quantity = parseInt(this.automaticView.find('.quantity').val());
    let frecuency = this.automaticView.find('.frecuency').val();
    let result = [];

    while (startDate <= endDate) {
      var budgetAmount = new BudgetAmount();
      budgetAmount.setPaymentDate(startDate.toISOString());
      result.push(budgetAmount);

      //Move forward the date
      switch (frecuency) {
        case 'days':
          startDate.setDate(startDate.getDate() + quantity);
          break;
        case 'weeks':
          startDate.setDate(startDate.getDate() + quantity * 7);
          break;
        case 'months':
          startDate.setMonth(startDate.getMonth() + quantity);
          break;
        default:
          throw 'Frecuency not valid';
      }
    }

    let amount = parseFloat(this.automaticView.find('.amount').attr('data-raw-value'));
    result.map(o => {
      o.setLocalAmount(amount);
      return o;
    });

    if (result.length > 0) {
      for (let i = 0; i < result.length; i++) {
        this._addNewDate(result[i]);
      }
    }

    this._toggleView();
    this._updateTotal();
  }

  /**
   * Save the modal
   */
  save() {
    let totalAmountQuarters = $('.total-amount-quarters').val();
    let totalAmount = $('.total-amount').val();

    if (totalAmountQuarters != totalAmount) {
      this._toasts.error(
        TranslationService.instance.translate('exception-title'),
        TranslationService.instance.translate('bd-modal-edit-disbursement-total-error')
      );
      return;
    }

    if (!this.manualViewValidator.isValid) {
      return;
    }

    let result = this._getResult();
    if (result.length > 0) {
      this.budgetLine.replaceAmountsWithoutPetitionsCheck(result);
    } else {
      this.budgetLine.setQuarterAmountsToNull();
      this.budgetLine.emptyAmounts();
    }

    this.budgetLine.setActionId(BudgetLinesActionsEnums.Update);
    this.parentModal.close();
  }

  onClosing(forceExit) {
    if (forceExit) {
      this.isDirty = false;
    }
  }
  get result() {
    return this.isDirty;
  }
}
