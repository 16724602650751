import './cashFlow.scss';
var htmlContent = require('./cashFlow.html');
import $ from '../../core/jquery/jquery-global';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { DatePicker } from '../date-picker/datePicker';
import { InputNumeric, InputNumericOptions } from '../input-numeric/inputNumeric';
import { Validator } from '../../core/validator/validator';
import { BudgetAmount } from '../budget-tree/budget-manager';
import { TranslationService } from '../../core/translation/translation';
const renderMarkup = () => htmlContent;

export class CashFlowComponent extends BaseModalContent {
  static MANUAL = 'manual';
  static AUTOMATIC = 'automatic';

  constructor(options) {
    super(renderMarkup());
    this._options = options || new CashFlowOptions();
    this.view = CashFlowComponent.MANUAL;
    this.value = null;
  }

  /**
   * Event fired after the modal creation
   */
  onCreated() {
    this._init();
  }

  /**
   * Initialize the component
   */
  _init() {
    this._enableButtons();
    this.manualView = this.parentModal.container.find('.manual-view');
    this.automaticView = this.parentModal.container.find('.automatic-view');
    this.listValues = this.manualView.find('.list-values');

    if (this._options.totalAmount) {
      this.automaticView.find('.amount').val(this._options.totalAmount.element.val());
    }

    DatePicker.createWithoutMaxDate(this.automaticView.find('.start-date'), this._options.year);
    DatePicker.createWithoutMaxDate(this.automaticView.find('.end-date'), this._options.year);
    InputNumeric.createInteger(this.automaticView.find('.quantity'));
    InputNumeric.createDecimal(this.automaticView.find('.amount'));

    this.manualViewValidator = new Validator(this.manualView);
    this.manualViewValidator.onValidationChanges.listen(isValid =>
      this._newDate.toggleClass('disabled', !isValid)
    );
    this.manualViewValidator.validate();

    this.automaticViewValidator = new Validator(this.automaticView);
    this.automaticViewValidator.validate();

    this.total = InputNumeric.createDecimal(this.parentModal.container.find('.total-amount'));

    if (this._options.readOnly) {
      this.parentModal.container.find('.cash-flow-component').addClass('read-only');
    }
    this._loadData();
    this._updateTotal();
  }

  _enableButtons() {
    this._newDate = this.parentModal.container.find('.new-date');
    this._cancel = this.parentModal.container.find('.cancel');
    this._save = this.parentModal.container.find('.save');
    this._automaticComplete = this.parentModal.container.find('.automatic-complete');
    this._calculateButton = this.parentModal.container.find('.calculate');

    if (this._options.readOnly) {
      this._newDate.remove();
      this._cancel.remove();
      this._save.remove();
      this._automaticComplete.remove();
    } else {
      this._newDate.click(() => {
        this._addNewDate();
      });
      this._cancel.click(() => this.cancel());
      this._save.click(() => this.save());
      this._automaticComplete.click(() => this._toggleView());
      this._calculateButton.click(() => this._calculate());
    }
  }

  _calculate() {
    if (!this.automaticViewValidator.isValid) {
      return;
    }

    this.listValues.html('');

    let startDate = DatePicker.parseDate(this.automaticView.find('.start-date').val());
    let endDate = DatePicker.parseDate(this.automaticView.find('.end-date').val());
    let amount = parseFloat(this.automaticView.find('.amount').attr('data-raw-value'));
    let quantity = parseFloat(this.automaticView.find('.quantity').attr('data-raw-value'));

    let totalDays = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
    let daysPerTerm = totalDays / quantity;
    let amountPerTerm = amount / quantity;

    for (let i = 0; i < quantity; i++) {
      this._addNewDate(startDate.toISOString(), amountPerTerm);
      startDate.setDate(startDate.getDate() + daysPerTerm);
    }

    this._updateTotal();
    this.manualViewValidator.validate();
    this._toggleView();
  }

  /**
   * Toggle between the automatic and manual views
   */
  _toggleView() {
    if (this.view == CashFlowComponent.MANUAL) {
      this.view = CashFlowComponent.AUTOMATIC;
      this.parentModal.container
        .find('#modal-title')
        .html(
          '<i class="icon-subir-nivel"></i> ' +
            TranslationService.instance.translate('req-detail-cashflow-auto-complete-title')
        );
      this.parentModal.container.find('#modal-title i').click(() => this._toggleView());
    } else {
      this.view = CashFlowComponent.MANUAL;
      this.parentModal.container.find('#modal-title').html('Flujo de caja previsto');
    }
    this.manualView.toggleClass('hidden');
    this.automaticView.toggleClass('hidden');
  }

  /**
   * Load the existing data into the modal, if no data is provided it will insert a new line
   */
  _loadData() {
    if (this._options.data.length > 0) {
      for (const o of this._options.data) {
        this._addNewDate(o.fecha, this._options.localCurrency == true ? o.importeLocal : o.importe);
      }
    } else {
      this._addNewDate();
    }
  }

  /**
   * Adds a new or existing date to the list
   */
  _addNewDate(date, amount) {
    let html = `<div class="item">
        <input type="text" readonly class="form-control date" required data-validator-date/>
        <input type="text" class="form-control amount" required min="0.01"/>
        <span class="icon-cerrar close"></span>
      </div>`;
    let temp = $(document.createElement('div'));
    temp.html(html);

    let inputNumeric = InputNumeric.createDecimal(temp.find('.amount'));
    inputNumeric.element.keyup(() => this._updateTotal());
    inputNumeric.element.change(() => (this.isDirty = true));
    if (amount) {
      inputNumeric.parse(amount);
    }

    let inputDate = temp.find('.date');
    if (date) {
      inputDate.val(DatePicker.toLocaleDateString(DatePicker.parseDate(date)));
    }
    DatePicker.createWithoutMaxDate(inputDate, this._options.year);

    let closeIcon = temp.find('.icon-cerrar');

    if (this._options.readOnly) {
      inputNumeric.element.attr('disabled', 'disabled');
      inputDate.attr('disabled', 'disabled');
      closeIcon.remove();
    } else {
      closeIcon.click(e => this._removeDate(e));
    }

    this.listValues.append(temp.children());
    this.manualViewValidator.refreshFields();
  }

  /**
   * Remove a date from the list
   */
  _removeDate(event) {
    let qtyDates = $(event.target)
      .closest('.list-values')
      .children().length;

    if (qtyDates > 1) {
      $(event.target)
        .closest('.item')
        .remove();

      this._updateTotal();
      this.manualViewValidator.refreshFields();
    }
  }

  /**
   * Update the total value by adding all the amounts in the list
   */
  _updateTotal() {
    let total = 0;
    this.listValues.find('.amount').each((index, element) => {
      if (element.dataset.rawValue) {
        total += parseFloat(element.dataset.rawValue);
      }
    });
    this.total.parse(Math.round(total * 100) / 100);
  }

  /**
   * Cancel the modal
   */
  cancel() {
    this.value = null;
    this.parentModal.close();
  }

  /**
   * Save the modal
   */
  save() {
    if (!this.manualViewValidator.isValid) {
      return;
    }

    let result = [];

    this.listValues.children().each((i, e) => {
      let date = $(e)
        .find('.date')
        .val();

      let amount = $(e)
        .find('.amount')
        .data('raw-value');

      result.push({
        fecha: DatePicker.parseDate(date).toISOString(),
        importeLocal: amount,
        importe: amount
      });
    });

    this.value = result;
    this.parentModal.close();
  }

  get result() {
    return this.value;
  }
}

export class CashFlowOptions {
  year = 2020;
  readOnly = false;
  localCurrency = true;
  data = null;
}
