import './manage-permission.scss';
var htmlContent = require('./manage-permission.html');
import { HttpService } from '../../core/http/http';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { ToastService } from '../../shared/services/toastService';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import { Validator } from '../../core/validator/validator';
import { TranslationService } from '../../core/translation/translation';
import { AuthenticationService } from '../../shared/services/authenticationService';

const renderMarkup = () => htmlContent;

export class permissionManagerComponent extends BaseModalContent {
  constructor(params) {
    super(renderMarkup());
    this.toastService = new ToastService();
    this.httpService = new HttpService();
    this.authenticationService = new AuthenticationService();
    this._params = params;
  }

  onOpened() {
    this.init();
  }

  onLoading(callback) {
    Promise.all([this._loadUsers(), this._loadCompanies()]).then(response => {
      callback();
    });
    return true;
  }

  _loadUsers() {
    return this.httpService
      .get(this.httpService.config.loadUsersDragados)
      .then(response => {
        this._users = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  _loadCompanies() {
    return this.httpService
      .get(
        this.httpService.config.loadCompaniesPerUserWithAdmin +
          '?loginUsuario=' +
          this.authenticationService.getAuthenticatedUser().login
      )
      .then(response => {
        this._companies = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  _loadInvestmentAreas(idEmpresa) {
    return this.httpService
      .get(
        this.httpService.config.loadInvestmentAreasPerUserAndCompany +
          '?loginUsuario=' +
          this.authenticationService.getAuthenticatedUser().login +
          '&idEmpresa=' +
          idEmpresa +
          '&tieneQueSerAdmin=true'
      )
      .then(response => {
        this._investmentAreas = response;
        if (this._investmentAreas && this._investmentAreas.length > 0) {
          this.investmentAreaSelector.loadData(this._investmentAreas, 'idAreaInversion', 'nombre');
          this.investmentAreaSelector.clearSelection();
          this.fillInvestmentArea();
          this.investmentAreaSelector.removeRule('disabled');
        } else {
          this.investmentAreaSelector.setRule('disabled', 'disabled');
        }
        this.parentModal.toggleLoadingIcon(false);
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  init() {
    this.buildSelectors();
    this.chkIsAdmin = this.parentModal.container.find('#chk-is-profile-type');
    this.chkMatrixApproval = this.parentModal.container.find('#chk-matrixApproval');
    this.chkBudgetRead = this.parentModal.container.find('#chk-budget-read');
    this.chkBudgetModify = this.parentModal.container.find('#chk-budget-modify');
    this.chkBudgetValidate = this.parentModal.container.find('#chk-budget-validate');
    this.chkBudgetApprove = this.parentModal.container.find('#chk-budget-approve');
    this.chkRequestRead = this.parentModal.container.find('#chk-request-read');
    this.chkRequestModify = this.parentModal.container.find('#chk-request-modify');
    this.chkRequestValidate = this.parentModal.container.find('#chk-request-validate');
    this.chkRequestApprove = this.parentModal.container.find('#chk-request-approve');
    this.chkRequestCancel = this.parentModal.container.find('#chk-request-cancel');

    if (typeof this._params === 'undefined') {
      // create mode
      this.parentModal.container.find('#manage-permission').click(() => this.createPermission());
      // change the button text to "create"
      this.parentModal.container
        .find('#manage-permission-txt')
        .text(TranslationService.instance.translate('config-cp-create-button'));
    } else {
      // edit mode
      this.parentModal.container.find('#manage-permission').click(() => this.editPermission());
      // add save icon to the button
      this.parentModal.container.find('#manage-permission').addClass('with-icon save');
      this.parentModal.container.find('#manage-permission-icon').addClass('icon icon-guardar');
      // change the button text to "edit"
      this.parentModal.container
        .find('#manage-permission-txt')
        .text(TranslationService.instance.translate('config-ep-save-button'));
      // fill the fields with the params data
      this.fillEditFields();
    }
    this.parentModal.container.find('#btn-cancel').click(() => {
      this.value = null;
      this.parentModal.close();
    });
  }

  buildSelectors() {
    this.userSelector = new DropDownComponent(document.getElementById('user-selector'));
    this.userSelector.setRules({
      required: true
    });
    this.userSelector.loadData(this._users, 'login', 'nombre');

    this.companySelector = new DropDownComponent(document.getElementById('company-selector'));
    this.companySelector.setRules({
      required: true
    });
    this.companySelector.loadData(this._companies, 'idEmpresa', 'nombre');

    this.investmentAreaSelector = new DropDownComponent(
      document.getElementById('investment-area-selector')
    );
    this.investmentAreaSelector.setRules({
      required: true
    });

    this.investmentAreaSelector.setRule('disabled', 'disabled');

    this.companySelector.onChanges.listen(company => {
      if (company && company.idEmpresa) {
        this.parentModal.toggleLoadingIcon(true);
        this._loadInvestmentAreas(company.idEmpresa);
      } else {
        this.investmentAreaSelector.clearSelection();
        this.investmentAreaSelector.setRule('disabled', 'disabled');
      }
    });

    this.validator = new Validator(this.parentModal.container);
    this.validator.validate();
  }

  fillEditFields() {
    if (typeof this._params.idUsuarioNavigationLogin !== 'undefined') {
      this.userSelector.setSelected(this._params.idUsuarioNavigationLogin, 'login', 'nombre');
    }
    if (typeof this._params.esAdmin !== 'undefined') {
      this.chkIsAdmin.prop('checked', this._params.esAdmin);
    }
    if (typeof this._params.idEmpresa !== 'undefined') {
      this.companySelector.setSelected(this._params.idEmpresa, 'idEmpresa', 'nombre');
    }

    if (typeof this._params.idAreaInversion !== 'undefined') {
      this._investmentAreaToFill = this._params.idAreaInversion;
    }

    if (typeof this._params.solicitaAprobacionMatricial !== 'undefined') {
      this.chkMatrixApproval.prop('checked', this._params.solicitaAprobacionMatricial);
    }
    if (typeof this._params.leePresupuesto !== 'undefined') {
      this.chkBudgetRead.prop('checked', this._params.leePresupuesto);
    }
    if (typeof this._params.modificaPresupuesto !== 'undefined') {
      this.chkBudgetModify.prop('checked', this._params.modificaPresupuesto);
    }
    if (typeof this._params.validaPresupuesto !== 'undefined') {
      this.chkBudgetValidate.prop('checked', this._params.validaPresupuesto);
    }
    if (typeof this._params.apruebaPresupuesto !== 'undefined') {
      this.chkBudgetApprove.prop('checked', this._params.apruebaPresupuesto);
    }
    if (typeof this._params.leeSolicitud !== 'undefined') {
      this.chkRequestRead.prop('checked', this._params.leeSolicitud);
    }
    if (typeof this._params.modificaSolicitud !== 'undefined') {
      this.chkRequestModify.prop('checked', this._params.modificaSolicitud);
    }
    if (typeof this._params.validaSolicitud !== 'undefined') {
      this.chkRequestValidate.prop('checked', this._params.validaSolicitud);
    }
    if (typeof this._params.apruebaSolicitud !== 'undefined') {
      this.chkRequestApprove.prop('checked', this._params.apruebaSolicitud);
    }
    if (typeof this._params.anulaSolicitud !== 'undefined') {
      this.chkRequestCancel.prop('checked', this._params.anulaSolicitud);
    }
  }

  fillInvestmentArea() {
    if (typeof this._investmentAreaToFill !== 'undefined') {
      this.investmentAreaSelector.setSelected(
        this._investmentAreaToFill,
        'idAreaInversion',
        'nombre'
      );
      // Removes it because it has already been filled
      this._investmentAreaToFill = undefined;
    }
  }

  // Tiggered when create button is clicked
  createPermission() {
    if (!this.validator.isValid) {
      return;
    }

    this.parentModal.toggleLoadingIcon(true);

    let body = {
      loginUsuario: this.userSelector.getValue(),
      esAdmin: this.chkIsAdmin.is(':checked'),
      idEmpresa: this.companySelector.getValue(),
      idAreaInversion: this.investmentAreaSelector.getValue(),
      solicitaAprobacionMatricial: this.chkMatrixApproval.is(':checked'),
      leePresupuesto: this.chkBudgetRead.is(':checked'),
      modificaPresupuesto: this.chkBudgetModify.is(':checked'),
      validaPresupuesto: this.chkBudgetValidate.is(':checked'),
      apruebaPresupuesto: this.chkBudgetApprove.is(':checked'),
      leeSolicitud: this.chkRequestRead.is(':checked'),
      modificaSolicitud: this.chkRequestModify.is(':checked'),
      validaSolicitud: this.chkRequestValidate.is(':checked'),
      apruebaSolicitud: this.chkRequestApprove.is(':checked'),
      anulaSolicitud: this.chkRequestCancel.is(':checked'),
      usuarioActualizacion: this.authenticationService.getAuthenticatedUser().login
    };

    this.parentModal.toggleLoadingIcon(true);
    this.httpService
      .post(this.httpService.config.createPermission, body)
      .then(response => {
        this.value = true;
        this.parentModal.close();
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      })
      .finally(() => this.parentModal.toggleLoadingIcon(false));
  }

  editPermission() {
    if (!this.validator.isValid) {
      return;
    }

    this.parentModal.toggleLoadingIcon(true);

    let body = {
      idPermisoUsuario: this._params.idPermisoUsuario,
      loginUsuario: this.userSelector.getValue(),
      esAdmin: this.chkIsAdmin.is(':checked'),
      idEmpresa: this.companySelector.getValue(),
      idAreaInversion: this.investmentAreaSelector.getValue(),
      solicitaAprobacionMatricial: this.chkMatrixApproval.is(':checked'),
      leePresupuesto: this.chkBudgetRead.is(':checked'),
      modificaPresupuesto: this.chkBudgetModify.is(':checked'),
      validaPresupuesto: this.chkBudgetValidate.is(':checked'),
      apruebaPresupuesto: this.chkBudgetApprove.is(':checked'),
      leeSolicitud: this.chkRequestRead.is(':checked'),
      modificaSolicitud: this.chkRequestModify.is(':checked'),
      validaSolicitud: this.chkRequestValidate.is(':checked'),
      apruebaSolicitud: this.chkRequestApprove.is(':checked'),
      anulaSolicitud: this.chkRequestCancel.is(':checked'),
      usuarioActualizacion: this.authenticationService.getAuthenticatedUser().login
    };

    this.parentModal.toggleLoadingIcon(true);
    this.httpService
      .put(this.httpService.config.editPermission, body)
      .then(response => {
        this.value = true;
        this.parentModal.close();
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      })
      .finally(() => this.parentModal.toggleLoadingIcon(false));
  }

  get result() {
    return this.value;
  }
}
