import './editGroup.scss';
var htmlContent = require('./editGroup.html');
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { HttpService } from '../../core/http/http';
import { Validator } from '../../core/validator/validator';

const renderMarkup = () => htmlContent;

export class EditGroupComponent extends BaseModalContent {
  constructor(options, textValue, participation, estructuradoEnUte, textSubtitle) {
    super(renderMarkup());
    this.value = null;
    this.options = options;
    this.textValue = textValue;
    this.participation = participation;
    this.estructuradoEnUte = estructuradoEnUte;
    this.textSubtitle = textSubtitle;
  }

  onCreated() {
    this.init();
  }

  init() {
    if (!this.estructuradoEnUte) {
      this.parentModal.container.find('.participation-group').hide();
      this.parentModal.container.find('.subtitle').text(this.textSubtitle);
    }

    this.parentModal.container.find('.cancel').click(() => this.cancel());
    this.parentModal.container.find('.edit').click(() => this.create());
    this.parentModal.container.find('.name').val(this.textValue);
    this.parentModal.container.find('.participation').val(this.participation);
    this.validator = new Validator(this.parentModal.container);
    this.validator.validate();
  }

  cancel() {
    this.value = null;
    this.parentModal.close();
  }

  create() {
    if (!this.validator.isValid) {
      return;
    }

    this.value = {
      name: this.parentModal.container.find('.name').val(),
      participation: this.parentModal.container.find('.participation').val()
    };
    this.parentModal.close();
  }

  get result() {
    return this.value;
  }
}
