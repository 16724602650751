import './navigation-card.scss';
let componentTemplate = require('./navigation-card.html');

import { Component } from '../base-component';
import { Event } from '../../../core/events/event';
import { ToastService } from '../../services/toastService';
import { TranslationService } from '../../../core/translation/translation';

export class NavigationCardComponent {
  constructor(options) {
    this._options = options;

    this._container = null;
    this._currentItem = null;
    this.onNavigation = new Event();
    this.visualizationOptions = new Event();
    this.onSave = new Event();
    this.afterDraw = new Event();
    this._parentTree = [];
  }

  get breadCrumbs() {
    return this._parentTree;
  }
  get currentNode() {
    return this._parentTree.length > 0 ? this._parentTree[this._parentTree.length - 1] : null;
  }

  updateData(data) {
    if (!this._options.comparerProperty) {
      throw 'comparer property required';
    }

    this._options.data = data;

    if (this._currentItem) {
      let nodeBreadcrumbs = this._findInTree(this._options.data[0], this._currentItem);

      if (nodeBreadcrumbs) {
        this._parentTree = nodeBreadcrumbs;
        this._currentItem = nodeBreadcrumbs[nodeBreadcrumbs.length - 1];
      }
    }

    this.draw();
  }

  init() {
    this._container = document.querySelector(this._options.selector);
    this._container.innerHTML = this._options.componentTemplate;

    if (typeof this._options.cardTemplate === 'string') {
      let temp = document.createElement('div');
      temp.innerHTML = this._options.cardTemplate;
      this._cardTemplate = temp.firstChild;
    } else if (this._options.cardTemplate) {
      this._cardTemplate = this._options.cardTemplate;
    } else {
      this._cardTemplate = this._container.querySelector('.card-template');
    }

    this._cardContainer = this._container.querySelector('.card-container');
    this._navigationUp = this._container.querySelector('.navigation-up');
    this._navigationUp.addEventListener('click', () => this.navigateUp());

    //Visualization options button
    this._btnVisualizationOptions = this._container.querySelector('#btn-visualization-options');
    if (this._btnVisualizationOptions != null) {
      this._btnVisualizationOptions.addEventListener('click', () =>
        this._triggerVisualizationOptions()
      );
    }

    //Save budget on petitions
    this._btnSave = this._container.querySelector('#btn-save-mobile');
    if (this._btnSave != null) {
      this._btnSave.addEventListener('click', () => this._triggerSave());
    }

    if (this._options.onRenderComponent) {
      this._options.onRenderComponent(this._container);
    }

    if (!Array.isArray(this._options.data)) {
      this._options.data = [this._options.data];
    }

    this._setFirstNode();

    this.draw();
    this.onNavigation.execute();
  }

  _setFirstNode() {
    if (!this._options.startNode || !this._options.comparerProperty) {
      return;
    }

    let nodeBreadcrumbs = this._findInTree(this._options.data[0], this._options.startNode);
    if (nodeBreadcrumbs) {
      this._parentTree = nodeBreadcrumbs;
      nodeBreadcrumbs.pop();
      this._currentItem = nodeBreadcrumbs[nodeBreadcrumbs.length - 1];
    }
  }

  _findInTree(startNode, node) {
    if (startNode[this._options.comparerProperty] == node[this._options.comparerProperty]) {
      return [startNode];
    }

    if (!this._hasChildren(startNode)) {
      return null;
    }

    for (const child of this._getChildren(startNode)) {
      let result = this._findInTree(child, node);
      if (result) {
        result.unshift(startNode);
        return result;
      }
    }

    return null;
  }

  navigateUp() {
    this._parentTree.pop();
    if (this._parentTree.length === 0) {
      this._currentItem = null;
    } else {
      this._currentItem = this._parentTree[this._parentTree.length - 1];
    }
    this.draw();
    this.onNavigation.execute();
  }

  draw() {
    this._cardContainer.innerHTML = '';
    let isRoot = this._currentItem == null;

    this._drawTitle();

    if (isRoot) {
      let root = this._options.data[0];

      if (root == null || typeof root == 'undefined') {
        return;
      }

      this._drawCard(root);
    } else {
      if (this._hasChildren(this._currentItem)) {
        let children = this._getChildren(this._currentItem);

        if (this._options.sort) {
          children.sort(this._options.sort);
        }

        for (const child of children) {
          this._drawCard(child);
        }
      }
    }

    this.afterDraw.execute();
  }

  navigateDown(node) {
    this._parentTree.push(node);
    this._currentItem = node;
    this.draw();
    this.onNavigation.execute();
  }

  _drawCard(element) {
    let cardTemplate = this._cardTemplate.cloneNode(true);
    cardTemplate.classList.remove('card-template');

    let linkDown = cardTemplate.querySelector('.navigation-down');
    if (this._hasChildren(element)) {
      linkDown.addEventListener('click', () => {
        this.navigateDown(element);
      });
    } else {
      linkDown.remove();
    }

    this._cardContainer.appendChild(cardTemplate);
    if (this._options.onRenderCard) {
      this._options.onRenderCard(cardTemplate, element, this._parentTree);
    }
  }

  _drawTitle() {
    if (this._parentTree.length === 0) {
      this._navigationUp.textContent = '';
    } else {
      if (this._options.onRenderBreadcrumbs) {
        this._options.onRenderBreadcrumbs(this._navigationUp, this._parentTree);
      } else {
        this._navigationUp.textContent = this._parentTree.map((x, index) => index).join(' / ');
      }
    }
  }

  _triggerVisualizationOptions() {
    this.visualizationOptions.execute();
  }

  _triggerSave() {
    this.onSave.execute();
  }

  _hasChildren(element) {
    return (
      typeof this._getChildren(element) !== 'undefined' &&
      this._getChildren(element) !== null &&
      this._getChildren(element).length > 0
    );
  }
  _getChildren(element) {
    return element[this._options.navigationProperty];
  }
}
export class NavigationCardOptions {
  selector = null;
  data = null;
  navigationProperty = null;
  onRenderCard = null;
  onRenderComponent = null;
  componentTemplate = componentTemplate;
  cardTemplate = null;
  onRenderBreadcrumbs = null;
  startNode = null;
  comparerProperty = null;
  sort = null;
}
