import './reports.scss';
var htmlContent = require('./reports.html');
import { Component } from '../../shared/components/base-component';
import { ModalComponent } from '../../shared/components/modal';
import $ from 'jquery';
import { visualizeBudgetReportComponent } from '../../components/visualize-budget-report/visualize-budget-report';
import { TranslationService } from '../../core/translation/translation';
import { graphicComparativeReportComponent } from '../../components/graphic-comparative-report/graphic-comparative-report';
import { graphicBudgetComponent } from '../../components/graphic-budget/graphic-budget';
import { visualizeAggregatedBudgetsReportComponent } from '../../components/visualize-aggregated-budgets-report/visualize-aggregated-budgets-report';

const renderMarkup = () => htmlContent;

export class ReportsPage extends Component {
  constructor() {
    super(renderMarkup());
    // Page logic
    this.init();
  }

  init() {
    $(document).ready(() => {
      let selectNav = document.querySelectorAll('.links-item');
      let i = 0;
      selectNav.forEach(e => {
        if (i == 3) {
          $(e).addClass('active');
          i++;
        } else {
          $(e).removeClass('active');
          i++;
        }
      });
      this.enableButtons();
    });
  }

  enableButtons() {
    $('#btn-toogle-menu').click(() => this._toggleMenu());
    $('#reports-budgets').click(() => this.visualizeBudgetReport());
    $('#graphic-comparative').click(() => this.graphicComparativeReport());
    $('#graphic-breakdown').click(() => this.graphicBreakdownReport());
    $('#aggregated-budgets-report').click(() => this.visualizeAggregatedBudgetsReport());
    $('.btn-sidenav').click(() => $('.sidenav').addClass('menuComplete'));
  }

  visualizeBudgetReport() {
    let modal = new ModalComponent(
      TranslationService.instance.translate('reports-br-modal-title'),
      new visualizeBudgetReportComponent()
    );
    modal.onClosed.listen(() => {
      if (modal.result != null) {
        //visualize(modal.result.exercise, ...);
      }
    });
  }

  graphicComparativeReport() {
    let modal = new ModalComponent(
      TranslationService.instance.translate('reports-gc-modal-title'),
      new graphicComparativeReportComponent()
    );
    modal.onClosed.listen(() => {
      if (modal.result != null) {
        //visualize(modal.result.exercise, ...);
      }
    });
  }

  graphicBreakdownReport() {
    let modal = new ModalComponent(
      TranslationService.instance.translate('reports-gb-modal-title'),
      new graphicBudgetComponent()
    );
    modal.onClosed.listen(() => {
      if (modal.result != null) {
        //visualize(modal.result.exercise, ...);
      }
    });
  }

  visualizeAggregatedBudgetsReport() {
    let modal = new ModalComponent(
      TranslationService.instance.translate('reports-abr-modal-title'),
      new visualizeAggregatedBudgetsReportComponent()
    );
    modal.onClosed.listen(() => {
      if (modal.result != null) {
        //visualize(modal.result.exercise, ...);
      }
    });
  }

  _toggleMenu() {
    $('.sidenav').toggleClass('menuComplete');
  }
}
