import './manageInvestmentArea.scss';
var htmlContent = require('./manageInvestmentArea.html');
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { HttpService } from '../../core/http/http';
import { Validator } from '../../core/validator/validator';
import { ToastService } from '../../shared/services/toastService';
import { AuthenticationService } from '../../shared/services/authenticationService';
import { TranslationService } from '../../core/translation/translation';

const renderMarkup = () => htmlContent;

export class ManageInvestmentArea extends BaseModalContent {
  constructor(options) {
    super(renderMarkup());
    this.httpService = new HttpService();
    this.toastService = new ToastService();
    this.authenticationService = new AuthenticationService();
    this._options = options;
    this.value = null;
  }

  onOpened() {
    this.init();
  }

  init() {
    if (this._options.data.firmantes) {
      this.parentModal.container.find('.firmantes').show();
      this.parentModal.container.find('#data-firmantes-company').text(TranslationService.instance.translate('config-ia-modal-dataCompany') + ": " + this._options.data.Empresa);
      for (let index = 1; index <= 6; index++) {
        this.parentModal.container.find('#data-type-f' + index).text(TranslationService.instance.translate('config-ia-modal-signer') + " " + index);
      }
      this.parentModal.container.find('#firma1').val(this._options.data.firma1);
      this.parentModal.container.find('#firma2').val(this._options.data.firma2);
      this.parentModal.container.find('#firma3').val(this._options.data.firma3);
      this.parentModal.container.find('#firma4').val(this._options.data.firma4);
      this.parentModal.container.find('#firma5').val(this._options.data.firma5);
      this.parentModal.container.find('#firma6').val(this._options.data.firma6);
    }
    else {
      this.parentModal.container.find('.firmantes').hide();
    }

    this.parentModal.container.find('.cancel').click(() => this.cancel());
    this.parentModal.container.find('.save').click(() => this.save());
    this.parentModal.container.find('#data-type').html(this._options.type);
    this.chkStructured = this.parentModal.container.find('#chk-structured');
    this.chkSegmented = this.parentModal.container.find('#chk-segmented');

    if (this._options.data.nombre != null) {
      this.parentModal.container.find('#name').val(this._options.data.nombre);
    }
    if (this._options.data.estructuradoEnUte != null) {
      this.chkStructured.prop('checked', this._options.data.estructuradoEnUte);
    }
    if (this._options.data.segmentadaTipologiaObra != null) {
      this.chkSegmented.prop('checked', this._options.data.segmentadaTipologiaObra);
    }

    this.validator = new Validator(this.parentModal.container);
    this.validator.validate();
  }

  cancel() {
    this.value = null;
    this.parentModal.close();
  }

  save() {
    if (!this.validator.isValid) {
      return;
    }

    this.parentModal.toggleLoadingIcon(true);

    this._options.data.nombre = this.parentModal.container.find('#name').val();
    this._options.data.estructuradoEnUte = this.chkStructured.is(':checked');
    this._options.data.segmentadaTipologiaObra = this.chkSegmented.is(':checked');
    
    this._options.data.usuarioActualizacion = this.authenticationService.getAuthenticatedUser().login;
    
    this._options.data.firma1 = this.parentModal.container.find('#firma1').val();
    this._options.data.firma2 = this.parentModal.container.find('#firma2').val();
    this._options.data.firma3 = this.parentModal.container.find('#firma3').val();
    this._options.data.firma4 = this.parentModal.container.find('#firma4').val();
    this._options.data.firma5 = this.parentModal.container.find('#firma5').val();
    this._options.data.firma6 = this.parentModal.container.find('#firma6').val();
    this._options.data.idEmpresa = this._options.data.idEmpresa;

    if (this._options.isCreate == true) {
      this.httpService
        .post(this._options.actionUrl, this._options.data)
        .then(response => {
          this.value = true;
          this.parentModal.close();
        })
        .catch(error => {
          this._toasts.error(error.title, error.message);
          console.error(error);
        })
        .finally(() => this.parentModal.toggleLoadingIcon(false));
    } else {
      this.httpService
        .put(this._options.actionUrl, this._options.data)
        .then(response => {
          this.value = true;
          this.parentModal.close();
        })
        .catch(error => {
          this._toasts.error(error.title, error.message);
          console.error(error);
        })
        .finally(() => this.parentModal.toggleLoadingIcon(false));
    }
  }

  get result() {
    return this.value;
  }
}

export class ManageInvestmentAreaOptions {
  type = null;
  isCreate = true;
  actionUrl = null;
  data = null;
}
