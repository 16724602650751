import './configure-alerts.scss';
var htmlContent = require('./configure-alerts.html');
import { HttpService } from '../../core/http/http';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { ToastService } from '../../shared/services/toastService';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import { Validator } from '../../core/validator/validator';
import { AuthenticationService } from '../../shared/services/authenticationService';

const renderMarkup = () => htmlContent;

export class configureAlertsComponent extends BaseModalContent {
  constructor(params) {
    super(renderMarkup());
    this.toastService = new ToastService();
    this.httpService = new HttpService();
    this.authenticationService = new AuthenticationService();
    this._params = params;
  }

  onOpened() {
    this.init();
  }

  onLoading(callback) {
    Promise.all([this._loadUsers(), this._loadCompanies()]).then(response => {
      callback();
    });
    return true;
  }

  _loadUsers() {
    return this.httpService
      .get(this.httpService.config.loadUsers)
      .then(response => {
        this._users = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  _loadCompanies() {
    return this.httpService
      .get(
        this.httpService.config.loadCompaniesPerUser +
          this.authenticationService.getAuthenticatedUser().login
      )
      .then(response => {
        this._companies = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  _loadInvestmentAreas(idEmpresa) {
    return this.httpService
      .get(
        this.httpService.config.loadInvestmentAreasPerUserAndCompany +
          '?loginUsuario=' +
          this.authenticationService.getAuthenticatedUser().login +
          '&idEmpresa=' +
          idEmpresa +
          '&tieneQueSerAdmin=false'
      )
      .then(response => {
        this._investmentAreas = response;
        if (this._investmentAreas && this._investmentAreas.length > 0) {
          this.investmentAreaSelector.loadData(this._investmentAreas, 'idAreaInversion', 'nombre');
          this.investmentAreaSelector.clearSelection();
          this.fillInvestmentArea();
          this.investmentAreaSelector.removeRule('disabled');
        } else {
          this.investmentAreaSelector.setRule('disabled', 'disabled');
        }
        this.parentModal.toggleLoadingIcon(false);
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  init() {
    this.buildSelectors();
    this.chkBudget1 = this.parentModal.container.find('#chk-budget-1');
    this.chkBudget2 = this.parentModal.container.find('#chk-budget-2');
    this.chkBudget3 = this.parentModal.container.find('#chk-budget-3');
    this.chkBudget4 = this.parentModal.container.find('#chk-budget-4');
    this.chkRequest1 = this.parentModal.container.find('#chk-request-1');
    this.chkRequest2 = this.parentModal.container.find('#chk-request-2');
    this.chkRequest3 = this.parentModal.container.find('#chk-request-3');
    this.chkRequest4 = this.parentModal.container.find('#chk-request-4');
    this.chkRequest5 = this.parentModal.container.find('#chk-request-5');

    if (typeof this._params === 'undefined') {
      // create mode
      this.parentModal.container.find('#save-mail-alert').click(() => this.createMailAlertConfig());
    } else {
      // edit mode
      this.parentModal.container.find('#save-mail-alert').click(() => this.editMailAlertConfig());
      // fill the fields with the params data
      this.fillEditFields();
    }
    this.parentModal.container.find('#btn-cancel').click(() => {
      this.value = false;
      this.parentModal.close();
    });
  }

  buildSelectors() {
    this.userSelector = new DropDownComponent(document.getElementById('user-selector'));
    this.userSelector.setRules({
      required: true
    });
    this.userSelector.loadData(this._users, 'idUsuario', 'nombre');

    this.companySelector = new DropDownComponent(document.getElementById('company-selector'));
    this.companySelector.setRules({
      required: true
    });
    this.companySelector.loadData(this._companies, 'idEmpresa', 'nombre');

    this.investmentAreaSelector = new DropDownComponent(
      document.getElementById('investment-area-selector')
    );
    this.investmentAreaSelector.setRules({
      required: true
    });

    this.investmentAreaSelector.setRule('disabled', 'disabled');

    this.companySelector.onChanges.listen(company => {
      if (company && company.idEmpresa) {
        this.parentModal.toggleLoadingIcon(true);
        this._loadInvestmentAreas(company.idEmpresa);
      } else {
        this.investmentAreaSelector.clearSelection();
        this.investmentAreaSelector.setRule('disabled', 'disabled');
      }
    });

    this.validator = new Validator(this.parentModal.container);
    this.validator.validate();
  }

  fillEditFields() {
    if (typeof this._params.idUsuario !== 'undefined') {
      this.userSelector.setSelected(this._params.idUsuario, 'idUsuario', 'nombre');
    }
    if (typeof this._params.idEmpresa !== 'undefined') {
      this.companySelector.setSelected(this._params.idEmpresa, 'idEmpresa', 'nombre');
    }

    if (typeof this._params.idAreaInversion !== 'undefined') {
      this._investmentAreaToFill = this._params.idAreaInversion;
    }

    if (typeof this._params.borradorPresupuesto !== 'undefined') {
      this.chkBudget1.prop('checked', this._params.borradorPresupuesto);
    }
    if (typeof this._params.estadoPresupuesto !== 'undefined') {
      this.chkBudget2.prop('checked', this._params.estadoPresupuesto);
    }
    if (typeof this._params.responsablePresupuesto !== 'undefined') {
      this.chkBudget3.prop('checked', this._params.responsablePresupuesto);
    }
    if (typeof this._params.aprobarPresupuesto !== 'undefined') {
      this.chkBudget4.prop('checked', this._params.aprobarPresupuesto);
    }
    if (typeof this._params.borradorSolicitud !== 'undefined') {
      this.chkRequest1.prop('checked', this._params.borradorSolicitud);
    }
    if (typeof this._params.estadoSolicitud !== 'undefined') {
      this.chkRequest2.prop('checked', this._params.estadoSolicitud);
    }
    if (typeof this._params.responsableSolicitud !== 'undefined') {
      this.chkRequest3.prop('checked', this._params.responsableSolicitud);
    }
    if (typeof this._params.aprobarSolicitud !== 'undefined') {
      this.chkRequest4.prop('checked', this._params.aprobarSolicitud);
    }
    if (typeof this._params.aprobarRechazarEnFlujoSolicitud !== 'undefined') {
      this.chkRequest5.prop('checked', this._params.aprobarRechazarEnFlujoSolicitud);
    }
  }

  fillInvestmentArea() {
    if (typeof this._investmentAreaToFill !== 'undefined') {
      this.investmentAreaSelector.setSelected(
        this._investmentAreaToFill,
        'idAreaInversion',
        'nombre'
      );
      // Removes it because it has already been filled
      this._investmentAreaToFill = undefined;
    }
  }

  // Tiggered when create button is clicked
  createMailAlertConfig() {
    if (!this.validator.isValid) {
      return;
    }

    this.parentModal.toggleLoadingIcon(true);

    let body = {
      idUsuario: this.userSelector.getValue(),
      idEmpresa: this.companySelector.getValue(),
      idAreaInversion: this.investmentAreaSelector.getValue(),
      borradorPresupuesto: this.chkBudget1.is(':checked'),
      estadoPresupuesto: this.chkBudget2.is(':checked'),
      responsablePresupuesto: this.chkBudget3.is(':checked'),
      aprobarPresupuesto: this.chkBudget4.is(':checked'),
      borradorSolicitud: this.chkRequest1.is(':checked'),
      estadoSolicitud: this.chkRequest2.is(':checked'),
      responsableSolicitud: this.chkRequest3.is(':checked'),
      aprobarSolicitud: this.chkRequest4.is(':checked'),
      aprobarRechazarEnFlujoSolicitud: this.chkRequest5.is(':checked'),
      usuarioActualizacion: this.authenticationService.getAuthenticatedUser().login
    };

    this.parentModal.toggleLoadingIcon(true);
    this.httpService
      .post(this.httpService.config.createMailAlert, body)
      .then(response => {
        this.value = true;
        this.parentModal.close();
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      })
      .finally(() => this.parentModal.toggleLoadingIcon(false));
  }

  editMailAlertConfig() {
    if (!this.validator.isValid) {
      return;
    }

    this.parentModal.toggleLoadingIcon(true);

    let body = {
      idAvisoCorreo: this._params.idAvisoCorreo,
      idUsuario: this.userSelector.getValue(),
      idEmpresa: this.companySelector.getValue(),
      idAreaInversion: this.investmentAreaSelector.getValue(),
      borradorPresupuesto: this.chkBudget1.is(':checked'),
      estadoPresupuesto: this.chkBudget2.is(':checked'),
      responsablePresupuesto: this.chkBudget3.is(':checked'),
      aprobarPresupuesto: this.chkBudget4.is(':checked'),
      borradorSolicitud: this.chkRequest1.is(':checked'),
      estadoSolicitud: this.chkRequest2.is(':checked'),
      responsableSolicitud: this.chkRequest3.is(':checked'),
      aprobarSolicitud: this.chkRequest4.is(':checked'),
      aprobarRechazarEnFlujoSolicitud: this.chkRequest5.is(':checked'),
      usuarioActualizacion: this.authenticationService.getAuthenticatedUser().login
    };

    this.parentModal.toggleLoadingIcon(true);
    this.httpService
      .put(this.httpService.config.editMailAlert, body)
      .then(response => {
        this.value = true;
        this.parentModal.close();
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      })
      .finally(() => this.parentModal.toggleLoadingIcon(false));
  }

  get result() {
    return this.value;
  }
}
