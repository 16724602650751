import { HttpService } from '../http/http';
import { Router } from '../router/router';

export class TranslationService {
  static _instance = null;
  static get instance() {
    if (!TranslationService._instance) {
      throw 'Must be initialized first';
    }
    return TranslationService._instance;
  }

  constructor(options) {
    this._resourceFiles = {};

    options.defaultLanguage = options.defaultLanguage.toLocaleLowerCase();
    if (options.currentLanguage) {
      options.currentLanguage = options.currentLanguage.toLocaleLowerCase();
    }

    this._options = options;
    this._currentLanguage = options.currentLanguage || options.defaultLanguage;
    this._http = new HttpService('');
  }

  /**
   * Initialize the translation service. Only one service can be initalized at a time
   * @param {TranslationServiceOptions} options
   */
  static init(options) {
    TranslationService._instance = new TranslationService(options);
  }

  /**
   * Get the resource file of the language
   * @param {String} lang
   */
  getResourceFile(lang) {
    let resourceFile = this._resourceFiles[lang.toLocaleLowerCase()];

    if (!resourceFile) {
      this._resourceFiles[this._getShortenLanguageName(lang).toLocaleLowerCase()];
    }

    return resourceFile;
  }

  /**
   * Get the translation of a keyword
   * @param {String} key
   */
  translate(key) {
    key = key.toLocaleLowerCase();
    let resourceFile = this.getResourceFile(this._currentLanguage);

    if (!resourceFile) {
      resourceFile = this._resourceFiles[this._options.defaultLanguage];
    }

    let translation = resourceFile[key];
    if (!translation) {
      translation = this._resourceFiles[this._options.defaultLanguage][key];
    }

    if (typeof translation === 'undefined') {
      return 'Missing translation key: ' + key;
    }

    return translation;
  }

  /**
   * Translate a html element
   * @param {Element} rootElement
   */
  translateHtml(rootElement) {
    if (typeof rootElement === 'string') {
      let temp = document.createElement('div');
      temp.innerHTML = rootElement;
      rootElement = temp.firstChild;
    }
    let translableElements = rootElement.querySelectorAll('[data-trans]');

    for (const element of translableElements) {
      let translation = this.translate(element.dataset.trans);
      if (translation) {
        element.textContent = translation;
      }
    }

    return rootElement;
  }

  /**
   * Change the selected language
   * @param {*} lang
   */
  changeLanguage(lang) {
    if (!this._validateLanguage(lang)) {
      throw 'not valid language, correct format like "es_ES" or "es"';
    }
    this._currentLanguage = lang.toLocaleLowerCase();
  }

  /**
   * Load a new resource file and returns the promise
   * @param {String} lang
   * @param {String} url
   */
  loadResourceFile(lang, url) {
    if (!this._validateLanguage(lang)) {
      throw 'not valid language, correct format like "es_ES" or "es"';
    }
    lang = lang.toLocaleLowerCase();

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        if (xhr.status === 200) {
          this._resourceFiles[lang] = JSON.parse(xhr.responseText);
          resolve();
        } else {
          reject();
        }
      };
      xhr.onerror = () => {
        reject();
      };
      xhr.open('GET', url);
      xhr.send();
    });
  }

  get language() {
    return this._currentLanguage;
  }

  get languageShortName() {
    return this._getShortenLanguageName(this._currentLanguage);
  }

  /**
   * Converts es_ES to es
   * @param {String} lang
   */
  _getShortenLanguageName(lang) {
    return lang.split('_')[0];
  }

  /**
   * Check if a language is correctly formatted
   * @param {String} lang
   */
  _validateLanguage(lang) {
    return lang.match(/^[a-zA-Z]{2}(_[a-zA-Z]{2})?$/g);
  }
}

export class TranslationServiceOptions {
  defaultLanguage = null;
  currentLanguage = null;
}
