import './configuration.scss';
var htmlContent = require('./configuration.html');
import { Component } from '../../shared/components/base-component';
import { AuthenticationService } from '../../shared/services/authenticationService';
import $ from 'jquery';
import { HttpService } from '../../core/http/http';
import { ToastService } from '../../shared/services/toastService';
import { ViewsLoader } from '../../core/views/views-loader';
import { Spinner } from '../../shared/components/spinner/spinner';
import { TranslationService } from '../../core/translation/translation';
import { ModalComponent } from '../../shared/components/modal';
import {
  ModalYesNoComponent,
  ModalYesNoOptions
} from '../../components/modalyesno/modalyesno';
const renderMarkup = () => htmlContent;

export class ConfigurationPage extends Component {
  constructor() {
    super(renderMarkup());
    this._http = new HttpService();
    this.authenticationService = new AuthenticationService();
    this._toasts = new ToastService();
    this.viewsLoader = new ViewsLoader();

    $(document).ready(() => {

      let selectNav = document.querySelectorAll('.links-item');
      let i = 0;
      selectNav.forEach(e => {
        if (i == 6) {
          $(e).addClass('active');
          i++;
        } else {
          $(e).removeClass('active');
          i++;
        }
      });
      if (!this.authenticationService.getAuthenticatedUser().indDesarrollador) {
        $("a[href$='/#configuration/physicSign']").addClass('hidden');
      }



      $('#yearBudget')
        .off()
        .click(() => this.yearBudget());

    });
  }

  yearBudget() {
    var modal = new ModalComponent(
      TranslationService.instance.translate('config-year-budget'),
      new ModalYesNoComponent()
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      if (modal.result) {
        //$('#loader-container').removeClass('hidden');
        //this.viewsLoader.loadView(new Spinner('big'), document.getElementById('loader-container'));
        let data = [];
        data.usuarioSolicitante = this.authenticationService.getAuthenticatedUser().login;
        let generatingPDF = TranslationService.instance.translate('generating-pdf-year');
        this._toasts.success(generatingPDF);
        return this._http
          .post(this._http.config.generateYearBudget, HttpService.objectToFormData(data))
          .then(response => {
            response;
            let generatePDF = TranslationService.instance.translate('generate-pdf-year');
            this._toasts.success(generatePDF + response);
          })
          .catch(error => {
            this._toasts.exception(error.title);
            console.error(error);
          });
      }
    });
  }
}
