import './additionalCosts.scss';
var htmlContent = require('./additionalCosts.html');
import $ from 'jquery';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { Validator } from '../../core/validator/validator';
import { ToastService } from '../../shared/services/toastService';
import { InputNumeric } from '../input-numeric/inputNumeric';
const renderMarkup = () => htmlContent;

export class AdditionalCostsComponent extends BaseModalContent {
  constructor(options) {
    super(renderMarkup());
    this.value = null;
    this._options = options || new AdditionalCostsOptions();
    this._table = null;
    this._selectedLine = null;
  }

  onCreated() {
    this.init();
  }

  init() {
    this._table = this.parentModal.container.find('.cost-table');

    this.enableButtons();
    this.validation();

    if (this._options.data.length > 0) {
      for (const o of this._options.data) {
        this.addLine(
          o.nombre,
          this._options.localCurrency ? o.importeLocal : o.importe,
          o.conImpuestos
        );
      }
    } else if (!this._options.data.readOnly) {
      this.addLine();
    }
  }

  validation() {
    this.validator = new Validator(this.parentModal.container);
    this.validator.onValidationChanges.listen(isValid =>
      this._newLine.toggleClass('disabled', !isValid)
    );
    this.validator.validate();
  }
  enableButtons() {
    this._cancel = this.parentModal.container.find('.cancel');
    this._save = this.parentModal.container.find('.save');
    this._newLine = this.parentModal.container.find('.new-line');
    this._deleteLine = this.parentModal.container.find('.delete-line');

    if (this._options.readOnly) {
      this._cancel.remove();
      this._save.remove();
      this._newLine.remove();
      this._deleteLine.remove();
    } else {
      this._cancel.click(() => this.cancel());
      this._save.click(() => this.save());
      this._newLine.click(() => this.addLine(null, null, true));
      this._deleteLine.click(() => this.removeLine());
    }
  }

  addLine(concept, imprt, taxIncluded) {
    let html = `<div class="row">
      <span class ="title-container"><input type="text" class="concept" required/></span>
      <span class="amount-container"><input type="text" class="import" required min="0.01"/></span>
      <span class="taxes-container">
        <label class="checkbox-container">
        <input id="chk-budget-read" type="checkbox" ${taxIncluded ? 'checked' : ''}/>
        <div class="checkbox">
          <span class="icon-aprobar"></span>
        </div>
        <span class="txt" data-trans="config-cp-chk-budget-read"></span>
      </label>
    </span>
    </div>`;

    let temp = $(document.createElement('div'));
    temp.html(html);
    let row = temp.children().first();

    if (this._options.readOnly) {
      temp.find('input').attr('disabled', 'disabled');
    } else {
      row.click(e => this.selectLine(e));
    }

    if (concept) {
      temp.find('.concept').val(concept);
    }
    if (imprt) {
      temp.find('.import').val(imprt);
    }

    InputNumeric.createDecimal(temp.find('.import'));
    this._table.append(row);
    this.validator.refreshFields();

    this._table.removeClass('empty');
  }

  selectLine(event) {
    if (this._selectedLine && this._selectedLine[0] === event.target) {
      return;
    }
    if (this._selectedLine) {
      this._selectedLine.removeClass('selected');
    } else {
      this._deleteLine.removeClass('disabled');
    }

    this._selectedLine = $(event.target).closest('.row');
    this._selectedLine.addClass('selected');
  }

  removeLine() {
    if (!this._selectedLine) {
      return;
    }
    this._selectedLine.remove();
    this._selectedLine = null;
    this._deleteLine.addClass('disabled');

    if (this._table.find('.row:not(.header)').length == 0) {
      this._table.addClass('empty');
    }

    this.validator.refreshFields();
  }

  cancel() {
    this.value = null;
    this.parentModal.close();
  }

  save() {
    if (!this.validator.isValid) {
      return;
    }

    let result = [];
    let rows = this._table.find('.row:not(.header)');
    rows.each((index, element) => {
      let row = $(element);
      result.push({
        nombre: row.find('.concept').val(),
        importeLocal: Number(row.find('.import').attr('data-raw-value')),
        importe: Number(row.find('.import').attr('data-raw-value')),
        conImpuestos: row.find('#chk-budget-read').is(':checked')
      });
    });

    this.value = result;
    this.parentModal.close();
  }

  get result() {
    return this.value;
  }
}

export class AdditionalCostsOptions {
  readOnly = false;
  data = [];
  localCurrency = true;
}
