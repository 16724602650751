import './editLine.scss';
var htmlContent = require('./editLine.html');
import $ from 'jquery';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { HttpService } from '../../core/http/http';
import { Validator } from '../../core/validator/validator';
import { ToastService } from '../../shared/services/toastService';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';

const renderMarkup = () => htmlContent;

export class EditLineComponent extends BaseModalContent {
  constructor(areaId, companyId, textValue) {
    super(renderMarkup());
    this.httpService = new HttpService();
    this.areaId = areaId;
    this.companyId = companyId;
    this.value = null;
    this.toastService = new ToastService();
    this.textValue = textValue;
  }

  onCreated() {
    this.init();
  }

  init() {
    this.parentModal.container.find('.cancel').click(() => this.cancel());
    this.parentModal.container.find('.edit').click(() => this.edit());
    this.buildLineNameSelector();

    $('.filter-container')[0].children[0].value = this.textValue;
    
    this.validator = new Validator(this.parentModal.container);
    this.validator.validate();
  }

  buildLineNameSelector() {
    let lineNameSelector = new DropDownComponent(
      document.getElementById('line-name-selctor'),
      true
    );
    lineNameSelector.setRules({
      required: true
    });
    lineNameSelector.loadRemote(
      `${this.httpService.config.loadBudgetGroups}?idEmpresa=${this.companyId}&idArea=${this.areaId}`,
      'nombre',
      'nombre'
    );
    lineNameSelector.onChanges.listen(lineObj => {
      this.value = {};
      this.value.name = lineNameSelector.getValue();
    });

    $('.dropdown').attr('max-length', 100);
  }

  cancel() {
    this.value = null;
    this.parentModal.close();
  }

  edit() {
    this.validator.validate();
    if (!this.validator.isValid) {
      return;
    }

    this.parentModal.close();
  }

  get result() {
    return this.value;
  }
}
