import './side-nav.scss';
var htmlContent = require('./side-nav.html');
import { Component } from '../../shared/components/base-component';
import $ from 'jquery';
import { HttpService } from '../../core/http/http';
import { ToastService } from '../../shared/services/toastService';
import { TranslationService } from '../../core/translation/translation';
import { Router } from '../../core/router/router';
import { AuthenticationService } from '../../shared/services/authenticationService';
import { LangugesCodesEnum } from '../../config/enums';
import { AppConfig } from '../../core/app-config/app-config';
import { AppConfigKeys } from '../../config/keys';
import { Spinner } from '../../shared/components/spinner/spinner';
import { ViewsLoader } from '../../core/views/views-loader';
import { SessionStorageService } from '../../core/sessionStorage/sessionStorage';
import { SessionKeys } from '../../config/keys';

const renderMarkup = () => htmlContent;

export class SideNavPage extends Component {
  constructor() {
    super(renderMarkup());

    // Page logic
    $(document).ready(() => {
      this.httpService = new HttpService();
      this.toastService = new ToastService();
      this.authenticationService = new AuthenticationService();
      this.viewsLoader = new ViewsLoader();
      this.sessionStorage = new SessionStorageService();
      $('.icon-solicitudes').click(() => this._setRequestDefault());

      var links = document.getElementsByClassName('links-item');
      $('.links-item')
        .not('#language')
        .click(() => $('.sidenav').removeClass('menuComplete'));
      for (let index = 0; index < links.length; index++) {
        const element = links[index];
        element.addEventListener('click', () => {
          var activeLinks = document.getElementsByClassName('links-item active');
          if (element == $('#language')[0]) {
            if (element.classList.contains('active')) {
              $('#language').removeClass('active');
              $('#select-language').removeClass('active');
            } else {
              element.classList.add('active');
              $('#select-language').addClass('active');
            }
          } else {
            if (activeLinks.length > 1) {
              activeLinks[1].classList.remove('active');
            }
            if (activeLinks.length > 0) {
              activeLinks[0].classList.remove('active');
            }
            activeLinks.length > 0 ? activeLinks[0].classList.remove('active') : false;
            element.classList.add('active');
            $('#language').removeClass('active');
            $('#select-language').removeClass('active');
          }
        });
      }

      //Bind signout
      $('#signout').click(() => this.authenticationService.logout());

      //Bind to show/hide menu clicking on profile circle
      $('.profilecircle').click(() => $('.sidenav').toggleClass('menuComplete'));

      //Bind event to close menu on click on close icon -- only mobile
      $('#close-menu-mobile').click(() => $('.sidenav').removeClass('menuComplete'));

      $('#help-page').click(() => {
        this.downloadHelpFile();
      });

      $('#support-email').attr(
        'href',
        'mailto:' + AppConfig.instance.getProperty(AppConfigKeys.SupportEmail)
      );

      //Lang bindings
      $('#lang-es').click(e => this.selectSpanish());
      $('#lang-en').click(e => this.selectEnglish());
      $('#lang-pl').click(e => this.selectPolski());

      if (this.authenticationService.getAuthenticatedUser() != null) {
        //Build profile + current flag
        this.buildProfile();

        //Load pending petitions
        this.getNumberOfPetitions();
        setInterval(() => {
          this.getNumberOfPetitions();
        }, 60000);

        //Load pending requests
        this.getNumberOfRequests();
        setInterval(() => {
          this.getNumberOfRequests();
        }, 60000);
      }
    });
  }

  _setRequestDefault() {
    this.sessionStorage.setItem(SessionKeys.RequestLastTab, 1);
  }
  
  buildProfile() {
    let user = this.authenticationService.getAuthenticatedUser();
    switch (user.idIdioma) {
      case LangugesCodesEnum.Spanish:
        this.setSpainFlag();
        TranslationService.instance.changeLanguage(LangugesCodesEnum.Spanish);
        break;
      case LangugesCodesEnum.Polski:
        this.setPolandFlag();
        TranslationService.instance.changeLanguage(LangugesCodesEnum.Polski);
        break;
      case LangugesCodesEnum.English:
        this.setUnitedStatesFlag();
        TranslationService.instance.changeLanguage(LangugesCodesEnum.English);
        break;
    }

    if (this.authenticationService.isImpersonating()) {
      let names = this.authenticationService.getMsalAccount().name.split(',');
      let firstname = names[1].trim();
      let lastname = names[0].trim();
      $('#username').text(`${firstname} ${lastname}`);
      $('#letters').text(firstname[0] + lastname[0]);
      $('#impersonation-title').show();
      $('#impersonation-username').show().text(user.nombre);
    } else {
      $('#username').text(user.nombre);
      let names = user.nombre.split(' ');
      $('#letters').text(names[0][0] + names[1][0]);
      $('#impersonation-title').hide();
      $('#impersonation-username').hide().text('');
    }

    //If user is developer allow to impersonate on login
    if ( this.authenticationService.canImpersonate()) {
      $('#username')
        .addClass('active')
        .off()
        .click(() => location.assign('/#login'));
    }

    //If user is not admin. Remove config
    if (user.indAdministrador == false) {
      $("a[href$='#configuration']").hide();
    }
  }

  selectSpanish() {
    this.setSpainFlag();
    this.changeLanguage(LangugesCodesEnum.Spanish);
  }
  setSpainFlag() {
    $('.language-option').removeClass('active');
    $('#lang-es').addClass('active');
    $('#current-lang').removeClass('icon-castellano icon-polaco icon-ingles');
    $('#current-lang').addClass('icon-castellano');
    $('#current-lang').html('<span class="path1"></span><span class="path2"></span>');
    $('#select-language').removeClass('active');
    $('#language').removeClass('active');
  }

  selectPolski() {
    this.setPolandFlag();
    this.changeLanguage(LangugesCodesEnum.Polski);
  }
  setPolandFlag() {
    $('.language-option').removeClass('active');
    $('#lang-pl').addClass('active');
    $('#current-lang').removeClass('icon-castellano icon-polaco icon-ingles');
    $('#current-lang').addClass('icon-polaco');
    $('#current-lang').html('<span class="path1"></span><span class="path2"></span>');
    $('#select-language').removeClass('active');
    $('#language').removeClass('active');
  }

  selectEnglish() {
    this.setUnitedStatesFlag();
    this.changeLanguage(LangugesCodesEnum.English);
  }
  setUnitedStatesFlag() {
    $('.language-option').removeClass('active');
    $('#lang-en').addClass('active');
    $('#current-lang').removeClass('icon-castellano icon-polaco icon-ingles');
    $('#current-lang').addClass('icon-ingles');
    $('#current-lang').html(
      '<i class="icon-ingles"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span><span class="path12"></span><span class="path13"></span><span class="path14"></span><span class="path15"></span><span class="path16"></span><span class="path17"></span><span class="path18"></span><span class="path19"></span><span class="path20"></span><span class="path21"></span><span class="path22"></span><span class="path23"></span><span class="path24"></span><span class="path25"></span><span class="path26"></span><span class="path27"></span><span class="path28"></span><span class="path29"></span><span class="path30"></span><span class="path31"></span><span class="path32"></span><span class="path33"></span><span class="path34"></span><span class="path35"></span><span class="path36"></span><span class="path37"></span><span class="path38"></span></i>'
    );
    $('#select-language').removeClass('active');
    $('#language').removeClass('active');
  }

  changeLanguage(lang) {
    TranslationService.instance.changeLanguage(lang);
    let user = this.authenticationService.getAuthenticatedUser();
    user.idIdioma = lang;
    this.authenticationService.setAuthenticatedUser(user);
    location.reload();
  }

  downloadHelpFile() {
    let loadIcon = document.createElement('div');
    loadIcon.classList = 'load-icon-full-screen';
    this.viewsLoader.loadView(new Spinner('big'), loadIcon);

    $('body').append(loadIcon);

    this.httpService
      .downloadFile(
        `${this.httpService.config.getPdfManual}?idioma=${
          TranslationService.instance.languageShortName
        }&usuarioSolicitante=${this.authenticationService.getAuthenticatedUser().login}`,
        `${TranslationService.instance.translate('common-user-manual')}.pdf`
      )
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      })
      .finally(() => loadIcon.remove());
  }
  getNumberOfPetitions() {
    this.httpService
      .get(
        this.httpService.config.loadPetitionsNumber +
          '/' +
          this.authenticationService.getAuthenticatedUser().login
      )
      .then(response => {
        if (response === 0) {
          $('#petitions-number').hide();
        } else {
          $('#petitions-number').show();
          $('#petitions-number').text(response);
        }
      })
      .catch(error => {
        this.toastService.exception(error.title);
      });
  }

  getNumberOfRequests() {
    this.httpService
      .get(
        this.httpService.config.loadRequestsNumber +
          '/' +
          this.authenticationService.getAuthenticatedUser().login
      )
      .then(response => {
        if (response === 0) {
          $('#requests-number').hide();
        } else {
          $('#requests-number').show();
          $('#requests-number').text(response);
        }
      })
      .catch(error => {
        this.toastService.exception(error.title);
      });
  }
}
