import './budgets.scss';
var htmlContent = require('./budgets.html');
import { Component } from '../../shared/components/base-component';
import { ModalComponent } from '../../shared/components/modal';
import { HttpService } from '../../core/http/http';
import { CreateBudgetComponent } from '../../components/create-budget/createBudget';
import { CreateAggregateBudgetComponent } from '../../components/create-aggregate-budget/createAggregateBudget';
import $ from 'jquery';
import { BudgetStatusEnum, BudgetStatusClassesEnum } from '../../config/enums';
import { ViewsLoader } from '../../core/views/views-loader';
import { Spinner } from '../../shared/components/spinner/spinner';
import { TranslationService } from '../../core/translation/translation';
import { AuthenticationService } from '../../shared/services/authenticationService';
import { Router } from '../../core/router/router';
import { ToastService } from '../../shared/services/toastService';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import { SessionStorageService } from '../../core/sessionStorage/sessionStorage';
import { SessionKeys } from '../../config/keys';

const renderMarkup = () => htmlContent;

export class BudgetsPage extends Component {
  constructor() {
    super(renderMarkup());
    this.httpService = new HttpService();
    this.sessionStorage = new SessionStorageService();
    this.viewsLoader = new ViewsLoader();
    this.authenticationService = new AuthenticationService();
    this.init();
    this.toastService = new ToastService();
    
    this.selectedIds = [];
  }

  existsInSession(val) {
    return (
      this.sessionStorage.hasItem(val) &&
      this.sessionStorage.getItem(val) !== '' &&
      this.sessionStorage.getItem(val) !== 'undefined'
    );
  }

  init() {
    $(document).ready(() => {
      //document.querySelector('.current-year').textContent = new Date().getFullYear();
      let selectNav = document.querySelectorAll('.links-item');
      let i = 0;
      selectNav.forEach(e => {
        if (i == 0) {
          $(e).addClass('active');
          i++;
        } else {
          $(e).removeClass('active');
          i++;
        }
      });
      $('#btn-modal-create-budget').click(() => this.createNewBudget());
      $('#btn-modal-create-budget-aggregate').click(() => this.createAggregateBudget());
      $('#btn-toogle-menu').click(() => this._toggleMenu());
      this._buildSelectors();
      this._updateButtonState();
      //this.loadBudgets();
      //this._filterTriggered();
    });
  }

  // Will load the list of visible budgets for the user
  loadBudgets() {
    $('#loader-container').removeClass('hidden');
    this.viewsLoader.loadView(new Spinner('big'), document.getElementById('loader-container'));
    this.httpService
      .get(
        `${this.httpService.config.loadBudgets}/${this.authenticationService.getAuthenticatedUser().login
        }`
      )
      .then(response => {
        $('#loader-container').addClass('hidden');
        var screenWidth = window.innerWidth;
        if (screenWidth >= 768) {
          this.buildTableContent(response);
        } else {
          this.buildCardContent(response);
        }

        $(window).resize(() => {
          screenWidth = window.innerWidth;
          if (screenWidth >= 768) {
            this.buildTableContent(response);
          } else {
            this.buildCardContent(response);
          }
        });
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }

  _updateButtonState() {
    const $button = $('#btn-modal-create-budget-aggregate');

    if (this.authenticationService.getAuthenticatedUser().indGestorPresupuestosAgregados) {
      $button.removeClass('hide');
    } else {
      $button.addClass('hide');
      return;
    }

    if (this.selectedIds.length === 0) {
      $button.addClass('disabled');
    } else {
      $button.removeClass('disabled');
    }

  }

  _buildSelectors() {
    return Promise.all([this._loadYears(), this._loadCompanies(), this._loadInvestmentAreas()]).then(() => {

      //YEARS 

      this.yearSelector = new DropDownComponent(document.getElementById('exercises-selector-filter'));
      this.yearSelector.loadData(this._years, 'id', 'label');

      const year = this.existsInSession(SessionKeys.BudgetYear)
        ? parseInt(this.sessionStorage.getItem(SessionKeys.BudgetYear))
        : new Date().getFullYear();
      if(this._years.some(x => x.id === year)) {
        this.yearSelector.setSelected(year, 'id', 'label');
      }

      this.yearSelector.onChanges.listen(_ => this._filterTriggered());

      // STATUS

      this.statusSelector = new DropDownComponent(document.getElementById('status-selector-filter'));
      this.statusSelector.loadLocal(
        Object.values(BudgetStatusEnum).map(x => TranslationService.instance.translate(x)),
        Object.keys(BudgetStatusEnum)
      );

      if (this.existsInSession(SessionKeys.BudgetStatus)) {
        this.statusSelector.setSelected(
          this.sessionStorage.getItem(SessionKeys.BudgetStatus), 'id', 'label')
      }

      this.statusSelector.onChanges.listen(status => {
        document.getElementById('status-selector-filter').className =
          `status-selector ${status.value ? BudgetStatusEnum[status.value] : ''}`;
        this._filterTriggered();
      });

      // COMPANY

      this.companySelector = new DropDownComponent(document.getElementById('company-selector-filter'));
      this.companySelector.loadData(this._companies, 'idEmpresa', 'nombre');

      if (this.existsInSession(SessionKeys.BudgetCompany)) {
        this.companySelector.setSelected(
          parseInt(this.sessionStorage.getItem(SessionKeys.BudgetCompany)), 'idEmpresa', 'nombre')
      }

      this.companySelector.onChanges.listen(_ => this._filterTriggered());


      //INVESTMENT AREA

      this.investmentAreaSelector = new DropDownComponent(
        document.getElementById('investment-area-selector-filter')
      );
      this.investmentAreaSelector.loadData(this._investmentAreas, 'idAreaInversion', 'nombre');

      if (this.existsInSession(SessionKeys.BudgetInvestmentArea)) {
        this.investmentAreaSelector.setSelected(
          parseInt(this.sessionStorage.getItem(SessionKeys.BudgetInvestmentArea)), 'idAreaInversion', 'nombre')
      }

      this.investmentAreaSelector.onChanges.listen(_ => this._filterTriggered());

      this._filterTriggered();

      $('.reset-filters').click(() => {
        this._cleaning = true;
        this.yearSelector.clearSelection();
        this.investmentAreaSelector.clearSelection();
        this.companySelector.clearSelection();
        this.statusSelector.clearSelection();
      });
    });
  }

  _loadYears () {
    return this.httpService.get(this.httpService.config.loadBudgetsYears +
      this.authenticationService.getAuthenticatedUser().login
    ).then(response => {
      this._years = response.reverse().map(year => ({id: year, label: year.toString()}));
    }).catch(error => {
      this._toasts.error(error.title, error.message);
      console.error(error);
    });
  }

  _loadCompanies() {
    return this.httpService
      .get(this.httpService.config.loadCompaniesPerUser +
        this.authenticationService.getAuthenticatedUser().login)
      .then(response => {
        this._companies = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }
  _loadInvestmentAreas() {
    return this.httpService
      .get(this.httpService.config.loadInvestmentAreasPerUser +
        this.authenticationService.getAuthenticatedUser().login)
      .then(response => {
        this._investmentAreas = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  _filterTriggered() {
    if (this._cleaning) {
      this._cleaning = false;
      this.loadBudgets();
      return;
    }
    let params = [
      this._selectToUrl(this.yearSelector, 'AreaTrabajo.Ejercicio'),
      this._selectToUrl(this.investmentAreaSelector, 'AreaTrabajo.IdAreaInversion'),
      this._selectToUrl(this.companySelector, 'AreaTrabajo.IdEmpresa'),
      this._selectToUrl(this.statusSelector, 'IdEstado')
    ];

    // SET FILTER VALUES

    if (this.yearSelector) {
      this.sessionStorage.setItem(SessionKeys.BudgetYear, this.yearSelector.getValue());
    }
    if (this.investmentAreaSelector) {
      this.sessionStorage.setItem(SessionKeys.BudgetInvestmentArea, this.investmentAreaSelector.getValue());
    }
    if (this.companySelector) {
      this.sessionStorage.setItem(SessionKeys.BudgetCompany, this.companySelector.getValue());
    }
    if (this.statusSelector) {
      this.sessionStorage.setItem(SessionKeys.BudgetStatus, this.statusSelector.getValue());
    }

    if (params.filter(x => x.indexOf('=0') == -1).length == 0) {
      //this.loadBudgets();
      return;
    }

    let url =
      this.httpService.config.loadBudgetsByFilters +
      this.authenticationService.getAuthenticatedUser().login +
      '&' +
      params.join('&');

    $('#loader-container').removeClass('hidden');
    this.viewsLoader.loadView(new Spinner('big'), document.getElementById('loader-container'));
    this.httpService
      .get(url)
      .then(response => {
        $('#loader-container').addClass('hidden');
        var screenWidth = window.innerWidth;
        if (screenWidth >= 768) {
          this.buildTableContent(response);
        } else {
          this.buildCardContent(response);
        }

        $(window).resize(() => {
          screenWidth = window.innerWidth;
          if (screenWidth >= 768) {
            this.buildTableContent(response);
          } else {
            this.buildCardContent(response);
          }
        });
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }

  _selectToUrl(select, urlParam) {
    if (select && select.getValue() !== '') {
      return urlParam + '=' + select.getValue();
    } else {
      return urlParam + '=0';
    }
  }

  // Will build the structure of the table with the budgets loaded.
  buildTableContent(budgets) {
    var innerHtmlTable = '';
    if (budgets.length == 0) {
      innerHtmlTable = `<div class="table-row all">
              <span>${TranslationService.instance.translate('common-msg-no-budgets')}</span>
            </div>`;
    } else {
      budgets.forEach(budget => {
        innerHtmlTable = innerHtmlTable.concat(`<div class="table-row">
      <div class="cell remove">
        ${
          !budget.estaAgregado &&
          budget.idEstado === 2 && 
          budget.idUsuarioResponsable === this.authenticationService.getAuthenticatedUser().idUsuario && 
          this.authenticationService.getAuthenticatedUser().indGestorPresupuestosAgregados && 
          !budget.esAgregado 
            ? `<input type="checkbox" class="table-checkbox" data-id="${budget.idPresupuesto}">` 
            : ''
        }
      </div>
      <div class="cell open center">
        <a href="/#budgets/${budget.idPresupuesto}" class="icon-abrir" title="${TranslationService.instance.translate('common-table-open')}"></a>
      </div>
      <div class="cell exercise left">${budget.ejercicio}</div>
      <div class="cell company left">${budget.empresa}</div>
      <div class="cell investmentArea left">${budget.areaInversion}</div>
      <div class="cell status left">
        <div class="circle-state ${BudgetStatusClassesEnum[budget.idEstado]}"></div>
        ${budget.esRevision
            ? TranslationService.instance.translate('review')
            : `${TranslationService.instance.translate(BudgetStatusEnum[budget.idEstado])} ${budget.estaAgregado ? '(' + TranslationService.instance.translate('aggregate') + ')' : ''}`
          }</div>
      <div class="cell requests center">
        <div class="center">${budget.numSolicitudes}</div>
      </div>
    </div>`);
      });
    }
    $('#budgets-table-content').html(innerHtmlTable);
    $('.table-checkbox').change(function(e) {
      const budgetId = $(e.target).data('id');
      if (e.target.checked) {
        if (!this.selectedIds.includes(budgetId)) {
          this.selectedIds.push(budgetId);
        }
      } else {
        const index = this.selectedIds.indexOf(budgetId);
        if (index > -1) {
          this.selectedIds.splice(index, 1);
        }
      }

      this._updateButtonState();

    }.bind(this));
  }

  buildCardContent(budgets) {
    var innerHtml = '';
    if (budgets.length == 0) {
      innerHtml = `<div class="empty">
      <span class="msg">${TranslationService.instance.translate('common-msg-no-budgets')}</span>
    </div>`;
    } else {
      budgets.forEach(budget => {
        innerHtml = innerHtml.concat(`
      <div class="card">
        <div class="card-row">
          <div class="card-row-element all">
            <span class="title with-link">${budget.ejercicio + ' ' + budget.empresa}</span>
            <a href="/#budgets/${budget.idPresupuesto}" class="icon-abrir" title="${TranslationService.instance.translate('common-table-open')}"></a>
          </div>
        </div>
        <div class="card-row">
          <div class="card-row-element three-quarters">
            <span class="label">${TranslationService.instance.translate('common-invtm-area')}</span>
            <span class="description">${budget.areaInversion}</span>
          </div>
        </div>
        <div class="card-row">
          <div class="card-row-element three-quarters">
            <span class="label">${TranslationService.instance.translate(
          'budgets-table-state-budget'
        )}</span>
            <div class="description"> <span class="circle-state ${BudgetStatusClassesEnum[budget.idEstado]
          }"></span>
            ${TranslationService.instance.translate(BudgetStatusEnum[budget.idEstado])}
            </div>
          </div>
          <div class="card-row-element quarter">
            <span class="label">${TranslationService.instance.translate('common-requests')}</span>
            <span class="description">${budget.numSolicitudes}</span>
          </div>
        </div>
      </div>
      `);
      });
    }
    $('.cards-container').html(innerHtml);
  }

  // Will trigger the create new budget modal
  createNewBudget() {
    let modal = new ModalComponent(
      TranslationService.instance.translate('cb-title'),
      new CreateBudgetComponent()
    );
    modal.onClosed.listen(() => {
      if (typeof modal.result !== 'undefined' && modal.result != null)
        Router.inst.navigate(`budgets/${modal.result}`);
    });
  }

  createAggregateBudget() {
    let modal = new ModalComponent(
      TranslationService.instance.translate('cb-title-aggregate'),
      new CreateAggregateBudgetComponent(this.selectedIds)
    );
    modal.onClosed.listen(() => {
      if (typeof modal.result !== 'undefined' && modal.result != null)
        Router.inst.navigate(`budgets/${modal.result}`);
    });
  }

  _toggleMenu() {
    $('.sidenav').toggleClass('menuComplete');
  }
}
