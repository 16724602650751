import { Rule } from '../rule';

export class DateRule extends Rule {
  static _regex = /[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}/;

  get name() {
    return 'date';
  }

  getErrorMessage(element, lang) {
    return `El campo no es una fecha valida`;
  }

  isValid(element) {
    return element.val().match(DateRule._regex);
  }
}
