import './graphic-budget.scss';
var htmlContent = require('./graphic-budget.html');
import { HttpService } from '../../core/http/http';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { ToastService } from '../../shared/services/toastService';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import { Validator } from '../../core/validator/validator';
import { TranslationService } from '../../core/translation/translation';
import { AuthenticationService } from '../../shared/services/authenticationService';

const renderMarkup = () => htmlContent;

export class graphicBudgetComponent extends BaseModalContent {
  constructor() {
    super(renderMarkup());
    this.toastService = new ToastService();
    this.httpService = new HttpService();
    this.authenticationService = new AuthenticationService();
  }

  onOpened() {
    this.init();
  }

  onLoading(callback) {
    Promise.all([this._loadYears(), this._loadCompanies(), this._loadInvestmentAreas()]).then(response => {
      callback();
    });
    return true;
  }

  _loadYears () {
    return this.httpService.get(this.httpService.config.loadBudgetsYears +
      this.authenticationService.getAuthenticatedUser().login
    ).then(response => {
      this._years = response.reverse().map(year => ({id: year, label: year.toString()}));
    }).catch(error => {
      this._toasts.error(error.title, error.message);
      console.error(error);
    });
  }

  _loadCompanies() {
    return this.httpService
      .get(this.httpService.config.loadCompanies)
      .then(response => {
        this._companies = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  _loadInvestmentAreas() {
    return this.httpService
      .get(this.httpService.config.loadInvestmentAreas)
      .then(response => {
        this._investmentAreas = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  init() {
    this.buildSelectors();
    this.parentModal.container
      .find('#btn-visualize-report')
      .click(() => this.visualizeBudgetReport());
    this.parentModal.container.find('#btn-cancel').click(() => {
      this.value = false;
      this.parentModal.close();
    });
  }

  buildSelectors() {
    this.yearSelector = new DropDownComponent(document.getElementById('year-selector'));
    this.yearSelector.setRules({
      required: true
    });
    this.yearSelector.loadData(this._years, 'id', 'label');
    const currentYear = new Date().getFullYear();
    if(this._years.some(x => x.id === currentYear)) {
      this.yearSelector.setSelected(currentYear, 'id', 'label');
    }

    this.companySelector = new DropDownComponent(document.getElementById('company-selector'));
    this.companySelector.setRules({
      required: true
    });
    this.companySelector.loadData(this._companies, 'idEmpresa', 'nombre');

    this.investmentAreaSelector = new DropDownComponent(
      document.getElementById('investment-area-selector')
    );
    this.investmentAreaSelector.setRules({
      required: true
    });
    this.investmentAreaSelector.loadData(this._investmentAreas, 'idAreaInversion', 'nombre');

    this.validator = new Validator(this.parentModal.container);
    this.validator.validate();
  }

  // Tiggered when create button is clicked
  visualizeBudgetReport() {
    if (!this.validator.isValid) {
      return;
    }
    this.parentModal.toggleLoadingIcon(true);

    this.httpService
      .get(
        `${this.httpService.config.checkBreakdownReport}?UsuarioSolicitante=${
          this.authenticationService.getAuthenticatedUser().login
        }&AreaTrabajo.IdEmpresa=${this.companySelector.getValue()}&AreaTrabajo.IdAreaInversion=${this.investmentAreaSelector.getValue()}&AreaTrabajo.Ejercicio=${this.yearSelector.getValue()}&Idioma=${this.authenticationService
          .getAuthenticatedUser()
          .idIdioma.substr(0, 2)}`
      )
      .then(response => {
        if (response === true) {
          this.httpService
            .downloadFile(
              this.httpService.config.budgetReport +
                '?UsuarioSolicitante=' +
                this.authenticationService.getAuthenticatedUser().login +
                '&AreaTrabajo.IdEmpresa=' +
                this.companySelector.getValue() +
                '&AreaTrabajo.IdAreaInversion=' +
                this.investmentAreaSelector.getValue() +
                '&AreaTrabajo.Ejercicio=' +
                this.yearSelector.getValue() +
                '&Idioma=' +
                this.authenticationService.getAuthenticatedUser().idIdioma.substr(0, 2)
            )
            .then(() => {
              this.parentModal.close();
            })
            .catch(err => {
              this.toastService.exception(err.title);
              console.error(err);
            })
            .finally(() => this.parentModal.toggleLoadingIcon(false));
        } else {
          this.toastService.error(
            TranslationService.instance.translate('exception-title'),
            TranslationService.instance.translate('reports-msg-no-data')
          );
          this.parentModal.toggleLoadingIcon(false);
        }
      })
      .catch(error => {
        this.parentModal.toggleLoadingIcon(false);
        this.toastService.exception(error.title);
        console.error(error);
      });
  }

  get result() {
    return this.value;
  }
}
