import './card-line-budget.scss';
import { TranslationService } from '../../../core/translation/translation';
import { DatePicker } from '../../../components/date-picker/datePicker';
import { InputNumeric, InputNumericOptions } from '../../../components/input-numeric/inputNumeric';
let cardTemplate = require('./card-line-budget.html');

export class CardLineBudget {
  static get template() {
    return TranslationService.instance.translateHtml(cardTemplate);
  }

  constructor(options) {
    this._options = options;
  }

  render(card, data, breadcrumbs) {
    let budgetLine = this._options.budgetManager.getLine(data.id);
    card.dataset.id = data.id;

    let quarters = budgetLine.getAllQuarters(this._options.localCurrency, this._options.unit);

    card.querySelector('.title').textContent = budgetLine.getDescription();
    card.querySelector('.t1').textContent = quarters.q1
      ? InputNumeric.formatWithoutComponent(quarters.q1)
      : '-';
    card.querySelector('.t2').textContent = quarters.q2
      ? InputNumeric.formatWithoutComponent(quarters.q2)
      : '-';
    card.querySelector('.t3').textContent = quarters.q3
      ? InputNumeric.formatWithoutComponent(quarters.q3)
      : '-';
    card.querySelector('.t4').textContent = quarters.q4
      ? InputNumeric.formatWithoutComponent(quarters.q4)
      : '-';
    card.querySelector('.annual').textContent = quarters.annual
      ? InputNumeric.formatWithoutComponent(quarters.annual)
      : '-';
    card.querySelector('.request-to').textContent = budgetLine.getRequestedTo() || '-';

    let acquisitionDate = card.querySelector('.acquisition-date');
    if (budgetLine.getAcquisitionDate() != null) {
      acquisitionDate.textContent = DatePicker.toLocaleDateString(
        DatePicker.parseDate(budgetLine.getAcquisitionDate())
      );
    } else {
      acquisitionDate.textContent = '-';
    }

    let disbursementDates = card.querySelector('.disbursement-date');
    if (budgetLine.hasAmounts()) {
      let htmlContent = '';
      for (const budgetAmount of budgetLine.getAmounts()) {
        htmlContent += '<span>-' + budgetAmount.getPaymentDateFormatted() + '</span>';
      }
      disbursementDates.innerHTML = htmlContent;
    } else {
      disbursementDates.textContent = '-';
    }

    let translateKey = 'bd-';
    switch (breadcrumbs.length) {
      case 0:
        translateKey += 'work';
        break;
      case 1:
        translateKey += 'group';
        break;
      default:
        translateKey += 'line';
        break;
    }
    if (data.children) {
      translateKey += data.children.length > 1 ? 's' : '';
      card.querySelector('.children-text').textContent = `${
        data.children.length
      } ${TranslationService.instance.translate(translateKey)}`;
    }

    if (!this._options.contextMenu) {
      card.querySelector('.context-menu-container').remove();
    }
  }
}
export class CardLineBudgetOptions {
  budgetManager = null;
  localCurrency = null;
  unit = null;
  contextMenu = true;
}
