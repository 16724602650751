import { Rule } from './rule';
import { Event } from '../events/event';
import { RequiredRule } from './rules/requiredRule';
import { MaxRule } from './rules/maxRule';
import { MinRule } from './rules/minRule';
import $ from 'jquery';
import { DateRule } from './rules/date';
import { ToastService } from '../../shared/services/toastService';
import { TranslationService } from '../translation/translation';
import { MaxLengthRule } from './rules/maxLengthRule';

/**
 * Class used to validate forms
 */
export class Validator {
  /**
   * Default rules implemented by all forms
   */
  static _DEFAULT_RULES = [];
  /**
   * Add a new default rule
   * @param {Rule} rule
   */
  static addDefaultRule(rule) {
    Validator._DEFAULT_RULES.push(rule);
  }

  /**
   * Event fired when the validation changes from true to false or viceversa
   */
  onValidationChanges = new Event();

  /**
   * Initialize the validator. The form does not necesarly have to be a form. Can be any element that encapsulates all the inputs.
   * @param {*} form
   */
  constructor(form, lang = 'es-ES') {
    this._form = form;
    this._$el = $(form);
    this._isValid = true;
    this._rules = [...Validator._DEFAULT_RULES];
    this._inputs = null;
    this._submit = null;
    this._lang = lang;
    this._toastService = new ToastService();
    this._maxLengthError = false;
  }

  /**
   * Force a validation
   */
  validate() {
    if (this._inputs == null) {
      this.refreshFields();
    }

    if (this._inputs.length > 0) {
      this._inputs.each((i, e) => {
        this._validate(e);
      });
      //If there is no inputs, empty force the validation to true
    } else {
      if (!this._isValid) {
        this.onValidationChanges.execute(true);
      }

      this._isValid = true;
    }
  }

  /**
   * Handle the changes of the input
   * @param {Event} e
   */
  _handleChanges(e) {
    this._validate(e.target);
    this._setAsDirty(e.target);
  }

  /**
   * Refresh all the fields attached to the validator
   */
  refreshFields() {
    this._inputs = this._$el.find('input, select, textarea');
    this._inputs.off('change.validator');
    this._inputs.on('change.validator', this._handleChanges.bind(this));
    this._inputs.off('keyup.validator');
    this._inputs.on('keyup.validator', this._handleChanges.bind(this));
    this._inputs.off('invalid.validator');
    this._inputs.on('invalid.validator', () => {
      e => e.preventDefault(); //Prevent browser to show default dialogs
    });

    //If it's a new element add the pristine class
    this._inputs.not('.pristine,.dirty').each((i, e) => {
      $(e).addClass('pristine');
    });

    if (this._submit) {
      this._submit.off('click.validator');
    }

    this._submit = this._$el.find('.submit-form');
    this._submit.on('click.validator', this._sendForm.bind(this));

    this.validate();
  }

  /**
   * Event executed when sending the form
   */
  _sendForm() {
    this._inputs.filter('.pristine').each((i, e) => this._setAsDirty(e));

    if (!this.isValid) {
      if (this._maxLengthError) {
        this._toastService.error(
          TranslationService.instance.translate('exception-title'),
          TranslationService.instance.translate('common-error-max'),
        );
      }
      else {
        this._toastService.error(
          TranslationService.instance.translate('exception-title'),
          TranslationService.instance.translate('common-error-form'),
        );
      }
    }
    
    this._maxLengthError = false;

  }

  /**
   * Check if a rule can validate a element
   * @param {Rule} rule
   * @param {DOMElement} target
   */
  _canValidate(rule, target) {
    return target.hasAttribute(rule.name) || target.hasAttribute('data-validator-' + rule.name);
  }

  _setAsDirty(element) {
    $(element)
      .removeClass('pristine')
      .addClass('dirty');
  }

  /**
   * Fired when a field has been updated
   * @param {Event} event
   */
  _validate(element) {
    let target = $(element);
    let validRules = this._rules
      .filter(x => this._canValidate(x, element))
      .sort((a, b) => a.priority - b.priority);

    let isValidTarget = true;
    for (let i = 0; i < validRules.length; i++) {
      let rule = validRules[i];
      isValidTarget = isValidTarget && rule.isValid(target);
      if (this._maxLengthError == false && rule.name == 'max-length') {
        this._maxLengthError = rule.isValid(target) ? false : true;
      }
    }

    if (isValidTarget) {
      target.removeClass('has-errors');
    } else {
      target.addClass('has-errors');
    }

    //Validate all the elements in the form except the ones that are not visibles with the attribute "data-validator-ignore-invisible"
    let isValidForm =
      this._inputs.filter(
        '.has-errors:not([data-validator-ignore-invisible]), .has-errors[data-validator-ignore-invisible]:visible'
      ).length == 0;

    //Fire event only when validation changes
    if (isValidForm != this._isValid) {
      this.onValidationChanges.execute(isValidForm);
    }

    this._isValid = isValidForm;
  }

  /**
   * Add a new Rule
   * @param {*} rule
   */
  addRule(rule) {
    this._rules.push(rule);
  }

  /**
   * Remove a rule by it's name
   * @param {Name} ruleName
   */
  removeRule(ruleName) {
    this._rules = this._rules.filter(e => e.name == ruleName);
  }

  /**
   * Check if the form is valid
   */
  get isValid() {
    return this._isValid;
  }
}

Validator.addDefaultRule(new RequiredRule());
Validator.addDefaultRule(new MaxRule());
Validator.addDefaultRule(new MinRule());
Validator.addDefaultRule(new DateRule());
Validator.addDefaultRule(new MaxLengthRule());
