import $ from '../../core/jquery/jquery-global';
import { TranslationServiceOptions, TranslationService } from '../../core/translation/translation';

export class InputNumeric {
  /**
   * Syntaxic sugar for instantiation of integer
   * @param {*} element
   */
  static createInteger(element, allowNegatives = false) {
    let options = new InputNumericOptions();
    options.decimals = 0;
    options.useThousandSeparator = false;
    options.allowNegatives = allowNegatives;
    return new InputNumeric(element, options);
  }
  /**
   * Syntaxic sugar for instantiation of decimal
   * @param {*} element
   */
  static createDecimal(element, allowNegatives = false) {
    let options = new InputNumericOptions();
    options.decimals = 2;
    options.allowNegatives = allowNegatives;
    return new InputNumeric(element, options);
  }

  static formatWithoutComponent(value, decimals) {
    if (typeof decimals === 'undefined') {
      decimals = 2;
    }

    let options = InputNumeric.getLocaleSettings();
    value = value.toString().replace('.', options.decimalSeparator);

    return InputNumeric.formatNumber(
      value,
      decimals,
      options.thousandSeparator,
      options.decimalSeparator
    );
  }

  /**
   * Apply the format to the number
   */
  static formatNumber(number, decimals, thousandSeparator, decimalSeparator) {
    if (number == '' || number == '-') {
      return number;
    }

    let isNegative = number.indexOf('-') !== -1;
    let numberSplitted = number
      .toString()
      .replace('-', '')
      .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
      .split(decimalSeparator);

    let integerPart = parseInt(numberSplitted[0]);
    if (isNaN(integerPart)) {
      integerPart = '';
    }

    let decimalResult = '';
    let integerResult = '';

    //Round the decimal part to the max length of the decimals
    if (numberSplitted.length > 1) {
      let decimal = 0;

      if (numberSplitted[1].length > 0) {
        decimal = parseFloat('0.' + numberSplitted[1]);
        decimal *= Math.pow(10, decimals);
        decimal = Math.round(decimal);
        decimal /= Math.pow(10, decimals);
      }

      //If decimal is just one means the decimal part was .999... so it must be added to integer
      if (decimal == 1) {
        integerPart += 1;
      } else {
        //Add the comma either when we have a value or while the user is typeing the number
        let decimalLength = numberSplitted[1].length;
        let isUnfinishedDecimal = decimal == 0 && decimalLength > 0 && decimalLength < decimals;

        if (decimal > 0 || decimalLength == 0 || isUnfinishedDecimal) {
          decimalResult += decimalSeparator;
        }

        if (decimal > 0 || isUnfinishedDecimal) {
          decimalResult += decimal != 0 ? decimal.toString().split('.')[1] : decimal.toString();
        }
      }
    }

    let integerText = integerPart.toString();
    let thousandCount = 0;
    for (let i = integerText.length - 1; i >= 0; i--) {
      if (thousandCount % 3 == 0 && thousandCount !== 0) {
        integerResult = integerText[i] + thousandSeparator + integerResult;
      } else {
        integerResult = integerText[i] + integerResult;
      }

      thousandCount++;
    }

    let total = (isNegative ? '-' : '') + integerResult + decimalResult;
    if (total == decimalSeparator || total == '-' + decimalSeparator) {
      return '';
    }

    if (!Number.isSafeInteger(parseInt(integerText == '' ? '0' : integerText))) {
      return 'Err: NSI';
    }

    return total;
  }

  static getLocaleSettings() {
    let options = {};

    switch (TranslationService.instance.languageShortName) {
      case 'en':
        options.decimalSeparator = '.';
        options.thousandSeparator = ',';
        break;
      default:
        options.decimalSeparator = ',';
        options.thousandSeparator = '.';
        break;
    }

    return options;
  }

  constructor(element, options) {
    this.originalElement = element;
    this.options = options || new InputNumericOptions();

    this._setLocaleSettings();
    this.$el = $(element);
    this.$el.blur(e => this._blur());
    this._init();
  }

  _setLocaleSettings() {
    if (!this.options.useLocaleSettings) {
      return;
    }

    Object.assign(this.options, InputNumeric.getLocaleSettings());
  }

  _init() {
    if (this.$el.attr('data-raw-value')) {
      this.$el.val(parseFloat(this.$el.attr('data-raw-value')));
    }
    this.$el.keypress(event => this._keyPressed(event));
    this.$el.keyup(event => this._keyUp(event));
    this.$el.focusout(event => this._focusOut());
    this.$el.val(this.$el.val().replace('.', this.options.decimalSeparator));

    this.keyupDetected = false;
    this.keydownDetected = false;

    this.format();
  }

  /**
   * Get the inner jquery element
   */
  get element() {
    return this.$el;
  }

  format() {
    if (this.options.useThousandSeparator) {
      let newText = InputNumeric.formatNumber(
        this.$el.val(),
        this.options.decimals,
        this.options.thousandSeparator,
        this.options.decimalSeparator
      );

      let oldPosition = this.$el[0].selectionStart;
      let charDifference = newText.length - this.$el.val().length;

      if (newText !== this.$el.val()) {
        this.$el.val(newText);

        if (oldPosition) {
          this.$el[0].selectionStart = oldPosition + charDifference;
          this.$el[0].selectionEnd = this.$el[0].selectionStart;
        }
      }
    }

    let rawValue = this.$el
      .val()
      .replace(new RegExp('\\' + this.options.thousandSeparator, 'g'), '')
      .replace(new RegExp('\\' + this.options.decimalSeparator, 'g'), '.')
      .replace(/\.$/g, '');
    if (rawValue == '-') rawValue = '';

    this.$el.attr('data-raw-value', rawValue);
  }

  /**
   * Parse a normal decimal value (with .) into the input custom format
   * @param {Number} val
   */
  parse(val) {
    let rawValue = val.toString().replace('.', this.options.decimalSeparator);
    this.$el.val(rawValue);
    this.format();
  }

  _fixSizeNumber() {
    let integer = this._getIntegerPart();

    if (integer.length > this.options.maxLength - this.options.decimals) {
      this.$el.val(integer.substring(0, this.options.maxLength - this.options.decimals));
    }
  }

  _getIntegerPart() {
    let data = this.$el.val().split(this.options.decimalSeparator);

    if (data.length > 0) {
      return data[0].replace(new RegExp('\\' + this.options.thousandSeparator, 'g'), '');
    }

    return '';
  }

  _focusOut() {
    if (!this.keyupDetected && this.keydownDetected) {
      this._fixSizeNumber();
      this.format();
    }
    this.keyupDetected = false;
    this.keydownDetected = false;
  }

  /**
   * Event fired after the key has been released
   */
  _keyUp() {
    this._fixSizeNumber();
    this.format();

    this.keyupDetected = true;
  }

  /**
   * Event fired after the key has been pressed
   * @param {Event} event
   */
  _keyPressed(event) {
    this.keydownDetected = true;

    let isValidKey = true;
    isValidKey = isValidKey && this._isValidKeyCode(event);
    isValidKey = isValidKey && this._isValidDecimalSeparator(event);
    isValidKey = isValidKey && this._isValidDecimal(event);
    isValidKey = isValidKey && this._isValidMinusSymbol(event);
    isValidKey = isValidKey && this._isValidIntLength(event);

    if (!isValidKey) {
      event.preventDefault();
    }
  }

  _isValidIntLength(event) {
    let data = this.$el.val().split(this.options.decimalSeparator);

    if (data.length > 0) {
      let integer = data[0].replace(new RegExp('\\' + this.options.thousandSeparator, 'g'), '');
      return (
        integer.length < this.options.maxLength - this.options.decimals ||
        !this._isKeyNumber(event) ||
        this.$el[0].selectionStart > data[0].length
      );
    } else {
      return true;
    }
  }

  /**
   * Check that the numbers of decimals is correct
   * @param {*} event
   */
  _isValidDecimal(event) {
    let data = this.$el.val().split(this.options.decimalSeparator);

    //No decimals for now
    if (data.length == 1) return true;
    if (this.$el[0].selectionStart <= data[0].length) return true;

    let decimals = data[1].length;
    return decimals < this.options.decimals || !this._isKeyNumber(event);
  }

  _isValidMinusSymbol(event) {
    let data = this.$el.val();

    if (this.options.allowNegatives) {
      if (this._isMinusSymbol(event)) {
        return event.target.selectionStart == 0 && data.indexOf('-') === -1;
      } else {
        return (
          data.indexOf('-') === -1 ||
          event.target.selectionStart > 0 ||
          this._isAlwaysValidKeyCode(event)
        );
      }
    } else {
      return !this._isMinusSymbol(event);
    }
  }

  _isAlwaysValidKeyCode(event) {
    return (
      event.key == 'Backspace' || //backspace
      event.key == 'Tab' || //tab
      event.key == 'ArrowLeft' || //left arrow
      event.key == 'ArrowRight' || //right arrow
      event.key == 'Delete' || //delete
      event.key == 'Home' || //start
      event.key == 'End' //end
    );
  }
  /**
   * All valid keys a user can press
   * @param {Event} event
   */
  _isValidKeyCode(event) {
    if (event.shiftKey == true) {
      return (
        event.key == 'Shift' || //Shift arrow
        event.key == 'Home' || //start
        event.key == 'End'
      ); //end;
    } else if (event.ctrlKey == true) {
      return event.key == 'c' || event.key == 'v' || event.key == 'a'; //Ctrl C/V/A
    } else {
      return (
        this._isKeyNumber(event) ||
        this._isAlwaysValidKeyCode(event) ||
        this._isDot(event) ||
        this._isComma(event) ||
        this._isMinusSymbol(event)
      );
    }
  }

  _isMinusSymbol(event) {
    return event.key === '-';
  }

  _isKeyNumber(event) {
    return isFinite(event.key);
  }

  /**
   * Check if the event is a dot
   * @param {*} event
   */
  _isDot(event) {
    return event.key === '.';
  }

  /**
   * Check if the event is a comma
   * @param {*} event
   */
  _isComma(event) {
    return event.key === ',';
  }

  /**
   * Check that decimals are enabled and allow only one dot
   * @param {Event} event
   */
  _isValidDecimalSeparator(event) {
    if (this.options.decimals > 0) {
      return !this._isDecimalSeparator(event) || this.DecimalAllowed();
    } else {
      return !this._isDot(event) && !this._isComma(event);
    }
  }

  DecimalAllowed() {
    return (this.$el.val().indexOf(this.options.decimalSeparator) === -1 && this.$el.val().length > 0 &&
            this.$el.val() !== '-');
  }

  _isDecimalSeparator(event) {
    if (this.options.decimalSeparator == '.') {
      if (this._isComma(event)) {
        if (this.DecimalAllowed()) {
          this.$el.get()[0].value = this.$el.val() + String.fromCharCode(46);
        }
 
        return true;
      }
      return this._isDot(event);
    } else if (this.options.decimalSeparator == ',') {
      if (this._isDot(event)) {
        if (this.DecimalAllowed()) {
          this.$el.get()[0].value = this.$el.val() + String.fromCharCode(44);
        }
        
        return true;
      }
      return this._isComma(event);
    }
    throw 'undefined decimal separator';
  }
  /**
   *
   * @param {*} event
   */
  _blur(event) {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');
    if (
      (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) &&
      this.$el.val().length > 3
    ) {
      this.$el.trigger('change');
    }
  }
}

export class InputNumericOptions {
  decimals = 2;
  decimalSeparator = ',';
  thousandSeparator = '.';
  useThousandSeparator = true;
  useLocaleSettings = true;
  allowNegatives = true;
  maxLength = 13;
}
