import './budget-edit-move-line-mobile.scss';
var htmlContent = require('./budget-edit-move-line-mobile.html');
import $ from 'jquery';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import {
  NavigationCardOptions,
  NavigationCardComponent
} from '../../shared/components/navigation-card/navigation-card';
import { CardLineBudget } from '../../shared/components/card-line-budget/card-line-budget';
import { TranslationService } from '../../core/translation/translation';

const renderMarkup = () => htmlContent;

export class BudgetEditMoveLineMobile extends BaseModalContent {
  constructor(options) {
    super(renderMarkup());
    this._options = options;
  }

  onOpened() {
    this.init();
  }

  init() {
    this.parentModal.container
      .find('#btn-save-budget')
      .click(() => this._moveLine(this._navigationCard.currentNode.id));
    this.parentModal.container.find('#btn-cancel').click(() => this.cancel());

    let data = JSON.parse(this._options.budgetManager.getChildrenJson());

    this._cardLineOptions = Object.assign({}, this._options);
    this._cardLineOptions.contextMenu = false;

    let navigationCardOptions = new NavigationCardOptions();
    navigationCardOptions.data = data;
    navigationCardOptions.navigationProperty = 'children';
    navigationCardOptions.selector = '.budget-edit-move-line-mobile .cards-container';
    navigationCardOptions.cardTemplate = CardLineBudget.template;
    navigationCardOptions.onRenderCard = (card, data, breadcrumbs) =>
      this._onRenderCard(card, data, breadcrumbs);
    navigationCardOptions.onRenderBreadcrumbs = (container, breadcrumbs) =>
      this._onRenderBreadcrumbs(container, breadcrumbs);
    navigationCardOptions.startNode = { id: this._options.budgetLineId };
    navigationCardOptions.comparerProperty = 'id';

    this._navigationCard = new NavigationCardComponent(navigationCardOptions);
    this._navigationCard.onNavigation.listen(() => this._onNavigation());
    this._navigationCard.init();
  }

  _onNavigation() {
    let disableSave = false;

    if (this._navigationCard.currentNode) {
      let currentNode = this._options.budgetManager.getLine(this._navigationCard.currentNode.id);

      let isSameLevel = currentNode.getChildrenBudgetLines()[this._options.budgetLineId];

      disableSave = disableSave || isSameLevel;
    } else {
      disableSave = true;
    }

    disableSave = disableSave || this._navigationCard.breadCrumbs.length <= 1;
    document.querySelector('#btn-save-budget').classList.toggle('disabled', disableSave);
  }

  _onRenderCard(card, data, breadcrumbs) {
    let cardLineBudget = new CardLineBudget(this._cardLineOptions);
    cardLineBudget.render(card, data, breadcrumbs);

    let line = this._options.budgetManager.getLine(data.id);
    let isDisabled =
      line.childrenAreRequested() ||
      line.getRequestedTo() != null ||
      line.getId() == this._options.budgetLineId;

    card.classList.toggle('disabled', isDisabled);
    if (isDisabled) {
      let navigationDown = card.querySelector('.navigation-down');
      if (navigationDown) {
        navigationDown.remove();
      }
    }

    if (!isDisabled && !line.hasChildren() && !line.childrenContainId(data.id)) {
      let temp = document.createElement('div');
      temp.innerHTML = `<div class="insert-here">
        ${TranslationService.instance.translate('bd-modal-move-line-insert-here')}
      </div>`;

      card.appendChild(temp.firstChild);
      card.querySelector('.insert-here').addEventListener('click', () => this._moveLine(data.id));
    }
  }

  _onRenderBreadcrumbs(container, breadcrumbs) {
    let text = breadcrumbs
      .map(x => this._options.budgetManager.getLine(x.id).getDescription())
      .join(' / ');

    container.innerHTML = `<span class="icon-subir-nivel"></span><span class="text">${text}</span>`;
  }

  _moveLine(lineId) {
    let movedLine = this._options.budgetManager.getLine(this._options.budgetLineId);
    let currentLine = this._options.budgetManager.getLine(lineId);

    let allOrders = Object.values(currentLine.getChildrenBudgetLines()).map(x => x.getOrder());
    let greaterOrder = 0;
    if (allOrders.length > 0) {
      greaterOrder = Math.max(...allOrders) + 1;
    }

    this._options.budgetManager.moveBudgetLine(
      movedLine.getId(),
      lineId,
      greaterOrder,
      movedLine.getOrder()
    );
    this._value = true;
    this.parentModal.close();
  }

  get result() {
    return this._value;
  }

  cancel() {
    this._value = null;
    this.parentModal.close();
  }
}
export class BudgetEditMoveLineMobileOptions {
  budgetManager = null;
  budgetLineId = null;
}
