import { Rule } from '../rule';

export class MaxRule extends Rule {
  get name() {
    return 'max';
  }

  getErrorMessage(element, lang) {
    return `El campo debe ser superior a ${element.attr('max')}`;
  }

  isValid(element) {
    let value = element.attr('data-raw-value');
    if (!value) {
      value = element.val();
    }
    return parseFloat(value) <= parseFloat(element.attr('max'));
  }
}
