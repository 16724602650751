import './budget-edit-mobile.scss';
var htmlContent = require('./budget-edit-mobile.html');
import $ from 'jquery';
import { HttpService } from '../../core/http/http';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { ToastService } from '../../shared/services/toastService';
import { DatePicker } from '../date-picker/datePicker';
import { ModalComponent } from '../../shared/components/modal';
import { DisbursementDates } from '../../components/disbursement-dates/disbursementDates';
import { InputNumeric } from '../input-numeric/inputNumeric';
import { BudgetLinesActionsEnums } from '../../config/enums';
import { TranslationService } from '../../core/translation/translation';

const renderMarkup = () => htmlContent;

export class BudgetEditMobileComponent extends BaseModalContent {
  constructor(budgetLine) {
    super(renderMarkup());
    this._budgetLine = budgetLine;
    this.toastService = new ToastService();
    this.httpService = new HttpService();
    this._value = {};
  }

  onOpened() {
    this.init();
  }

  init() {
    this.q1Amount = this.parentModal.container.find('#quarter1');
    this.q2Amount = this.parentModal.container.find('#quarter2');
    this.q3Amount = this.parentModal.container.find('#quarter3');
    this.q4Amount = this.parentModal.container.find('#quarter4');
    this.annualAmount = this.parentModal.container.find('#annual');
    this.acquisitionDate = this.parentModal.container.find('#acquisition-date');
    this.acquisitionDate.attr(
      'placeholder',
      TranslationService.instance.translate('common-placeholder-select')
    );
    this.disbursementDateButton = this.parentModal.container.find('#btn-disbursement-date');

    this.acquisitionDate.change(e =>
      this._budgetLine.setAcquisitionDate(
        DatePicker.toIsoString(DatePicker.parseDate(e.target.value))
      )
    );

    this.fillValues();

    this.disbursementDateButton.click(() => this.disbursementDatesModal());
    this.parentModal.container.find('#btn-save-budget').click(() => this.saveBudget());
    this.parentModal.container.find('#btn-cancel').click(() => {
      this._value = false;
      this.parentModal.close();
    });

    this.q1Amount.focusin(e => {
      $(e.target).data('oldVal', $(e.target).val());
    });
    this.q1Amount.change(e => {
      this._budgetLine.editAmountQuarterWithoutRemovingDates(
        1,
        Number(this.q1Amount.attr('data-raw-value')),
        $(e.target).data('oldVal')
      );
      this.updateAnnual();
    });
    this.q2Amount.focusin(e => {
      $(e.target).data('oldVal', $(e.target).val());
    });
    this.q2Amount.change(e => {
      this._budgetLine.editAmountQuarterWithoutRemovingDates(
        2,
        Number(this.q2Amount.attr('data-raw-value')),
        $(e.target).data('oldVal')
      );
      this.updateAnnual();
    });
    this.q3Amount.focusin(e => {
      $(e.target).data('oldVal', $(e.target).val());
    });
    this.q3Amount.change(e => {
      this._budgetLine.editAmountQuarterWithoutRemovingDates(
        3,
        Number(this.q3Amount.attr('data-raw-value')),
        $(e.target).data('oldVal')
      );
      this.updateAnnual();
    });
    this.q4Amount.focusin(e => {
      $(e.target).data('oldVal', $(e.target).val());
    });
    this.q4Amount.change(e => {
      this._budgetLine.editAmountQuarterWithoutRemovingDates(
        4,
        Number(this.q4Amount.attr('data-raw-value')),
        $(e.target).data('oldVal')
      );
      this.updateAnnual();
    });
  }

  fillValues() {
    this.q1Amount.val(this._budgetLine.getTotalAmountFromQuarter(1, true));
    this.q2Amount.val(this._budgetLine.getTotalAmountFromQuarter(2, true));
    this.q3Amount.val(this._budgetLine.getTotalAmountFromQuarter(3, true));
    this.q4Amount.val(this._budgetLine.getTotalAmountFromQuarter(4, true));
    InputNumeric.createDecimal(this.q1Amount, true);
    InputNumeric.createDecimal(this.q2Amount, true);
    InputNumeric.createDecimal(this.q3Amount, true);
    InputNumeric.createDecimal(this.q4Amount, true);

    if (!this._budgetLine.canStoreBudgetLineAmounts()) {
      this.q1Amount.attr('disabled', '');
      this.q2Amount.attr('disabled', '');
      this.q3Amount.attr('disabled', '');
      this.q4Amount.attr('disabled', '');
      this.disbursementDateButton.attr('disabled', '');
    }

    this._budgetLine.validateAmounts();
    this.updateAnnual();

    this.acquisitionDate.each((i, e) => {
      if (this._budgetLine.getAcquisitionDate() != null) {
        e.value = DatePicker.parseDate(this._budgetLine.getAcquisitionDate()).toLocaleDateString(
          'es-ES'
        );
      }
      DatePicker.create(e, this._budgetLine.getExercise());
    });
  }

  updateAnnual() {
    let annual =
      Number(this.q1Amount.attr('data-raw-value')) +
      Number(this.q2Amount.attr('data-raw-value')) +
      Number(this.q3Amount.attr('data-raw-value')) +
      Number(this.q4Amount.attr('data-raw-value'));
    annual = !isNaN(annual) ? annual : 0;
    this.annualAmount.attr('data-raw-value', annual);
    InputNumeric.createDecimal(this.annualAmount, true);

    this.checkBudgetLineErrors();
  }

  checkBudgetLineErrors() {
    if (this._budgetLine.hasErrors) {
      $('#btn-disbursement-date').addClass('has-errors');
    } else {
      $('#btn-disbursement-date').removeClass('has-errors');
    }
  }

  disbursementDatesModal() {
    this._value.action = 'open-disbursement-modal';
    this.parentModal.close();
  }

  // Tiggered when create button is clicked
  saveBudget() {
    // updates the budget line instance
    this._budgetLine.setActionId(BudgetLinesActionsEnums.Update);
    this._value = {};
    this._value.action = 'save';

    let q1 = this.q1Amount.attr('data-raw-value');
    let q2 = this.q2Amount.attr('data-raw-value');
    let q3 = this.q3Amount.attr('data-raw-value');
    let q4 = this.q4Amount.attr('data-raw-value');

    this._budgetLine.setAmountQ1Local(q1 != '' ? Number(q1) : null);
    this._budgetLine.setAmountQ2Local(q2 != '' ? Number(q2) : null);
    this._budgetLine.setAmountQ3Local(q3 != '' ? Number(q3) : null);
    this._budgetLine.setAmountQ4Local(q4 != '' ? Number(q4) : null);

    this.parentModal.close();
  }

  get result() {
    return this._value;
  }
}
