import './visualization-options.scss';
var htmlContent = require('./visualization-options.html');
import { HttpService } from '../../core/http/http';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { ToastService } from '../../shared/services/toastService';

const renderMarkup = () => htmlContent;

export class VisualizationOptionsComponent extends BaseModalContent {
  constructor(options) {
    super(renderMarkup());
    this._options = options || new VisualizationOptionsParams();
    this.toastService = new ToastService();
    this.httpService = new HttpService();
    // Modal name data-translation: visualization-options-modal-title
  }

  onOpened() {
    this.init();
  }

  init() {
    this.buildOptions();
    this.parentModal.container.find('#btn-select-options').click(() => this.selectOptions());
  }

  buildOptions() {
    this.badgeLocalSpan = this.parentModal.container.find('#local-badge');
    this.badgeSelector = this.parentModal.container.find('#select-badge');
    this.unitSelector = this.parentModal.container.find('#select-units');
    this.badgeLocalSpan.text(this._options.localCurrency);
    let badgeValue = this._options.isLocal ? 0 : 1;
    this.badgeSelector.find('input[value=' + badgeValue + ']').prop('checked', true);
    this.unitSelector.find('input[value=' + this._options.selectedUnit + ']').prop('checked', true);
  }

  // Tiggered when create button is clicked
  selectOptions() {
    let badgeSelected = parseInt(this.badgeSelector.find('input[name=select-badge]:checked').val());
    let unitSelected = parseInt(this.unitSelector.find('input[name=select-unit]:checked').val());

    this.value = {
      selectedUnit: unitSelected,
      isLocal: badgeSelected == 0
    };
    this.parentModal.close();
  }

  get result() {
    return this.value;
  }
}

export class VisualizationOptionsParams {
  selectedUnit = 0;
  localCurrency = 'Euro(€)';
  isLocal = true;
}
