export class LocalStorageService {
  constructor() {
    if (LocalStorageService.inst) {
      return LocalStorageService.inst;
    }
    LocalStorageService.inst = this;
    return this;
  }

  getItem(key) {
    return window.localStorage.getItem(key);
  }

  setItem(key, value) {
    window.localStorage.setItem(key, value);
  }

  removeItem(key) {
    window.localStorage.removeItem(key);
  }

  clearData() {
    window.localStorage.clear();
  }
}
