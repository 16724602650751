import $ from '../../core/jquery/jquery-global';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-es';
import 'jquery-ui/ui/i18n/datepicker-pl';
import { TranslationService } from '../../core/translation/translation';

export class DatePicker {
  static create(element, year) {
    return new DatePicker(element, {
      minDate: year ? new Date(year, 0, 1) : null,
      maxDate: year ? new Date(year, 11, 31) : null,
      changeYear: true
    });
  }

  static createWithoutMaxDate(element, year) {
    return new DatePicker(element, {
      minDate: year ? new Date(year, 0, 1) : null,
      changeYear: true
    });
  }

  static createWithoutMinDate(element, year) {
    return new DatePicker(element, {
      maxDate: year ? new Date(year, 11, 31) : null,
      changeYear: true
    });
  }

  /**
   * Parse a string dd[-/]mm[-/]yy into a date object
   * @param {String} text
   */
  static parseDate(text) {
    if (!text) return null;
    if (text.indexOf('T') === -1) {
      let splitted = text.split(/[-/]+/);

      switch (TranslationService.instance.languageShortName) {
        case 'en':
          return new Date(
            Date.UTC(splitted[2], parseInt(splitted[0]) - 1, splitted[1], 0, 0, 0, 0)
          );
        default:
          return new Date(
            Date.UTC(splitted[2], parseInt(splitted[1]) - 1, splitted[0], 0, 0, 0, 0)
          );
      }
    } else if (text.indexOf('Z') === -1) {
      let splitted1 = text.split('T')[0].split(/[-/]+/);
      return new Date(Date.UTC(splitted1[0], parseInt(splitted1[1]) - 1, splitted1[2], 0, 0, 0, 0));
    } else {
      return new Date(text);
    }
  }


  /**
   * Parse a string dd[-/]mm[-/]yy into a date object
   * @param {String} text
   */
  static parseDateTime(text) {
    if (!text) return null;
    if (text.indexOf('T') === -1) {
      let splitted = text.split(/[-/]+/);
      switch (TranslationService.instance.languageShortName) {
        case 'en':
          return new Date(
            Date.UTC(splitted[2], parseInt(splitted[0]) - 1, splitted[1], 0, 0, 0, 0)
          );
        default:
          return new Date(
            Date.UTC(splitted[2], parseInt(splitted[1]) - 1, splitted[0], 0, 0, 0, 0)
          );
      }
    } else if (text.indexOf('Z') === -1) {
      let splitted1 = text.split('T')[0].split(/[-/]+/);
      let splitted2 = text.split('T')[1].split(/[-/]+/);
      return new Date(Date.UTC(splitted1[0], parseInt(splitted1[1]) - 1, splitted1[2], splitted2[0], splitted2[1], 0, 0));
    } else {
      return new Date(text);
    }
  }

  static toIsoString(date) {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000).toISOString();
  }

  static localTimeFromUTC(date) {
    //convert the offset to milliseconds, add to targetTime, and make a new Date
    var offSet = new Date().getTimezoneOffset() * 60 * 1000;
    var localDate = new Date(date.getTime() - offSet);

    return localDate;
  }

  static addMonths(date, count) {
    if (date && count) {
      var m,
        d = (date = new Date(+date)).getDate();
      date.setMonth(date.getMonth() + count, 1);
      m = date.getMonth();
      date.setDate(d);
      if (date.getMonth() !== m) date.setDate(0);
    }
    return date;
  }

  static addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  static toLocaleDateString(date, keepClientUTC = false) {
    let options = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    };

    if (!keepClientUTC) {
      options.timeZone = 'UTC';
    }

    return date.toLocaleDateString(TranslationService.instance.language.replace('_', '-'), options);
  }

  constructor(element, options) {
    this.originalElement = element;
    this.$el = $(element);
    this.options = options;
    this._init();
  }

  _init() {
    this._setLocaleFormatDate();
    let settings = Object.assign(
      {},
      $.datepicker.regional[TranslationService.instance.languageShortName],
      this.options
    );
    this.$el.datepicker(settings);
    //this.$el.datepicker(this.options);
  }

  _setLocaleFormatDate() {
    if (this.options.dateFormat) {
      return;
    }

    switch (TranslationService.instance.languageShortName) {
      case 'en':
        this.options.dateFormat = 'mm/dd/yy';
        break;
      default:
        this.options.dateFormat = 'dd/mm/yy';
        break;
    }
  }

  /**
   * Get the inner jquery element
   */
  get element() {
    return this.$el;
  }
}
