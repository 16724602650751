import './input-file.scss';
var htmlContent = require('./input-file.html');
import $ from 'jquery';
import { Component } from '../base-component';
import { Event } from '../../../core/events/event';
import { ToastService } from '../../services/toastService';
import { TranslationService } from '../../../core/translation/translation';

const renderMarkup = () => htmlContent;
export class InputFileComponent extends Component {
  static FILE_LAYOUT = `<div class="file">
    <span class="icon-documento"></span>
    <span class="file-text"></span>
    <span class="icon-eliminar"></span>
  </div>`;

  constructor(options) {
    super(renderMarkup());
    this._options = options || new InputFileOptions();

    this._inputFileContainer = null;
    this._inputFile = null;
    this._fileValidator = null;
    this._fileDisplayGroup = null;
    this._files = [];
    this._deletedFiles = [];
    this.onChange = new Event();
    this._toasts = new ToastService();
  }

  onLoaded() {
    this._init();
  }

  get files() {
    return this._files;
  }
  get deletedFiles() {
    return this._deletedFiles;
  }

  _init() {
    this._inputFileContainer = $('.input-file-container');

    this._fileValidator = this._inputFileContainer.find('.file-validator');
    this._buttonContainer = this._inputFileContainer.find('.attach');
    this._inputFile = this._inputFileContainer.find('.attach-file');
    this._inputFile.change(() => this._changedAttachtment());

    this._fileDisplayGroup = this._inputFileContainer.find('.files');

    if (this._options.allowMultipleFiles) {
      this._inputFile.attr('multiple', '');
    }

    if (this._options.readOnly) {
      this._buttonContainer.remove();
    }

    //Add all rules
    for (const key in this._options.rules) {
      if (Object.prototype.hasOwnProperty.call(this._options.rules, key)) {
        const element = this._options.rules[key];
        this._fileValidator.attr(key, element);
      }
    }

    for (const existingFile of this._options.existingFiles) {
      this._addFile(existingFile);
      this._mapFiles();
    }
  }

  _changedAttachtment() {
    if (!this._options.allowMultipleFiles) {
      this._inputFileContainer.toggleClass('files-attached', false);
      this._fileDisplayGroup.html('');
      this._files = [];
    }

    if (this._inputFile.val() === '') {
      this._fileValidator.val('');
      this._inputFile.val('');
      return;
    }

    let files = this._inputFile[0].files;
    for (const file of files) {
      this._addFile(file);
    }

    this._inputFileContainer.toggleClass('files-attached', this.files.length > 0);
    this._mapFiles();
    this.onChange.execute();
    this._inputFile.val('');
  }

  _mapFiles() {
    this._fileValidator.val(this._files.map(x => x.name).join(','));
  }

  _removeFile(fileDisplay, file) {
    if (!file.isNewFile) {
      this.deletedFiles.push(file);
    }

    this._files = this._files.filter(x => x.name != file.name);
    fileDisplay.remove();
    this._mapFiles();
    this.onChange.execute();
  }

  _addFile(file) {
    if (this._files.find(x => x.name === file.name)) {
      this._toasts.error(
        TranslationService.instance.translate('common-existent-file-err'),
        TranslationService.instance
          .translate('common-existent-file-description')
          .replace('{0}', file.name)
      );

      return;
    }

    file.isNewFile = typeof file.lastModifiedDate !== 'undefined' && file.lastModifiedDate !== null;
    this._files.push(file);

    let fileDisplay = $(this._options.fileLayout);
    fileDisplay.find('.file-text').text(file.name);

    if (!this._options.allowDelete || this._options.readOnly) {
      fileDisplay.find('.icon-eliminar').remove();
    } else {
      fileDisplay.find('.icon-eliminar').click(() => {
        this._removeFile(fileDisplay, file);
      });
    }

    if (this._options.onRenderedFile) {
      this._options.onRenderedFile(file, fileDisplay);
    }
    this._fileDisplayGroup.append(fileDisplay);
  }
}
export class InputFileOptions {
  existingFiles = [];
  readOnly = false;
  allowMultipleFiles = false;
  allowDelete = false;
  onRenderedFile = null;
  fileLayout = InputFileComponent.FILE_LAYOUT;
  rules = {};
}
