import './budgets-query.scss';
import '../../styles/variables.scss';
var htmlContent = require('./budgets-query.html');
import { Component } from '../../shared/components/base-component';
import $ from '../../core/jquery/jquery-global';
import { BudgetStatusEnum, BudgetStatusClassesEnum } from '../../config/enums';
import { HttpService } from '../../core/http/http';
import { ToastService } from '../../shared/services/toastService';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import { ViewsLoader } from '../../core/views/views-loader';
import { Spinner } from '../../shared/components/spinner/spinner';
import { TranslationService } from '../../core/translation/translation';
import { List, ListOptions } from '../../shared/components/list/list';
import { FilterBudgetComponent } from '../../components/filter-budget-report/filter-budget-report';
import { ModalComponent } from '../../shared/components/modal';
import { AuthenticationService } from '../../shared/services/authenticationService';
import { SessionStorageService } from '../../core/sessionStorage/sessionStorage';
import { SessionKeys } from '../../config/keys';

const renderMarkup = () => htmlContent;

export class BudgetsQueryPage extends Component {
  constructor() {
    super(renderMarkup());
    this._cleaning = false;
    this.triggeredOnMobile = false;
    $(document).ready(() => {
      let selectNav = document.querySelectorAll('.links-item');
      let i = 0;
      selectNav.forEach(e => {
        if (i == 3) {
          $(e).addClass('active');
          i++;
        } else {
          $(e).removeClass('active');
          i++;
        }
      });
      this.httpService = new HttpService();
      this.toastService = new ToastService();
      this.authenticationService = new AuthenticationService();
      this.sessionStorage = new SessionStorageService();
      this.viewsLoader = new ViewsLoader();
      this._init();
    });
  }

  existsInSession(val) {
    return (
      this.sessionStorage.hasItem(val) &&
      this.sessionStorage.getItem(val) !== '' &&
      this.sessionStorage.getItem(val) !== 'undefined'
    );
  }

  _init() {
    this.viewsLoader.loadView(new Spinner('big'), document.getElementById('loader-container'));
    $('#btn-toogle-menu').click(() => this._toggleMenu());
    $('#btn-filters').click(() => this.mobileFilterBudgetModal());
    this._buildSelectors().then(() => {
      $('#loader-container').addClass('hidden');
      this._buildPage();
    });
  }

  _buildPage() {
    let options = new ListOptions();
    options.searchSelector = '.search-input';
    options.textShowingSelector = '.text-showing-results';
    options.textShowingValue = TranslationService.instance.translate('common-showing-results');
    options.includeFieldsSearch = [
      'areaTrabajo.empresa',
      'areaTrabajo.areaInversion',
      'areaTrabajo.ejercicio',
      'pendienteDe',
      'estado'
    ];
    if (window.innerWidth > 767) {
      this.buildTableContent(options);
    } else {
      this.buildCardsContent(options);
      let h = window.innerHeight - 221;
    }
    $(window).resize(() => {
      if (window.innerWidth > 767) {
        this.buildTableContent(options);
      } else {
        this.buildCardsContent(options);
      }
    });
  }

  buildTableContent(options) {
    options.listSelector = '#budgets-query-table';
    options.onRenderRow = function (row) {
      return `<div class="table-row">
        <div class="cell center open">
          <a href="#budgets/${row.idPresupuesto}" class="icon-abrir" title="${TranslationService.instance.translate('common-table-open')}"></a>
        </div>
        <div class="cell company">
          ${row.areaTrabajo.empresa}
        </div>
        <div class="cell investmentArea">
          ${row.areaTrabajo.areaInversion}
        </div>
        <div class="cell exercise margin">
          ${row.areaTrabajo.ejercicio}
        </div>
        <div class="cell pending">
          <div class="description-mobile">${TranslationService.instance.translate(
        'common-pending'
      )}</div>
          <div>${row.pendienteDe == null ? '' : row.pendienteDe}</div>
        </div>
        <div class="cell status">
          <div class="description-mobile">${TranslationService.instance.translate(
        'budgets-table-state-budget'
      )}</div>
          <div class="state">
            <i class="bq-circle-state ${BudgetStatusEnum[row.idEstado]}"></i>
            <span class="bq-status-text">${row.esRevision
          ? TranslationService.instance.translate('review')
          : TranslationService.instance.translate(BudgetStatusEnum[row.idEstado])
        }</span>
          </div>
        </div>`;
    };

    this._list = new List(options);
  }

  buildCardsContent(options) {
    options.listSelector = '#budgets-query-cards';
    options.onRenderRow = function (row) {
      let title =
        row.areaTrabajo.empresa +
        '</br>' +
        row.areaTrabajo.areaInversion +
        '</br>' +
        row.areaTrabajo.ejercicio;
      return `<div class="card">
        <div class="card-row">
          <div class="card-row-element all">
            <span class="title with-link">${title}</span>
            <a href="/#budgets/${row.idPresupuesto}" class="icon-abrir" title="${TranslationService.instance.translate('common-table-open')}"></a>        
          </div>
        </div>
        <div class="card-row">
          <div class="card-row-element half">
            <div class="label">
            ${TranslationService.instance.translate('budgets-table-state-budget')}
            </div>
            <div class="description">
            <span class="circle-state ${BudgetStatusClassesEnum[row.idEstado]}"></span>
              <div class="status-text">${TranslationService.instance.translate(
        BudgetStatusEnum[row.idEstado]
      )}</div>
            </div>
          </div>
          <div class="card-row-element half">
            <div class="label">
            ${TranslationService.instance.translate('common-pending')}
            </div>
            <div class="description">
              <div>${row.pendienteDe == null ? '' : row.pendienteDe}</div>
            </div>
          </div>
        </div>
      </div>
      `;
    };

    this._list = new List(options);
  }

  _buildSelectors() {
    return Promise.all([this._loadYears(), this._loadCompanies(), this._loadInvestmentAreas()]).then(() => {

      //YEARS 

      this.yearSelector = new DropDownComponent(document.getElementById('exercises-selector'));
      this.yearSelector.loadData(this._years, 'id', 'label');

      const year = this.existsInSession(SessionKeys.BudgetQueryYear)
        ? parseInt(this.sessionStorage.getItem(SessionKeys.BudgetQueryYear))
        : new Date().getFullYear();
      if(this._years.some(x => x.id === year)) {
        this.yearSelector.setSelected(year, 'id', 'label');
      }

      this.yearSelector.onChanges.listen(_ => this._filterTriggered());

      // STATUS

      this.statusSelector = new DropDownComponent(document.getElementById('status-selector'));
      this.statusSelector.loadLocal(
        Object.values(BudgetStatusEnum).map(x => TranslationService.instance.translate(x)),
        Object.keys(BudgetStatusEnum).map(x => Number(x)),
      );

      if (this.existsInSession(SessionKeys.BudgetQueryStatus)) {
        this.statusSelector.setSelected(
          parseInt(this.sessionStorage.getItem(SessionKeys.BudgetQueryStatus)), 'id', 'label');
      }

      this.statusSelector.onChanges.listen(status => {
        document.getElementById('status-selector').className =
          `status-selector ${status.value ? BudgetStatusEnum[status.value] : ''}`;
        this._filterTriggered();
      });

      // COMPANY

      this.companySelector = new DropDownComponent(document.getElementById('company-selector'));
      this.companySelector.loadData(this._companies, 'idEmpresa', 'nombre');

      if (this.existsInSession(SessionKeys.BudgetQueryCompany)) {
        this.companySelector.setSelected(
          parseInt(this.sessionStorage.getItem(SessionKeys.BudgetQueryCompany)), 'idEmpresa', 'nombre');
      }

      this.companySelector.onChanges.listen(_ => this._filterTriggered());

      //INVESTMENT AREA

      this.investmentAreaSelector = new DropDownComponent(document.getElementById('investment-area-selector'));
      this.investmentAreaSelector.loadData(this._investmentAreas, 'idAreaInversion', 'nombre');

      if (this.existsInSession(SessionKeys.BudgetQueryInvestmentArea)) {
        this.investmentAreaSelector.setSelected(
          parseInt(this.sessionStorage.getItem(SessionKeys.BudgetQueryInvestmentArea)), 'idAreaInversion', 'nombre');
      }

      this.investmentAreaSelector.onChanges.listen(_ => this._filterTriggered());

      this._filterTriggered();

      $('.reset-filters').click(() => {
        this._cleaning = true;

        this.yearSelector.clearSelection();
        this.investmentAreaSelector.clearSelection();
        this.companySelector.clearSelection();
        this.statusSelector.clearSelection();
        this._list.updateData([]);

        this.sessionStorage.clearData();

        this._cleaning = false;
        this._list.updateData([]);
      });
    });
  }

  _selectToUrl(select, urlParam) {
    if (select && select.getValue() !== '') {
      return urlParam + '=' + select.getValue();
    } else {
      return urlParam + '=0';
    }
  }

  _filterTriggered() {
    if (this._cleaning) {
      return;
    }
    this.triggeredOnMobile == false;

    let params = [
      this._selectToUrl(this.yearSelector, 'AreaTrabajo.Ejercicio'),
      this._selectToUrl(this.investmentAreaSelector, 'AreaTrabajo.IdAreaInversion'),
      this._selectToUrl(this.companySelector, 'AreaTrabajo.IdEmpresa'),
      this._selectToUrl(this.statusSelector, 'IdEstado')
    ];

    // SET FILTER VALUES

    if (this.yearSelector) {
      this.sessionStorage.setItem(SessionKeys.BudgetQueryYear, this.yearSelector.getValue());
    }
    if (this.investmentAreaSelector) {
      this.sessionStorage.setItem(SessionKeys.BudgetQueryInvestmentArea, this.investmentAreaSelector.getValue());
    }
    if (this.companySelector) {
      this.sessionStorage.setItem(SessionKeys.BudgetQueryCompany, this.companySelector.getValue());
    }
    if (this.statusSelector) {
      this.sessionStorage.setItem(SessionKeys.BudgetQueryStatus, this.statusSelector.getValue());
    }

    if (params.filter(x => x.indexOf('=0') == -1).length == 0) {
      return;
    }

    let url =
      this.httpService.config.loadInformePresupuestos +
      this.authenticationService.getAuthenticatedUser().login +
      '&' +
      params.join('&');

    this.httpService
      .get(url)
      .then(response => {
        this._list.updateData(response);
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }

  _loadYears () {
    return this.httpService.get(this.httpService.config.loadBudgetsYears +
      this.authenticationService.getAuthenticatedUser().login
    ).then(response => {
      this._years = response.reverse().map(year => ({id: year, label: year.toString()}));
    }).catch(error => {
      this._toasts.error(error.title, error.message);
      console.error(error);
    });
  }

  _loadCompanies() {
    return this.httpService
      .get(this.httpService.config.loadCompaniesPerUser +
        this.authenticationService.getAuthenticatedUser().login)
      .then(response => {
        this._companies = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }
  _loadInvestmentAreas() {
    return this.httpService
      .get(this.httpService.config.loadInvestmentAreasPerUser +
        this.authenticationService.getAuthenticatedUser().login)
      .then(response => {
        this._investmentAreas = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  mobileFilterBudgetModal() {
    let options = {
      year: this.yearSelector.getValue(),
      company: this.companySelector.getValue(),
      investmentArea: this.investmentAreaSelector.getValue(),
      status: this.statusSelector.getValue()
    };

    let modal = new ModalComponent(
      TranslationService.instance.translate('reports-filter-budgets-modal-title'),
      new FilterBudgetComponent(options)
    );
    modal.onClosed.listen(() => {
      this.triggeredOnMobile = true;
      let res = modal.result;
      if (res.year != '') this.yearSelector.setSelected(res.year, 'id', 'label');
      else this.yearSelector.clearSelection();

      if (res.company != '') this.companySelector.setSelected(res.company, 'idEmpresa', 'nombre');
      else this.companySelector.clearSelection();

      if (res.investmentArea != '')
        this.investmentAreaSelector.setSelected(res.investmentArea, 'idAreaInversion', 'nombre');
      else this.investmentAreaSelector.clearSelection();

      if (res.status != '') this.statusSelector.setSelected(res.status, 'id', 'label');
      else this.statusSelector.clearSelection();
    });
  }

  _toggleMenu() {
    $('.sidenav').toggleClass('menuComplete');
  }
}
