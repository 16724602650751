import { Component } from './base-component';
import $ from 'jquery';
import { ViewsLoader } from '../../core/views/views-loader';
import { Event } from '../../core/events/event';
import { Spinner } from './spinner/spinner';
import { Router } from '../../core/router/router';

export class ModalComponent extends Component {
  constructor(title, content) {
    super();
    this.viewsLoader = new ViewsLoader();
    this.onClosing = new Event();
    this.onClosed = new Event();
    this.onOpening = new Event();
    this.onOpened = new Event();
    this.router = new Router();

    this.userHasChangeListenerId = null;
    this.title = title;
    this.content = content;
    this.modalContainer = null;
    this.init();
  }

  init() {
    this.modalContainer = $('#modal');
    this.modalContainer.addClass('show');
    $('#modal-title').text(this.title);
    this.modalContainer.find('.closeModal').click(() => this.close(true));

    this.onNavigationListenerId = this.router.onNavigation.listen(event => this.close(true));
    this.viewsLoader.loadView(new Spinner(), document.getElementById('loading-icon'));

    this.content.parentModal = this;
    this.content.onCreating(this);
    this.open();
    this.content.onCreated(this);
  }

  close(forceExit) {
    this.router.onNavigation.remove(this.onNavigationListenerId);
    this.modalContainer = $('#modal');
    this.modalContainer.find('.closeModal').off();
    this.modalContainer.find('#boxModal').off();
    this.modalContainer.off();
    $('#view').removeClass('modal-open');

    this.onClosing.execute();
    this.content.onClosing(forceExit);
    this.modalContainer.removeClass('show');
    this.content.onClosed();
    this.onClosed.execute();
  }

  open() {
    $('#view').addClass('modal-open');
    //When opening fires an event on the content, if the event returns true it will wait for the callback to be called
    if (this.content.onLoading(() => this.loadContent()) === true) {
      this.toggleLoadingIcon(true);
    } else {
      this.loadContent();
    }
  }

  loadContent() {
    this.toggleLoadingIcon(false);

    this.onOpening.execute();
    this.content.onOpening();

    this.viewsLoader.loadView(this.content, document.getElementById('modal-content'));

    this.content.onOpened();
    this.onOpened.execute();
  }

  get container() {
    return this.modalContainer;
  }

  get result() {
    return this.content.result;
  }

  toggleLoadingIcon(display) {
    this.modalContainer.toggleClass('loading', display);
  }
}
