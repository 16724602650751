import { HttpService } from '../http/http';

export class AppConfig {
  static _instance = null;
  static get instance() {
    if (!AppConfig._instance) {
      new AppConfig(); //It will assign itself
    }

    return AppConfig._instance;
  }

  constructor() {
    if (AppConfig._instance) {
      return AppConfig._instance;
    }
    AppConfig._instance = this;

    this._http = new HttpService();
    this._config = null;
  }

  init() {
    this._http
      .loadConfigFile('../../../resources/config/app.config.json')
      .then(response => (this._config = response));
  }

  getProperty(property) {
    if (!this._config) {
      throw 'AppConfig must be initalized before accesing the config';
    }

    return this._config[property];
  }
}
