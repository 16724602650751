import './importRequest.scss';
var htmlContent = require('./importRequest.html');
import $ from 'jquery';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { HttpService } from '../../core/http/http';
import { Validator } from '../../core/validator/validator';
import { ToastService } from '../../shared/services/toastService';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import { InputNumeric } from '../input-numeric/inputNumeric';
import { AuthenticationService } from '../../shared/services/authenticationService';
import { TranslationService, TranslationServiceOptions } from '../../core/translation/translation';

const renderMarkup = () => htmlContent;

export class ImportRequestComponent extends BaseModalContent {
  constructor(currentUTE, references, selectedFilter) {
    super(renderMarkup());
    this.httpService = new HttpService();
    this.value = null;
    this.currentUTE = currentUTE;
    this.references = references;
    this.toastService = new ToastService();
    this.authenticationService = new AuthenticationService();
    this._requests = [];
    this.investmentAreas = [];
    this.selectedFilter = selectedFilter;
    this.firstTime = true;
  }

  onLoading(callback) {
    Promise.all([this._loadYears(), this._loadCompanies()]).then(response => {
      callback();
    });
    return true;
  }

  _loadYears () {
    return this.httpService.get(this.httpService.config.loadBudgetsYears +
      this.authenticationService.getAuthenticatedUser().login
    ).then(response => {
      this._years = response.reverse().map(year => ({id: year, label: year.toString()}));
    }).catch(error => {
      this._toasts.error(error.title, error.message);
      console.error(error);
    });
  }

  _loadCompanies() {
    return this.httpService
      .get(
        this.httpService.config.loadCompaniesPerUser +
          this.authenticationService.getAuthenticatedUser().login
      )
      .then(response => {
        this._companies = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  _loadInvestmentAreas(idEmpresa) {
    return this.httpService
      .get(
        this.httpService.config.loadInvestmentAreasPerUserAndCompany +
          '?loginUsuario=' +
          this.authenticationService.getAuthenticatedUser().login +
          '&idEmpresa=' +
          idEmpresa +
          '&tieneQueSerAdmin=false'
      )
      .then(response => {
        this._investmentAreas = response;
        this.investmentAreas = response;
        if (this._investmentAreas && this._investmentAreas.length > 0) {
          this.investmentAreaSelector.loadData(this._investmentAreas, 'idAreaInversion', 'nombre');
          this.investmentAreaSelector.clearSelection();
          this.investmentAreaSelector.removeRule('disabled');
          if (this.firstTime) {
            this.investmentAreaSelector.setSelected(this.selectedFilter.investment, 'idAreaInversion', 'nombre');
            this.firstTime = false;
          }
        } else {
          this.investmentAreaSelector.setRule('disabled', 'disabled');
        }
        this.parentModal.toggleLoadingIcon(false);
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  onOpened() {
    this.init();
  }

  init() {
    this.buildSelectors();

    this.parentModal.container.find('.cancel').click(() => this.cancel());
    this.parentModal.container.find('.create').click(() => this.create());

    this.validator = new Validator(this.parentModal.container);

    this.parentModal.container.find('.ute').val(this.currentUTE);
    this.originImport = InputNumeric.createDecimal('.origin-import');
    InputNumeric.createDecimal('.import');

    this.validator.validate();
  }

  cancel() {
    this.value = null;
    this.parentModal.close();
  }

  buildSelectors() {
    this.yearSelector = new DropDownComponent(
      document.getElementById('import-year-selector'),
      false
    );
    this.yearSelector.setRules({
      required: true
    });
    this.yearSelector.loadData(this._years, 'id', 'label');
    const currentYear = new Date().getFullYear();
    if(this._years.some(x => x.id === currentYear)) {
      this.yearSelector.setSelected(currentYear, 'id', 'label');
    }
    this.yearSelector.onChanges.listen(() => this._changedFilter());

    this.companySelector = new DropDownComponent(
      document.getElementById('import-company-selector')
    );
    this.companySelector.setRules({
      required: true
    });
    this.companySelector.onChanges.listen(() => this._changedFilter());
    this.companySelector.loadData(this._companies, 'idEmpresa', 'nombre');

    this.investmentAreaSelector = new DropDownComponent(
      document.getElementById('import-investment-area-selector')
    );
    this.investmentAreaSelector.setRules({
      required: true
    });

    this.investmentAreaSelector.setRule('disabled', 'disabled');

    this.companySelector.onChanges.listen(company => {
      if (company && company.idEmpresa) {
        this.parentModal.toggleLoadingIcon(true);
        this._loadInvestmentAreas(company.idEmpresa);
      } else {
        this.investmentAreaSelector.clearSelection();
        this.investmentAreaSelector.setRule('disabled', 'disabled');
      }
    });

    this.investmentAreaSelector.onChanges.listen(() => this._changedFilter());

    this.originReference = new DropDownComponent(document.getElementById('origin-reference'));
    this.originReference.setRules({
      required: true
    });
    this.originReference.setRule('disabled', 'disabled');
    this.originReference.onChanges.listen(() => this._changedOriginRequest());

    this.yearSelector.setSelected(this.selectedFilter.year, 'id', 'id');
    this.companySelector.setSelected(this.selectedFilter.company, 'idEmpresa', 'nombre');
  }

  _changedFilter() {
    let year = this.yearSelector.getValue();
    let company = this.companySelector.getValue();
    let investmentArea = this.investmentAreaSelector.getValue();
    let isValid = year != '' && company != '' && investmentArea != '';

    let investmentArearSelected = this.investmentAreas.find(
      x => x.idAreaInversion === investmentArea
    );

    if (investmentArearSelected != undefined && !investmentArearSelected.estructuradoEnUte) {
      $('.ute-container.ute').hide();
    } else {
      $('.ute-container.ute').show();
    }

    /*this.investmentAreas.forEach(element => {
      if (element.idAreaInversion == investmentArea) {
        
      }
    });*/

    if (!isValid) {
      this.originReference.setRule('disabled', 'disabled');
      return;
    }
    this.originReference.clearSelection();
    this.parentModal.toggleLoadingIcon(true);
    this.parentModal.container.find('.reference').val('');

    this.httpService
      .post(this.httpService.config.loadRequestsFiltered, {
        idEmpresa: company,
        ejercicio: year,
        idAreaInversion: investmentArea
      })
      .then(response => {
        this._requests = response;
        this.originReference.loadData(this._requests, 'idSolicitud', 'referencia');
        if (
          typeof this._requests !== 'undefined' &&
          this._requests.length &&
          this._requests.length > 0
        ) {
          this.originReference.removeRule('disabled');
        } else {
          this.originReference.setRule('disabled', 'disabled');
        }
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      })
      .finally(() => this.parentModal.toggleLoadingIcon(false));
  }

  _changedOriginRequest() {
    let value = this._requests.find(x => x.idSolicitud == this.originReference.getValue());
    if (typeof value !== 'undefined') {
      this.originImport.parse(value.importe);
      this.parentModal.container.find('.origin-ute').val(value.porcentajeUte);

      if (typeof this.references !== 'undefined') {
        let reference = this.references.find(t => t.tipoSolicitud == value.idTipo).referencia;
        this.parentModal.container.find('.reference').val(reference);
        this.validator.validate();
      }
    } else {
      this.originImport.parse('');
      this.parentModal.container.find('.origin-ute').val('');
    }
  }

  create() {
    if (!this.validator.isValid) {
      return;
    }

    this.value = {
      year: this.yearSelector.getValue(),
      company: this.companySelector.getValue(),
      investmentArea: this.investmentAreaSelector.getValue(),
      reference: this.parentModal.container.find('.reference').val(),
      requestOriginId: this.originReference.getValue()
    };
    this.parentModal.close();
  }

  get result() {
    return this.value;
  }
}
