import { Component } from '../../../shared/components/base-component';

export class BudgetTreeBaseRenderer extends Component {
  /**
   * Get the target type of the renderer
   */
  get targetType() {
    throw 'NotImplementedException';
  }

  /**
   * Check if line is selected
   */
  get isLineSelected() {
    throw 'NotImplementedException';
  }

  /**
   * Get the selected line id
   */
  get selectedLineId() {
    throw 'NotImplementedException';
  }

  /**
   * Get the current unit
   */
  get currentUnit() {
    throw 'NotImplementedException';
  }

  /**
   * Get the current currency
   */
  get currentCurrency() {
    throw 'NotImplementedException';
  }

  /**
   * Get the edited value event
   */
  get editedValue() {
    throw 'NotImplementedException';
  }

  /**
   * Rebuilds the view
   */
  rebuild() {
    throw 'NotImplementedException';
  }

  /**
   * Switchs currency
   */
  switchCurrency(currency) {
    throw 'NotImplementedException';
  }

  /**
   * Switchs unit
   */
  switchUnit(unit) {
    throw 'NotImplementedException';
  }

  /**
   * Clear the selection
   */
  clearSelection(clearSelection) {
    throw 'NotImplementedException';
  }

  /**
   * Destroys the element cleaning the html and saving in memory all the necesary things so it can be restaured in the future
   */
  destroy() {
    throw 'NotImplementedException';
  }
}
