import { Err404Page } from '../../views/errors/404';
import { TranslationService } from '../translation/translation';

export class ViewsLoader {
  constructor() {
    if (ViewsLoader.inst) {
      return ViewsLoader.inst;
    }
    ViewsLoader.inst = this;
    return this;
  }

  loadView = (page, rootElement) => {
    if (page == undefined) {
      page = new Err404Page();
    }

    rootElement.innerHTML = '';
    rootElement.appendChild(TranslationService.instance.translateHtml(page.getElement()));

    if (page.onLoaded) {
      page.onLoaded();
    }
  };
}
