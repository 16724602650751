import $ from '../../core/jquery/jquery-global';
import 'nestable2';
import './budgetTree.scss';
import { Component } from '../../shared/components/base-component';
import { Event } from '../../core/events/event';
import {
  UnitsEnums,
  BudgetLinesActionsEnums,
  PetitionStatusEnum,
  BudgetLinesTypesEnum
} from '../../config/enums';
import { DisbursementDates } from '../../components/disbursement-dates/disbursementDates';
import { ModalComponent } from '../../shared/components/modal';
import { InputNumeric, InputNumericOptions } from '../input-numeric/inputNumeric';
import { ToastService } from '../../shared/services/toastService';
import { DatePicker } from '../date-picker/datePicker';
import { TranslationService } from '../../core/translation/translation';
import { BudgetTreeDesktopRenderer } from './renders/budget-tree-desktop/budget-tree-desktop-renderer';
import { BudgetTreeMobileRenderer } from './renders/budget-tree-mobile/budget-tree-mobile-renderer';
import { Router } from '../../core/router/router';
import { CreateLineComponent } from '../../components/create-line/createLine';
import { EditLineComponent } from '../../components/edit-line/editLine';
import { CreateGroupComponent } from '../../components/create-group/createGroup';
import { EditGroupComponent } from '../../components/edit-group/editGroup';
import { BudgetAskInfoComponent } from '../../components/budget-ask-info/budgetAskInfo';
import { RemoveLineComponent } from '../../components/remove-line/removeLine';
import { SessionStorageService } from '../../core/sessionStorage/sessionStorage';
import {
  ConfirmationDeleteOptions,
  ConfirmationDeleteComponent
} from '../../components/confirmation-delete/confirmationDelete';
import { BudgetLine } from './budget-manager';
import { HttpService } from '../../core/http/http';

let template = require('./budgetTree.html');

/**
 * Class to manage the budget tree
 */
export class BudgetTree extends Component {
  /**
   * Get the selected line id
   */
  get isLineSelected() {
    return this._currentRenderer.isLineSelected;
  }

  get rootLineId() {
    return this._budgetManager.getRootLineId();
  }

  /**
   * Get the selected line id
   */
  get selectedLineId() {
    return this._currentRenderer.selectedLineId;
  }

  /**
   * Get the current unit
   */
  get currentUnit() {
    return this._currentRenderer.currentUnit;
  }

  /**
   * Get the current currency
   */
  get currentCurrency() {
    return this._currentRenderer.currentCurrency;
  }

  /**
   * Get the edited value event
   */
  get editedValue() {
    return this._onEditedValue;
  }

  /**
   * Get the budget saved event
   */
  get budgetSaved() {
    return this._onBudgetSaved;
  }

  /**
   * Get the ask info done event
   */
  get askInfoDone() {
    return this._onAskInfoDone;
  }

  /**
   * Rebuilds the view
   */
  rebuild() {
    this._currentRenderer.rebuild();
  }

  /**
   * Switchs currency
   */
  switchCurrency(currency) {
    this._options.localCurrency = currency;
    this._currentRenderer.switchCurrency(currency);
  }

  /**
   * Switchs unit
   */
  switchUnit(unit) {
    this._options.unit = unit;
    this._currentRenderer.switchUnit(unit);
  }

  /**
   * Clear the selection
   */
  clearSelection() {
    this._currentRenderer.clearSelection();
  }

  constructor(options) {
    super();

    this._options = options || new BudgetTreeOptions();
    if (this._options.budgetManager == null) throw 'Budget manager cannot be null';

    this._budgetManager = options.budgetManager;
    this._currentRenderer = null;
    this._onEditedValue = new Event();
    this._onBudgetSaved = new Event();
    this._onAskInfoDone = new Event();
    this.beforeChangeRenderer = new Event();
    this.afterChangeRenderer = new Event();
    this._toastService = new ToastService();
    this._httpService = new HttpService();
    this._router = new Router();
    this._onNavigationId = this._router.onNavigation.listen(() => this.destroy());
    this._mediaQueries = [];
    this.isDirty = false;
    this.urlPDF = options.urlPDF;
    //Set html of tree
    document.querySelector(this._options.selector).innerHTML = template;

    this._addMediaEvent('screen and (max-width:767px)', 'mobile');
    this._addMediaEvent('screen and (min-width:768px)', 'desktop');
  }

  /**
   * This event only will be fired once when the user navigates. If the user exits the current page it will destroy itself
   */
  destroy() {
    this._router.onNavigation.remove(this._onNavigationId);

    for (const mediaQuery of this._mediaQueries) {
      mediaQuery.mediaEvent.removeListener(mediaQuery.fn);
    }

    if (this._currentRenderer) {
      this._currentRenderer.destroy();
    }
  }

  /**
   * Add a new media event targeting one display
   * @param {*} mediaQuery
   * @param {*} targetDisplay
   */
  _addMediaEvent(mediaQuery, targetDisplay) {
    let mediaEvent = window.matchMedia(mediaQuery);

    let updateCurrentRendererIfMatches = event => {
      if (event.matches) {
        this.beforeChangeRenderer.execute(targetDisplay);
        this._updateCurrentRenderer(targetDisplay);
        this.afterChangeRenderer.execute(targetDisplay);
      }
    };

    mediaEvent.addListener(updateCurrentRendererIfMatches);
    updateCurrentRendererIfMatches(mediaEvent);

    this._mediaQueries.push({
      mediaEvent: mediaEvent,
      fn: updateCurrentRendererIfMatches
    });
  }

  /**
   * Decide what renderer will be used
   * @param {*} targetDisplay
   */
  _updateCurrentRenderer(targetDisplay) {
    if (this._currentRenderer !== null && this._currentRenderer.targetType !== targetDisplay) {
      this._currentRenderer.destroy();
      this._currentRenderer = null;
    }

    let options = Object.assign({}, this._options);
    options.isDirty = this.isDirty;
    switch (targetDisplay) {
      case 'mobile':
        options.selector += ' .mobile-display';
        options.createNewLine = () => this.createNewLine();
        options.deleteLine = () => this.deleteLine();
        options.askInfo = () => this.askInfo();
        options.saveBudget = () => this.saveBudget();
        this._currentRenderer = new BudgetTreeMobileRenderer(options);
        break;
      case 'desktop':
        options.selector += ' .desktop-display';
        this._currentRenderer = new BudgetTreeDesktopRenderer(options);
        break;
    }

    this._currentRenderer.editedValue.listen(() => {
      this.isDirty = true;
      this._onEditedValue.execute();
    });
  }

  /**
   * Line selected (no root line) (with quarters editables)
   */
  _canCreateLine() {
    return (
      this.isLineSelected &&
      ((!this._options.isPetition && this.selectedLineId !== this.rootLineId) ||
        (this._options.isPetition &&
          this._budgetManager.getLine(this.selectedLineId).getTypeId() !=
          BudgetLinesTypesEnum.Budget)) &&
      this._budgetManager.getLine(this.selectedLineId).getNodeLevel() <=
      this._budgetManager.getMaxDepth() &&
      this._budgetManager.getLine(this.selectedLineId).allQuartersCanBeEdited() &&
      this._budgetManager.getLine(this.selectedLineId).getTypeId() != BudgetLinesTypesEnum.Budget
    );
  }

  _canEditLine() {
    return (
      this.isLineSelected && this._budgetManager.getLine(this.selectedLineId).getNodeLevel() > 1 &&
      ((!this._options.isPetition && this.selectedLineId !== this.rootLineId) ||
        (this._options.isPetition &&
          this._budgetManager.getLine(this.selectedLineId).getTypeId() !=
          BudgetLinesTypesEnum.Budget)) &&
      this._budgetManager.getLine(this.selectedLineId).getNodeLevel() <=
      this._budgetManager.getMaxDepth() &&
      this._budgetManager.getLine(this.selectedLineId).allQuartersCanBeEdited() &&
      this._budgetManager.getLine(this.selectedLineId).getTypeId() != BudgetLinesTypesEnum.Budget
    );
  }
  /**
   * line selected but node level limit reached (with quarters editables)
   */
  _isMaxDepthReached() {
    return (
      this.isLineSelected &&
      this.selectedLineId !== this.rootLineId &&
      this._budgetManager.getLine(this.selectedLineId).getNodeLevel() >
      this._budgetManager.getMaxDepth()
    );
  }

  /**
   * no line selected or root line selected (with quarters editables)
   */
  _canCreateGroup() {
    return (
      (!this.isLineSelected &&
        this._budgetManager.getLine(this.rootLineId).allQuartersCanBeEdited()) ||
      (this.isLineSelected &&
        this.selectedLineId == this.rootLineId &&
        this._budgetManager.getLine(this.selectedLineId).allQuartersCanBeEdited())
    );
  }

  _canEditGroup() {
    return (
      (!this.isLineSelected &&
        this._budgetManager.getLine(this.rootLineId).allQuartersCanBeEdited()) ||
      (this.isLineSelected &&
        this._budgetManager.getLine(this.selectedLineId).getNodeLevel() == 1 &&
        this._budgetManager.getLine(this.selectedLineId).allQuartersCanBeEdited())
    );
  }

  _areQuartersNotEditable() {
    return (
      (!this.isLineSelected &&
        !this._budgetManager.getLine(this.rootLineId).allQuartersCanBeEdited()) ||
      (this.isLineSelected &&
        !this._budgetManager.getLine(this.selectedLineId).allQuartersCanBeEdited())
    );
  }

  createNewLine() {
    if (this._options.isPetition && !this.isLineSelected) {
      this._toastService.error(
        TranslationService.instance.translate('info-title'),
        TranslationService.instance.translate('msg-line-not-selected')
      );
    } else if (this._canCreateLine()) {
      this.createNewLineModal();
    } else if (this._isMaxDepthReached()) {
      this._toastService.error(
        TranslationService.instance.translate('exception-title'),
        TranslationService.instance.translate('msg-max-depth-reached')
      );
    } else if (this._canCreateGroup()) {
      if (
        (!this._options.isPetition &&
          this._budgetManager.getLine(this.rootLineId).getRequestedToStatus() ===
          PetitionStatusEnum.Pending) ||
        (this._options.isPetition &&
          (this._budgetManager.getLine(this.rootLineId).getRequestedTo() == null ||
            this._budgetManager.getLine(this.rootLineId).getRequestedTo() != this._options.userId))
      ) {
        this._toastService.error(
          TranslationService.instance.translate('exception-title'),
          TranslationService.instance.translate('msg-root-line-requested')
        );
      } else {
        this.createNewGroupModal();
      }
    } else if (this._areQuartersNotEditable()) {
      this._toastService.error(
        TranslationService.instance.translate('exception-title'),
        TranslationService.instance.translate('msg-line-with-amounts')
      );
    }
  }


  editLine() {
    if (this._options.isPetition && !this.isLineSelected) {
      this._toastService.error(
        TranslationService.instance.translate('info-title'),
        TranslationService.instance.translate('msg-line-not-selected')
      );
    } else if (this._canEditLine()) {
      this.editLineModal();
    } else if (this._isMaxDepthReached()) {
      this._toastService.error(
        TranslationService.instance.translate('exception-title'),
        TranslationService.instance.translate('msg-max-depth-reached')
      );
    } else if (this._canEditGroup()) {
      if (
        (!this._options.isPetition &&
          this._budgetManager.getLine(this.rootLineId).getRequestedToStatus() ===
          PetitionStatusEnum.Pending) ||
        (this._options.isPetition &&
          (this._budgetManager.getLine(this.rootLineId).getRequestedTo() == null ||
            this._budgetManager.getLine(this.rootLineId).getRequestedTo() != this._options.userId))
      ) {
        this._toastService.error(
          TranslationService.instance.translate('exception-title'),
          TranslationService.instance.translate('msg-root-line-requested')
        );
      } else {
        this.editGroupModal();
      }
    } else if (this._areQuartersNotEditable()) {
      this._toastService.error(
        TranslationService.instance.translate('exception-title'),
        TranslationService.instance.translate('msg-line-with-amounts')
      );
    }
  }


  cleanLine() {
    if (this._options.isPetition && !this.isLineSelected) {
      this._toastService.error(
        TranslationService.instance.translate('info-title'),
        TranslationService.instance.translate('msg-line-not-selected')
      );
    } 
    var lineToEdit = this._budgetManager.getLine(this.selectedLineId);

    lineToEdit.setQuarterAmountsToNull();
    lineToEdit.emptyAmounts();
    lineToEdit.setActionId(BudgetLinesActionsEnums.Update);
    this.editedValue.execute();
    this.rebuild();
  }


  editLineModal() {
    var that = this;
    var lineToEdit = this._budgetManager.getLine(this.selectedLineId);
    let modal = new ModalComponent(
      TranslationService.instance.translate('bd-modal-edit-line-title'),
      new EditLineComponent(this._options.investmentAreaId, this._options.companyId, lineToEdit.description)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      lineToEdit.setDescription(modal.result.name);
      lineToEdit.setActionId(BudgetLinesActionsEnums.Update);
      this.editedValue.execute();
      this.rebuild();
    });
  }

  createNewLineModal() {
    let modal = new ModalComponent(
      TranslationService.instance.translate('bd-modal-create-line-title'),
      new CreateLineComponent(this._options.investmentAreaId, this._options.companyId)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      let newLine = new BudgetLine(this._budgetManager.staticId, this._budgetManager.getExercise());
      newLine.setDescription(modal.result.name);
      newLine.setActionId(BudgetLinesActionsEnums.Add);
      this._budgetManager.addLine(newLine, this.selectedLineId);
      this._budgetManager.staticId++;
      this.editedValue.execute();
      this.rebuild();
    });
  }

  createNewGroupModal() {
    let textSubtitle = TranslationService.instance.translate('bd-modal-create-group-subtitle-notstructured');
    let modal = new ModalComponent(
      TranslationService.instance.translate('bd-modal-create-group-title'),
      new CreateGroupComponent(null, this._options.estructuradoEnUte, textSubtitle)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      let newLine = new BudgetLine(this._budgetManager.staticId, this._budgetManager.getExercise());
      newLine.setDescription(modal.result.name);
      newLine.setPertentage(parseFloat(modal.result.participation));
      newLine.setActionId(BudgetLinesActionsEnums.Add);
      this._budgetManager.addLine(newLine, this.rootLineId);
      this._budgetManager.staticId++;
      this.editedValue.execute();
      this.rebuild();
    });
  }

  editGroupModal() {
    var lineToEdit = this._budgetManager.getLine(this.selectedLineId);
    let textSubtitle = TranslationService.instance.translate('bd-modal-edit-group-subtitle-notstructured');
    let modal = new ModalComponent(
      TranslationService.instance.translate('bd-modal-edit-group-title'),
      new EditGroupComponent(
        null,
        lineToEdit.description,
        lineToEdit.percentage,
        this._options.estructuradoEnUte,
        textSubtitle
      )
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      lineToEdit.setDescription(modal.result.name);
      lineToEdit.setPertentage(parseFloat(modal.result.participation));
      lineToEdit.setActionId(BudgetLinesActionsEnums.Update);
      this.editedValue.execute();
      this.rebuild();
    });
  }

  /**
   * Trigger modal to ask for info of specific line/s to different user
   */
  askInfo() {
    if (!this.isLineSelected) {
      this._toastService.info(
        TranslationService.instance.translate('info-title'),
        TranslationService.instance.translate('msg-line-not-selected')
      );
    } else {
      let lines = this._budgetManager.getTreeList(this.selectedLineId);
      if (!this._options.isPetition) lines = lines.filter(l => l.getRequestedTo() == null);
      let line = this._budgetManager.getLine(this.selectedLineId);
      let breadcums = line.description;
      while (line.getParentNode() != null) {
        line = line.getParentNode();
        breadcums = line.description + ' / ' + breadcums;
      }
      var modal = new ModalComponent(
        TranslationService.instance.translate('bd-modal-ask-info-title'),
        new BudgetAskInfoComponent(lines, breadcums)
      );
      modal.onClosed.listen(() => {
        if (modal.result != null) {
          this._onAskInfoDone.execute();
        }
      });
    }
  }

  deleteLine() {
    if (!this.isLineSelected) {
      this._toastService.info(
        TranslationService.instance.translate('info-title'),
        TranslationService.instance.translate('msg-line-not-selected')
      );
    } else if (this.selectedLineId === this.rootLineId) {
      this._toastService.info(
        TranslationService.instance.translate('info-title'),
        TranslationService.instance.translate('msg-root-line-selected')
      );
    } else {
      let lines = this._budgetManager.getTreeList(this.selectedLineId);
      var modal = new ModalComponent(
        TranslationService.instance.translate('bd-modal-remove-line-title'),
        new RemoveLineComponent(lines)
      );
      modal.onClosed.listen(() => {
        if (modal.result == null) return;
        if (modal.result.remove === true) {
          lines.forEach(line => {
            this._budgetManager.removeLine(line.id);
          });
          this.editedValue.execute();
          this._currentRenderer.clearSelection();
          this._currentRenderer.rebuild();
        }
      });
    }
  }

  deleteBudget() {
    let options = new ConfirmationDeleteOptions();
    options.itemName = this._title;
    options.actionUrl = `${this._httpService.config.deleteBudget}?loginUsuario=${this._options.userId
      }&idPresupuesto=${this._budgetManager.getBudgetId()}`;

    let modal = new ModalComponent(
      TranslationService.instance.translate('common-modal-confirmation'),
      new ConfirmationDeleteComponent(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      location.assign('/#budgets');
    });
  }

  saveBudget() {
    $('#loader-container').removeClass('hidden');
    let body = this._budgetManager.exportJSON();

    let url = '';
    if (this._options.isPetition) {
      url = `${this._httpService.config.savePetitionBudget
        }?idPresupuesto=${this._budgetManager.getBudgetId()}&idPeticion=${this._options.petitionId}`;
    } else {
      url = `${this._httpService.config.saveBudget
        }?idPresupuesto=${this._budgetManager.getBudgetId()}`;
    }
    this._httpService
      .put(url, body)
      .then(response => {
        let jsonResponse = response;
        this._toastService.success(
          TranslationService.instance.translate('success-title'),
          TranslationService.instance.translate('msg-save-ok')
        );
        $('#btn-save').attr('disabled', true);
        $('#btn-modal-ask-info').attr('disabled', false);
        $('#btn-modal-edit-line').attr('disabled', false);
        $('#btn-modal-edit-line').attr('disabled', false);
        $(window).off('beforeunload');
        this.isDirty = false;

        $('#loader-container').addClass('hidden');
        this._onBudgetSaved.execute();
      })
      .catch(error => {
        this._toastService.exception(error.title);
        console.error(error);
        $('#loader-container').addClass('hidden');
      });
  }

  downloadPDF() {
    $('#loader-container').removeClass('hidden');
    this._httpService
      .get(
        `${this._httpService.config.checkBudgetReport
        }?idPresupuesto=${this._budgetManager.getBudgetId()}&usuarioSolicitante=${this._options.userId
        }`
      )
      .then(response => {
        if (response === true) {
          if (this.urlPDF === null) {
            this._httpService
              .downloadFile(
                `${this._httpService.config.getBudgetPDF
                }/?idPresupuesto=${this._budgetManager.getBudgetId()}&idMoneda=${this._options.localCurrency == true ? '' : this._options.euroCurrencyId
                }&idioma=${TranslationService.instance._currentLanguage.substr(
                  0,
                  2
                )}&usuarioSolicitante=${this._options.userId}`,
                this._options.pdfName
              )
              .then(() => {
                $('#loader-container').addClass('hidden');
              })
              .catch(error => {
                $('#loader-container').addClass('hidden');
                this._toastService.exception(error.title);
                console.error(error);
              });
          }
          else {
            window.open(this.urlPDF);
            $('#loader-container').addClass('hidden');
          }

        }
      })
      .catch(error => {
        $('#loader-container').addClass('hidden');
        this._toastService.exception(error.title);
        console.error(error);
      });
  }
}

export class BudgetTreeOptions {
  investmentAreaId = null;
  companyId = null;
  budgetManager = null;
  allowDrag = true;
  unit = UnitsEnums.Unit;
  localCurrency = true;
  readonly = false;
  userId = null;
  isPetition = false;
  petitionId = null;
  selector = null;
  pdfName = null;
  euroCurrencyId = null;
  estructuradoEnUte = null;
  urlPDF = null;
}
