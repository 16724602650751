import './requestFlowApproval.scss';
import $ from 'jquery';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { HttpService } from '../../core/http/http';
import { ToastService } from '../../shared/services/toastService';
import { Validator } from '../../core/validator/validator';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import { ConfigService } from '../../shared/services/config';
import { AuthenticationService } from '../../shared/services/authenticationService';
import { TranslationService } from '../../core/translation/translation';

var htmlContent = require('./requestFlowApproval.html');
const renderMarkup = () => htmlContent;

export class RequestFlowApprovalComponent extends BaseModalContent {
  constructor(options) {
    super(renderMarkup());
    this._http = new HttpService();
    this._toasts = new ToastService();
    this._options = options || new RequestFlowApprovalOptions();
    this._config = ConfigService.getInstance();
    this._users = null;
    this._allowPhysicalSign = null;
    this._allowMatrix = null;
    this._defaultValues = null;
    this._auth = new AuthenticationService();
  }

  onOpened() {
    this._init();
  }

  onLoading(callback) {
    Promise.all([this._loadConfig(), this._loadFlowConfig(), this._loadResponsables(), this._loadMatrixConfig()]).then(
      response => {
        callback();
      }
    );
    return true;
  }

  _init() {
    this.parentModal.container.find('.request-flow-approval').addClass(this._options.flowType);

    this._responsableFilter = this.parentModal.container.find('#responsable-filter');
    this._responsableMatrix1Filter = this.parentModal.container.find('#responsable-filter-matrix-1');
    this._responsableMatrix2Filter = this.parentModal.container.find('#responsable-filter-matrix-2');
    this._matrixApproval = this.parentModal.container.find('.matrix-approval');
    this._matrixApprovalGroup = this.parentModal.container.find('.matrix-approval-group');
    this._matrixGroup = this.parentModal.container.find('.matrix-group');

    this._physicalSign = this.parentModal.container.find('.physical-sign');
    this._defaultValuesChk = this.parentModal.container.find('.default-values');
    this._comentarios = this.parentModal.container.find('.comments');
    this._signerFilter = this.parentModal.container.find('#signer-filter');
    this._physicalSignGroup = this.parentModal.container.find('.physical-sign-group');
    this._signerGroup = this.parentModal.container.find('.signer-group');

    this._responsableMatrixPosterior = this.parentModal.container.find(".responsable-matrix-posterior");

    this._responsableMatrix1Selector = new DropDownComponent(
      document.getElementById('responsable-filter-matrix-1')
    );
    this._responsableMatrix2Selector = new DropDownComponent(
      document.getElementById('responsable-filter-matrix-2')
    );

    this._responsableMatrix1Selector.loadData(this._users, 'idUsuario', 'nombre');

    this._responsableMatrix2Selector.loadData(this._users, 'idUsuario', 'nombre');

    this._responsableSelector = new DropDownComponent(
      document.getElementById('responsable-filter')
    );
    this._responsableSelector.setRules({
      required: true
    });
    this._responsableSelector.loadData(this._users, 'idUsuario', 'nombre');

    this._signerSelector = new DropDownComponent(document.getElementById('signer-filter'));
    this._signerSelector.loadData(this._users, 'idUsuario', 'nombre');

    this.parentModal.container.find('.cancel').click(() => this.parentModal.close());
    this._saveButton = this.parentModal.container.find('.save');
    this._saveButton.click(() => this.save());

    this._physicalSign.change(() => this._changedPhysicalSign());
    this._matrixApproval.change(() => this._changedMatrixApproval());

    this._hideFields();
    this._setDefault();
    this._validatorInit();
  }

  _validatorInit() {
    this._validator = new Validator(this.parentModal.container);
    this._validator.validate();
  }

  _loadConfig() {
    return this._config
      .getGlobalConfig()
      .then(x => (this._allowPhysicalSign = x.permitirFirmasFisicas));
  }

  _loadFlowConfig() {
    return this._config
      .getFlowConfig(this._options.validateObjectId, this._options.flowType)
      .then(x => (this._defaultValues = x));
  }

  _loadMatrixConfig() {
    return this._config
      .getMatrixConfig(this._options.validateObjectId, this._options.flowType)
      .then(x => (this._allowMatrix = x));
  }

  _setDefault() {
    if (this._defaultValues != null) {
      if (this._allowPhysicalSign && this._defaultValues.solicitarFirma) {
        this._physicalSign.prop('checked', this._defaultValues.solicitarFirma).trigger('change');

        this._defaultValues.idUsuarioSolicitante;

        this._signerSelector.setSelected(
          this._defaultValues.idResponsableFirma,
          'idUsuario',
          'nombre'
        );
      }

      if (this._defaultValues.idResponsable != null) {
        this._responsableSelector.setSelected(
          this._defaultValues.idResponsable,
          'idUsuario',
          'nombre'
        );
      }

      if (this._defaultValues.idAprobadorMatricial1 != null && this._defaultValues.idAprobadorMatricial2 != null) {
        this._matrixApproval.prop('checked', true).trigger('change');
        this._responsableMatrix1Selector.setSelected(
          this._defaultValues.idAprobadorMatricial1,
          'idUsuario',
          'nombre'
        );
        this._responsableMatrix2Selector.setSelected(
          this._defaultValues.idAprobadorMatricial2,
          'idUsuario',
          'nombre'
        );
      }

    }
  }

  _hideFields() {
    if (!this._allowPhysicalSign) {
      this._physicalSignGroup.addClass('hidden');
      this._signerGroup.addClass('hidden');
    }
    if (!this._allowMatrix) {
      this._matrixApprovalGroup.addClass('hidden');
      this._matrixGroup.addClass('hidden');
    }
  }

  _changedPhysicalSign() {
    this._signerGroup.toggleClass('hidden', !this._physicalSign.is(':checked'));
    this._signerSelector.setRule('required', this._physicalSign.is(':checked'));
    if (this._validator != undefined) {
      this._validator.validate();
    }
  }

  _changedMatrixApproval() {

    if (this._matrixApproval.is(':checked')) {
      this._responsableMatrixPosterior
        .find('.txt')
        .html(
          TranslationService.instance.translate('common-responsable-matrix-posterior')
        );
    } else {
      this._responsableMatrixPosterior
        .find('.txt')
        .html(
          TranslationService.instance.translate('common-responsable')
        );
    }

    this._matrixGroup.toggleClass('hidden', !this._matrixApproval.is(':checked'));
    this._responsableMatrix1Selector.setRule('required', this._matrixApproval.is(':checked'));
    this._responsableMatrix2Selector.setRule('required', this._matrixApproval.is(':checked'));
    if (this._validator != undefined) {
      this._validator.validate();
    }
  }

  _loadResponsables() {
    return this._http
      .get(this._http.config.loadUsers)
      .then(response => {
        this._users = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  _sendRequestFlowApproval(url, request) {
    this.parentModal.toggleLoadingIcon(true);
    this._http
      .post(url, request)
      .then(response => {
        this._toasts.success(
          TranslationService.instance.translate('success-title'),
          TranslationService.instance.translate('msg-flow-requested')
        );
        this.value = { success: true };
        this.parentModal.close();
      })
      .catch(error => {
        // if (error.message.indexOf("innerHTML") == -1) {
          this._toasts.exception(error.title, error.message);
          console.error(error);
        // }
      })
      .finally(() => {
        this.parentModal.toggleLoadingIcon(false);
      });
  }

  _getUrl() {
    switch (this._options.flowType) {
      case 'budget':
        return this._http.config.requestBudgetValidation;
      case 'request':
        return this._http.config.requestRequestValidation;
      default:
        throw 'not valid request type';
    }
  }

  _buildBody() {
    let request = new RequestFlowApprovalApi();
    request.idUsuarioResponsable = this._responsableSelector.getValue(); //TODO: change when select component is finished this._responsableFilter.val();
    request.conFirma = this._physicalSign.is(':checked');
    request.idUsuarioFirma = request.conFirma ? this._signerSelector.getValue() : null;
    request.establecerComoPasoPorDefecto = this._defaultValuesChk.is(':checked');
    request.comentarios = this._comentarios.val();
    request.usuarioSolicitante = this._options.userId;

    request.matrixApproval = this._matrixApproval.is(':checked');
    request.idUsuarioMatrix1 = this._matrixApproval.is(':checked') ? this._responsableMatrix1Selector.getValue() : 0;
    request.idUsuarioMatrix2 = this._matrixApproval.is(':checked') ? this._responsableMatrix2Selector.getValue() : 0;

    if (this._options.flowType == 'budget') {
      request.idPresupuesto = this._options.validateObjectId;
    } else {
      request.idSolicitud = this._options.validateObjectId;
    }
    return request;
  }

  save() {
    if (!this._validator.isValid) {
      return;
    }

    let url = this._getUrl();
    let body = this._buildBody();
    this._sendRequestFlowApproval(url, body);
  }

  get result() {
    return this.value;
  }
}

export class RequestFlowApprovalOptions {
  userId = null;
  validateObjectId = null;
  /**
   * Create the request flow approval for a budget or request
   */
  initializerUserId = null;
  flowType = null;
}

class RequestFlowApprovalApi {
  idUsuarioResponsable = null;
  conFirma = null;
  idUsuarioFirma = null;
  establecerComoPasoPorDefecto = null;
  comentarios = null;
  usuarioSolicitante = null;
}
