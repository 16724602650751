import { BudgetTreeBaseRenderer } from '../budget-tree-base-renderer';
import { Event } from '../../../../core/events/event';
import { UnitsEnums } from '../../../../config/enums';
import {
  NavigationCardComponent,
  NavigationCardOptions
} from '../../../../shared/components/navigation-card/navigation-card';
import { TranslationService } from '../../../../core/translation/translation';
import { DatePicker } from '../../../date-picker/datePicker';
import $ from '../../../../core/jquery/jquery-global';
import './budget-tree-mobile-renderer.scss';
import { BudgetEditMobileComponent } from '../../../budget-edit-mobile/budget-edit-mobile';
import { BudgetLine } from '../../budget-manager';
import { DisbursementDates } from '../../../disbursement-dates/disbursementDates';
import { ModalComponent } from '../../../../shared/components/modal';
import {
  BudgetEditMoveLineMobile,
  BudgetEditMoveLineMobileOptions
} from '../../../budget-edit-move-line-mobile/budget-edit-move-line-mobile';
import {
  BudgetEditReorderLineMobileOptions,
  BudgetEditReorderLineMobile
} from '../../../budget-edit-reorder-line-mobile/budget-edit-reorder-line-mobile';
import {
  CardLineBudget,
  CardLineBudgetOptions
} from '../../../../shared/components/card-line-budget/card-line-budget';
import {
  VisualizationOptionsComponent,
  VisualizationOptionsParams
} from '../../../visualization-options/visualization-options';
import { ToastService } from '../../../../shared/services/toastService';
let template = require('./budget-tree-mobile-renderer.html');

export class BudgetTreeMobileRenderer extends BudgetTreeBaseRenderer {
  /**
   * Get the target type of the renderer
   */
  get targetType() {
    return 'mobile';
  }

  /**
   * Get the selected line id
   */
  get isLineSelected() {
    return this._selectedCard != null;
  }

  /**
   * Get the selected line id
   */
  get selectedLineId() {
    return this._selectedCard == null ? null : parseInt($(this._selectedCard).attr('data-id'));
  }

  /**
   * Get the edited value event
   */
  get editedValue() {
    return this._onEditedValue;
  }

  /**
   * Switch the unit to be shoun on the fields
   */
  switchUnit(unit) {
    this._options.unit = unit;
    this._cardLineOptions.unit = unit;
    this._navigationCard.draw();
  }

  /**
   * Switch the unit to be shoun on the fields
   */
  switchCurrency(isLocalCurrency) {
    this._options.localCurrency = isLocalCurrency;
    this._cardLineOptions.localCurrency = isLocalCurrency;
    this._navigationCard.draw();
  }

  /**
   * Get the current unit
   */
  get currentUnit() {
    return this._options.unit;
  }

  /**
   * Get the current currency
   */
  get currentCurrency() {
    return this._options.localCurrency;
  }

  constructor(options) {
    super();

    this._options = options || new BudgetTreeMobileRendererOptions();

    this._navigationCard = null;
    this._budgetManager = this._options.budgetManager;
    this._selectedCard = null;
    this._onEditedValue = new Event();
    this.toastService = new ToastService();

    this._init();
  }

  _init() {
    document.querySelector(this._options.selector).innerHTML = template;
    let data = JSON.parse(this._budgetManager.getChildrenJson());

    this._cardLineOptions = Object.assign({}, this._options);
    this._cardLineOptions.contextMenu = true;

    let navigationCardOptions = new NavigationCardOptions();
    navigationCardOptions.data = data;
    navigationCardOptions.navigationProperty = 'children';
    navigationCardOptions.selector = '.budget-tree-mobile-container .cards-container';
    navigationCardOptions.cardTemplate = CardLineBudget.template;
    if (this._options.isPetition) {
      navigationCardOptions.componentTemplate = `<div class="navigation-card">
      <div class="menu">
        <div class="navigation-up"></div>
        <div id="btn-save-mobile" class="button primary-button with-icon" disabled>
        <span class="icon-guardar"></span>
        <div class="txtButton">
        ${TranslationService.instance.translate('common-btn-save')}
        </div>
        </div>
      </div>
      <div class="card-container"></div>
    </div>`;
    } else {
      navigationCardOptions.componentTemplate = `<div class="navigation-card">
      <div class="menu">
        <div class="navigation-up"></div>
        <div id="btn-visualization-options" class="button primary-button with-icon no-text">
          <span class="icon icon-opciones-visualizacion"></span>
        </div>
      </div>
      <div class="card-container"></div>
    </div>`;
    }

    navigationCardOptions.onRenderCard = (card, data, breadcrumbs) =>
      this._onRenderCard(card, data, breadcrumbs);
    navigationCardOptions.onRenderBreadcrumbs = (container, breadcrumbs) =>
      this._onRenderBreadcrumbs(container, breadcrumbs);
    navigationCardOptions.comparerProperty = 'id';
    navigationCardOptions.sort = (a, b) => {
      return (
        this._budgetManager.getLine(a.id).getOrder() - this._budgetManager.getLine(b.id).getOrder()
      );
    };

    this._navigationCard = new NavigationCardComponent(navigationCardOptions);
    this._navigationCard.onNavigation.listen(() => this._onNavigation()); //Reset selected card when user navigate
    this._navigationCard.afterDraw.listen(() => this._afterDraw()); //Reset selected card when user navigate
    this._navigationCard.visualizationOptions.listen(() => this.visualizationOptions()); //Open the modal to select the currency and the unit to see the data
    this._navigationCard.onSave.listen(() => this.saveCallback());
    this._navigationCard.init();
    document.addEventListener('click', this._clickAnywhere);

    this.isDirty = this._options.isDirty || false;

    if (this._options.isDirty) {
      this._editionCallback();
    }
  }

  _onRenderBreadcrumbs(container, breadcrumbs) {
    let text = breadcrumbs.map(x => this._budgetManager.getLine(x.id).getDescription()).join(' / ');

    container.innerHTML = `<span class="icon-chevron"></span><span class="text">${text}</span>`;
  }

  _onNavigation() {
    this.clearSelection();
  }
  _afterDraw() {
    if (this._navigationCard.currentNode) {
      let children = this._navigationCard.currentNode.children;
      if (children == null || children.length == 0) {
        this._navigationCard.navigateUp();
      }
    }
  }

  _onRenderCard(card, data, breadcrumbs) {
    let cardLineBudget = new CardLineBudget(this._cardLineOptions);
    cardLineBudget.render(card, data, breadcrumbs);

    let budgetLine = this._options.budgetManager.getLine(data.id);

    card.addEventListener('click', event => {
      let force = this._isOptionMenu(event.target);
      if (force) {
        this._selectCard(card, force);
      } else {
        this._selectCard(card);
      }
    });

    card.querySelector('.create-line').addEventListener('click', this._options.createNewLine);
    card.querySelector('.delete-line').addEventListener('click', this._options.deleteLine);
    card.querySelector('.ask-info').addEventListener('click', this._options.askInfo);
    card.querySelector('.edit-line').addEventListener('click', () => this._editLine());
    card.querySelector('.move-line').addEventListener('click', () => this._moveLine());
    card.querySelector('.reorder-lines').addEventListener('click', () => this._reorderLines());

    let isDisabled =
      this._options.readonly ||
      !this._options.localCurrency ||
      this._options.unit !== UnitsEnums.Unit ||
      (!this._options.isPetition && budgetLine.childrenAreRequested()) ||
      (!this._options.isPetition && budgetLine.getRequestedTo() != null) ||
      (this._options.isPetition &&
        budgetLine.getRequestedTo() != null &&
        budgetLine.getRequestedTo() != this._options.userId);
    card.classList.toggle('disabled', isDisabled);
    if (isDisabled) {
      card.querySelector('.context-menu-container').remove();
    } else {
      let disabledReorder = Object.keys(budgetLine.getChildrenBudgetLines()).length <= 1;
      if (disabledReorder) {
        card.querySelector('.reorder-lines').remove();
      }

      let disableMovements = breadcrumbs.length <= 0;
      if (disableMovements) {
        card.querySelector('.move-line').remove();
        if (!disabledReorder) {
          card.querySelector('.reorder-lines').remove();
        }
      }

      let disableDelete = breadcrumbs.length == 0;
      if (disableDelete) {
        card.querySelector('.delete-line').remove();
      }

      let disableEdit =
        (!this._options.isPetition && !budgetLine.canStoreBudgetLineAmounts()) ||
        (this._options.isPetition && !budgetLine.canStorePetitionLineAmounts(this._options.userId));
      if (disableEdit) {
        card.querySelector('.edit-line').remove();
        card.querySelector('.ask-info').remove();
      }
    }
  }

  _selectCard(card, force) {
    $('.card:not([data-id=' + card.dataset.id + '])').removeClass('selected');

    this._selectedCard = card;
    card.classList.toggle('selected', force);
  }

  _isOptionMenu(element) {
    return $(element).hasClass('icon-opciones') || $(element).hasClass('context-menu-container');
  }
  /**
   * Create as arrow function to have this available
   */
  _clickAnywhere = event => {
    let target = $(event.target);
    if (this._isOptionMenu(target)) {
      event.preventDefault();
      event.stopPropagation();

      $('.card .context-menu.active').removeClass('active');

      target
        .parents('.card')
        .find('.context-menu')
        .toggleClass('active');
    } else {
      $('.context-menu-container .context-menu').toggleClass('active', false);
    }
  };

  /**
   * Triggered when a modification is done. Trigger edited event and disable visualization options.
   */
  _editionCallback() {
    this.isDirty = true;
    this._onEditedValue.execute();
  }

  /**
   * Rebuilds the view
   */
  rebuild() {
    let selectedLineId = this.selectedLineId;

    let data = JSON.parse(this._budgetManager.getChildrenJson());
    this._navigationCard.updateData(data);

    if (selectedLineId != null) {
      this._selectedCard = $(`.card[data-id="${selectedLineId}"]`);
      this._selectedCard.addClass('selected');
    }
  }

  /**
   * Open modal to select visualization options and rebuild the cards on confirm close.
   */
  visualizationOptions() {
    if (this.isDirty) {
      // When user edit budget is needed to save budget before switch to Euros view.
      this.toastService.info(
        TranslationService.instance.translate('info-title'),
        TranslationService.instance.translate('msg-save-required')
      );
      return;
    }

    let options = new VisualizationOptionsParams();
    options.selectedUnit = this._options.unit;
    options.localCurrency = 'Local'; //TODO: review
    options.isLocal = this._options.localCurrency;
    let modal = new ModalComponent(
      TranslationService.instance.translate('visualization-options-modal-title'),
      new VisualizationOptionsComponent(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._options.localCurrency = modal.result.isLocal;
      this._options.unit = modal.result.selectedUnit;
      this._cardLineOptions.localCurrency = modal.result.isLocal;
      this._cardLineOptions.unit = modal.result.selectedUnit;
      this.rebuild();
    });
  }

  /**
   * Callback executed when save button is clicked on petitions.
   */
  saveCallback() {
    this._options.saveBudget();
  }

  /**
   * Clear the selection
   */
  clearSelection() {
    this._selectedCard = null;
  }

  /**
   * Destroys the element cleaning the html and saving in memory all the necesary things so it can be restaured in the future
   */
  destroy() {
    let selector = document.querySelector(this._options.selector);
    if (selector != null) selector.innerHTML = '';
    document.removeEventListener('click', this._clickAnywhere);
  }

  _moveLine() {
    let options = new BudgetEditMoveLineMobileOptions();
    options.budgetLineId = this.selectedLineId;
    options.budgetManager = this._budgetManager;

    let modal = new ModalComponent(
      TranslationService.instance.translate('move-modal-title'),
      new BudgetEditMoveLineMobile(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._editionCallback();
      this.rebuild();
    });
  }
  _reorderLines() {
    let options = new BudgetEditReorderLineMobileOptions();
    options.budgetLine = this.selectedLineId;
    options.budgetManager = this._budgetManager;
    options.unit = this._options.unit;
    options.localCurrency = this._options.localCurrency;

    let modal = new ModalComponent(
      TranslationService.instance.translate('reorder-modal-title'),
      new BudgetEditReorderLineMobile(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._editionCallback();
      this.rebuild();
    });
  }
  _editLine() {
    let budgetLine = this._budgetManager.getLine(this.selectedLineId);

    // create a copy temporarily for editing the budget line with modals
    let tempBudgetLine = new BudgetLine(null, budgetLine.getExercise());
    tempBudgetLine.addAmounts(budgetLine.getAmounts());
    tempBudgetLine.setQuartersAmounts(
      budgetLine.getAmountQ1(),
      budgetLine.getAmountQ2(),
      budgetLine.getAmountQ3(),
      budgetLine.getAmountQ4()
    );
    tempBudgetLine.setLocalQuartersAmounts(
      budgetLine.getAmountQ1Local(),
      budgetLine.getAmountQ2Local(),
      budgetLine.getAmountQ3Local(),
      budgetLine.getAmountQ4Local()
    );
    tempBudgetLine.setAcquisitionDate(budgetLine.getAcquisitionDate());
    this._openEditLineModal(budgetLine, tempBudgetLine);
  }

  _openEditLineModal(originalBudgetLine, tempBudgetLine) {
    let modal = new ModalComponent(
      originalBudgetLine.getDescription(),
      new BudgetEditMobileComponent(tempBudgetLine)
    );
    modal.onClosed.listen(() => {
      if (modal.result !== false && modal.result.action === 'save') {
        // if the action is "save", it saves changes
        if (this._options.isPetition) {
          originalBudgetLine.replacePetitionAmounts(
            tempBudgetLine.getAmounts(),
            this._options.userId
          );
        } else {
          originalBudgetLine.replaceAmounts(tempBudgetLine.getAmounts());
        }

        //Add new values
        originalBudgetLine.setLocalQuartersAmounts(
          tempBudgetLine.getAmountQ1Local(),
          tempBudgetLine.getAmountQ2Local(),
          tempBudgetLine.getAmountQ3Local(),
          tempBudgetLine.getAmountQ4Local()
        );

        originalBudgetLine.setAcquisitionDate(tempBudgetLine.getAcquisitionDate());
        originalBudgetLine.setActionId(tempBudgetLine.getActionId());

        this._editionCallback();
        this.rebuild();
      } else if (modal.result !== false && modal.result.action === 'open-disbursement-modal') {
        // if the action is "open-disbursement-modal", open a new modal with the budget line copy

        tempBudgetLine.setLocalQuartersAmounts(
          Number($('#quarter1').attr('data-raw-value')),
          Number($('#quarter2').attr('data-raw-value')),
          Number($('#quarter3').attr('data-raw-value')),
          Number($('#quarter4').attr('data-raw-value'))
        );

        let disbursementDatesModal = new ModalComponent(
          TranslationService.instance.translate('bd-modal-disbur-dates-title'),
          new DisbursementDates(tempBudgetLine)
        );
        disbursementDatesModal.onClosed.listen(() => {
          // on closed, open again the editing modal keeping the changes at [tempBudgetLine]
          this._openEditLineModal(originalBudgetLine, tempBudgetLine);
        });
      }
    });
  }
}

export class BudgetTreeMobileRendererOptions {
  budgetManager = null;
  allowDrag = true;
  unit = UnitsEnums.Unit;
  localCurrency = true;
  readonly = false;
  userId = null;
  isPetition = false;
  selector = null;
  createNewLine = null;
  askInfo = null;
  deleteLine = null;
  saveBudget = null;
  isDirty = false;
}
