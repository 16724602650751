var content = require("!!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/postcss-loader/src/index.js??ref--9-2!../../../node_modules/sass-loader/dist/cjs.js!./createLine.scss");

if (typeof content === 'string') {
  content = [[module.id, content, '']];
}

var options = {}

options.insert = "head";
options.singleton = false;

var update = require("!../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js")(content, options);

if (content.locals) {
  module.exports = content.locals;
}
