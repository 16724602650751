import './configInvestmentAreas.scss';
var htmlContent = require('./configInvestmentAreas.html');
import { Component } from '../../shared/components/base-component';
import $ from 'jquery';
import { HttpService } from '../../core/http/http';
import { ViewsLoader } from '../../core/views/views-loader';
import { ToastService } from '../../shared/services/toastService';
import { Spinner } from '../../shared/components/spinner/spinner';
import { TranslationService } from '../../core/translation/translation';
import { ModalComponent } from '../../shared/components/modal';
import {
  ManageInvestmentArea,
  ManageInvestmentAreaOptions
} from '../../components/manage-investment-area/manageInvestmentArea';
import {
  ConfirmationDeleteOptions,
  ConfirmationDeleteComponent
} from '../../components/confirmation-delete/confirmationDelete';
import { AuthenticationService } from '../../shared/services/authenticationService';

const renderMarkup = () => htmlContent;

export class ConfigInvestmentAreasPage extends Component {
  constructor() {
    super(renderMarkup());
    this._companies = null;
    this._investmentAreas = null;
    this._budgetItems = null;
    this._selectedCompany = null;
    this._selectedInvestmentArea = null;
    this._selectedBudgetItem = null;
    $(document).ready(() => {
      let selectNav = document.querySelectorAll('.links-item');
      let i = 0;
      selectNav.forEach(e => {
        if (i == 6) {
          $(e).addClass('active');
          i++;
        } else {
          $(e).removeClass('active');
          i++;
        }
      });
      this.httpService = new HttpService();
      this.viewsLoader = new ViewsLoader();
      this.toastService = new ToastService();
      this.authenticationService = new AuthenticationService();
      this._loadCompanies();
    });
  }

  _loadCompanies() {
    $('#loader-container').removeClass('hidden');
    this.viewsLoader.loadView(new Spinner('big'), document.getElementById('loader-container'));

    this.httpService
      .get(this.httpService.config.loadCompanies)
      .then(response => {
        this._companies = response;
        this._buildCompaniesSelector();
        this._buildInvestmentAreaSelector();
        this._buildBudgetItemsSelector();
        this.enableButtons();
        $('#loader-container').addClass('hidden');
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }
  _buildCompaniesSelector() {
    let htmlContent = '';

    if (this._companies != null && this._companies.length > 0) {
      this._companies.forEach(company => {
        htmlContent += `<div class="selector-element" data-id="${company.idEmpresa}">
          <span>${company.nombre}</span>
        </div>`;
      });
    } else {
      htmlContent = `<span class="msg" data-trans="config-ia-msg-investment-areas"></span>`;
    }
    $('#companies-selector').toggleClass(
      'empty',
      this._companies == null || this._companies.length == 0
    );
    $('#companies-selector').html(htmlContent);
    $('#companies-selector')
      .find('.selector-element')
      .click(e => this.selectCompany(e));
  }
  selectCompany(event) {
    $('#companies-selector')
      .find('.selector-element')
      .removeClass('selected');
    event.currentTarget.classList.add('selected');
    this._selectedCompany = this._companies.filter(
      c => c.idEmpresa == parseInt(event.currentTarget.getAttribute('data-id'))
    )[0];
    this._selectedInvestmentArea = null;
    this._selectedBudgetItem = null;
    this._budgetItems = null;
    this._loadInvestmentAreas();
    this._buildBudgetItemsSelector();
  }

  _loadInvestmentAreas() {
    this.httpService
      .get(`${this.httpService.config.loadInvestmentAreas}?idEmpresa=${this._selectedCompany.idEmpresa}`)
      .then(response => {
        this._investmentAreas = response;
        this._buildInvestmentAreaSelector();
        this.enableButtons();
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }
  _buildInvestmentAreaSelector() {
    let htmlContent = '';
    if (this._investmentAreas == null) {
      htmlContent = `<span class="msg">${TranslationService.instance.translate(
        'config-ia-msg-investment-areas'
      )}</span>`;

      //htmlContent = `<span class="msg" data-trans="config-ia-msg-investment-areas"></span>`;
    } else if (this._investmentAreas.length > 0) {
      let permissionNoCheckHtml = '<span class="circle-state red">';
      let permissionCheckHtml = '<span class="circle-state green">';
      this._investmentAreas.forEach(investmentArea => {
        htmlContent += `<div class="selector-element" data-id="${investmentArea.idAreaInversion}">
          <div class="cell">
          ${investmentArea.estructuradoEnUte ? permissionCheckHtml : permissionNoCheckHtml}
          </div>
          <span>${investmentArea.nombre}</span>
          </div>`;
      });
    } else {
      htmlContent = `<span class="msg">${TranslationService.instance.translate(
        'config-ia-msg-investment-areas-empty'
      )}</span>`;
    }
    $('#investment-areas-selector').toggleClass(
      'empty',
      this._investmentAreas == null || this._investmentAreas.length == 0
    );
    $('#investment-areas-selector').html(htmlContent);
    $('#investment-areas-selector')
      .find('.selector-element')
      .click(e => this.selectInvestmentArea(e));
  }
  selectInvestmentArea(event) {
    $('#investment-areas-selector')
      .find('.selector-element')
      .removeClass('selected');
    event.currentTarget.classList.add('selected');
    this._selectedInvestmentArea = this._investmentAreas.filter(
      ia => ia.idAreaInversion == parseInt(event.currentTarget.getAttribute('data-id'))
    )[0];
    this._selectedBudgetItem = null;
    this._loadBudgetItems();
  }
  addInvestmentArea() {
    let options = new ManageInvestmentAreaOptions();
    options.type = TranslationService.instance.translate('common-invtm-area');
    options.isCreate = true;
    options.actionUrl = this.httpService.config.createInvestmentArea;
    options.data = {
      idAreaInversion: null,
      nombre: null,
      estructuradoEnUte: null,
      firmantes: true,
      firma1: null,
      firma2: null,
      firma3: null,
      firma4: null,
      firma5: null,
      firma6: null,
      idEmpresa: this._selectedCompany.idEmpresa,
      Empresa: this._selectedCompany.nombre,
    };
    let modal = new ModalComponent(
      TranslationService.instance.translate('config-ia-modal-add-area'),
      new ManageInvestmentArea(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._selectedBudgetItem = null;
      this._selectedInvestmentArea = null;
      this._budgetItems = null;
      this._loadInvestmentAreas();
      this._buildBudgetItemsSelector();
    });
  }
  editInvestmentArea() {
    let options = new ManageInvestmentAreaOptions();
    options.type = TranslationService.instance.translate('common-invtm-area');
    options.isCreate = false;
    options.actionUrl = this.httpService.config.updateInvestmentArea;
    options.data = this._selectedInvestmentArea;
    options.data.firmantes =  true;
    options.data.idEmpresa = this._selectedCompany.idEmpresa;
    options.data.Empresa = this._selectedCompany.nombre;
    let modal = new ModalComponent(
      TranslationService.instance.translate('config-ia-modal-edit-name'),
      new ManageInvestmentArea(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._selectedBudgetItem = null;
      this._selectedInvestmentArea = null;
      this._budgetItems = null;
      this._loadInvestmentAreas();
      this._buildBudgetItemsSelector();
    });
  }
  removeInvestmentArea() {
    let options = new ConfirmationDeleteOptions();
    options.itemName = this._selectedInvestmentArea.nombre;
    options.actionUrl = `${this.httpService.config.deleteInvestmentArea}?idAreaInversion=${
      this._selectedInvestmentArea.idAreaInversion
    }&loginUsuario=${this.authenticationService.getAuthenticatedUser().login}`;
    let modal = new ModalComponent(
      TranslationService.instance.translate('common-modal-confirmation'),
      new ConfirmationDeleteComponent(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._selectedBudgetItem = null;
      this._selectedInvestmentArea = null;
      this._budgetItems = null;
      this._loadInvestmentAreas();
      this._buildBudgetItemsSelector();
    });
  }

  _loadBudgetItems() {
    this.httpService
      .get(
        `${this.httpService.config.loadBudgetGroups}?idEmpresa=${this._selectedCompany.idEmpresa}&idArea=${this._selectedInvestmentArea.idAreaInversion}`
      )
      .then(response => {
        this._budgetItems = response;
        this._buildBudgetItemsSelector();
        this.enableButtons();
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }
  _buildBudgetItemsSelector() {
    let htmlContent = '';

    if (this._budgetItems == null) {
      htmlContent = `<span class="msg">${TranslationService.instance.translate(
        'config-ia-msg-budget-items'
      )}</span>`;
    } else if (this._budgetItems.length > 0) {
      this._budgetItems.forEach(budgetItem => {
        htmlContent += `<div class="selector-element" data-id="${budgetItem.idGrupoPresupuestario}">
          <span>${budgetItem.nombre}</span>
        </div>`;
      });
    } else {
      htmlContent = `<span class="msg">${TranslationService.instance.translate(
        'config-ia-msg-budget-items-empty'
      )}</span>`;
    }

    $('#budget-items-selector').toggleClass(
      'empty',
      this._budgetItems == null || this._budgetItems.length == 0
    );
    $('#budget-items-selector').html(htmlContent);
    $('#budget-items-selector')
      .find('.selector-element')
      .click(e => this.selectBudgetItem(e));
  }
  selectBudgetItem(event) {
    $('#budget-items-selector')
      .find('.selector-element')
      .removeClass('selected');
    event.currentTarget.classList.add('selected');
    this._selectedBudgetItem = this._budgetItems.filter(
      bi => bi.idGrupoPresupuestario == parseInt(event.currentTarget.getAttribute('data-id'))
    )[0];
    this.enableButtons();
  }
  addBudgetItem() {
    let options = new ManageInvestmentAreaOptions();
    options.type = TranslationService.instance.translate('common-budget-item');
    options.isCreate = true;
    options.actionUrl = this.httpService.config.createBudgetItem;
    options.data = {
      idGrupoPresupuestario: null,
      idEmpresa: this._selectedCompany.idEmpresa,
      idAreaInversion: this._selectedInvestmentArea.idAreaInversion,
      nombre: null,
      orden: this._budgetItems.length,
      estructuradoEnUte: true
    };
    let modal = new ModalComponent(
      TranslationService.instance.translate('config-ia-modal-add-clasification'),
      new ManageInvestmentArea(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._selectedBudgetItem = null;
      this._loadBudgetItems();
    });
  }
  editBudgetItem() {
    let options = new ManageInvestmentAreaOptions();
    options.type = TranslationService.instance.translate('common-budget-item');
    options.isCreate = false;
    options.actionUrl = this.httpService.config.updateBudgetItem;
    options.data = this._selectedBudgetItem;
    let modal = new ModalComponent(
      TranslationService.instance.translate('config-ia-modal-edit-name'),
      new ManageInvestmentArea(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._selectedBudgetItem = null;
      this._loadBudgetItems();
    });
  }
  removeBudgetItem() {
    let options = new ConfirmationDeleteOptions();
    options.itemName = this._selectedBudgetItem.nombre;
    options.actionUrl = `${this.httpService.config.deleteBudgetItem}?idGrupoPresupuesto=${
      this._selectedBudgetItem.idGrupoPresupuestario
    }&loginUsuario=${this.authenticationService.getAuthenticatedUser().login}`;
    let modal = new ModalComponent(
      TranslationService.instance.translate('common-modal-confirmation'),
      new ConfirmationDeleteComponent(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._selectedBudgetItem = null;
      this._loadBudgetItems();
    });
  }

  enableButtons() {
    //Companies buttons
    $('#btn-add-company').removeAttr('disabled');
    $('#btn-edit-company').attr(
      'disabled',
      this._companies == null || this._selectedCompany == null
    );
    $('#btn-remove-company').attr(
      'disabled',
      this._companies == null || this._selectedCompany == null
    );

    //Investment areas buttons
    $('#btn-add-ia').click(() => this.addInvestmentArea());
    $('#btn-add-ia').attr('disabled', this._investmentAreas == null);
    $('#btn-edit-ia').click(() => this.editInvestmentArea());
    $('#btn-edit-ia').attr(
      'disabled',
      this._investmentAreas == null || this._selectedInvestmentArea == null
    );
    $('#btn-remove-ia').click(() => this.removeInvestmentArea());
    $('#btn-remove-ia').attr(
      'disabled',
      this._investmentAreas == null || this._selectedInvestmentArea == null
    );

    //Budget items buttons
    $('#btn-add-bi').click(() => this.addBudgetItem());
    $('#btn-add-bi').attr('disabled', this._budgetItems == null);
    $('#btn-edit-bi').click(() => this.editBudgetItem());
    $('#btn-edit-bi').attr(
      'disabled',
      this._budgetItems == null || this._selectedBudgetItem == null
    );
    $('#btn-remove-bi').click(() => this.removeBudgetItem());
    $('#btn-remove-bi').attr(
      'disabled',
      this._budgetItems == null || this._selectedBudgetItem == null
    );
  }
}
