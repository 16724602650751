import './removeLine.scss';
var htmlContent = require('./removeLine.html');
import { Component } from '../../shared/components/base-component';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { TranslationService } from '../../core/translation/translation';

const renderMarkup = () => htmlContent;

export class RemoveLineComponent extends BaseModalContent {
  constructor(lines) {
    super(renderMarkup());
    this.value = null;
    this.lines = lines;
  }

  onCreated() {
    this.init();
  }

  init() {
    this.buildContent();
    this.parentModal.container.find('#cancel').click(() => this.cancel());
    this.parentModal.container.find('#delete').click(() => this.delete());
  }

  buildContent() {
    let html = '';
    this.lines.forEach(line => {
      html += `
      <div class="line">
        <span class="icon-eliminar"></span><span class="text">${line.description}</span>
      </div>`;
    });
    this.parentModal.container.find('#lines-container').html(html);
    this.parentModal.container
      .find('#delete')
      .text(
        `${TranslationService.instance.translate('bd-modal-remove-line-btn-rl1')} ${
          this.lines.length
        } ${TranslationService.instance.translate('bd-modal-remove-line-btn-rl2')}`
      );
  }

  cancel() {
    this.value = null;
    this.parentModal.close();
  }

  delete() {
    this.value = {
      remove: true
    };
    this.parentModal.close();
  }

  get result() {
    return this.value;
  }
}
