/**
 * Rule for the validator
 */
export class Rule {
  /**
   * Check if the element is valid
   * @param {JQueryElement} element
   */
  isValid(element) {
    return true;
  }

  /**
   * Get the error message
   * @param {JQueryElement} element
   * @param {String} lang
   */
  getErrorMessage(element, lang) {
    return '';
  }

  /**
   * Name of the validator
   */
  get name() {
    return '';
  }

  /**
   * Get the priority of the validator, the bigger the number the more importance
   */
  get priority() {
    return 1;
  }
}
