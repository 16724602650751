import './request-detail.scss';
var htmlContent = require('./request-detail.html');
import $ from '../../core/jquery/jquery-global';
import { Component } from '../../shared/components/base-component';
import { ViewsLoader } from '../../core/views/views-loader';
import { HttpService } from '../../core/http/http';
import { ToastService } from '../../shared/services/toastService';
import { Spinner } from '../../shared/components/spinner/spinner';
import { Router } from '../../core/router/router';
import {
  UnitsEnums,
  RequestFlowStatusClassesEnum,
  RequestTypeEnum,
  RequestStatusNameEnum,
  FlowActionEnum,
  FlowStatusEnum,
  RequestStatusEnum,
  FlowType,
  RequestTypeOfWorkEnum
} from '../../config/enums';
import {
  ConfirmationDeleteOptions,
  ConfirmationDeleteComponent
} from '../../components/confirmation-delete/confirmationDelete';
import { DatePicker } from '../../components/date-picker/datePicker';
import { RequestDetailEditorPage } from '../request-detail-editor/request-detail-editor';
import {
  RejectValidationFlowComponent,
  RejectValidationFlowOptions
} from '../../components/reject-validation-flow/rejectValidationFlow';
import {
  ApproveValidationFlowOptions,
  ApproveValidationFlowComponent
} from '../../components/approve-validation-flow/approveValidationFlow';
import {
  RequestFlowApprovalComponent,
  RequestFlowApprovalOptions
} from '../../components/request-flow-approval/requestFlowApproval';
import { ModalComponent } from '../../shared/components/modal';
import { InputNumeric } from '../../components/input-numeric/inputNumeric';
import {
  OffersReceivedOptions,
  OffersReceivedComponent
} from '../../components/offers-received/offersReceived';
import { CashFlowOptions, CashFlowComponent } from '../../components/cash-flow/cashFlow';
import {
  AdditionalCostsOptions,
  AdditionalCostsComponent
} from '../../components/additional-costs/additionalCosts';
import {
  InputFileOptions,
  InputFileComponent
} from '../../shared/components/input-file/input-file';
import { TranslationService } from '../../core/translation/translation';
import {
  VisualizationOptionsComponent,
  VisualizationOptionsParams
} from '../../components/visualization-options/visualization-options';
import { AuthenticationService } from '../../shared/services/authenticationService';
import {
  ConceptsMobileOptions,
  ConceptsMobileComponent
} from '../../components/concepts-mobile/concepts-mobile';
import {
  ConceptMobileOptions,
  ConceptMobileComponent
} from '../../components/concept-mobile/concept-mobile';
import { ConfigService } from '../../shared/services/config';
import { LocalStorageService } from '../../core/localStorage/localStorage';

const renderMarkup = () => htmlContent;

export class RequestDetailPage extends Component {
  constructor(params) {
    super(renderMarkup());
    $(document).ready(() => {
      let selectNav = document.querySelectorAll('.links-item');
      let i = 0;
      selectNav.forEach(e => {
        if (i == 2) {
          $(e).addClass('active');
          i++;
        } else {
          $(e).removeClass('active');
          i++;
        }
      });
      this.httpService = new HttpService();
      this.toastService = new ToastService();
      this.viewsLoader = new ViewsLoader();
      this.authenticationService = new AuthenticationService();
      this._allowPhysicalSign = null;
      this._requestId = params.id;

      $('#loader-container').removeClass('hidden');
      this._loadConfig().then(() => this._loadRequest());

      this._request = null;
      this.localCurrency = true;
      this.unitDiv = 1;
      this.selectedUnit = UnitsEnums.Unit;
      this.isLocalCurrency = true;
      this.canApproveOrReject = false;
      //$('.go-back').click(() => { if (window.history.length > 1) { window.history.back() } });

      this.localStorageService = new LocalStorageService();
      let lastPage = this.localStorageService.getItem('lastPageRequest');
      let goBackUrl = '#' + lastPage;
      if (lastPage != null) {
        let goBackUrl = '#' + lastPage;
      } else {
        goBackUrl += 'requests/';
      }
      $('.go-back').attr('href', goBackUrl);
    });
  }

  _loadRequest() {
    $('#loader-container').removeClass('hidden');
    this.viewsLoader.loadView(new Spinner('big'), document.getElementById('loader-container'));
    this.httpService
      .get(
        `${this.httpService.config.loadRequestDetails}/${this._requestId}/false/${this.authenticationService.getAuthenticatedUser().login
        }`
      )
      .then(response => {
        this._request = response;
        this.buildPage();
        this.loadApprovalFlow(this._request);
        $('#loader-container').addClass('hidden');
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
        location.assign('/#requests');
      });
  }

  buildPage() {
    this.buildTitle();
    this.buildHead();
    this.buildCommonContent();
    if (this._request.idTipo == RequestTypeEnum.Investment) {
      this.buildInvestmentContent();
    } else {
      this.buildDisinvestmentContent();
    }
    this.enableButtons();
  }

  buildTitle() {
    $('#view-title').html(this._request.nombre);
  }

  buildHead() {
    //Reset classes
    $('.circle-state')
      .removeClass()
      .addClass('circle-state');
    $('.circle-state').addClass(RequestFlowStatusClassesEnum[this._request.idEstado]);

    // Badge:
    let badgeId = this._request.idMoneda;
    this._localCurrencyId = this._request.idMoneda;
    this._euroCurrencyId = this._request.monedasSolicitud.find(
      x => x.nombreCorto === 'EUR'
    ).idTipoMoneda;
    this.localBadge = this._request.monedasSolicitud.filter(m => m.idTipoMoneda === badgeId)[0];
    let html = `<span id="badge-item-local">${this.localBadge.nombreLargoPlural} (${this.localBadge.simbolo})</span>`;
    if (this.localBadge.nombreCorto != 'EUR') {
      html += `<span id="badge-item-euros">Euros (€)</span>`;
    }
    $('#badges-selector').html(html);
    $('#badge-item-local')
      .off()
      .click(() => this.switchCurrency(true));
    if (this.localBadge.idTipoMoneda != 'EUR') {
      $('#badge-item-euros')
        .off()
        .click(() => this.switchCurrency(false));
    }
    $('#badge-item-local').addClass('active');

    // Units:
    $('#unit-units').addClass('active');
  }

  rebuildContent() {
    this.buildCommonContent();
    if (this._request.idTipo == RequestTypeEnum.Investment) {
      this.buildInvestmentContent();
    } else {
      this.buildDisinvestmentContent();
    }
  }
  buildCommonContent() {
    //Set reference
    $('#content-reference')
      .find('.content-element')
      .text(this._request.referencia);

    //Set date
    $('#content-date')
      .find('.content-element')
      .text(DatePicker.toLocaleDateString(DatePicker.parseDate(this._request.fecha)));

    //Set request
    $('#content-request')
      .find('.content-element')
      .text(this._request.nombre);

    //Set request type
    $('#content-request-type')
      .find('.content-element')
      .text(
        this._request.idTipo == RequestTypeEnum.Investment
          ? TranslationService.instance.translate('investment')
          : TranslationService.instance.translate('disinvestment')  
      );

    //Set plan
    if (this._request.enPlan) {
      $('#content-inplan')
        .find('#in-plan')
        .removeClass('hidden');
    } else {
      $('#content-inplan')
        .find('#in-plan')
        .addClass('hidden');
    }

    //Set no plan
    if (this._request.noEnPlan) {
      $('#content-inplan')
        .find('#not-in-plan')
        .removeClass('hidden');
    } else {
      $('#content-inplan')
        .find('#not-in-plan')
        .addClass('hidden');
    }

    //Set exceded
    if (this._request.excedido) {
      $('#content-inplan')
        .find('#exceded')
        .removeClass('hidden');
    } else {
      $('#content-inplan')
        .find('#exceded')
        .addClass('hidden');
    }

    //Set UTE
    $('#content-work-ute')
      .find('.content-element')
      .text(this._request.detalleSolicitud.grupo);

    if (!this._request.estructuradoEnUte) {
      let uteStructured = TranslationService.instance.translate(
        'req-detail-lbl-work-ute-notstructured'
      );
      $('#content-work-ute')
        .find('.label-element')
        .text(uteStructured);
      $('#content-ute-percent').hide();
      $('#content-delivery-pos').hide();
    }

    //Set UTE percentage
    $('#content-ute-percent')
      .find('.content-element')
      .text(this._request.detalleSolicitud.porcentajeUte + '%');

    //Set amount
    $('#content-amount')
      .find('.content-element')
      .text(
        this.isLocalCurrency == true
          ? InputNumeric.formatWithoutComponent(this._request.importeLocal / this.unitDiv) +
          ' ' +
          this.localBadge.nombreCorto
          : InputNumeric.formatWithoutComponent(this._request.importe / this.unitDiv) +
          ' ' +
          this._getCurrencyName()
      );

    //Set tax
    $('#content-tax')
      .find('.content-element')
      .text(
        `${this._request.detalleSolicitud.porcImpuestos}% -
        ${this.isLocalCurrency == true
          ? InputNumeric.formatWithoutComponent(
            (this._request.importeLocal * this._request.detalleSolicitud.porcImpuestos) /
            100 /
            this.unitDiv
          ) +
          ' ' +
          this.localBadge.nombreCorto
          : InputNumeric.formatWithoutComponent(
            (this._request.importe * this._request.detalleSolicitud.porcImpuestos) /
            100 /
            this.unitDiv
          ) +
          ' ' +
          this._getCurrencyName()
        }`
      );

    //Set aditional amounts
    let aditionalAmounts = 0;
    this._request.detalleSolicitud.costeAdicional.forEach(aditionalAmount => {
      if (aditionalAmount.conImpuestos) {
        aditionalAmounts +=
          this.isLocalCurrency == true ? aditionalAmount.importeLocal : aditionalAmount.importe;
      } else {
        let localTaxAdditionalAmount =
          (aditionalAmount.importeLocal * this._request.detalleSolicitud.porcImpuestos) / 100;
        let taxAdditionalAmount =
          (aditionalAmount.importe * this._request.detalleSolicitud.porcImpuestos) / 100;

        aditionalAmounts +=
          this.isLocalCurrency == true
            ? aditionalAmount.importeLocal + localTaxAdditionalAmount
            : aditionalAmount.importe + taxAdditionalAmount;
      }
    });
    $('#content-additional-costs')
      .find('.content-element')
      .text(
        this.isLocalCurrency == true
          ? InputNumeric.formatWithoutComponent(aditionalAmounts / this.unitDiv) +
          ' ' +
          this.localBadge.nombreCorto
          : InputNumeric.formatWithoutComponent(aditionalAmounts / this.unitDiv) +
          ' ' +
          this._getCurrencyName()
      );

    let textAdditionalCosts = TranslationService.instance.translate('req-detail-btn-see');
    if (this._request.detalleSolicitud.costeAdicional.length > 0) {
      textAdditionalCosts += ` (${this._request.detalleSolicitud.costeAdicional.length})`;
    }
    $('#btn-aditional-costs').text(textAdditionalCosts);

    //Set total amount
    let taxAmount =
      this.isLocalCurrency == true
        ? (this._request.importeLocal * this._request.detalleSolicitud.porcImpuestos) / 100
        : (this._request.importe * this._request.detalleSolicitud.porcImpuestos) / 100;
    $('#content-total-amount')
      .find('.content-element')
      .text(
        this.isLocalCurrency == true
          ? InputNumeric.formatWithoutComponent(this._request.importeTotalLocal / this.unitDiv) +
          ' ' +
          this.localBadge.nombreCorto
          : InputNumeric.formatWithoutComponent(this._request.importeTotal / this.unitDiv) +
          ' ' +
          this._getCurrencyName()
      );

    //Set delivery term
    let quantity = this._request.detalleSolicitud.numeroPlazoEntrega;
    let period = this._request.detalleSolicitud.periodoPlazoEntrega;
    let periodName;

    if (period == 'W') {
      periodName = TranslationService.instance.translate(
        quantity == 1 ? 'req-detail-week' : 'req-detail-weeks-pl'
      );
    } else {
      periodName = TranslationService.instance.translate(
        quantity == 1 ? 'req-detail-month' : 'req-detail-months-pl'
      );
    }

    $('#content-delivery-term')
      .find('.content-element')
      .text(quantity + ' ' + periodName);

    //Set delivery position
    $('#content-delivery-pos')
      .find('.content-element')
      .text(this._request.detalleSolicitud.posicionEntrega);

    //Set way pay
    $('#content-way-pay')
      .find('.content-element')
      .text(this._request.detalleSolicitud.formaPago);

    //Set observations
    $('.observations')
      .find('.content-element')
      .text(this._request.detalleSolicitud.observaciones);

    //Set concepts on mobile
    $('#content-concepts-mobile')
      .find('.content-element')
      .text(
        this._request.detalleSolicitud.concepto != null
          ? this._request.detalleSolicitud.concepto.length
          : '-'
      );

    let attachmentOptions = new InputFileOptions();
    attachmentOptions.readOnly = true;
    attachmentOptions.existingFiles = this._request.detalleSolicitud.idSolicitudNavigationAdjuntoSolicitud.map(
      e => {
        return {
          name: e.nombre,
          comment: e.comentarios,
          url: e.url,
          idAdjuntoSolicitud: e.idAdjuntoSolicitud
        };
      }
    );
    attachmentOptions.fileLayout = `
      <div class="file">
        <span class="icon-documento"></span>
        <a class="file-text" href="javascript:void()" target="_blank"></a>
        <span class="file-comment-readonly"></span>
      </div>`;
    attachmentOptions.onRenderedFile = (file, htmlFile) => {
      let fileInput = htmlFile.find('.file-comment-readonly');
      fileInput.hide();
      let fileText = htmlFile.find('.file-text');

      if (file.comment) {
        fileInput.text(file.comment);
      }

      fileText.attr('href', file.url);
    };
    this._attachments = new InputFileComponent(attachmentOptions);
    this.viewsLoader.loadView(
      this._attachments,
      document.querySelector('.documentation-container')
    );

    // Table concepts load...
    this.showConceptTable();

    // Display TypeofWork 
    this.displayTypeOfWork(this._request.idEmpresa, this._request.idAreaInversion);
  }
  buildInvestmentContent() {
    //Set provider
    $('#content-provider')
      .find('.content-element')
      .text(this._request.detalleSolicitud.detalleInversion.proveedor);

    //Elements number of cashflow
    if (this._request.detalleSolicitud.flujoCaja.length > 0) {
      $('#btn-cashflow').text(
        TranslationService.instance.translate('req-detail-open-cashflow') +
        ` (${this._request.detalleSolicitud.flujoCaja.length})`
      );
    }

    //Hide sell to
    $('#content-sell-to').hide();

    //Hide received offers
    $('#content-received-offers').hide();

    //Set rebuy option
    $('#content-rebuy-option')
      .find('.content-element')
      .text(
        this._request.detalleSolicitud.detalleInversion.opcionRecompra
          ? TranslationService.instance.translate('common-yes')
          : TranslationService.instance.translate('common-no')
      );

    //Set rebuy percentage
    if (this._request.detalleSolicitud.detalleInversion.opcionRecompra) {
      $('#content-rebuy-percent').show();
      $('#content-rebuy-percent')
        .find('.content-element')
        .text(this._request.detalleSolicitud.detalleInversion.porcentajeRecompra + '%');
    } else {
      $('#content-rebuy-percent').hide();
    }
  }
  buildDisinvestmentContent() {
    //Set sell to
    $('#content-sell-to')
      .find('.content-element')
      .text(this._request.detalleSolicitud.detalleDesinversion.destinatarioVenta);

    //Hide provider
    $('#content-provider').hide();

    //Set received offers
    let receivedOffers = 0;
    if (
      this._request.detalleSolicitud.detalleDesinversion &&
      this._request.detalleSolicitud.detalleDesinversion.ofertaRecibida
    ) {
      receivedOffers = this._request.detalleSolicitud.detalleDesinversion.ofertaRecibida.length;
    }
    $('#content-received-offers').show();
    $('#content-received-offers')
      .find('.content-element')
      .text(receivedOffers);

    //Hide rebuy option
    $('#content-rebuy-option').hide();

    //Hide rebuy percent
    $('#content-rebuy-percent').hide();
  }

  enableButtons() {
    $('#btn-edit-request').attr('disabled', true);
    $('#btn-edit-request')
      .off()
      .click(() => this.editRequest());
    $('.btn-cancel-request')
      .off()
      .click(() => this.cancelRequest(this._request.idSolicitud));
    $('.btn-download-pdf')
      .off()
      .click(() => {
        var timeOut = 0;
        timeOut = this.infoDownload(this._request.idEstado, this._localCurrencyId);
        setTimeout(() => {
          this.downloadPDF(this._request.idSolicitud);
        }, timeOut);

      });
    $('#unit-units')
      .off()
      .click(() => this.switchUnit(UnitsEnums.Unit));
    $('#unit-thousands')
      .off()
      .click(() => this.switchUnit(UnitsEnums.Thousand));
    $('#unit-millions')
      .off()
      .click(() => this.switchUnit(UnitsEnums.Millions));
    $('#btn-ap-fl')
      .off()
      .click(() => this.toggleApprovalFlow());
    $('#btn-aditional-costs')
      .off()
      .click(() => this.openAditionalCosts());
    $('#btn-cashflow')
      .off()
      .click(() => this.openCashflow());
    $('#btn-received-offers')
      .off()
      .click(() => this.openReceivedOffers());
    $('.btn-cancel-request').hide();
    if (this._request.idEstado != RequestStatusNameEnum.Approved) {
      $('.btn-cancel-request').hide();
    } else {
      $('#btn-edit-request').hide();
      //GET PERMISO ANULAR
      let anular = false;

      this.httpService
      .get(
        `${this.httpService.config.canCancelRequest}?idSolicitud=
        ${this._requestId}&usuarioSolicitante=${this.authenticationService.getAuthenticatedUser().login
        }`
      )
      .then(canUserCancel => {
        anular = canUserCancel;
        if(anular){
          $('.btn-cancel-request').show();
          $('.btn-cancel-request').toggleClass('hidden', window.innerWidth > 767);
        }
      });
    }

    $('.requestdetails-container')
      .off()
      .click(e => {
        if ($(e.target).parents('#btn-options').length > 0 || e.target.id == 'btn-options') {
          $('#context-menu').toggleClass('active');
        } else {
          $('#context-menu').removeClass('active');
        }
      });

    //Mobile
    $('#btn-toogle-menu').click(() => this._toggleMenu());
    $('#btn-view-options').click(() => this._visualizationOptionsMobile());
    $('#details-tab').click(() => this.detailsTab());
    $('#btn-concepts-mobile').click(() => this._openConceptsMobile());
    $('#approval-flow-tab').click(() => this.approvalFlowTab());
  }


  infoDownload(status = -1, currency = 0) {
    var timeOut = 0;
    var localCurrency = this.isLocalCurrency;
    /* TOASTS QUE SE UTILIZABAN CON FLUJOS DE FIRMA FÍSICA */
    /*
    if (status == 3 && currency != 0) {
      this.toastService.info(
        TranslationService.instance.translate('info-title'),
        TranslationService.instance.translate('msg-info-request-local')
      );
      timeOut = 3000;
    }
    if (status == 2 && (currency != 0 && localCurrency)) {
      this.toastService.info(
        TranslationService.instance.translate('info-title'),
        TranslationService.instance.translate('msg-info-request-sign-local')
      );
      timeOut = 3000;
    }
    */
    return timeOut;
  }

  openReceivedOffers() {
    let options = new OffersReceivedOptions();
    options.readOnly = true;
    options.localCurrency = this.isLocalCurrency;
    options.data = this._request.detalleSolicitud.detalleDesinversion.ofertaRecibida;
    options.utePercentage = this._request.detalleSolicitud.porcentajeUte;
    var modal = new ModalComponent(
      TranslationService.instance.translate('req-detail-lbl-received-offers'),
      new OffersReceivedComponent(options)
    );
  }
  openCashflow() {
    let options = new CashFlowOptions();
    options.year = 2020;
    options.readOnly = true;
    options.localCurrency = this.isLocalCurrency;
    options.data = this._request.detalleSolicitud.flujoCaja;
    var modal = new ModalComponent(
      TranslationService.instance.translate('req-detail-cashflow-title'),
      new CashFlowComponent(options)
    );
  }
  openAditionalCosts() {
    let options = new AdditionalCostsOptions();
    options.readOnly = true;
    options.data = this._request.detalleSolicitud.costeAdicional;
    options.localCurrency = this.isLocalCurrency;
    var modal = new ModalComponent(
      TranslationService.instance.translate('req-detail-lbl-additional-costs'),
      new AdditionalCostsComponent(options)
    );
  }

  editRequest() {
    let params = {};
    params.id = this._requestId;
    params.data = this._request;
    Router.inst.navigate('requests/' + this._requestId + '/edit', true, { params });
  }

  cancelRequest(requestId) {
    let options = new ConfirmationDeleteOptions();
    options.itemName = this._title;
    options.actionUrl = `${this.httpService.config.cancelRequest
      }?idSolicitud=${requestId}&usuarioSolicitante=${this.authenticationService.getAuthenticatedUser().login
      }`;
    options.isPut = true;
    options.text = TranslationService.instance.translate('modal-common-cancel-item-question');
    options.buttonText = TranslationService.instance.translate('config-cp-chk-request-cancel');

    let modal = new ModalComponent(
      TranslationService.instance.translate('common-modal-confirmation'),
      new ConfirmationDeleteComponent(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._loadRequest();
    });
  }

  downloadPDF(requestId) {
    $('#loader-container').removeClass('hidden');
    this.httpService
      .get(
        `${this.httpService.config.checkRequestReport
        }?idSolicitud=${requestId}&usuarioSolicitante=${this.authenticationService.getAuthenticatedUser().login
        }`
      )
      .then(response => {
        if (response === true) {
          if (this._request.urlPDF === null) {
            this.httpService
              .downloadFile(
              this.httpService.config.getRequestPDF + 
                  `?idSolicitud=${requestId}&idMoneda=${(this._euroCurrencyId, this.isLocalCurrency == true ? this._localCurrencyId: this._euroCurrencyId)}` +
                  `&idioma=${this.authenticationService .getAuthenticatedUser() .idIdioma.substr(0, 2)}`+
                  `&usuarioSolicitante=${this.authenticationService.getAuthenticatedUser().login}`,
                `${this._request.referencia}-${this._request.nombre}.pdf`
              )
              .then(() => {
                $('#loader-container').addClass('hidden');
              })
              .catch(error => {
                this.toastService.exception(error.title);
                console.error(error);
              });
          } else {
            window.open(this._request.urlPDF);
            $('#loader-container').addClass('hidden');
          }
        }
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }

  downloadFlowPDF (requestId) {
    $('#loader-container').removeClass('hidden');
    this.httpService.downloadFile(
      this.httpService.config.getRequestPDFWithFullFlow +
      `?idSolicitud=${requestId}&idMoneda=${(this._euroCurrencyId, this.isLocalCurrency == true ? this._localCurrencyId : this._euroCurrencyId)}` +
      `&idioma=${this.authenticationService.getAuthenticatedUser().idIdioma.substr(0, 2)}` +
      `&usuarioSolicitante=${this.authenticationService.getAuthenticatedUser().login}`,
      `${this._request.referencia}-${this._request.nombre}.pdf`,
    ).then(() => {
      $('#loader-container').addClass('hidden');
    }).catch(error => {
      this.toastService.exception(error.title);
      console.error(error);
    });
  }

  switchUnit(unit) {
    $('.unit').removeClass('active');
    switch (unit) {
      case UnitsEnums.Unit:
        $('#unit-units').addClass('active');
        this.unitDiv = 1;
        this.selectedUnit = UnitsEnums.Unit;
        break;
      case UnitsEnums.Thousand:
        $('#unit-thousands').addClass('active');
        this.unitDiv = 1000;
        this.selectedUnit = UnitsEnums.Thousand;
        break;
      case UnitsEnums.Millions:
        $('#unit-millions').addClass('active');
        this.unitDiv = 1000000;
        this.selectedUnit = UnitsEnums.Millions;
        break;
    }
    this.buildCommonContent();
    if (this._request.idTipo == RequestTypeEnum.Investment) {
      this.buildInvestmentContent();
    } else {
      this.buildDisinvestmentContent();
    }
  }
  switchCurrency(isLocal) {
    this.isLocalCurrency = isLocal;
    $('#badge-item-local').toggleClass('active', this.isLocalCurrency);
    $('#badge-item-euros').toggleClass('active', !this.isLocalCurrency);
    this.rebuildContent();
  }

  showConceptTable() {
    if (this._request.idTipo == RequestTypeEnum.Investment) {
      this._buildInvestmentConceptTable();
    } else {
      this._buildDisinvestmentConceptTable();
    }

    //Enable tooltips when needed
    if (this._toolTips) {
      this._toolTips.each((i, e) => {
        if (e.hasAttribute('title')) {
          $(e).tooltip('dispose');
        }
      });
      $('.tooltip.show').remove();
    }

    this._toolTips = $('[data-toggle="tooltip"]');
    this._toolTips.each((i, e) => {
      $(e).tooltip();
    });
  }
  _buildInvestmentConceptTable() {
    let requestConceptsHtml = '';

    this._request.detalleSolicitud.concepto.forEach((petition, index) => {
      let budgetLinePath = null;
      let budgetLineName = '';

      if (petition.idLineaPresupuesto) {
        budgetLinePath = $(petition.partidaPresupuestaria.split('/'));
        budgetLineName = budgetLinePath[budgetLinePath.length - 1];
      }

      let percentDivisa = petition.conceptoInversion.importeLocal / petition.conceptoInversion.importe;

      $('.table-head').addClass("table-investment");
      $('.table-head .acquisitionDate').hide();
      $('.table-head .contableValue').hide();
      $('.table-head .disinvestment').hide();
      requestConceptsHtml += `<div class="table-row table-investment">
        <div class="cell center concept">${index + 1}</div>
        <div class="cell center units">
        ${petition.unidades ? petition.unidades : ''}
        </div>
        <div class="cell description">
        ${petition.descripcion ? petition.descripcion : ''}
        </div>
        <div class="cell totalValue">
        <input type="text" disabled
        class="concept-input decimal" value="${!isNaN(petition.conceptoInversion.importeLocal)
          ? this.isLocalCurrency == true
            ? petition.conceptoInversion.importeLocal / this.unitDiv
            : petition.conceptoInversion.importe / this.unitDiv
          : ''
        }"/>
        </div>
        <div class="cell budgetAmount">
        <input type="text" disabled
        class="concept-input decimal" value="${!isNaN(petition.importePresupuestado)
          ? this.isLocalCurrency == true
            ? petition.importePresupuestado
            : petition.importePresupuestado / percentDivisa
          : ''
        }"/>
        </div>
        <div class="cell approvedAmount">
        <input type="text" disabled
        class="concept-input decimal" value="${!isNaN(petition.importeAprobado)
          ? this.isLocalCurrency == true
            ? petition.importeAprobado
            : petition.importeAprobado / percentDivisa
          : ''
        }"/>
        </div>
        <div class="cell budgetItem" data-toggle="tooltip" title="${petition.partidaPresupuestaria
        }">
        ${petition.idLineaPresupuesto ? budgetLineName : ''}
        </div>
        <div class="cell budgetItem planArea no-center-vertical">
        ${petition.idLineaPresupuesto
          ? '<div class="plan center"><span>' +
          TranslationService.instance.translate('onplan') +
          '</span></div>'
          : '<div class="plan no center"><span>' +
          TranslationService.instance.translate('notonplan') +
          '</span></div>'
        }</div>
      </div>`;
    });

    if (requestConceptsHtml.length == 0) {
      requestConceptsHtml = `<div class="table-row all">
        <span>${TranslationService.instance.translate('common-msg-no-elements')}</span>
      </div>`;
    }
    $('#concepts-table-content').html(requestConceptsHtml);
    let contents = $('#concepts-table-content').find('.table-row');
    contents.find('.concept-input.decimal').each((i, e) => InputNumeric.createDecimal(e));
  }
  _buildDisinvestmentConceptTable() {
    let requestConceptsHtml = '';
    let budgetLinePath = null;
    let budgetLineName = '';

    this._request.detalleSolicitud.concepto.forEach((petition, index) => {
      if (petition.idLineaPresupuesto) {
        budgetLinePath = $(petition.partidaPresupuestaria.split('/'));
        budgetLineName = budgetLinePath[budgetLinePath.length - 1];
      }

      requestConceptsHtml += `<div class="table-row">
        <div class="cell center concept">${index + 1}</div>
        <div class="cell center units">
        ${petition.unidades ? petition.unidades : ''}
        </div>
        <div class="cell description">
        ${petition.descripcion ? petition.descripcion : ''}
        </div>
        <div class="cell acquisitionDate">
        ${petition.conceptoDesinversion.fechaAdquisicion
          ? DatePicker.toLocaleDateString(
            DatePicker.parseDate(petition.conceptoDesinversion.fechaAdquisicion)
          )
          : ''
        }
        </div>
        <div class="cell totalValue">
        <input type="text" disabled
        class="concept-input decimal" value="${!isNaN(petition.conceptoDesinversion.valorAdquisicionLocal)
          ? this.isLocalCurrency == true
            ? petition.conceptoDesinversion.valorAdquisicionLocal / this.unitDiv
            : petition.conceptoDesinversion.valorAdquisicion / this.unitDiv
          : ''
        }"/>
        </div>
        <div class="cell contableValue">
        <input type="text"  disabled
        class="concept-input decimal" value="${!isNaN(petition.conceptoDesinversion.valorContableLocal)
          ? this.isLocalCurrency == true
            ? petition.conceptoDesinversion.valorContableLocal / this.unitDiv
            : petition.conceptoDesinversion.valorContable / this.unitDiv
          : ''
        }"/>
        </div>
        <div class="cell disinvestment">
        <input type="text" disabled
        class="concept-input decimal" value="${!isNaN(petition.conceptoDesinversion.desinversionEurosLocal)
          ? this.isLocalCurrency == true
            ? petition.conceptoDesinversion.desinversionEurosLocal / this.unitDiv
            : petition.conceptoDesinversion.desinversionEuros / this.unitDiv
          : ''
        }"/>
        </div>
        <div class="cell budgetAmount">
        <input type="text" disabled
        class="concept-input decimal" value="0"/>
        </div>
        <div class="cell approvedAmount">
        <input type="text" disabled
        class="concept-input decimal" value="0"/>
        </div>
        <div class="cell budgetItem" data-toggle="tooltip" title="${petition.partidaPresupuestaria
        }">
        ${petition.idLineaPresupuesto ? budgetLineName : ''}
        </div>
        <div class="cell planArea no-center-vertical">
        ${petition.idLineaPresupuesto
          ? '<div class="plan center"><span>' +
          TranslationService.instance.translate('onplan') +
          '</span></div>'
          : '<div class="plan no center"><span>' +
          TranslationService.instance.translate('notonplan') +
          '</span></div>'
        }</div>
      </div>`;
    });

    if (requestConceptsHtml.length == 0) {
      requestConceptsHtml = `<div class="table-row all">
        <span>${TranslationService.instance.translate('common-msg-no-elements')}</span>
      </div>`;
    }
    $('#concepts-table-content').html(requestConceptsHtml);
    let contents = $('#concepts-table-content').find('.table-row');
    contents.find('.concept-input.decimal').each((i, e) => InputNumeric.createDecimal(e));
  }

  loadApprovalFlow(request) {
    this.httpService
      .get(
        `${this.httpService.config.loadRequestApprovalFlow
          .replace('idUsuario', this.authenticationService.getAuthenticatedUser().login)
          .replace('idSolicitud', request.idSolicitud)}?usuarioLogin=${this.authenticationService.getAuthenticatedUser().login
        }`
      )
      .then(response => {
        this._renderApprovalFlow(response);
        $(window).resize(() => {
          this._renderApprovalFlow(response);
        });
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }
  toggleApprovalFlow() {
    if ($('.approval-flow').hasClass('active')) {
      $('.approval-flow').removeClass('active');
    } else {
      $('.approval-flow').addClass('active');
    }
  }
  _renderApprovalFlow(response) {
    if (response.length > 0) {
      var esRevision = false;
      if ((response.length - 2) > 0) {
        esRevision = response[response.length - 2].esRevision
      }
      $('#btn-edit-request').attr(
        'disabled',
        this._request.idEstado != RequestStatusNameEnum.Draft &&
        !esRevision
      );

      if (
        response[response.length - 1].idUsuarioResponsable !=
        this.authenticationService.getAuthenticatedUser().idUsuario
      ) {
        $('#btn-edit-request').addClass('disabled');
      }
    }
    else {
      //SI PUEDE INICIAR FLUJO PUEDE EDITAR
      this.httpService
        .get(
          `${this.httpService.config.canStartRequestValidation}?idSolicitud=
        ${this._requestId}&usuarioSolicitante=${this.authenticationService.getAuthenticatedUser().login
          }`
        )
        .then(response => {
          $('#btn-edit-request').attr(
            'disabled',
            this._request.idEstado != RequestStatusNameEnum.Draft || !response
          );
        })
        .catch(error => {
          this.toastService.exception(error.title);
          console.error(error);
          location.assign('/#budgets');
        });

    }
    if (window.innerWidth >= 768) {
      this._renderApprovalFlowDesktop(response);
    } else {
      this._renderApprovalFlowMobile(response);
    }

    $('.approval-flow-container').toggleClass('allow-physical-sign', this._allowPhysicalSign);
  }

  _loadConfig() {
    return ConfigService.getInstance()
      .getGlobalConfig()
      .then(x => (this._allowPhysicalSign = x.permitirFirmasFisicas))
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }

  _renderApprovalFlowDesktop(response) {
    if (response.length == 0) {
      $('.approval-flow-container').addClass('not-found');
      let msgNotFound = document.querySelector('.scroll-flow-requested');
      msgNotFound.innerHTML = `
        <div class="flow-requested all">
          <div class="msg">${TranslationService.instance.translate(
        'bd-apfl-msg-negative-request'
      )}</div>
          <div class="status">
            <div class="budget-status">
              <i class="circle-state orange"></i>
              <span class="state-txt-approval-flow">${TranslationService.instance.translate(
        'draft'
      )}</span>
            </div>
          </div>
        </div>`;
      let btns = document.querySelector('.btns-container');
      btns.innerHTML = `
        <div id="request-approval" class="button with-icon success-button">
          <span class="icon-aprobar"></span>
          <div class="txt">${TranslationService.instance.translate('common-request-ap')}</div>
        </div>
        <div id="request-delete" class="button with-icon danger-button">
          <span class="icon-eliminar"></span>
          <div class="txt">${TranslationService.instance.translate('bd-btn-delete-request')}</div>
        </div>
      `;
      $('#request-approval').click(() => this.requestFlowApproval(this._request));
      $('#request-delete').click(() => this.deleteRequest(this._request));

      this.httpService
        .get(
          `${this.httpService.config.canStartRequestValidation}?idSolicitud=
          ${this._requestId}&usuarioSolicitante=${this.authenticationService.getAuthenticatedUser().login
          }`
        )
        .then(response => {
          if (!response) {
            $('#request-approval').addClass('disabled');
            $('#request-delete').addClass('disabled');
          }
        })
        .catch(error => {
          this.toastService.exception(error.title);
          console.error(error);
          location.assign('/#budgets');
        });
    } else {
      let flows = document.querySelector('.scroll-flow-requested');
      flows.innerHTML = '';
      response.forEach((e, i) => {
        let date = DatePicker.toLocaleDateString(DatePicker.parseDate(e.fecha));
        let state;
        let esPrimerMatricial = i < response.length - 1 && e.esAprobacionMatricial && response[i+1].esAprobacionMatricial;
        let esSegundoMatricial = i != 0 && e.esAprobacionMatricial && response[i-1].esAprobacionMatricial;
        if (e.idEstadoAprobacion == 1) {
          state = 'orange';
        } else if (e.idEstadoAprobacion == 2) {
          state = 'yellow';
        } else {
          state = 'green';
        }
        flows.innerHTML += `
        <div class="flow-requested">
          <div class="data date">${date}</div>
          <div class="data action">${i == 0
            ? TranslationService.instance.translate('af-initial-request')
            : esPrimerMatricial ?
            TranslationService.instance.translate('af-tramitacion-request') + " 1/2"
            : esSegundoMatricial ?
            TranslationService.instance.translate('af-tramitacion-request') + " 2/2"
            : TranslationService.instance.translate('af-tramitacion-request')
          }</div>
          <div class="data responsable">${e.idUsuarioResponsableNavigationNombre != null
            ? e.idUsuarioResponsableNavigationNombre
            : ''
          } <span>${e.idUsuarioResponsableNavigationCargo != null ? e.idUsuarioResponsableNavigationCargo : ''
          }</span></div>
          <div class="data sign">${e.idUsuarioFirmaNavigationNombre != null ? e.idUsuarioFirmaNavigationNombre : ''
          } 
          <span>${e.idUsuarioFirmaNavigationCargo != null ? e.idUsuarioFirmaNavigationCargo : ''
          }</span></div>
          <div class="data document">${e.urlDocumentoFirma != null
            ? '<a href="' +
            e.urlDocumentoFirma +
            '" target="_blank" ><span class="icon-documento"></span></a>'
            : ''
          }</div>
          <div class="data status">
            <div class="budget-status">
              <i class="circle-state ${RequestFlowStatusClassesEnum[e.idEstadoAprobacion]}"></i>
              <span class="state-txt-approval-flow">${this.getStatusDescription(e)}</span>
            </div>
          </div>
          <div class="data comments">${e.comentarios}</div>
        </div>
        `;
      });

      $('.request-status-text').text(this.getStatusDescription(response[response.length - 1]));

      let btns = document.querySelector('.btns-container');
      btns.innerHTML = `
                <div id="btn-download-flow-pdf" class="button with-icon primary-button">
                  <span class="icon-PDF"></span>
                  <div class="text">${TranslationService.instance.translate('bd-btn-pdf')}</div>
                </div>`;
      $('#btn-download-flow-pdf')
      .off()
      .click(() => {
        let timeOut = 0;
        timeOut = this.infoDownload(this._request.idEstado, this._localCurrencyId);
        setTimeout(() => {
          this.downloadFlowPDF(this._request.idSolicitud);
        }, timeOut);
      });
      
      if (this._request.idEstado == 2) {
        this.httpService
          .get(
            `${this.httpService.config.canApproveRequest}?idSolicitud=
            ${this._requestId}&usuarioSolicitante=${this.authenticationService.getAuthenticatedUser().login
            }`
          )
          .then(canUserApprove => {
            this.canApproveOrReject = canUserApprove;
          });

        this.httpService
          .get(
            `${this.httpService.config.canApproveOrRejectRequest}?idSolicitud=
            ${this._requestId}&usuarioSolicitante=${this.authenticationService.getAuthenticatedUser().login
            }`
          )
          .then(canUserApprove => {
            let btns = document.querySelector('.btns-container');
            btns.innerHTML = `
                <div id="btn-apply-for" class="button with-icon success-button">
                  <span class="icon-aprobar"></span>
                  <div class="txt">
                    ${TranslationService.instance.translate(this._request.esRevision ? 'common-request-ap' : 'req-apfl-btn-apply-for')}
                  </div>
                </div>
                <div id="btn-review-for" class="button with-icon warning-button">
                  <span class="icon-cerrar"></span>
                  <div class="txt">${TranslationService.instance.translate('req-apfl-btn-to-review')}</div>
                </div>
                <div id="btn-to-refuse" class="button with-icon danger-button">
                  <span class="icon-cerrar"></span>
                  <div class="txt">${TranslationService.instance.translate('req-apfl-btn-to-refuse')}</div>
                </div>
                <div id="btn-download-flow-pdf" class="button with-icon primary-button">
                  <span class="icon-PDF"></span>
                  <div class="text">${TranslationService.instance.translate('bd-btn-pdf')}</div>
                </div>`;

            $('#btn-download-flow-pdf')
              .off()
              .click(() => {
                let timeOut = 0;
                timeOut = this.infoDownload(this._request.idEstado, this._localCurrencyId);
                setTimeout(() => {
                  this.downloadFlowPDF(this._request.idSolicitud);
                }, timeOut);
              });

            if (canUserApprove) {
              $('#btn-apply-for').click(() =>
                this.approveFlowApproval(this._request, response[response.length - 1])
              );
              $('#btn-review-for').click(() =>
                this.reviewFlowApproval(this._request, response[response.length - 1])
              );
              $('#btn-to-refuse').click(() =>
                this.rejectFlowApproval(response[response.length - 1].idAprobacion)
              );

              if (response[response.length - 1].esRevision) {
                $('#btn-review-for').addClass('disabled');
              }
            } else {
              $('#btn-apply-for').addClass('disabled');
              $('#btn-review-for').addClass('disabled');
              $('#btn-to-refuse').addClass('disabled');
            }
          })
          .catch(error => {
            this.toastService.exception(error.title);
            console.error(error);
            location.assign('/#budgets');
          });

        //Disable buttons if the user logged in not the current approver
        if (
          response[response.length - 1].idUsuarioResponsable !=
          this.authenticationService.getAuthenticatedUser().idUsuario
        ) {
          $('#btn-apply-for').addClass('disabled');
          $('#btn-review-for').addClass('disabled');
          $('#btn-to-refuse').addClass('disabled');
        }
      }
      if (this._request.idEstado == 3) {
        $('#btn-apply-for').hide();
        $('#btn-review-for').hide();
        $('#btn-to-refuse').hide();
      }
    }
  }
  _renderApprovalFlowMobile(response) {
    if (response.length == 0) {
      $('#btn-mob-aprrove-af').hide();
      $('#btn-mob-reject-af').hide();
      $('#btn-mob-revision-af').hide();
      $('#btn-mob-download-flow-pdf').hide();
      $('#btn-mob-validate-af')
        .off()
        .click(() => this.requestFlowApproval(this._request));
      $('#btn-mob-delete-af')
        .off()
        .click(() => this.deleteRequest(this._request));
    } else {
      let html = '';
      //Hide button to validate
      $('#btn-mob-validate-af').hide();
      $('#btn-mob-delete-af').hide();
      //Hide empty flow msg
      $('#approval-flow-mobile')
        .find('.msg')
        .hide();
      response.forEach((flow, index) => {
        let date = DatePicker.toLocaleDateString(DatePicker.parseDate(flow.fecha));
        let state;

        if (flow.idEstadoAprobacion == 1) {
          state = 'orange';
        } else if (flow.idEstadoAprobacion == 2) {
          state = 'yellow';
        } else {
          state = 'green';
        }
        html += `
        <div class="card">
          <div class="card-row">
            <span class="title">${index == 0
            ? TranslationService.instance.translate('af-initial-request')
            : TranslationService.instance.translate('af-step') + index
          }</span>
            ${flow.urlDocumentoFirma != null
            ? '<a href="' + flow.urlDocumentoFirma + '" class="icon icon-documento"></a>'
            : ''
          }
          </div>
          <div class="card-row">
            <div class="card-row-element half">
              <span class="label">${TranslationService.instance.translate('common-date')}</span>
              <span class="description">${date}</span>
            </div>
            <div class="card-row-element half">
              <span class="label">${TranslationService.instance.translate('common-state')}</span>
              <div class="state-container">
                <i class="circle-state center ${RequestFlowStatusClassesEnum[flow.idEstadoAprobacion]
          }"></i>
                <span class="description right">${TranslationService.instance.translate(
                  FlowStatusEnum[flow.idEstadoAprobacion]
          )}</span>
              </div>
            </div>
          </div>
          <div class="card-row">
            <div class="card-row-element half">
              <span class="label">${TranslationService.instance.translate(
            'common-responsable'
          )}</span>
              <span class="description ellipsis">${flow.idUsuarioResponsableNavigationNombre != null
            ? flow.idUsuarioResponsableNavigationNombre
            : '-'
          }</span>
              <span class="subdescription ">${flow.idUsuarioResponsableNavigationCargo != null
            ? flow.idUsuarioResponsableNavigationCargo
            : '-'
          }</span>
            </div>
            <div class="card-row-element half">
              <span class="label">${TranslationService.instance.translate('common-sign')}</span>
              <span class="description">${flow.idUsuarioFirmaNavigationNombre != null
            ? flow.idUsuarioFirmaNavigationNombre
            : '-'
          }</span>
            </div>
          </div>
          <div class="card-row">
            <div class="card-row-element all">
              <span class="label">${TranslationService.instance.translate('common-comments')}</span>
              <span class="description">${flow.comentarios != null ? flow.comentarios : '-'}</span>
            </div>
          </div>
          </div>`;
      });

      if (this._request.idEstado == 2) {
        this.httpService
          .get(
            `${this.httpService.config.canApproveRequest}?idSolicitud=
            ${this._requestId}&usuarioSolicitante=${this.authenticationService.getAuthenticatedUser().login
            }`
          )
          .then(canUserApprove => {
            this.canApproveOrReject = canUserApprove;
          });

        this.httpService
          .get(
            `${this.httpService.config.canApproveOrRejectRequest}?idSolicitud=
            ${this._requestId}&usuarioSolicitante=${this.authenticationService.getAuthenticatedUser().login
            }`
          )
          .then(canUserApprove => {
 

            if (canUserApprove) {
              $('#btn-mob-validate-af').click(() =>
                this.approveFlowApproval(this._request, response[response.length - 1])
              );
              $('#btn-mob-revision-af').click(() =>
                this.reviewFlowApproval(this._request, response[response.length - 1])
              );
              $('#btn-mob-reject-af').click(() =>
                this.rejectFlowApproval(response[response.length - 1].idAprobacion)
              );

              if (response[response.length - 1].esRevision) {
                $('#btn-mob-revision-af').addClass('disabled');
              }
            } else {
              $('#btn-mob-validate-af').addClass('disabled');
              $('#btn-mob-revision-af').addClass('disabled');
              $('#btn-mob-reject-af').addClass('disabled');
            }
          })
          .catch(error => {
            this.toastService.exception(error.title);
            console.error(error);
            location.assign('/#budgets');
          });

        //Disable buttons if the user logged in not the current approver
        if (
          response[response.length - 1].idUsuarioResponsable !=
          this.authenticationService.getAuthenticatedUser().idUsuario
        ) {
          $('#btn-mob-validate-af').addClass('disabled');
          $('#btn-mob-revision-af').addClass('disabled');
          $('#btn-mob-reject-af').addClass('disabled');
        }
      }

      if (this._request.idEstado == 3) {
        $('#btn-mob-validate-af').hide();
        $('#btn-mob-revision-af').hide();
        $('#btn-mob-reject-af').hide();
      }

      //Add cards html to cards container
      $('#approval-flow-cards-container').html(html);
      //Bind click buttons events
      $('#btn-mob-validate-af')
        .off()
        .click(() => this.requestFlowApproval(this._request));
      $('#btn-mob-aprrove-af')
        .off()
        .click(() => this.approveFlowApproval(this._request, response[response.length - 1]));
      $('#btn-mob-reject-af')
        .off()
        .click(() => this.rejectFlowApproval(response[response.length - 1].idAprobacion));
      $('#btn-mob-revision-af')
        .off()
        .click(() => this.reviewFlowApproval(this._request, response[response.length - 1]));
      $('#btn-mob-download-flow-pdf')
        .off()
        .click(() => {
          let timeOut = 0;
          timeOut = this.infoDownload(this._request.idEstado, this._localCurrencyId);
          setTimeout(() => {
            this.downloadFlowPDF(this._request.idSolicitud);
          }, timeOut);  
        });
      //Disable buttons if the user logged in not the current approver
      if (
        response[response.length - 1].idUsuarioResponsable !=
        this.authenticationService.getAuthenticatedUser().idUsuario
      ) {
        $('#btn-mob-aprrove-af').addClass('disabled');
        $('#btn-mob-reject-af').addClass('disabled');
        $('#btn-mob-revision-af').addClass('disabled');
      }
      else{
        if (response[response.length - 1].esRevision) {
          $('#btn-mob-revision-af').addClass('disabled');
        }
      }
      //Hide buttons if is not pending of approval
      $('#btn-mob-aprrove-af').toggleClass(
        'hidden',
        this._request.idEstado != RequestStatusNameEnum.PendindApproval &&
        this._request.idEstado != RequestStatusNameEnum.Draft
      );
      $('#btn-mob-reject-af').toggleClass(
        'hidden',
        this._request.idEstado != RequestStatusNameEnum.PendindApproval &&
        this._request.idEstado != RequestStatusNameEnum.Draft
      );
      $('#btn-mob-revision-af').toggleClass(
        'hidden',
        this._request.idEstado != RequestStatusNameEnum.PendindApproval &&
        this._request.idEstado != RequestStatusNameEnum.Draft
      );
    }
  }

  _getCurrencyName() {
    return this._request.monedasSolicitud[0].nombreCorto;
  }
  /**
   *
   * @param {*} request
   */
  requestFlowApproval(request) {
    let modalOptions = new RequestFlowApprovalOptions();
    modalOptions.flowType = 'request';
    modalOptions.validateObjectId = request.idSolicitud;
    modalOptions.userId = this.authenticationService.getAuthenticatedUser().login;
    let modal = new ModalComponent(
      TranslationService.instance.translate('common-request-ap'),
      new RequestFlowApprovalComponent(modalOptions)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._loadRequest(this._request.idSolicitud);
    });
  }

  getStatusDescription(e) {
    let translationKey = '';

    if (e.esRevision) {
      switch (e.idEstadoAprobacion) {
        case 2:
          translationKey = 'review';
          break;
        case 3:
          translationKey = 'review-requested';
          break;
        default:
          translationKey = FlowStatusEnum[e.idEstadoAprobacion];
          break;
      }
    } else {
      translationKey = FlowStatusEnum[e.idEstadoAprobacion];
    }
    return TranslationService.instance.translate(translationKey);
  }

  /**
   *
   * @param {*} request
   */
  deleteRequest(request) {
    let options = new ConfirmationDeleteOptions();
    options.itemName = this._title;
    options.actionUrl = `${this.httpService.config.deleteRequest}?loginUsuario=${this.authenticationService.getAuthenticatedUser().login
      }&idSolicitud=${request.idSolicitud}`;

    let modal = new ModalComponent(
      TranslationService.instance.translate('common-modal-confirmation'),
      new ConfirmationDeleteComponent(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      location.assign('/#requests');
    });
  }

  /**
   * Trigger modal for approve or validate the flow
   * @param {*} request
   */
  approveFlowApproval(request, lastApproval) {
    let modalOptions = new ApproveValidationFlowOptions();
    modalOptions.flowType = 'request';
    modalOptions.approvalId = lastApproval.idAprobacion;
    modalOptions.validateObjectId = request.idSolicitud;
    modalOptions.userId = this.authenticationService.getAuthenticatedUser().login;
    modalOptions.requiredSignerId = lastApproval.idUsuarioFirma;
    modalOptions.isReview = lastApproval.esRevision;
    modalOptions.canApproveOrReject = this.canApproveOrReject;

    let modal = new ModalComponent(
      TranslationService.instance.translate('bd-modal-apfor-title'),
      new ApproveValidationFlowComponent(modalOptions)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._loadRequest(this._request.idSolicitud);
    });
  }

  reviewFlowApproval(request, lastApproval) {
    let modalOptions = new ApproveValidationFlowOptions();
    modalOptions.flowType = 'request';
    modalOptions.approvalId = lastApproval.idAprobacion;
    modalOptions.validateObjectId = request.idSolicitud;
    modalOptions.userId = this.authenticationService.getAuthenticatedUser().login;
    modalOptions.requiredSignerId = lastApproval.idUsuarioFirma;
    modalOptions.isReview = true;
    modalOptions.canApproveOrReject = false;

    let modal = new ModalComponent(
      TranslationService.instance.translate('bd-modal-review-request-title'),
      new ApproveValidationFlowComponent(modalOptions)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._loadRequest(this._request.idSolicitud);
    });
  }

  rejectFlowApproval(approvalId) {
    let options = new RejectValidationFlowOptions();
    options.approvalId = approvalId;
    options.flowType = FlowType.Request;
    let modal = new ModalComponent(
      TranslationService.instance.translate('req-apfl-btn-to-refuse'),
      new RejectValidationFlowComponent(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._loadRequest(this._request.idSolicitud);
    });
  }

  _toggleMenu() {
    $('.sidenav').toggleClass('menuComplete');
  }

  approvalFlowTab() {
    //Hide details content
    $('.request-details-content').addClass('hidden');
    //Show approval flow content
    $('.approval-flow-mobile').removeClass('hidden');
    //Remove active from details tab
    $('#details-tab').removeClass('active');
    //Add active to approval flow tab
    $('#approval-flow-tab').addClass('active');
  }
  detailsTab() {
    //Hide approval flow content
    $('.request-details-content').removeClass('hidden');
    //Show details content
    $('.approval-flow-mobile').addClass('hidden');
    //Add active from details tab
    $('#details-tab').addClass('active');
    //Remove active to approval flow tab
    $('#approval-flow-tab').removeClass('active');
  }

  _visualizationOptionsMobile() {
    let options = new VisualizationOptionsParams();
    options.isLocal = this.isLocalCurrency;
    options.selectedUnit = this.selectedUnit;
    options.localCurrency = 'Euro(€)';
    var modal = new ModalComponent(
      TranslationService.instance.translate('visualization-options-modal-title'),
      new VisualizationOptionsComponent(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this.isLocalCurrency = modal.result.isLocal;
      this.selectedUnit = modal.result.selectedUnit;
      switch (this.selectedUnit) {
        case UnitsEnums.Unit:
          $('#unit-units').addClass('active');
          this.unitDiv = 1;
          this.selectedUnit = UnitsEnums.Unit;
          break;
        case UnitsEnums.Thousand:
          $('#unit-thousands').addClass('active');
          this.unitDiv = 1000;
          this.selectedUnit = UnitsEnums.Thousand;
          break;
        case UnitsEnums.Millions:
          $('#unit-millions').addClass('active');
          this.unitDiv = 1000000;
          this.selectedUnit = UnitsEnums.Millions;
          break;
      }
      this.buildCommonContent();
      if (this._request.idTipo == RequestTypeEnum.Investment) {
        this.buildInvestmentContent();
      } else {
        this.buildDisinvestmentContent();
      }
    });
  }
  _openConceptsMobile() {
    let options = new ConceptsMobileOptions();
    options.requestType = this._request.idTipo;
    options.unitDiv = this.unitDiv;
    options.readOnly = true;
    options.localCurrency = this.isLocalCurrency;
    options.data = this._request.detalleSolicitud.concepto;
    var modal = new ModalComponent(
      TranslationService.instance.translate('req-detail-concepts-title'),
      new ConceptsMobileComponent(options)
    );
  }

  displayTypeOfWork(companyId, investmentAreaId) {
    //GET SEGMENTADA POR TIPO DE TRABAJO
    let isSegmented = false;

    this.httpService
    .get(
      this.httpService.config.loadSegmented +
      '?loginUsuario=' +
      this.authenticationService.getAuthenticatedUser().login +
      '&idEmpresa=' +
      companyId +
      '&idAreaInversion=' +
      investmentAreaId
    )
    .then(areaSegmented=> {
      isSegmented = areaSegmented;
      if (isSegmented && this._request.idTipologiaObra > 0 ){
        $('#content-request-type')
        .find('.content-element')
        .append (' | ')
        .append(this._request.idTipologiaObra == RequestTypeOfWorkEnum.CivilWork
            ? TranslationService.instance.translate('civil-work')
            :TranslationService.instance.translate('building')
            );
      }
    });

  }
}
