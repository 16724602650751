import './createRequest.scss';
var htmlContent = require('./createRequest.html');
import $ from 'jquery';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { HttpService } from '../../core/http/http';
import { Validator } from '../../core/validator/validator';
import { ToastService } from '../../shared/services/toastService';
import { AuthenticationService } from '../../shared/services/authenticationService';

const renderMarkup = () => htmlContent;

export class CreateRequestComponent extends BaseModalContent {
  constructor(areaId, companyId, references, isSegmented) {
    super(renderMarkup());
    this.httpService = new HttpService();
    this.areaId = areaId;
    this.companyId = companyId;
    this.references = references;
    this.value = null;
    this.toastService = new ToastService();
    this.authenticationService = new AuthenticationService();
    this.isSegmented = isSegmented;
  }

  onCreated() {
    this.init();
  }

  init() {
    this.parentModal.container.find('.cancel').click(() => this.cancel());
    this.parentModal.container.find('.create').click(() => this.create());

    if (typeof this.references !== 'undefined') {
      this.parentModal.container.find('.reference').val(this.references[0].referencia);
      $('.request-type').change(() => {
        this.changeReference();
      });
    }

    if(!this.isSegmented){
      $('.containerTypology').hide();
    }

    this.validator = new Validator(this.parentModal.container);
    this.validator.validate();
  }

  changeReference() {
    let requestType = this.parentModal.container
      .find('.request-type:checked')
      .first()
      .val();
    let reference = this.references.find(t => t.tipoSolicitud == requestType).referencia;
    this.parentModal.container.find('.reference').val(reference);
    this.validator.validate();
  }

  cancel() {
    this.value = null;
    this.parentModal.close();
  }

  create() {
    if (!this.validator.isValid) {
      return;
    }
    let reference = this.parentModal.container.find('.reference').val();
    this.httpService
      .get(
        `${this.httpService.config.existReference}?referencia=${reference}&usuarioSolicitante=${this.authenticationService.getAuthenticatedUser().login
        }`
      )
      .then(existeReferencia => {
        if(!existeReferencia){
          this.value = {
            reference: this.parentModal.container.find('.reference').val(),
            requestName: this.parentModal.container.find('.request-name').val(),
            requestType: this.parentModal.container.find('.request-type:checked').val(),
            requestTypology: this.parentModal.container.find('.request-typology:checked').val()
          };
          this.parentModal.close();
        }
        else{
          let msgError = "excepcionyaexistereferencia"
          this.toastService.exception(msgError);
          return;
        }
      });
  }

  get result() {
    return this.value;
  }
}
