import './group-selector.scss';
var htmlContent = require('./group-selector.html');
import $ from '../../core/jquery/jquery-global';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { TranslationService } from '../../core/translation/translation';
const renderMarkup = () => htmlContent;

export class GroupSelectorComponent extends BaseModalContent {
  constructor(options) {
    super(renderMarkup());
    this._options = options || new GroupSelectorOptions();
    this.value = null;
    this._groups = options.data;
  }

  /**
   * Event fired after the modal creation
   */
  onCreated() {
    this._init();
  }

  /**
   * Initialize the component
   */
  _init() {
    this._enableButtons();
    this._buildGroupTable();

    if (this._options.selectedId != null) {
      $('#row-' + this._options.selectedId).click();
      //$('#row-' + this._options.selectedId).addClass('groupSelected');
    }
  }

  _buildGroupTable() {
    var html = '';
    if (this._groups != null && this._groups.childLines.length > 0) {
      let collapseIcon = '';
      if (this._groups.lineTypeId == 3 && !this._groups.isCollapsed) {
        collapseIcon = '<span class="icon icon-plegar" data-id="' + this._groups.id + '"></span>';
      } else if (this._groups.lineTypeId == 3 && this._groups.isCollapsed) {
        collapseIcon =
          '<span class="icon icon-desplegar" data-id="' + this._groups.id + '"></span>';
      }
      html += `<div class="table-row ${this._groups.lineTypeId < 4 ? 'bolder' : ''}" id="row-${
        this._groups.id
      } ${'data-id="' + this._groups.id}" ${'type-id="' + this._groups.lineTypeId}">
               <div class="cell name ${
                 this._groups.level != null ? 'lvl-' + this._groups.level : ''
               }">
                <div class="wrapper">
                  ${collapseIcon}
                  <span class="lineDescription">${this._groups.description}</span>
                </div>
               </div>
              </div>`;
      html = this._buildChildGroupLinesHtml(this._groups.childLines, html);
    } else {
      html = `<div class="table-row all">
              <span>${TranslationService.instance.translate('requests-empty-group')}</span>
            </div>`;
    }
    $('#group-table-content').html(html);

    $('#main-container-balance')
      .find('.icon-plegar')
      .click(e => this._toggleCollapsed(e));
    $('#main-container-balance')
      .find('.icon-desplegar')
      .click(e => this._toggleCollapsed(e));

    $('.table-row').click(e => this._selectGroupLine(e));
  }

  _buildChildGroupLinesHtml(childLines, html) {
    childLines.forEach(childLine => {
      let collapseIcon = '';
      if (childLine.lineTypeId == 3 && !childLine.isCollapsed) {
        collapseIcon = '<span class="icon icon-plegar" data-id="' + childLine.id + '"></span>';
      } else if (childLine.lineTypeId == 3 && childLine.isCollapsed) {
        collapseIcon = '<span class="icon icon-desplegar" data-id="' + childLine.id + '"></span>';
      }
      html += `<div class="table-row ${childLine.lineTypeId < 4 ? 'bolder' : ''}" id="row-${
        childLine.id
      }" ${'data-id="' + childLine.id}" ${'type-id="' + childLine.lineTypeId}">
               <div class="cell name ${childLine.level != null ? 'lvl-' + childLine.level : ''}">
                <div class="wrapper">
                  ${collapseIcon}
                  <span class="lineDescription">${childLine.description}</span>
                </div>
               </div>
              </div>`;
      if (childLine.childLines.length > 0 && !childLine.isCollapsed) {
        html = this._buildChildGroupLinesHtml(childLine.childLines, html);
      }
    });
    return html;
  }

  _toggleCollapsed(event) {
    let line = this._groups.childLines.filter(
      cl => cl.id == parseInt(event.target.getAttribute('data-id'))
    )[0];
    line.isCollapsed = !line.isCollapsed;
    this._buildGroupTable();
  }

  _selectGroupLine(event) {
    if (event.currentTarget.getAttribute('type-id') == 4) {
      var id = (this.value = event.currentTarget.getAttribute('data-id'));
      var description = $(event.currentTarget)
        .find('.lineDescription')
        .text();
      this.value = new SelectedGroupLine(id, description);

      $('.table-row').removeClass('groupSelected');
      event.currentTarget.classList.add('groupSelected');
    }
  }

  _enableButtons() {
    this._cancel = this.parentModal.container.find('.cancel');
    this._save = this.parentModal.container.find('.save');
    this._delete = this.parentModal.container.find('.delete');

    this._cancel.click(() => this.cancel());
    this._save.click(() => this.save());
    this._delete.click(() => this.delete());
  }

  /**
   * Cancel the modal
   */
  cancel() {
    this.value = null;
    this.parentModal.close();
  }

  /**
   * Save the modal
   */
  save() {
    this.parentModal.close();
  }

  /**
   * Delete the value
   */
  delete() {
    this.value = 'delete';
    this.parentModal.close();
  }

  get result() {
    return this.value;
  }
}

export class GroupSelectorOptions {
  groups = null;
  selectedId = null;
  data = null;
}

export class GroupLine {
  constructor(id, description, lineTypeId = 4, level = null, isCollapsed = false) {
    this.id = id;
    this.description = description;
    this.lineTypeId = lineTypeId;
    this.level = level;
    this.childLines = [];
    this.isCollapsed = isCollapsed;
  }
}

export class SelectedGroupLine {
  constructor(id, description) {
    this.id = id;
    this.description = description;
  }
}
