export class Component {
  constructor(markup) {
    this.markup = markup;
    this.element = this.convertToDOM(markup);
  }

  getMarkup() {
    return this.markup;
  }

  getElement() {
    return this.element;
  }

  convertToDOM(stringHTML) {
    let el = document.createElement('div');
    el.innerHTML = stringHTML;
    return el;
  }
}
