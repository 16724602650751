import { Rule } from '../rule';

export class MinRule extends Rule {
  get name() {
    return 'min';
  }

  getErrorMessage(element, lang) {
    return `El campo debe ser inferior a ${element.attr('min')}`;
  }

  isValid(element) {
    let value = element.attr('data-raw-value');
    if (!value) {
      value = element.val();
    }
    return parseFloat(value) >= parseFloat(element.attr('min'));
  }
}
