import { Component } from '../base-component';
import $ from 'jquery';
import './toast.scss';
import { AppConfig } from '../../../core/app-config/app-config';
import { AppConfigKeys } from '../../../config/keys';
var htmlContent = require('./toast.html');

export class ToastComponent extends Component {
  constructor(id, type, icon, title, message) {
    super();
    this.id = id;
    this.init(type, icon, title, message);
  }

  init(type, icon, title, message) {
    $('#toast-container').prepend(htmlContent);
    $('#toast').attr('data-id', this.id);
    $('#toast').addClass(type);
    $('#toast-icon').addClass(icon);
    $('#toast-title').text(title);
    $('#toast-message').text(message);
    $('#toast-container')
      .find(`[data-id='${this.id}']`)
      .find('.icon-cerrar')
      .click(() => this.close());

    setTimeout(() => {
      this.close();
    }, AppConfig.instance.getProperty(AppConfigKeys.ToastOpenTime));
  }

  close() {
    $('#toast-container')
      .find(`[data-id='${this.id}']`)
      .remove();
  }
}
