import { DatePicker } from '../date-picker/datePicker';
import { BudgetLinesActionsEnums, UnitsEnums, PetitionStatusEnum } from '../../config/enums';
import { ToastService } from '../../shared/services/toastService';

/**
 * class to manage the budget
 */
export class BudgetManager {
  static _loadingData = false;

  constructor(budgetId, userId, maxDepth, exercise) {
    // BudgetLines object stores {BudgetLine} objects
    this.budgetId = budgetId;
    this.budgetLines = {};
    this.deletedBudgetLinesIds = [];
    this.staticId = -1;
    this.userId = userId;
    this.maxDepth = maxDepth;
    this.exercise = exercise;
  }

  getRootLineId() {
    return this.rootLineId;
  }

  getBudgetId() {
    return this.budgetId;
  }

  setBudgetId(budgetId) {
    this.budgetId = budgetId;
  }

  getMaxDepth() {
    return this.maxDepth;
  }

  getExercise() {
    return this.exercise;
  }

  /**
   * Reset the status of the budget, set it as new
   */
  resetStatus() {
    this.deletedBudgetLinesIds = [];

    for (const line of Object.values(this.budgetLines)) {
      line.resetStatus();
    }
  }

  /**
   * Adds a new line to the Budget manager and sets it as child of a parent line
   * @param {BudgetLine} budgetLine
   * @param {Number} parentId
   */
  addLine(budgetLine, parentId) {
    if (budgetLine.getId() in this.budgetLines) {
      throw 'Line already exists';
    }
    if (!(parentId in this.budgetLines)) {
      throw 'Parent ID does not exist';
    }

    budgetLine.setExercise(this.exercise);

    this.budgetLines[budgetLine.getId()] = budgetLine;
    this.budgetLines[parentId].addChildBudgetLine(budgetLine);
  }

  /**
   * Removes all the references to a BudgetLine by its id and logs the delete operation
   * @param {BudgetLine} budgetLine
   */
  removeLine(budgetLineId) {
    if (!(budgetLineId in this.budgetLines)) {
      throw 'Budget Line does not exist';
    }
    let line = this.budgetLines[budgetLineId];
    if (line.getActionId() != BudgetLinesActionsEnums.Add) {
      this.deletedBudgetLinesIds.push(budgetLineId);
    }
    let parent = line.getParentNode();
    if (parent) {
      line.getParentNode().deleteChildBudgetLine(budgetLineId, line.getOrder(), false);
    }

    delete this.budgetLines[budgetLineId];
    this.removeReferences(budgetLineId);
    if (parent) {
      line.getParentNode()._checkIntegrity(); //Not necessary, useful for debugging
    }
  }

  /**
   * Removes all the references to a BudgetLine by its id
   * @param {Number} budgetLineId
   */
  removeReferences(budgetLineId) {
    for (const key in this.budgetLines) {
      if (Object.prototype.hasOwnProperty.call(this.budgetLines, key)) {
        const element = this.budgetLines[key];
        delete element.getChildrenBudgetLines()[budgetLineId];
        if (element.getParentNode() != null && element.getParentNode().id === budgetLineId) {
          element.setParentNode(null);
        }
      }
    }
  }

  /**
   * Returns a budget line by its id
   * @param {Number} budgetLineId
   * @returns {BudgetLine}
   */
  getLine(budgetLineId) {
    if (!(budgetLineId in this.budgetLines)) {
      throw 'Budget Line does not exist';
    }

    return this.budgetLines[budgetLineId];
  }

  /**
   * Get lines id with errors
   * @returns {BudgetLine[]}
   */
  getLinesWithErrors() {
    var linesWithErrors = Object.values(this.budgetLines).filter(x => x.hasErrors);
    return linesWithErrors;
  }

  /**
   * Moves a budget line under another budget line in a determinated position. If not position provided it will be attached at the end.
   * @param {Number} idLine
   * @param {Number} newParentId
   * @param {Number} newPosition
   * @param {Number} oldPosition
   */
  moveBudgetLine(idLine, newParentId, newPosition, oldPosition) {
    if (!(idLine in this.budgetLines)) {
      throw 'ID line not found';
    }
    if (!(newParentId in this.budgetLines)) {
      throw 'parent ID line not found';
    }

    let line = this.budgetLines[idLine];
    let parent = this.budgetLines[newParentId];
    let oldParent = line.getParentNode();

    if (newPosition == oldPosition && oldParent.getId() == parent.getId()) {
      return;
    }

    line.setOrder(newPosition);
    if (oldPosition != undefined && line.getActionId() == -1)
      line.setActionId(BudgetLinesActionsEnums.Update);

    if (oldParent && oldParent.getId() != parent.getId()) {
      oldParent.deleteChildBudgetLine(idLine, oldPosition, true);
      oldPosition = null;
    }

    parent.addChildBudgetLine(line, oldPosition);
  }

  /**
   * Converts the items to the nestable format (ready for nestable creation)
   * @param linesJson is the json following the API response format
   * @returns a json with the items well-formatted
   */
  loadJsonItems(linesJson, exercise) {
    BudgetManager._loadingData = true;
    // Loads into [nodes] the json info in a structured way
    // Every node contains its own data and their children data
    this.exercise = exercise;
    let nodes = [];
    for (let line of linesJson) {
      if (line.idLineaPresupuesto > this.staticId) this.staticId = line.idLineaPresupuesto;

      // if the line has parent... and the parent exists in the current tree (partials trees exists in petitions)
      if (
        line.idLineaPadre != undefined &&
        linesJson.find(x => x.idLineaPresupuesto == line.idLineaPadre)
      ) {
        // if the parent node doesn't exist...
        if (nodes[line.idLineaPadre] == undefined) {
          // Creates it
          nodes[line.idLineaPadre] = { children: [] };
        }
        // Adds the child to the parent node
        nodes[line.idLineaPadre].children.push({
          id: line.idLineaPresupuesto,
          order: line.orden
        });
      } else {
        // if the line hasn't parent...
        // Sets it as no parent node (root line)
        this.rootLineId = line.idLineaPresupuesto;
      }
      let children = [];
      // If the current node contains children...
      if (nodes[line.idLineaPresupuesto] != undefined) {
        // Retrieves them to create the complete node
        children = nodes[line.idLineaPresupuesto].children;
      }
      // Creates the complete node structure
      // it contains its own data and their children data
      let amounts = [];
      for (let amountData of line.cuantias) {
        amounts.push({
          id: amountData.idCuantia,
          quarter: amountData.trimestre,
          paymentDate: amountData.fechaDesembolso,
          import: amountData.importe,
          localImport: amountData.importeLocal
        });
      }

      nodes[line.idLineaPresupuesto] = {
        id: line.idLineaPresupuesto,
        parentId: line.idLineaPadre || null,
        typeId: line.idTipoLineaPresupuesto,
        description: line.descripcion,
        percentage: line.porcentajeParticipacion,
        amountQ1: line.importeT1,
        amountQ2: line.importeT2,
        amountQ3: line.importeT3,
        amountQ4: line.importeT4,
        amountQ1Local: line.importeT1Local,
        amountQ2Local: line.importeT2Local,
        amountQ3Local: line.importeT3Local,
        amountQ4Local: line.importeT4Local,
        amountPrevious: line.importeAnterior,
        amountPreviousLocal: line.importeAnteriorLocal,
        amounts: amounts,
        acquisitionDate: line.fechaAdquisicion,
        disbursementDates: [],
        requestedTo: line.solicitadoA,
        requestedToStatus: line.estadoPeticion,
        order: line.orden,
        children: children
      };
    }

    // Loads the budget lines into the manager
    nodes.forEach(node => {
      let budgetLine = new BudgetLine(node.id, this.exercise);
      budgetLine.setDescription(node.description);
      budgetLine.setPertentage(node.percentage);
      budgetLine.setAcquisitionDate(node.acquisitionDate);
      budgetLine.setRequestedTo(node.requestedTo);
      budgetLine.setRequestedToStatus(node.requestedToStatus);
      budgetLine.setTypeId(node.typeId);
      budgetLine.setOrder(node.order);
      budgetLine.setAmountQ1(node.amountQ1);
      budgetLine.setAmountQ2(node.amountQ2);
      budgetLine.setAmountQ3(node.amountQ3);
      budgetLine.setAmountQ4(node.amountQ4);
      budgetLine.setAmountQ1Local(node.amountQ1Local);
      budgetLine.setAmountQ2Local(node.amountQ2Local);
      budgetLine.setAmountQ3Local(node.amountQ3Local);
      budgetLine.setAmountQ4Local(node.amountQ4Local);
      budgetLine.setPreviousAmmount(node.amountPrevious);
      budgetLine.setPreviousAmmountLocal(node.amountPreviousLocal);
      budgetLine.parentId = node.parentId; //This field has not a setter because it will be refreshed automatically when the parent is set. But for the root if it's a petition it will be untouched

      let budgetLineAmounts = new BudgetLineAmounts();
      for (let amount of node.amounts) {
        let budgetAmount = new BudgetAmount(
          amount.id,
          amount.paymentDate,
          amount.import,
          amount.localImport
        );
        budgetLineAmounts.addAmount(budgetAmount);
      }
      budgetLine.setBudgetLineAmounts(budgetLineAmounts);
      this.budgetLines[budgetLine.getId()] = budgetLine;
    });

    // Loads the children structure into the manager
    nodes.forEach(node => {
      node.children.forEach(child => {
        this.moveBudgetLine(child.id, node.id, child.order);
      });
    });
    this.staticId++;

    BudgetManager._loadingData = false;
  }

  /**
   * Returns an api-compatible JSON with the budget manager structure
   * @returns {Array.Object}
   */
  exportJSON() {
    let exportResult = [];
    for (const key in this.budgetLines) {
      if (Object.prototype.hasOwnProperty.call(this.budgetLines, key)) {
        const element = this.budgetLines[key];
        exportResult.push({
          usuarioSolicitante: this.userId,
          accion: element.actionId == null ? -1 : element.actionId,
          linea: {
            idLineaPresupuesto: element.id,
            idPresupuesto: this.budgetId,
            idTipoLineaPresupuesto: element.typeId,
            idLineaPadre: element.getParentId(),
            importeT1: element.amountQ1Local,
            importeT2: element.amountQ2Local,
            importeT3: element.amountQ3Local,
            importeT4: element.amountQ4Local,
            descripcion: element.description,
            porcentajeParticipacion: element.percentage,
            orden: element.order,
            cuantias: element.budgetLineAmounts.exportJSON(),
            fechaAdquisicion: element.acquisitionDate,
            usuarioActualizacion: this.userId
          }
        });
      }
    }
    this.deletedBudgetLinesIds.forEach(value => {
      exportResult.push({
        usuarioSolicitante: this.userId,
        accion: BudgetLinesActionsEnums.Delete,
        linea: {
          idLineaPresupuesto: value,
          usuarioActualizacion: this.userId
        }
      });
    });
    return exportResult;
  }

  /**
   * Returns a list with the budget line selected and the children recursively
   * @param {int} budgetLineId
   * @returns {Array.BudgetLine}
   */
  getTreeList(budgetLineId) {
    if (!(budgetLineId in this.budgetLines)) {
      throw 'Budget Line does not exist';
    }
    let lines = [];
    let line = this.budgetLines[budgetLineId];
    lines.push(line);

    /**
     * Push all the children lines on the list of lines to return
     * @param {BudgetLine} budgetLine
     * @param {BudgetLine[]} currentLines
     */
    let getNodeChildren = (budgetLine, currentLines) => {
      for (const key in budgetLine.childrenLines) {
        if (Object.prototype.hasOwnProperty.call(budgetLine.childrenLines, key)) {
          const element = budgetLine.childrenLines[key];
          currentLines.push(element);
          if (Object.keys(element.childrenLines) != null) {
            currentLines = getNodeChildren(element, currentLines);
          }
        }
      }
      return currentLines;
    };

    if (Object.keys(line.childrenLines) != null) {
      lines = getNodeChildren(line, lines);
    }

    return lines;
  }

  getChildrenJson() {
    /**
     * @param index is the id of one node stored at [this.budgetLines]
     * @returns the complete node structure (it contains its own data
     * and their children data)
     */
    let getNodeContent = index => {
      let children = [];
      let temp = Object.values(this.budgetLines[index].getChildrenBudgetLines());
      let budgetLineChildren = temp.sort((a, b) => a.getOrder() - b.getOrder());
      for (let child of budgetLineChildren) {
        children.push(getNodeContent(child.getId()));
      }
      return {
        id: this.budgetLines[index].getId(),
        children: children.length == 0 ? null : children
      };
    };

    /**
     * Adds a new line under the selected line
     * @param {String} node the node to be analyzed
     * @param {String} nodeLevel the current nodeLevel
     */
    let getNodeLevel = (node, nodeLevel) => {
      if (node.children && node.children.length > 0) {
        nodeLevel++;
        node.children.forEach(childNode => {
          this.budgetLines[childNode.id].setNodeLevel(nodeLevel);
          this.budgetLines[childNode.id].setParentNode(this.budgetLines[node.id]);
          if (childNode.children && childNode.children.length > 0) {
            childNode = getNodeLevel(childNode, nodeLevel);
          }
        });
      }
      return node;
    };

    // From the root line, it loads its data and converts it into a json
    // The children data is recovered rescursively through their parents
    let tree = getNodeContent(this.rootLineId);

    //Set the level of the node item to 0
    this.budgetLines[this.rootLineId].setNodeLevel(0);
    if (tree.children && tree.children.length > 0) {
      tree = getNodeLevel(tree, 0);
    }
    return JSON.stringify([tree]);
  }
}

/**
 * class to manage a budget line
 */
export class BudgetLine {
  constructor(id, exercise) {
    this.id = id;
    this.childrenLines = {};
    this.budgetLineAmounts = new BudgetLineAmounts(exercise);
    this.parentNode = null;
    this.parentId = null;
    this.order = null;
    this.exercise = exercise;
    this.amountQ1 = null;
    this.amountQ2 = null;
    this.amountQ3 = null;
    this.amountQ4 = null;
    this.amountQ1Local = null;
    this.amountQ2Local = null;
    this.amountQ3Local = null;
    this.amountQ4Local = null;
    this.amountPrevious = null;
    this.amountPreviousLocal = null;
    this.hasErrors = false;
    this.actionId = -1;
  }

  getParentId() {
    if (this.parentNode != null) {
      return this.parentNode.getId();
    } else {
      return this.parentId;
    }
  }

  hasChildren() {
    return this.childrenLines !== null && Object.keys(this.childrenLines).length > 0;
  }

  getId() {
    return this.id;
  }

  getExercise() {
    return this.exercise;
  }

  setExercise(exercise) {
    this.exercise = exercise;
  }

  getNodeLevel() {
    return this.nodeLevel;
  }

  setNodeLevel(nodeLevel) {
    this.nodeLevel = nodeLevel;
  }

  getDescription() {
    return this.description;
  }

  getOrder() {
    return this.order;
  }

  setOrder(order) {
    this.order = order;
  }

  setDescription(description) {
    this.description = description;
  }

  getPertentage() {
    return this.percentage;
  }

  setPertentage(percentage) {
    this.percentage = percentage;
  }

  setAmountQ1(amountQ1) {
    this.amountQ1 = amountQ1;
  }

  getAmountQ1() {
    return this.amountQ1;
  }

  setAmountQ2(amountQ2) {
    this.amountQ2 = amountQ2;
  }

  getAmountQ2() {
    return this.amountQ2;
  }

  setAmountQ3(amountQ3) {
    this.amountQ3 = amountQ3;
  }

  getAmountQ3() {
    return this.amountQ3;
  }

  setAmountQ4(amountQ4) {
    this.amountQ4 = amountQ4;
  }

  getAmountQ4() {
    return this.amountQ4;
  }

  setAmountQ1Local(amountQ1Local) {
    this.amountQ1Local = amountQ1Local;
  }

  getAmountQ1Local() {
    return this.amountQ1Local;
  }

  setAmountQ2Local(amountQ2Local) {
    this.amountQ2Local = amountQ2Local;
  }

  getAmountQ2Local() {
    return this.amountQ2Local;
  }

  setAmountQ3Local(amountQ3Local) {
    this.amountQ3Local = amountQ3Local;
  }

  getAmountQ3Local() {
    return this.amountQ3Local;
  }

  setAmountQ4Local(amountQ4Local) {
    this.amountQ4Local = amountQ4Local;
  }

  getAmountQ4Local() {
    return this.amountQ4Local;
  }

  setQuarterAmountsToNull() {
    this.amountQ1 = null;
    this.amountQ2 = null;
    this.amountQ3 = null;
    this.amountQ4 = null;
    this.amountQ1Local = null;
    this.amountQ2Local = null;
    this.amountQ3Local = null;
    this.amountQ4Local = null;
  }

  resetStatus() {
    this.actionId = -1;

    for (const child of Object.values(this.childrenLines)) {
      child.resetStatus();
    }
  }

  /**
   * Returns true if the budget line has not parents and children
   * with budget amounts stored
   * @returns {Boolean}
   */
  canStoreBudgetLineAmounts() {
    return (
      !this.parentsHaveAmounts() && !this.childrenHaveAmounts() && !this.childrenAreRequested()
    );
  }

  /**
   * Returns true if the budget line has not parents
   * with budget amounts stored and childen are not requested
   * @returns {Boolean}
   */
  canEditAmountDates() {
    return (
      !this.parentsHaveAmounts() && !this.childrenHaveAmounts() && !this.childrenAreRequested()
    );
  }

  /**
   *
   */
  canStorePetitionLineAmounts(user) {
    return (
      !this.parentsHaveAmounts() &&
      !this.childrenHaveAmounts() &&
      ((this.getRequestedTo() != null && this.getRequestedTo() == user) ||
        this.getRequestedTo() == null)
    );
  }

  /**
   * Sets a BudgetLineAmounts object as the amounts of this line
   * @param {BudgetLineAmounts} budgetLineAmounts
   */
  setBudgetLineAmounts(budgetLineAmounts) {
    if (this.canStoreBudgetLineAmounts()) {
      budgetLineAmounts.setExercise(this.exercise);
      this.budgetLineAmounts = budgetLineAmounts;
    } else {
      throw 'This budget line can not store budget line amounts';
    }
  }

  getBudgetAmountsExercise() {
    return this.budgetLineAmounts.getExercise();
  }

  /**
   * Checks if this line can store amounts and adds a new amount
   * to a budget line
   * @param {BudgetAmount} budgetAmount
   */
  addAmount(budgetAmount) {
    if (this.canStoreBudgetLineAmounts()) {
      this.budgetLineAmounts.addAmount(budgetAmount);
    } else {
      throw 'This budget line can not store budget line amounts';
    }
  }

  /**
   * Checks if this line can store amounts and adds an array
   * of budget amounts to a budget line
   * @param {Array.BudgetAmount} budgetAmounts
   */
  addAmounts(budgetAmounts) {
    if (this.canStoreBudgetLineAmounts()) {
      this.budgetLineAmounts.addAmounts(budgetAmounts);
    } else {
      throw 'This budget line can not store budget line amounts';
    }
  }

  /**
   * Checks if this line can store amounts, cleans the budget
   * amounts and adds an array of budget amounts to a budget line
   * @param {*} date
   */
  replaceAmounts(budgetAmounts) {
    if (this.canStoreBudgetLineAmounts()) {
      this.budgetLineAmounts.empty();
      this.budgetLineAmounts.addAmounts(budgetAmounts);
    } else {
      throw 'This budget line can not store budget line amounts';
    }
  }

  /**
   * Checks if this line can store amounts, cleans the budget
   * amounts and adds an array of budget amounts to a budget line
   * @param {*} date
   */
  replaceAmountsWithoutPetitionsCheck(budgetAmounts) {
    if (!this.parentsHaveAmounts() && !this.childrenHaveAmounts()) {
      this.budgetLineAmounts.empty();
      this.budgetLineAmounts.addAmounts(budgetAmounts);
    } else {
      throw 'This budget line can not store budget line amounts';
    }
  }

  /**
   * Cleans the budget amounts
   */
  emptyAmounts() {
    this.budgetLineAmounts.empty();
  }

  /**
   * Checks if this line can store amounts, cleans the budget
   * amounts and adds an array of budget amounts to a budget line
   * @param {*} date
   */
  replacePetitionAmounts(budgetAmounts, user) {
    if (this.canStorePetitionLineAmounts(user)) {
      this.budgetLineAmounts.empty();
      this.budgetLineAmounts.addAmounts(budgetAmounts);
    } else {
      throw 'This budget line can not store budget line amounts';
    }
  }

  /**
   * Checks if this line can store amounts and set an amount to a quarter
   * @param {Number} quarter
   * @param {Number} amount
   */
  editAmountQuarterWithoutRemovingDates(quarter, amount, lastAmount) {
    if (this.canStoreBudgetLineAmounts()) {
      this.budgetLineAmounts.editAmountQuarterWithoutRemovingDates(quarter, amount, lastAmount);
      this['amountQ' + quarter + 'Local'] = amount;
      this.validateAmounts();
    } else {
      throw 'This budget line can not store budget line amounts';
    }
  }

  /**
   * Checks if this line can store amounts and set an amount to a quarter
   * @param {Number} quarter
   * @param {Number} amount
   */
  editPetitionAmountQuarter(quarter, amount, user, lastAmount) {
    if (this.canStorePetitionLineAmounts(user)) {
      this.budgetLineAmounts.editAmountQuarterWithoutRemovingDates(quarter, amount, lastAmount);
      this['amountQ' + quarter + 'Local'] = amount;
      this.validateAmounts();
    } else {
      throw 'This budget line can not store budget line amounts';
    }
  }

  /**
   * Returns the stored amounts
   * @returns {Array}
   */
  getAmounts() {
    return this.budgetLineAmounts.getAmounts();
  }

  /**
   * Returns true if parents, grandparents, etc... have stored budget amounts
   * @returns {Boolean}
   */
  parentsHaveAmounts() {
    return (
      (this.parentNode && this.parentNode.hasAmounts()) ||
      (this.parentNode && this.parentNode.parentsHaveAmounts())
    );
  }

  /**
   * Returns true if children, grandchildren, etc... have stored budget amounts
   * @returns {Boolean}
   */
  childrenHaveAmounts() {
    for (let key in this.childrenLines) {
      let child = this.childrenLines[key];
      if (child.hasAmounts() || child.childrenHaveAmounts()) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns true if children, grandchildren, etc... has been requested to someone
   * @returns {Boolean}
   */
  childrenAreRequested() {
    for (let key in this.childrenLines) {
      let child = this.childrenLines[key];
      if (child.isRequested() || child.childrenAreRequested()) {
        return true;
      }
    }
    return false;
  }

  /**
   * Returns true if contains an id in any sublevel
   * @returns {Boolean}
   */
  childrenContainId(id) {
    for (let key in this.childrenLines) {
      let child = this.childrenLines[key];
      if (child.getId() === id || child.childrenAreRequested()) {
        return true;
      }
    }

    return false;
  }

  /**
   * Returns true if parents, grandparents, etc... has been requested to someone
   * @returns {Boolean}
   */
  parentsAreRequested() {
    let parent = this.getParentNode();
    while (parent != null) {
      if (parent.isRequested()) {
        return true;
      }

      parent = parent.getParentNode();
    }

    return null;
  }

  /**
   * Check if the line is requested
   */
  isRequested() {
    return this.requestedTo != '' && this.requestedToStatus == PetitionStatusEnum.Pending;
  }

  /**
   * Returns true if the line has amounts stored
   * @returns {Boolean}
   */
  hasAmounts() {
    return this.budgetLineAmounts != null && this.budgetLineAmounts.hasAmounts();
  }

  getAllQuarters(currency, unit) {
    let result = {};

    function convertToUnit(value, unitToConvert) {
      if (unitToConvert === UnitsEnums.Thousand) {
        return value / 1000;
      } else if (unitToConvert === UnitsEnums.Millions) {
        return value / 1000000;
      } else {
        return value;
      }
    }

    for (let i = 1; i <= 4; i++) {
      let quarter = this.getTotalAmountFromQuarter(i, currency);
      result['q' + i] = convertToUnit(quarter, unit);
    }

    result['annual'] = convertToUnit(this.getAnnualAmountsFromQuarters(currency), unit);

    return result;
  }

  getAllPrevious(currency, unit) {
    let result = {};

    function convertToUnit(value, unitToConvert) {
      if (unitToConvert === UnitsEnums.Thousand) {
        return value / 1000;
      } else if (unitToConvert === UnitsEnums.Millions) {
        return value / 1000000;
      } else {
        return value;
      }
    }

    let totalPrevious = this.getTotalAmountFromPrevious(currency);
    result['totalPrevious'] = convertToUnit(totalPrevious, unit);

    return result;
  }

  /**
   * Returns the total amounts for this line at a quarter
   * @param {Number} quarter
   * @param {Boolean} isLocalCurrency
   * @returns {Number}
   */
  getTotalAmountFromQuarter(quarter, isLocalCurrency) {
    let total = 0;

    if (isLocalCurrency) {
      total = this['amountQ' + quarter + 'Local'];
    } else {
      total = this['amountQ' + quarter];
    }

    for (let key in this.childrenLines) {
      let child = this.childrenLines[key];
      total += child.getTotalAmountFromQuarter(quarter, isLocalCurrency);
    }

    return total;
  }

  /**
   * Returns the previous total amounts for this line
   * @param {Boolean} isLocalCurrency
   * @returns {Number}
   */
  getTotalAmountFromPrevious(isLocalCurrency) {
    let total = 0;

    if (isLocalCurrency) {
      total = this['amountPreviousLocal'];
    } else {
      total = this['amountPrevious'];
    }

    return total;
  }

  /*
   * Set the line to hasErrors if total amounts and quarters total amounts does not match
   */
  validateAmounts() {
    let annualAmountFromQuarters = this.getAnnualAmountsFromQuarters(true);
    let annualAmount = this.getAnnualAmounts(true);
    this.hasErrors = annualAmount != annualAmountFromQuarters;
  }

  getAnnualAmountsFromQuarters(isLocalCurrency) {
    return (
      this.getTotalAmountFromQuarter(1, isLocalCurrency) +
      this.getTotalAmountFromQuarter(2, isLocalCurrency) +
      this.getTotalAmountFromQuarter(3, isLocalCurrency) +
      this.getTotalAmountFromQuarter(4, isLocalCurrency)
    );
  }

  setQuartersAmounts(amountQ1, amountQ2, amountQ3, amountQ4) {
    this.amountQ1 = amountQ1;
    this.amountQ2 = amountQ2;
    this.amountQ3 = amountQ3;
    this.amountQ4 = amountQ4;
  }

  setLocalQuartersAmounts(amountQ1Local, amountQ2Local, amountQ3Local, amountQ4Local) {
    this.amountQ1Local = amountQ1Local;
    this.amountQ2Local = amountQ2Local;
    this.amountQ3Local = amountQ3Local;
    this.amountQ4Local = amountQ4Local;
  }

  setPreviousAmmount(amountPrevious) {
    this.amountPrevious = amountPrevious;
  }

  getPreviousAmmount() {
    return this.amountPrevious;
  }

  setPreviousAmmountLocal(amountPreviousLocal) {
    this.amountPreviousLocal = amountPreviousLocal;
  }

  getPreviousAmmountLocal() {
    return this.amountPreviousLocal;
  }

  /**
   * Returns the total amounts for this line at a quarter
   * @param {Number} quarter
   * @param {Boolean} isLocalCurrency
   * @returns {Number}
   */
  getTotalQuarter(quarter, isLocalCurrency) {
    let total = 0;
    if (this.hasAmounts()) {
      let budgetAmounts = this.budgetLineAmounts;
      total = budgetAmounts.getTotalQuarter(quarter, isLocalCurrency);
    }
    for (let key in this.childrenLines) {
      let child = this.childrenLines[key];
      total += child.getTotalQuarter(quarter, isLocalCurrency);
    }

    return total;
  }

  /**
   * Returns the annual amounts for this line
   * @param {Boolean} isLocalCurrency
   * @returns {Number}
   */
  getAnnualAmounts(isLocalCurrency) {
    return (
      this.getTotalQuarter(1, isLocalCurrency) +
      this.getTotalQuarter(2, isLocalCurrency) +
      this.getTotalQuarter(3, isLocalCurrency) +
      this.getTotalQuarter(4, isLocalCurrency)
    );
  }

  allQuartersCanBeEdited() {
    return (
      this.budgetLineAmounts.quarterEditIsAllowed(1) &&
      this.budgetLineAmounts.quarterEditIsAllowed(2) &&
      this.budgetLineAmounts.quarterEditIsAllowed(3) &&
      this.budgetLineAmounts.quarterEditIsAllowed(4)
    );
  }

  quarterCanBeEdited(quarter) {
    return this.budgetLineAmounts.quarterEditIsAllowed(quarter);
  }

  getAcquisitionDate() {
    return this.acquisitionDate;
  }

  setAcquisitionDate(acquisitionDate) {
    this.acquisitionDate = acquisitionDate;
  }

  getRequestedTo() {
    return this.requestedTo;
  }

  setRequestedTo(requestedTo) {
    this.requestedTo = requestedTo;
  }

  getRequestedToStatus() {
    return this.requestedToStatus;
  }

  setRequestedToStatus(requestedToStatus) {
    this.requestedToStatus = requestedToStatus;
  }

  getActionId() {
    return this.actionId;
  }

  setActionId(actionId) {
    //If it's already set as created don't set as updated
    if (
      this.actionId == BudgetLinesActionsEnums.Add &&
      actionId == BudgetLinesActionsEnums.Update
    ) {
      return;
    }

    this.actionId = actionId;
  }

  getTypeId() {
    return this.typeId;
  }

  setTypeId(typeId) {
    this.typeId = typeId;
  }

  setParentNode(parentBudgetLine) {
    this.parentNode = parentBudgetLine;
    this.parentId = parentBudgetLine == null ? null : parentBudgetLine.getId();
  }

  deleteParentNode() {
    delete this.parentNode;
  }

  getParentNode() {
    return this.parentNode;
  }

  getChildrenBudgetLines() {
    return this.childrenLines;
  }

  _checkIntegrity() {
    let debug = window.location.href.indexOf('localhost:5000') !== -1;
    if (debug && !BudgetManager._loadingData) {
      let isValid = Object.values(this.childrenLines)
        .map(x => x.order)
        .sort((a, b) => a - b)
        .every((value, index) => value - index == 0);

      if (!isValid) {
        let toastService = new ToastService();
        toastService.error('There is an integrity error with the order of the rows');
      }
    }
  }

  /**
   * Adds a budget line reference as children of this budget line
   * @param {BudgetLine} childBudgetLine
   */
  addChildBudgetLine(childBudgetLine, oldPosition) {
    let lines = Object.values(this.childrenLines).filter(x => x.id !== childBudgetLine.id);

    //If adding a new line sets the id to 0 or the largest one plus 1
    if (childBudgetLine.getOrder() == null) {
      let largestId = Math.max(-1, ...lines.map(x => x.getOrder()));
      childBudgetLine.setOrder(largestId + 1);
    } else if (typeof oldPosition !== 'undefined') {
      if (
        oldPosition === null ||
        typeof oldPosition === 'undefined' ||
        childBudgetLine.order < oldPosition
      ) {
        //Move up or moving from other groups
        lines
          .filter(
            x => x.order >= childBudgetLine.order && (x.order < oldPosition || oldPosition == null)
          )
          .map(x => {
            x.setOrder(x.getOrder() + 1);
            if (x.getActionId() == -1) x.setActionId(BudgetLinesActionsEnums.Update); //Change status only when it's not on the default status. Old position null prevents to change it when it's loaded.
          });
      } else {
        //Move down
        lines
          .filter(x => x.order <= childBudgetLine.order && x.order > oldPosition)
          .map(x => {
            x.setOrder(x.getOrder() - 1);
            if (x.getActionId() == -1) x.setActionId(BudgetLinesActionsEnums.Update); //Change status only when it's not on the default status. Old position null prevents to change it when it's loaded.
          });
      }
    }

    this.childrenLines[childBudgetLine.getId()] = childBudgetLine;
    this._checkIntegrity();
  }

  /**
   * Deletes a budget line reference from the children of this budget line.
   *
   * @param {Number} idLine
   * @param {Number} deleteReference will be true unless reference is removed externally
   */
  deleteChildBudgetLine(idLine, oldPosition, deleteReference = true) {
    if (this.childrenLines[idLine]) {
      //Move backward all the lines that are in the same position or greater
      Object.values(this.childrenLines)
        .filter(x => x.id !== idLine)
        .filter(x => x.order >= oldPosition)
        .map(x => {
          x.setOrder(x.getOrder() - 1);
          if (x.getActionId() == -1 && typeof oldPosition !== 'undefined')
            x.setActionId(BudgetLinesActionsEnums.Update);
        });

      if (deleteReference) {
        delete this.childrenLines[idLine];
        this._checkIntegrity();
      }
    }
  }
}

/**
 * class to manage the amounts of a budget line
 */
export class BudgetLineAmounts {
  constructor(exercise) {
    this.budgetAmounts = {};
    this.exercise = exercise;
  }

  /**
   * Set the value of the exercise property
   * @param {int} exercise
   */
  setExercise(exercise) {
    this.exercise = exercise;
  }

  /**
   * Get the value of the exercise property
   */
  getExercise() {
    return this.exercise;
  }

  /**
   * Adds a new amount to a budget line
   * @param {BudgetAmount} budgetAmount
   */
  addAmount(budgetAmount) {
    this.budgetAmounts[budgetAmount.getPaymentDate()] = budgetAmount;
  }

  /**
   * Adds an array of budget amounts to a budget line
   * @param {*} budgetAmounts
   */
  addAmounts(budgetAmounts) {
    for (let i = 0; i < budgetAmounts.length; i++) {
      this.addAmount(budgetAmounts[i]);
    }
  }

  /**
   * @returns {Array} with the stored amounts
   */
  getAmounts() {
    let budgetAmounts = [];

    let sortedBudgetAmounts = Object.entries(this.budgetAmounts).sort(function(a, b) {
      return new Date(a[0]) - new Date(b[0]);
    });

    for (let key in sortedBudgetAmounts) {
      let paymentDate = sortedBudgetAmounts[key][0];
      budgetAmounts.push(this.budgetAmounts[paymentDate]);
    }
    return budgetAmounts;
  }

  /**
   * Cleans the budget amount array
   */
  empty() {
    delete this.budgetAmounts;
    this.budgetAmounts = {};
  }

  /**
   * @returns true if it contains amounts
   */
  hasAmounts() {
    return Object.keys(this.budgetAmounts).length > 0 ? true : false;
  }

  /**
   * Removes an amount from a budget line
   * @param {*} date
   */
  removeAmount(date) {
    if (!(date in this.budgetAmounts)) {
      throw 'PaymentDate not found';
    }

    delete this.budgetAmounts[date];
  }

  /**
   * Edits a payment date and amount
   * @param {*} date
   * @param {*} amount
   */
  editAmount(date, amount) {
    if (!(date in this.budgetAmounts)) {
      throw 'date not found';
    }
    if (!isNaN(amount)) {
      this.budgetAmounts[date].setLocalAmount(amount);
    } else {
      throw 'Amount is not a number';
    }
  }

  editAmountQuarterWithoutRemovingDates(quarter, amount, lastAmount) {
    // Can modify quarter?
    if (this.quarterEditIsAllowed(quarter)) {
      let amounts = this.getAmounts();
      if (amounts.length == 1){
        let currentAmount = amounts[0].getLocalAmount();
        if (isNaN(currentAmount)) {
          currentAmount = 0;
        }
        this.editAmount(amounts[0].getPaymentDate(), currentAmount + (amount - lastAmount))
      }
      else {
        let quarterAmounts = this.getQuarterAmounts(quarter);
        // if doesn't exist, add new amount
        let lastAmountIsZero = isNaN(lastAmount) || lastAmount == 0;
        if (quarterAmounts.length == 0 && !isNaN(amount) && amount != 0 && lastAmountIsZero) {
          let defaultPaymentDate = new Date(Date.UTC(this.exercise, (quarter - 1) * 3, 1));
          let newAmount = new BudgetAmount(null, defaultPaymentDate.toISOString(), amount, amount);

          this['amountQ' + quarter + 'Local'] = amount;
          this.addAmount(newAmount);
        }
      }
    }
  }

  /**
   * Returns true if the budget allows quarter amount editing
   * @param {Number} quarter
   * @returns {Boolean}
   */
  quarterEditIsAllowed(quarter) {
    let amounts = this.getQuarterAmounts(quarter);
    return amounts.length >= 0 && amounts.length <= 1;
  }

  /**
   * Returns the total amount for a quarter
   * @param {Number} quarterNumber
   * @returns {Number}
   */
  getTotalQuarter(quarterNumber, isLocalCurrency) {
    if (quarterNumber < 1 || quarterNumber > 4) {
      throw 'the range of the quarter must be within 1-4';
    }

    let total = 0;
    for (let key in this.budgetAmounts) {
      let budgetAmount = this.budgetAmounts[key];
      if (budgetAmount && budgetAmount.getQuarter() == quarterNumber) {
        if (isLocalCurrency) {
          total += budgetAmount.getLocalAmount();
        } else {
          total += budgetAmount.getAmount();
        }
      }
    }
    return total;
  }

  /**
   * Returns the sum of (local/not local) amounts
   * @param {Boolean} isLocalCurrency
   * @returns {Number}
   */
  getAnnual(isLocalCurrency) {
    let total = 0;
    for (let key in this.budgetAmounts) {
      let budgetAmount = this.budgetAmounts[key];
      if (isLocalCurrency) {
        total += budgetAmount.getLocalAmount();
      } else {
        total += budgetAmount.getAmount();
      }
    }
    return total;
  }

  /**
   * Returns a list with all the budget amounts of the line for a quarter
   * @param {Number} quarter
   * @returns {Array}
   */
  getQuarterAmounts(quarter) {
    let response = [];
    for (let key in this.budgetAmounts) {
      if (this.budgetAmounts[key]) {
        let budgetAmount = this.budgetAmounts[key];
        if (budgetAmount.getQuarter() == quarter) {
          response.push(this.budgetAmounts[key]);
        }
      }
    }
    return response;
  }

  /**
   * Export the object to 'cuantias' list of API object
   */
  exportJSON() {
    let exportResult = [];
    for (let key in this.budgetAmounts) {
      let budgetAmount = this.budgetAmounts[key];
      exportResult.push({
        idCuantia: budgetAmount.getId(),
        fechaDesembolso: budgetAmount.getPaymentDate(),
        importe: budgetAmount.getLocalAmount(),
        trimestre: budgetAmount.getQuarter()
      });
    }
    return exportResult;
  }
}

/**
 * Class to manage a Budget Amount
 */
export class BudgetAmount {
  constructor(id, paymentDate, amount, localAmount) {
    this.id = id;
    this.paymentDate = paymentDate;
    this.amount = amount ? amount : 0;
    this.localAmount = localAmount ? localAmount : 0;
    this._date = DatePicker.parseDate(paymentDate);
    if (typeof this.amount !== 'number' || typeof this.localAmount !== 'number') {
      throw 'Amount is not a number';
    }
  }

  getId() {
    return this.id;
  }

  getQuarter() {
    return Math.trunc(this._date.getUTCMonth() / 3) + 1;
  }

  getPaymentDate() {
    return this.paymentDate;
  }

  getPaymentDateFormatted() {
    return DatePicker.toLocaleDateString(this._date);
  }

  getAmount() {
    return this.amount;
  }

  getLocalAmount() {
    return this.localAmount;
  }

  setLocalAmount(newAmount) {
    if (!isNaN(newAmount)) {
      this.localAmount = newAmount;
    } else {
      throw 'Amount is not a number';
    }
  }

  setPaymentDate(newPaymentDate) {
    this.paymentDate = newPaymentDate;
    this._date = DatePicker.parseDate(newPaymentDate);
  }

  setAmount(newAmount) {
    if (typeof newAmount === 'number') {
      this.amount = newAmount;
    } else {
      throw 'Amount is not a number';
    }
  }
}
