import './visualize-budget-report.scss';
var htmlContent = require('./visualize-budget-report.html');
import { HttpService } from '../../core/http/http';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { ToastService } from '../../shared/services/toastService';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import { Validator } from '../../core/validator/validator';
import { TranslationService } from '../../core/translation/translation';
import { AuthenticationService } from '../../shared/services/authenticationService';

const renderMarkup = () => htmlContent;

export class visualizeBudgetReportComponent extends BaseModalContent {
  constructor() {
    super(renderMarkup());
    this.toastService = new ToastService();
    this.httpService = new HttpService();
    this.authenticationService = new AuthenticationService();
  }

  onOpened() {
    this.init();
  }

  onLoading(callback) {
    Promise.all([this._loadYears(), this._loadCompanies(), this._loadInvestmentAreas(), this._loadCurrencies()]).then(
      response => {
        callback();
      }
    );
    return true;
  }

  _loadYears () {
    return this.httpService.get(this.httpService.config.loadBudgetsYears +
      this.authenticationService.getAuthenticatedUser().login
    ).then(response => {
      this._years = response.reverse().map(year => ({id: year, label: year.toString()}));
    }).catch(error => {
      this._toasts.error(error.title, error.message);
      console.error(error);
    });
  }

  _loadCompanies() {
    return this.httpService
      .get(
        this.httpService.config.loadCompaniesPerUser +
          this.authenticationService.getAuthenticatedUser().login
      )
      .then(response => {
        this._companies = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  _loadInvestmentAreas() {
    return this.httpService
      .get(this.httpService.config.loadInvestmentAreas)
      .then(response => {
        this._investmentAreas = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  _loadCurrencies() {
    return this.httpService
      .get(this.httpService.config.loadCurrencies)
      .then(response => {
        this._currencies = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  init() {
    this.buildSelectors();
    this.chkOnlyApproved = this.parentModal.container.find('#chk-only-approved');
    this.badgeRadio = this.parentModal.container.find('#content-badge-select');
    this.radioTabContainer = this.parentModal.container.find('.radio-tab-container');

    this.parentModal.container
      .find('#btn-visualize-report')
      .click(() => this.visualizeBudgetReport());
    this.parentModal.container.find('#btn-cancel').click(() => {
      this.value = false;
      this.parentModal.close();
    });

    let euroCurrency = this._currencies.filter(c => c.simbolo === '€')[0];
    this.radioTabContainer.find('input[id=badge-euro]').val(euroCurrency.idTipoMoneda);

    this.companySelector.onChanges.listen(company => {
      let companyCurrency = this._currencies.filter(c => c.idTipoMoneda === company.idMoneda)[0];

      let sameCurrency = companyCurrency.idTipoMoneda == euroCurrency.idTipoMoneda;
      this.toggleLocalRadioButton(
        companyCurrency.nombreLargo,
        companyCurrency.simbolo,
        companyCurrency.idTipoMoneda,
        !sameCurrency
      );
    });
  }

  buildLocalRadioButton(name, symbol, id) {
    let template = `<label>
      <input id="badge-local" type="radio" name="badge" value="${id}" class="badge" checked />
      <span id="badge-local-txt">${name} (${symbol})</span>
    </label>`;
    let temp = document.createElement('div');
    temp.innerHTML = template;
    this.radioTabContainer.prepend(temp.firstChild);
  }
  toggleLocalRadioButton(name, symbol, id, status) {
    this.parentModal.container
      .find('#badge-local')
      .parent('label')
      .remove();
    this.parentModal.container.find('#badge-euro').prop('checked', false);

    if (status) {
      this.buildLocalRadioButton(name, symbol, id);
    } else {
      this.parentModal.container.find('#badge-euro').prop('checked', true);
    }
  }

  buildSelectors() {
    this.yearSelector = new DropDownComponent(document.getElementById('year-selector'));
    this.yearSelector.setRules({
      required: true
    });
    this.yearSelector.loadData(this._years, 'id', 'label');
    const currentYear = new Date().getFullYear();
    if(this._years.some(x => x.id === currentYear)) {
      this.yearSelector.setSelected(currentYear, 'id', 'label');
    }

    this.companySelector = new DropDownComponent(document.getElementById('company-selector'));
    this.companySelector.setRules({
      required: true
    });
    this.companySelector.loadData(this._companies, 'idEmpresa', 'nombre');

    this.investmentAreaSelector = new DropDownComponent(document.getElementById('investment-area-selector'));
    this.investmentAreaSelector.setRules({
      required: true
    });
    this.investmentAreaSelector.loadData(this._investmentAreas, 'idAreaInversion', 'nombre');

    this.validator = new Validator(this.parentModal.container);
    this.validator.validate();
  }

  // Tiggered when create button is clicked
  visualizeBudgetReport() {
    if (!this.validator.isValid) {
      return;
    }
    this.parentModal.toggleLoadingIcon(true);

    this.httpService
      .get(
        `${this.httpService.config.checkInvestmentReport}?UsuarioSolicitante=${
          this.authenticationService.getAuthenticatedUser().login
        }&IdMoneda=${this.badgeRadio
          .find('input[name=badge]:checked')
          .val()}&AreaTrabajo.IdAreaInversion=${this.investmentAreaSelector.getValue()}&Idioma=${this.authenticationService
          .getAuthenticatedUser()
          .idIdioma.substr(0, 2)}&SoloPresupuestosAprobados=${this.chkOnlyApproved.is(
          ':checked'
        )}&AreaTrabajo.IdEmpresa=${this.companySelector.getValue()}&AreaTrabajo.Ejercicio=${this.yearSelector.getValue()}`
      )
      .then(response => {
        if (response === true) {
          this.httpService
            .downloadFile(
              this.httpService.config.getInvestmentReport +
                '?UsuarioSolicitante=' +
                this.authenticationService.getAuthenticatedUser().login +
                '&IdMoneda=' +
                this.badgeRadio.find('input[name=badge]:checked').val() +
                '&AreaTrabajo.IdAreaInversion=' +
                this.investmentAreaSelector.getValue() +
                '&Idioma=' +
                this.authenticationService.getAuthenticatedUser().idIdioma.substr(0, 2) +
                '&SoloPresupuestosAprobados=' +
                this.chkOnlyApproved.is(':checked') +
                '&AreaTrabajo.IdEmpresa=' +
                this.companySelector.getValue() +
                '&AreaTrabajo.Ejercicio=' +
                this.yearSelector.getValue()
            )
            .then(() => {
              this.parentModal.close();
            })
            .catch(err => {
              this.toastService.exception(err.title);
              console.error(err);
            })
            .finally(() => this.parentModal.toggleLoadingIcon(false));
        } else {
          this.toastService.error(
            TranslationService.instance.translate('exception-title'),
            TranslationService.instance.translate('reports-msg-no-data')
          );
          this.parentModal.toggleLoadingIcon(false);
        }
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
        this.parentModal.toggleLoadingIcon(false);
      });
  }

  get result() {
    return this.value;
  }
}
