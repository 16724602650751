import './configApprovalFlow.scss';
var htmlContent = require('./configApprovalFlow.html');
import { Component } from '../../shared/components/base-component';
import $ from '../../core/jquery/jquery-global';
import { ConfigureFlowApprovalComponent } from '../../components/configure-flow-approval/configure-flow-approval';
import { HttpService } from '../../core/http/http';
import { ToastService } from '../../shared/services/toastService';
import { ViewsLoader } from '../../core/views/views-loader';
import { Spinner } from '../../shared/components/spinner/spinner';
import { TranslationService } from '../../core/translation/translation';
import {
  ConfirmationDeleteOptions,
  ConfirmationDeleteComponent
} from '../../components/confirmation-delete/confirmationDelete';
import { ModalComponent } from '../../shared/components/modal';
import { ListOptions, List } from '../../shared/components/list/list';
import { ApprovalFlowActionsEnum, WorkTypeEnum, InvestmentEnum } from '../../config/enums';
import { AuthenticationService } from '../../shared/services/authenticationService';

const renderMarkup = () => htmlContent;

export class ConfigApprovalFlowPage extends Component {
  constructor() {
    super(renderMarkup());
    $(document).ready(() => {
      let selectNav = document.querySelectorAll('.links-item');
      let i = 0;
      selectNav.forEach(e => {
        if (i == 6) {
          $(e).addClass('active');
          i++;
        } else {
          $(e).removeClass('active');
          i++;
        }
      });
      this.httpService = new HttpService();
      this.toastService = new ToastService();
      this.authenticationService = new AuthenticationService();
      this.viewsLoader = new ViewsLoader();
      this.init();
      this._flowConfig = null;
      this._selectedFlowConfig = null;
    });
  }

  _loadFlowConfig() {
    $('#loader-container').removeClass('hidden');
    this.viewsLoader.loadView(new Spinner('big'), document.getElementById('loader-container'));
    this.httpService
      .get(
        `${this.httpService.config.loadApprovalFlowConfig}?loginUsuario=${
          this.authenticationService.getAuthenticatedUser().login
        }`
      )
      .then(response => {
        $('#loader-container').addClass('hidden');
        this._flowConfig = response;
        this.buildPermissionsConfigTable();
        this.enableButtons();
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }

  init() {
    this._loadFlowConfig();
    this.bindButtons();
  }

  buildPermissionsConfigTable() {
    let options = new ListOptions();
    options.listSelector = '#approval-flows';
    options.searchSelector = '.search-input';
    options.textShowingSelector = '.text-showing-results';
    options.textShowingValue = TranslationService.instance.translate('common-showing-results');
    options.includeFieldsSearch = ['idUsuarioNavigationNombre'];

    options.onRenderRow = function(config) {
      let signCheckHtml = '<span class="circle-state green">';
      let amountMsg = '';
      let planHtml = '';
      let budgetRequest = '';
      if (config.importeMin == 0 && config.importeMax == null) {
        amountMsg = TranslationService.instance.translate('config-flow-amount-msg-all');
      } else if (config.importeMin != 0 && config.importeMax == null) {
        amountMsg = TranslationService.instance
          .translate('config-flow-amount-msg-min-no-max')
          .replace('{minAmount}', config.importeMin);
      } else {
        amountMsg = TranslationService.instance
          .translate('config-flow-amount-msg-min-max')
          .replace('{minAmount}', config.importeMin)
          .replace('{maxAmount}', config.importeMax);
      }
      switch (config.enPlan) {
        case 0:
          planHtml = `<span class="txt">${TranslationService.instance.translate(
            'config-flow-default-action-all'
          )}</span>`;
          break;
        case 1:
          planHtml = `<div class="plan center"><span>${TranslationService.instance.translate(
            'config-flow-default-action-inplan'
          )}</span></div>`;
          break;
        case 2:
          planHtml = `<div class="plan no center"><span>${TranslationService.instance.translate(
            'config-flow-default-action-notinplan'
          )}</span></div>`;
          break;
      }
      if (config.idAccionPresupuesto != null && config.idAccionSolicitud != null) {
        budgetRequest = `<span>${TranslationService.instance.translate(
          'common-all'
        )}</span>`;
      }
      else if(config.idAccionPresupuesto !=  null){
        budgetRequest = `<span>${TranslationService.instance.translate(
          'common-budget'
        )}</span>`;
      } else{
        budgetRequest = `<span>${TranslationService.instance.translate(
          'common-request'
        )}</span>`;
      }
      return `<div class="table-row" data-id="${config.idFlujoAprobacionDefecto}">
        <div class="cell left user">
          <span class="txt">${config.idUsuarioNavigationNombre}</span>
        </div>
        <div class="cell left company">
          <span class="txt">${config.idEmpresaNavigationNombre}</span></div>
        <div class="cell left end-section investmentArea">
          <span class="txt">${config.idAreaInversionNavigationNombre}</span></div>
        <div class="cell center budgetRequest">${budgetRequest}</div>        
        <div class="cell center workType">${
          config.tipologiaObra
            ? TranslationService.instance.translate(
                WorkTypeEnum[config.tipologiaObra]
              )
            : ''
        }</div>
        <div class="cell center investmentType">${
          config.tipoSolicitud
            ? TranslationService.instance.translate(
                InvestmentEnum[config.tipoSolicitud]
              )
            : ''
        }</div>
        <!-- <div class="cell center budget2">
          <span class="txt">${
            config.idResponsablePresupuestoNavigationNombre
              ? config.idResponsablePresupuestoNavigationNombre
              : ''
          }
        </span></div>
        <div class="cell center budget3">${
          config.solicitarFirmaPresupuesto == true ? signCheckHtml : ''
        }</div>
        <div class="cell center end-section budget4">
          <span class="txt">${
            config.idResponsableFirmaPresupuestoNavigationNombre
              ? config.idResponsableFirmaPresupuestoNavigationNombre
              : ''
          }
          </span>
        </div> -->
        <div class="cell center request1">${planHtml}</div>
        <div class="cell center request2">${amountMsg}</div>
        <!-- div class="cell center request3">${
          config.idAccionSolicitud
            ? TranslationService.instance.translate(
                ApprovalFlowActionsEnum[config.idAccionSolicitud]
              )
            : ''
        }</div -->
        <div class="cell center budget1">${
          config.idAccionPresupuesto
            ? TranslationService.instance.translate(
                ApprovalFlowActionsEnum[config.idAccionPresupuesto]
              )
            : ''
        }</div>
        <div class="cell center budget2">
          <span class="txt">${
            config.idResponsablePresupuestoNavigationNombre
              ? config.idResponsablePresupuestoNavigationNombre
              : ''
          }
        </span></div>
        <div class="cell center request3">${
          config.idAccionSolicitud
            ? TranslationService.instance.translate(
                ApprovalFlowActionsEnum[config.idAccionSolicitud]
              )
            : ''
        }</div>
        <div class="cell center request4">
          <span class="txt">${
            config.idResponsableSolicitudNavigationNombre
              ? config.idResponsableSolicitudNavigationNombre
              : ''
          }
          </span>
        </div>
       <!-- <div class="cell center request5">${
          config.solicitarFirmaSolicitud == true ? signCheckHtml : ''
        }</div>
        <div class="cell center request6">
          <span class="txt">${
            config.idResponsableFirmaSolicitudNavigationNombre
              ? config.idResponsableFirmaSolicitudNavigationNombre
              : ''
          }
          </span>
        </div>-->
      </div>`;
    };

    options.onRenderFinish = () => {
      $('#approval-flows')
        .find('.table-row')
        .click(e => this.selectTableRow(e));
    };

    this._list = new List(options);
    this._list.updateData(this._flowConfig);
  }

  selectTableRow(event) {
    $('#approval-flows')
      .find('.table-row')
      .removeClass('selected');
    event.currentTarget.classList.add('selected');
    this._selectedFlowConfig = this._flowConfig.filter(
      fc => fc.idFlujoAprobacionDefecto == parseInt(event.currentTarget.getAttribute('data-id'))
    )[0];
    this.enableButtons();
  }

  bindButtons() {
    $('#btn-create-flow').click(() => this.createFlowModal());
    $('#btn-edit-flow').click(() => this.editFlowModal());
    $('#btn-delete-flow').click(() => this.deleteFlowModal());
  }
  enableButtons() {
    $('#btn-create-flow').attr('disabled', this._flowConfig == null);
    $('#btn-edit-flow').attr('disabled', this._selectedFlowConfig == null);
    $('#btn-delete-flow').attr('disabled', this._selectedFlowConfig == null);
  }

  createFlowModal() {
    var modal = new ModalComponent(
      TranslationService.instance.translate('config-flow-new-modal-title'),
      new ConfigureFlowApprovalComponent()
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._selectedFlowConfig = null;
      this.enableButtons();
      this._loadFlowConfig();
    });
  }

  editFlowModal() {
    var modal = new ModalComponent(
      TranslationService.instance.translate('config-flow-new-modal-title'),
      new ConfigureFlowApprovalComponent(this._selectedFlowConfig)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._selectedFlowConfig = null;
      this.enableButtons();
      this._loadFlowConfig();
    });
  }

  deleteFlowModal() {
    let options = new ConfirmationDeleteOptions();
    options.itemName = `${this._selectedFlowConfig.idUsuarioNavigationNombre} 
    - ${this._selectedFlowConfig.idEmpresaNavigationNombre} - 
    ${this._selectedFlowConfig.idAreaInversionNavigationNombre}`;
    options.actionUrl = `${this.httpService.config.deleteApprovalFlowConfig}?idConfiguracion=${
      this._selectedFlowConfig.idFlujoAprobacionDefecto
    }&loginUsuario=${this.authenticationService.getAuthenticatedUser().login}`;
    let modal = new ModalComponent(
      TranslationService.instance.translate('common-modal-confirmation'),
      new ConfirmationDeleteComponent(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      this._selectedFlowConfig = null;
      this.enableButtons();
      this._loadFlowConfig();
    });
  }
}
