import { ViewsLoader } from '../views/views-loader.js';
import { SideNavPage } from '../../views/side-nav/side-nav';
import { Event } from '../events/event.js';
import { AuthenticationService } from '../../shared/services/authenticationService.js';
import $ from 'jquery';

export class Router {
  constructor(routerConfig, rootElement, sideNavElement) {
    if (Router.inst) {
      return Router.inst;
    }
    Router.inst = this;

    this.authenticationService = new AuthenticationService();
    this.viewsLoader = new ViewsLoader();
    this.routerConfig = routerConfig;
    this.rootElement = rootElement;
    this.sideNavElement = sideNavElement;
    this.isSideNavLoaded = false;
    this.preventDefault = false; // Flag to know if is needed to do something before navigate
    this.cancelNavigation = false; // Flag to know if is needed to cancel navigation when hash changes
    this.userHasChange = new Event();
    this.onBeforeNavigation = new Event(); //Simple event to know when the user has navigated
    this.onNavigation = new Event(); //Simple event to know when the user has navigated

    window.onhashchange = this.hashChanged;
    return this;
  }

  initialize() {
    this.hashChanged();
  }

  openInNewTab(url) {
    var win = window.open(url, '_blank');
    win.focus();
  }

  hashChanged = event => {
    if (this.preventDefault && !this.cancelNavigation) {
      this.userHasChange.execute(event);
    } else if (!this.preventDefault && !this.cancelNavigation) {
      const routeName = window.location.hash.length > 0 ? window.location.hash.substr(1) : '';
      this._loadComponent(routeName, {});
    } else {
      //Will not navigate for this time. Also reset flag
      this.cancelNavigation = false;
    }
  };

  reload(all) {
    if (all) {
      this.sideNavElement.innerHTML = '';
      this.isSideNavLoaded = false;
    }
    const routeName = window.location.hash.length > 0 ? window.location.hash.substr(1) : '';
    this._loadComponent(routeName);
  }

  navigate(routeName, modifyUrl = true, parameters = null) {
    if (modifyUrl) {
      this.cancelNavigation = true;
      window.location.hash = routeName;
    }
    if (parameters == null) {
      parameters = {};
    }
    this._loadComponent(routeName, parameters);
  }
  _loadComponent = (routeName, parameters) => {
    if (!this.authenticationService.isAuthenticationCompleted()) {
      routeName = 'default';
    } else if (routeName == '') {
      routeName = 'home';
    }

    // Clear window resize event always before navigate to new page.
    $(window).off('resize');
    this.onBeforeNavigation.execute(routeName, parameters);

    var routeResolver = this.routerConfig.getRoute(routeName, parameters);

    if (!this.authenticationService.isAuthenticated() && routeResolver.isSecured) {
      this.authenticationService.startAuthentication();
    } else {
      this.noAdminsGuard(routeResolver);
      this.noDevsGuard(routeResolver);
      this.viewsLoader.loadView(routeResolver.view, this.rootElement);
      if (routeResolver.sideNav && !this.isSideNavLoaded) {
        this.viewsLoader.loadView(new SideNavPage(), this.sideNavElement);
        this.isSideNavLoaded = true;
      } else if (!routeResolver.sideNav) {
        this.sideNavElement.innerHTML = '';
        this.isSideNavLoaded = false;
      }

      this.onNavigation.execute(routeName, parameters);
    }
  };

  noAdminsGuard(routeResolver) {
    if (
      routeResolver.onlyAdmins &&
      !this.authenticationService.getAuthenticatedUser().indAdministrador
    ) {
      location.assign('/#home');
    }
  }
  noDevsGuard(routeResolver) {
    if (
      routeResolver.onlyDevs &&
      !this.authenticationService.getAuthenticatedUser().indDesarrollador
    ) {
      location.assign('/#home');
    }
  }
  noImpersonateGuard(routeResolver) {
    if (routeResolver.onlyImpersonate && !this.authenticationService.canImpersonate()) {
      location.assign('/#home');
    }
  }
}
