import './choose-budget.scss';
var htmlContent = require('./choose-budget.html');
import { HttpService } from '../../core/http/http';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { ToastService } from '../../shared/services/toastService';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import { Validator } from '../../core/validator/validator';
import { AuthenticationService } from '../../shared/services/authenticationService';

const renderMarkup = () => htmlContent;

export class ChooseBudgetComponent extends BaseModalContent {
  constructor(options) {
    super(renderMarkup());
    this._options = options || new ChooseBudgetOptions();
    this.toastService = new ToastService();
    this.httpService = new HttpService();
    this.authenticationService = new AuthenticationService();
    // Modal name data-translation: choose-budget-modal-title
  }

  onOpened() {
    this.init();
  }

  onLoading(callback) {
    Promise.all([this._loadYears(), this._loadCompanies()]).then(response => {
      callback();
    });
    return true;
  }

  _loadYears () {
    return this.httpService.get(this.httpService.config.loadBudgetsYears +
      this.authenticationService.getAuthenticatedUser().login
    ).then(response => {
      this._years = response.reverse().map(year => ({id: year, label: year.toString()}));
    }).catch(error => {
      this._toasts.error(error.title, error.message);
      console.error(error);
    });
  }

  _loadCompanies() {
    return this.httpService
      .get(
        this.httpService.config.loadCompaniesPerUser +
          this.authenticationService.getAuthenticatedUser().login
      )
      .then(response => {
        this._companies = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  _loadInvestmentAreas(idEmpresa) {
    return this.httpService
      .get(
        this.httpService.config.loadInvestmentAreasPerUserAndCompany +
          '?loginUsuario=' +
          this.authenticationService.getAuthenticatedUser().login +
          '&idEmpresa=' +
          idEmpresa +
          '&tieneQueSerAdmin=false'
      )
      .then(response => {
        this._investmentAreas = response;
        if (this._investmentAreas && this._investmentAreas.length > 0) {
          this.investmentAreaSelector.loadData(this._investmentAreas, 'idAreaInversion', 'nombre');
          this.investmentAreaSelector.clearSelection();
          this.fillInvestmentArea();
          this.investmentAreaSelector.removeRule('disabled');
        } else {
          this.investmentAreaSelector.setRule('disabled', 'disabled');
        }
        this.parentModal.toggleLoadingIcon(false);
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  init() {
    this.buildSelectors();
    this.setOptionsValues();
    this.parentModal.container.find('#btn-choose-budget').click(() => this.chooseBudget());
    this.parentModal.container.find('#btn-cancel').click(() => {
      this._options = null;
      this.parentModal.close();
    });
  }

  buildSelectors() {
    this.yearSelector = new DropDownComponent(document.getElementById('modal-exercise-selector'));
    this.yearSelector.setRules({
      required: true
    });
    this.yearSelector.loadData(this._years, 'id', 'label');
    this.yearSelector.onChanges.listen(year => (this._options.year = year));

    this.companySelector = new DropDownComponent(document.getElementById('modal-company-selector'));
    this.companySelector.setRules({
      required: true
    });
    this.companySelector.loadData(this._companies, 'idEmpresa', 'nombre');
    this.companySelector.onChanges.listen(company => (this._options.company = company));

    this.investmentAreaSelector = new DropDownComponent(document.getElementById('modal-investment-area-selector'));
    this.investmentAreaSelector.setRules({
      required: true
    });

    this.investmentAreaSelector.setRule('disabled', 'disabled');
    this.investmentAreaSelector.onChanges.listen(
      investmentArea => (this._options.investmentArea = investmentArea)
    );

    this.companySelector.onChanges.listen(company => {
      if (company && company.idEmpresa) {
        this.parentModal.toggleLoadingIcon(true);
        this._loadInvestmentAreas(company.idEmpresa);
      } else {
        this.investmentAreaSelector.clearSelection();
        this.investmentAreaSelector.setRule('disabled', 'disabled');
      }
    });

    this.validator = new Validator(this.parentModal.container);
    this.validator.validate();
  }

  setOptionsValues() {
    if (
      typeof this._options.year !== 'undefined' &&
      this._options.year != null &&
      this._options.year != ''
    ) {
      this.yearSelector.setSelected(this._options.year, 'id', 'label');
    }

    if (
      typeof this._options.company !== 'undefined' &&
      this._options.company != null &&
      this._options.company != ''
    ) {
      this.companySelector.setSelected(this._options.company, 'idEmpresa', 'nombre');
    }

    if (
      typeof this._options.investmentArea !== 'undefined' &&
      this._options.investmentArea != null &&
      this._options.investmentArea != ''
    ) {
      this._investmentAreaToFill = this._options.investmentArea;
    }
  }

  fillInvestmentArea() {
    if (
      typeof this._investmentAreaToFill !== 'undefined' &&
      this.investmentAreaSelector.contains(this._investmentAreaToFill, 'idAreaInversion')
    ) {
      this.investmentAreaSelector.setSelected(
        this._investmentAreaToFill,
        'idAreaInversion',
        'nombre'
      );
      // Removes it because it has already been filled
      this._investmentAreaToFill = undefined;
    }
  }

  // Tiggered when create button is clicked
  chooseBudget() {
    if (!this.validator.isValid) {
      return;
    }

    this.parentModal.close();
  }

  onClosing(forceExit) {
    if (forceExit) {
      this._options = null;
    }
  }

  get result() {
    return this._options;
  }
}

export class ChooseBudgetOptions {
  year = null;
  company = null;
  investmentArea = null;
}
