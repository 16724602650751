import { AuthenticationService } from '../../shared/services/authenticationService'
import { Router } from '../router/router'


export class HttpService {
  constructor(baseUrl) {
    if (HttpService.inst) {
      return HttpService.inst;
    }
    HttpService.inst = this;
    this._router = new Router();
    this._router.onBeforeNavigation.listen(() => this._cancelAllRequests());
    this.authenticationService = new AuthenticationService();
    this._settedBaseUrl = baseUrl;
    this._currentRequests = [];

    return this;
  }

  /**Cancel all the ongoing requests if the user changes pages */
  _cancelAllRequests() {
    for (const xhr of this._currentRequests) {
      xhr.abort();
    }
    this._currentRequests = [];
  }

  _handleResponse(xhr, resolve, reject) {
    xhr.onload = () => {
      if (xhr.status === 200 || xhr.status === 204) {
        if (xhr.responseText.length) {
          resolve(JSON.parse(xhr.responseText));
        } else {
          resolve();
        }
      } else if (xhr.status === 401) {
        reject();
        this.authenticationService.forceLogout();
      } else {
        if (xhr.responseText.length) {
          try {
            reject(JSON.parse(xhr.responseText));
          } catch (e) {
            if (xhr.status === 500 || xhr.status === 503) {
              reject({ title: 'service unavailable' });
            } else if (xhr.status === 403) {
              reject({ title: 'excepcionnotienepermisos' });
            } else {
              reject();
            }
          }
        } else {
          if (xhr.status === 500 || xhr.status === 503) {
            reject({ title: 'service unavailable' });
          } else if (xhr.status === 403) {
            reject({ title: 'excepcionnotienepermisos' });
          } else {
            reject();
          }
        }
      }

      this._currentRequests = this._currentRequests.filter(x => x != xhr);
    };

    xhr.onerror = () => {
      if (xhr.responseText.length) {
        reject(JSON.parse(xhr.responseText));
      } else {
        reject({ title: 'service unavailable' });
      }

      this._currentRequests = this._currentRequests.filter(x => x != xhr);
    };
  }

  _sendRequest(xhr, token, body) {
    xhr.setRequestHeader('Authorization', token);
    const antiForgeryCookieValue = this.getCookieValue('XSRF-REQUEST-TOKEN')
    if(antiForgeryCookieValue) xhr.setRequestHeader('X-XSRF-TOKEN', antiForgeryCookieValue);
    xhr.withCredentials = true;

    if (typeof body !== 'undefined') {
      if (body instanceof FormData) {
        xhr.send(body);
      } else {
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(body));
      }
    } else {
      this._currentRequests.push(xhr);
      xhr.send();
    }
  }

  loadConfigFile(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      this._handleResponse(
        xhr,
        data => {
          resolve(data);
        },
        reject
      );

      xhr.open('GET', url);
      xhr.send();
    });
  }

  loadConfig() {
    return this.loadConfigFile('../../../resources/config/http.config.json').then(response => {
      this.config = response;
    });
  }

  loadMsalConfig() {
    return this.loadConfigFile('../../../resources/config/msal.config.json').then(response =>
      this.authenticationService.init(response)
    );
  }

  get(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      this._handleResponse(xhr, resolve, reject);

      this.authenticationService.obtainToken().then(() => {
        xhr.open('GET', this.config.baseUrl + url);
        this._sendRequest(xhr, this.authenticationService.getToken());
      });
    });
  }

  post(url, body) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      this._handleResponse(xhr, resolve, reject);

      this.authenticationService.obtainToken().then(() => {
        xhr.open('POST', this.config.baseUrl + url);
        this._sendRequest(xhr, this.authenticationService.getToken(), body);
      });
    });
  }

  patch(url, body) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      this._handleResponse(xhr, resolve, reject);

      this.authenticationService.obtainToken().then(() => {
        xhr.open('PATCH', this.config.baseUrl + url);
        this._sendRequest(xhr, this.authenticationService.getToken(), body);
      });
    });
  }

  put(url, body) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      this._handleResponse(xhr, resolve, reject);

      this.authenticationService.obtainToken().then(() => {
        xhr.open('PUT', this.config.baseUrl + url);
        this._sendRequest(xhr, this.authenticationService.getToken(), body);
      });
    });
  }

  del(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      this._handleResponse(xhr, resolve, reject);

      this.authenticationService.obtainToken().then(() => {
        xhr.open('DELETE', this.config.baseUrl + url);
        this._sendRequest(xhr, this.authenticationService.getToken());
      });
    });
  }

  delWithBody(url, body) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      this._handleResponse(xhr, resolve, reject);

      this.authenticationService.obtainToken().then(() => {
        xhr.open('DELETE', this.config.baseUrl + url);
        this._sendRequest(xhr, this.authenticationService.getToken(), body);
      });
    });
  }

  getFile(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = test => {
        if (xhr.status === 200) {
          var blob = new Blob([xhr.response], { type: xhr.getResponseHeader('content-type') });
          resolve(blob);
        } else {
          if (xhr.response.byteLength) {
            reject(JSON.parse(String.fromCharCode.apply(null, new Uint8Array(xhr.response))));
          } else {
            reject();
          }
        }
      };
      this.authenticationService.obtainToken().then(() => {
        xhr.open('GET', this.config.baseUrl + url);
        xhr.responseType = 'arraybuffer';
        this._sendRequest(xhr, this.authenticationService.getToken());
      });
    });
  }

  getCookieValue(cookieName) {
    const allCookies = decodeURIComponent(document.cookie).split("; ");
    for (let i = 0; i < allCookies.length; i++) {
      const cookie = allCookies[i];
      if (cookie.startsWith(cookieName + "=")){
        return cookie.substring(cookieName.length + 1);
      }
    }
    return "";
  }

  async downloadFile(url, fileName = 'document.pdf') {
    await this.getFile(url)
      .then(response => {
        let blobUrl = window.URL.createObjectURL(response);
        var is_chrome_ios = /CriOS\/[\d]+/.test(navigator.userAgent);
        var is_edge_ios = /EdgiOS\/[\d]+/.test(navigator.userAgent);
        if ((window.innerWidth>=768)&&(navigator.platform.includes("Win"))) {
          //Desktop
          window.open(blobUrl);
        } else if (is_chrome_ios || is_edge_ios){
          // En las nuevas versiones funciona el metodo window.open y deja de funcionar el workaround anterior en Edge
          // Chrome IOS or Edge IOS https://stackoverflow.com/questions/24485077/how-to-open-blob-url-on-chrome-ios
          /*
          var reader = new FileReader();
          var out = new Blob([response], {type: 'application/pdf'});         
          reader.onload = function(e){
            window.location.href = reader.result;
          }
          reader.readAsDataURL(out);
          */
         window.open(blobUrl);
        } 
        else {
          //Mobile
          let a = document.createElement('a');
          a.href = blobUrl;
          a.download = fileName;
          a.click();
        }
      })
      .catch(error => {
        throw error;
      });
  }

  static objectToFormData(data) {
    let formData = new FormData();
    let plainDataTree = HttpService.getPlainObject(data);

    for (let key in plainDataTree) {
      if (plainDataTree[key] != null) {
        formData.append(key, plainDataTree[key]);
      }
    }

    return formData;
  }

  static getPlainObject(data, propertyName) {
    let result = {};

    let properties = Object.keys(data).filter(x => x !== null && typeof x !== 'undefined');
    for (let key of properties) {
      let currentPropertyName = propertyName;

      if (!currentPropertyName) {
        currentPropertyName = key;
      } else {
        if (Array.isArray(data)) {
          currentPropertyName += '[' + key + ']';
        } else {
          currentPropertyName += '.' + key;
        }
      }

      if (data[key] === Object(data[key])) {
        result = Object.assign({}, result, this.getPlainObject(data[key], currentPropertyName));
      } else {
        result[currentPropertyName] = data[key];
      }
    }

    return result;
  }
}
