import './requests-query.scss';
var htmlContent = require('./requests-query.html');
import { Component } from '../../shared/components/base-component';
import $ from 'jquery';
import { HttpService } from '../../core/http/http';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import { Spinner } from '../../shared/components/spinner/spinner';
import { ViewsLoader } from '../../core/views/views-loader';
import {
  RequestTypeEnum,
  InvestmentTypeEnum,
  RequestStatusEnum,
  RequestStatusClassesEnum,
  RequestTypeEnumTranslation
} from '../../config/enums';
import { ToastService } from '../../shared/services/toastService';
import { ListOptions, List } from '../../shared/components/list/list';
import { TranslationService } from '../../core/translation/translation';
import { ModalComponent } from '../../shared/components/modal';
import { FilterRequestComponent } from '../../components/filter-request-report/filter-request-report';
import { AuthenticationService } from '../../shared/services/authenticationService';
import { LocalStorageService } from '../../core/localStorage/localStorage';
import { SessionStorageService } from '../../core/sessionStorage/sessionStorage';
import { SessionKeys } from '../../config/keys';
const renderMarkup = () => htmlContent;

export class RequestsQueryPage extends Component {
  constructor() {
    super(renderMarkup());

    this._cleaning = false;
    this.triggeredOnMobile = false;
    $(document).ready(() => {
      // Page logic
      let selectNav = document.querySelectorAll('.links-item');
      let i = 0;
      selectNav.forEach(e => {
        if (i == 3) {
          $(e).addClass('active');
          i++;
        } else {
          $(e).removeClass('active');
          i++;
        }
      });
      this.httpService = new HttpService();
      this.toastService = new ToastService();
      this.viewsLoader = new ViewsLoader();
      this.authenticationService = new AuthenticationService();
      this.sessionStorage = new SessionStorageService();
      this.localStorageService = new LocalStorageService();
      this._init();
    });
  }

  existsInSession(val) {
    return (
      this.sessionStorage.hasItem(val) &&
      this.sessionStorage.getItem(val) !== '' &&
      this.sessionStorage.getItem(val) !== 'undefined'
    );
  }

  _init() {
    this.viewsLoader.loadView(new Spinner('big'), document.getElementById('loader-container'));
    $('#btn-toogle-menu').click(() => this._toggleMenu());
    $('#btn-filters').click(() => this.mobileFilterRequestModal());
    this._buildSelectors().then(() => {
      $('#loader-container').addClass('hidden');
      this._buildPage();
    });
  }

  openClick() {
    this.localStorageService.setItem('lastPageRequest', "reports/requests-query/");
  }


  _buildPage() {
    let options = new ListOptions();
    options.searchSelector = '.search-input';
    options.textShowingSelector = '.text-showing-results';
    options.textShowingValue = TranslationService.instance.translate('common-showing-results');
    options.includeFieldsSearch = [
      'areaTrabajo.empresa',
      'areaTrabajo.areaInversion',
      'areaTrabajo.ejercicio',
      'referencia',
      'nombre',
      'idTipoNavigationNombre',
      'tipoInversion',
      'pendienteDe',
      'estado'
    ];
    if (window.innerWidth >= 767) {
      this.buildTableContent(options);
    } else {
      this.buildCardsContent(options);
      let h = window.innerHeight - 221;

      document.querySelector('.cards-container').style.height = String(h + 'px');
    }
    $(window).resize(() => {
      if (window.innerWidth >= 767) {
        this.buildTableContent(options);
      } else {
        this.buildCardsContent(options);
      }
    });
  }

  buildTableContent(options) {
    options.listSelector = '#petitions-done-table';
    var that = this;
    options.onRenderRow = function (row) {
      return `<div class="table-row">
      <div class="cell center open">
        <a href="#requests/${row.idSolicitud}" onclick="${that.openClick()}" class="icon-abrir" title="${TranslationService.instance.translate('common-table-open')}"></a>
      </div>
      <div class="cell company ">
        ${row.areaTrabajo.empresa}
      </div>
      <div class="cell investmentArea ">
        ${row.areaTrabajo.areaInversion}
      </div>
      <div class="cell margin exercise ">
        ${row.areaTrabajo.ejercicio}
      </div>
      <div class="cell reference ">
        ${row.referencia}
      </div>
      <div class="cell requestName ">
        ${row.nombre}
      </div>
      <div class="cell investmentType ">
      ${row.tipoInversion == null ? '' : row.tipoInversion}
      </div>
      <div class="cell requestType ">
      ${row.idTipo == 1
          ? TranslationService.instance.translate('investment')
          : TranslationService.instance.translate('disinvestment')
        }
      </div>
      <div class="cell pending ">
        ${row.usuarioEncargado == null ? '' : row.usuarioEncargado}
      </div>
      <div class="cell status ">
        <div class="state">
            <i class="bq-circle-state ${RequestStatusEnum[row.idEstado]}"></i>
            <span class="bq-status-text">${row.esRevision
          ? TranslationService.instance.translate('review')
          : TranslationService.instance.translate(RequestStatusEnum[row.idEstado])
        }</span>
        </div>
    </div>`;
    };

    this._list = new List(options);
  }

  buildCardsContent(options) {
    options.listSelector = '#petitions-done-cards';
    var that = this;
    options.onRenderRow = function (row) {
      return `<div class="card">
        <div class="card-row">
          <div class="card-row-element all">
            <span class="title with-link">${row.nombre}</span>
            <a href="/#requests/${row.idSolicitud}" onclick="${that.openClick()}" class="icon-abrir" title="${TranslationService.instance.translate('common-table-open')}"></a>        
          </div>
        </div>
        <div class="card-row">
          <div class="card-row-element half">
            <div class="label">
              ${TranslationService.instance.translate('common-reference')}
            </div>
            <div class="description">
              ${row.referencia}
            </div>
          </div>
          <div class="card-row-element half">
            <div class="label">
              ${TranslationService.instance.translate('common-exercises')}
            </div>
            <div class="description">
              ${row.areaTrabajo.ejercicio}
            </div>
          </div>
        </div>
        <div class="card-row">
          <div class="card-row-element half">
            <div class="label">
              ${TranslationService.instance.translate('common-company')}
            </div>
            <div class="description">
              ${row.areaTrabajo.empresa}
            </div>
          </div>
          <div class="card-row-element half">
            <div class="label">
              ${TranslationService.instance.translate('common-invtm-area')}
            </div>
            <div class="description">
              ${row.areaTrabajo.areaInversion}
            </div>
          </div>
        </div>
        <div class="card-row">
          <div class="card-row-element half">
            <div class="label">
              ${TranslationService.instance.translate('reports-requests-investment-type')}
            </div>
            <div class="description">
              ${row.tipoInversion == null ? '' : row.tipoInversion}
            </div>
          </div>
          <div class="card-row-element half">
            <div class="label">
            ${TranslationService.instance.translate('reports-requests-request-type')}
            </div>
            <div class="description">
              ${TranslationService.instance.translate(RequestTypeEnumTranslation[row.idTipo])}
            </div>
          </div>
        </div>
        <div class="card-row">
          <div class="card-row-element half">
            <div class="label">
              ${TranslationService.instance.translate('common-state')}
            </div>
            <div class="description">
              <span class="circle-state ${RequestStatusClassesEnum[row.idEstado]}"></span>
              <span class="status-text">${TranslationService.instance.translate(
        RequestStatusEnum[row.idEstado]
      )}</span>
            </div>
          </div>
          <div class="card-row-element half">
            <div class="label">
              ${TranslationService.instance.translate('common-pending')}
            </div>
            <div class="description">
              ${row.usuarioEncargado == null ? '' : row.usuarioEncargado}
            </div>
          </div>
        </div>
      </div>
      `;
    };

    this._list = new List(options);
  }

  _buildSelectors() {
    return Promise.all([this._loadYears(), this._loadCompanies(), this._loadInvestmentAreas()]).then(() => {

      //YEARS 

      this.yearSelector = new DropDownComponent(document.getElementById('exercises-selector'));
      this.yearSelector.loadData(this._years, 'id', 'label');

      const year = this.existsInSession(SessionKeys.RequestQueryYear)
        ? parseInt(this.sessionStorage.getItem(SessionKeys.RequestQueryYear))
        : new Date().getFullYear();
      if(this._years.some(x => x.id === year)) {
        this.yearSelector.setSelected(year, 'id', 'label');
      }

      this.yearSelector.onChanges.listen(_ => this._filterTriggered());

      // STATUS

      this.statusSelector = new DropDownComponent(document.getElementById('status-selector'));
      this.statusSelector.loadLocal(
        Object.values(RequestStatusEnum).map(x => TranslationService.instance.translate(x)),
        Object.keys(RequestStatusEnum).map(x => Number(x))
      );

      if (this.existsInSession(SessionKeys.RequestQueryStatus)) {
        this.statusSelector.setSelected(
          parseInt(this.sessionStorage.getItem(SessionKeys.RequestQueryStatus)), 'id', 'label')
      }

      this.statusSelector.onChanges.listen(status => {
        document.getElementById('status-selector').className =
          `status-selector ${status.value ? RequestStatusEnum[status.value] : ''}`;
        this._filterTriggered();
      });

      // REQUEST TYPE

      this.requestTypeSelector = new DropDownComponent(
        document.getElementById('request-type-selector')
      );
      this.requestTypeSelector.loadLocal(
        Object.keys(RequestTypeEnum).map(x => TranslationService.instance.translate(x)),
        Object.values(RequestTypeEnum)
      );

      if (this.existsInSession(SessionKeys.RequestQueryRequestType)) {
        this.requestTypeSelector.setSelected(
          parseInt(this.sessionStorage.getItem(SessionKeys.RequestQueryRequestType)), 'id', 'label')
      }

      this.requestTypeSelector.onChanges.listen(_ => this._filterTriggered());

      // INVESTMENT TYPE

      this.investmentTypeSelector = new DropDownComponent(
        document.getElementById('investment-type-selector')
      );
      this.investmentTypeSelector.loadLocal(
        Object.values(InvestmentTypeEnum).map(x => TranslationService.instance.translate(x)),
        Object.keys(InvestmentTypeEnum).map(x => Number(x))
      );

      if (this.existsInSession(SessionKeys.RequestQueryInvestmentType)) {
        this.investmentTypeSelector.setSelected(
          parseInt(this.sessionStorage.getItem(SessionKeys.RequestQueryInvestmentType)), 'id', 'label')
      }

      this.investmentTypeSelector.onChanges.listen(_ => this._filterTriggered());

      // COMPANY

      this.companySelector = new DropDownComponent(document.getElementById('company-selector'));
      this.companySelector.loadData(this._companies, 'idEmpresa', 'nombre');

      if (this.existsInSession(SessionKeys.RequestQueryCompany)) {
        this.companySelector.setSelected(
          parseInt(this.sessionStorage.getItem(SessionKeys.RequestQueryCompany)), 'idEmpresa', 'nombre')
      }

      this.companySelector.onChanges.listen(_ => this._filterTriggered());

      //INVESTMENT AREA

      this.investmentAreaSelector = new DropDownComponent(
        document.getElementById('investment-area-selector')
      );
      this.investmentAreaSelector.loadData(this._investmentAreas, 'idAreaInversion', 'nombre');

      if (this.existsInSession(SessionKeys.RequestQueryInvestmentArea)) {
        this.investmentAreaSelector.setSelected(
          parseInt(this.sessionStorage.getItem(SessionKeys.RequestQueryInvestmentArea)), 'idAreaInversion', 'nombre')
      }

      this.investmentAreaSelector.onChanges.listen(_ => this._filterTriggered());

      this._filterTriggered();

      $('.reset-filters').click(() => {
        this._cleaning = true;

        this.yearSelector.clearSelection();
        this.investmentAreaSelector.clearSelection();
        this.companySelector.clearSelection();
        this.statusSelector.clearSelection();
        this.requestTypeSelector.clearSelection();
        this.investmentTypeSelector.clearSelection();

        this.sessionStorage.clearData();
      
        this._cleaning = false;
        this._list.updateData([]);
      });
    });
  }

  _selectToUrl(select, urlParam) {
    if (select && select.getValue() !== '') {
      return urlParam + '=' + select.getValue();
    } else {
      return urlParam + '=0';
    }
  }

  _filterTriggered() {
    if (
      this._cleaning ||
      (this.triggeredOnMobile == true && this.allMobileSelectorsBinded == false)
    ) {
      return;
    }
    this.triggeredOnMobile == false;
    let params = [
      this._selectToUrl(this.yearSelector, 'AreaTrabajo.Ejercicio'),
      this._selectToUrl(this.investmentAreaSelector, 'AreaTrabajo.IdAreaInversion'),
      this._selectToUrl(this.companySelector, 'AreaTrabajo.IdEmpresa'),
      this._selectToUrl(this.statusSelector, 'IdEstado'),
      this._selectToUrl(this.requestTypeSelector, 'IdTipo'),
      this._selectToUrl(this.investmentTypeSelector, 'EnPlan')
    ];

    // SET FILTER VALUES

    if (this.yearSelector) {
      this.sessionStorage.setItem(SessionKeys.RequestQueryYear, this.yearSelector.getValue());
    }
    if (this.investmentAreaSelector) {
      this.sessionStorage.setItem(SessionKeys.RequestQueryInvestmentArea, this.investmentAreaSelector.getValue());
    }
    if (this.companySelector) {
      this.sessionStorage.setItem(SessionKeys.RequestQueryCompany, this.companySelector.getValue());
    }
    if (this.statusSelector) {
      this.sessionStorage.setItem(SessionKeys.RequestQueryStatus, this.statusSelector.getValue());
    }
    if (this.requestTypeSelector) {
      this.sessionStorage.setItem(SessionKeys.RequestQueryRequestType, this.requestTypeSelector.getValue());
    }
    if (this.investmentTypeSelector) {
      this.sessionStorage.setItem(SessionKeys.RequestQueryInvestmentType, this.investmentTypeSelector.getValue());
    }

    if (params.filter(x => x.indexOf('=0') == -1).length == 0) {
      return;
    }

    let url =
      this.httpService.config.loadInformeSolicitudes +
      this.authenticationService.getAuthenticatedUser().login +
      '&' +
      params.join('&');

    this.httpService
      .get(url)
      .then(response => {
        response.map(x => {
          let type = x.enPlan ? 1 : x.noEnPlan ? 2 : null;

          x.tipoInversion =
            type == null ? null : TranslationService.instance.translate(InvestmentTypeEnum[type]);
        });
        this._list.updateData(response);
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }

  _loadYears () {
    return this.httpService.get(this.httpService.config.loadBudgetsYears +
      this.authenticationService.getAuthenticatedUser().login
    ).then(response => {
      this._years = response.reverse().map(year => ({id: year, label: year.toString()}));
    }).catch(error => {
      this._toasts.error(error.title, error.message);
      console.error(error);
    });
  }

  _loadCompanies() {
    return this.httpService
      .get(this.httpService.config.loadCompaniesPerUser +
        this.authenticationService.getAuthenticatedUser().login)
      .then(response => {
        this._companies = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  _loadInvestmentAreas() {
    return this.httpService
      .get(this.httpService.config.loadInvestmentAreasPerUser +
        this.authenticationService.getAuthenticatedUser().login)
      .then(response => {
        this._investmentAreas = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  mobileFilterRequestModal() {
    let options = {
      year: this.yearSelector.getValue(),
      company: this.companySelector.getValue(),
      investmentArea: this.investmentAreaSelector.getValue(),
      status: this.statusSelector.getValue(),
      investmentType: this.investmentTypeSelector.getValue(),
      requestType: this.requestTypeSelector.getValue()
    };
    let modal = new ModalComponent(
      TranslationService.instance.translate('reports-filter-requests-modal-title'),
      new FilterRequestComponent(options)
    );
    modal.onClosed.listen(() => {
      this.allMobileSelectorsBinded = false;
      this.triggeredOnMobile = true;
      let res = modal.result;
      if (res.year != '') this.yearSelector.setSelected(res.year, 'id', 'label');
      else this.yearSelector.clearSelection();

      if (res.company != '') this.companySelector.setSelected(res.company, 'idEmpresa', 'nombre');
      else this.companySelector.clearSelection();

      if (res.investmentArea != '')
        this.investmentAreaSelector.setSelected(res.investmentArea, 'idAreaInversion', 'nombre');
      else this.investmentAreaSelector.clearSelection();

      if (res.status != '') this.statusSelector.setSelected(res.status, 'id', 'label');
      else this.statusSelector.clearSelection();

      if (res.requestType != '')
        this.requestTypeSelector.setSelected(res.requestType, 'id', 'label');
      else this.requestTypeSelector.clearSelection();

      this.allMobileSelectorsBinded = true;

      if (res.investmentType != '')
        this.investmentTypeSelector.setSelected(res.investmentType, 'id', 'label');
      else this.investmentTypeSelector.clearSelection();
    });
  }

  _toggleMenu() {
    $('.sidenav').toggleClass('menuComplete');
  }
}
