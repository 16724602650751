import $ from '../../../../core/jquery/jquery-global';
import 'nestable2';

import { Component } from '../../../../shared/components/base-component';
import { Event } from '../../../../core/events/event';
import {
  PetitionStatusClassesEnum,
  UnitsEnums,
  BudgetLinesActionsEnums,
  BudgetLinesTypesEnum
} from '../../../../config/enums';
import { DisbursementDates } from '../../../../components/disbursement-dates/disbursementDates';
import { ModalComponent } from '../../../../shared/components/modal';
import { InputNumeric, InputNumericOptions } from '../../../input-numeric/inputNumeric';
import { ToastService } from '../../../../shared/services/toastService';
import { DatePicker } from '../../../date-picker/datePicker';
import { TranslationService } from '../../../../core/translation/translation';
import { BudgetTreeBaseRenderer } from '../budget-tree-base-renderer';

import './budget-tree-desktop-renderer.scss';
let template = require('./budget-tree-desktop-renderer.html');

export class BudgetTreeDesktopRenderer extends BudgetTreeBaseRenderer {
  /**
   * Get the target type of the renderer
   */
  get targetType() {
    return 'desktop';
  }

  /**
   * Get the selected line id
   */
  get isLineSelected() {
    return this._selectedLine != null;
  }

  /**
   * Get the selected line id
   */
  get selectedLineId() {
    return this._selectedLine == null ? null : this._selectedLine.data('id');
  }

  /**
   * Get the current unit
   */
  get currentUnit() {
    return this._options.unit;
  }

  /**
   * Get the current currency
   */
  get currentCurrency() {
    return this._options.localCurrency;
  }

  /**
   * Get the edited value event listener
   */
  get editedValue() {
    return this._onEditedValue;
  }

  constructor(options) {
    super();

    this._options = options || new BudgetTreeDesktopRendererOptions();

    this._$element = null;
    this._rootItem = null;
    this._selectedLine = null;
    this._lines = null;
    this._startDragOriginalPositionLine = null;
    this._startDragOriginalParentId = null;
    this.init();
    this._onEditedValue = new Event();
    this._onRebuild = new Event();
    this.toastService = new ToastService();
    this.errorPaste = false;
  }

  destroy() {
    let selector = document.querySelector(this._options.selector);
    if (selector != null) selector.innerHTML = '';
  }

  /**
   * Initialize the class
   */
  init() {
    this._lastKeyEvent = null;

    let translatedHtml = TranslationService.instance.translateHtml(template);
    document.querySelector(this._options.selector).innerHTML = translatedHtml.innerHTML;
    this.budgetManager = this._options.budgetManager;

    this.element = document.getElementById('budget-tree');
    this.element.classList.add('dd');
    this._$element = $(this.element);

    // Items loaded from the json to the nestable structure
    let itemsJson = this.budgetManager.getChildrenJson();

    this.options = {
      json: itemsJson,
      maxDepth: this.budgetManager.getMaxDepth() + 2,
      itemRenderer: (item_attrs, content, children, options, item) =>
        this._renderNestedItem(item_attrs, content, children, options, item),
      beforeDragStop: (l, e, p) => this._beforeDragStop(l, e, p),
      onDragStart: (l, e) => this._onDragStart(l, e),
      callback: (l, e) => this._onLineMoved(l, e)
    };

    // Nestable creation
    this._$element.nestable(this.options);
    this._rootItem = this._$element.find('> .dd-list > .dd-item');
    this._refreshLines();
  }

  //COPYPASTE

  pasteExcel() {
    let that = this;
    $('div.cell input').bind('paste', null, function (e) {
      var input = $(this);
      var language = TranslationService.instance._currentLanguage;
      that._lines.find('.nestable-input.decimal').each((i, e) => e);
      $.each(e.originalEvent.clipboardData.items, function (i, v) {
        if (v.type === 'text/plain') {
          v.getAsString(function (text) {
            //text = text.trim('\r\n');
            if(language == "es_es"){
              text = text.replaceAll(".","");
              text = text.replaceAll(",",".");
            }
            console.log("TEXTO:" + text);
            var row = input.closest('div.table-row'),
              x = 0,
              y = input.closest('div.cell').index();

            $(".table-row").get().forEach(function (entry, index, array) {
              if (array[index] == row[0]) {
                x = index;
              }
            });

            var valuesRows = text.split('\r\n');
            console.log(x + ", " + y);
            for (var j = 0; j < valuesRows.length; j++) {
              console.log(valuesRows[j])
              var values = valuesRows[j].split('\t');
              for (var i = 0; i < values.length; i++) {
                if (values[i] != "") {
                  var col = row.find('input').eq(y - 1 + i);
                  row.find('input').eq(y - 1 + i).val(values[i]);
                  col.data()["rawValue"] = values[i];
                  //that._refreshLines();
                  that.onChangeInputPaste(col);
                }
              }
              row = $(".table-row").eq(x + j + 1);
            }
            that._lines.find('.nestable-input.decimal').each((i, e) => InputNumeric.createDecimal(e, true));
            that.errorPaste = false;
          });
        }
      });
    });
  }

  rebuild() {
    let selectedLineId = null;
    if (this._selectedLine != null) {
      selectedLineId = this._selectedLine.data('id');
    }

    this.options.json = this.budgetManager.getChildrenJson();
    this._$element.nestable('destroy');
    this._$element.nestable(this.options);
    this._rootItem = this._$element.find('> .dd-list > .dd-item');
    this._refreshLines();

    if (selectedLineId != null) {
      this._selectedLine = this._$element.find(`.dd-item[data-id="${selectedLineId}"]`);
      this._selectedLine.addClass('selected');
    }

    this._onRebuild.execute();
  }

  /**
   * Switch the unit to be shoun on the fields
   */
  switchUnit(unit) {
    this._options.unit = unit;
    this.rebuild();
  }

  /**
   * Switch the unit to be shoun on the fields
   */
  switchCurrency(isLocalCurrency) {
    this._options.localCurrency = isLocalCurrency;
    this.rebuild();
  }

  /**
   * Clear the current selected item
   */
  clearSelection() {
    this._selectedLine.removeClass('selected');
    this._selectedLine = null;
  }

  /**
   * Refresh the internal array
   */
  _refreshLines() {

    this._lines = this._$element.find('li');

    this._lines.off();
    this._lines.click(e => this._click(e));

    let contents = this._lines.find('.dd-content');
    contents.off();
    contents.click(e => this._click(e));

    let inputs = contents.find('input.nestable-input');
    inputs.off();
    inputs.change(e => this._onChangeInput(e));
    inputs.keydown(e => this._onKeyDownInput(e));

    let calendars = contents.find('.icon-calendar');
    calendars.off();
    calendars.click(e => this._calendarClick(e));

    let acquisitionDates = this._lines.find('.acquisition-date');
    acquisitionDates.each((i, e) => {
      let lineId = parseInt(e.getAttribute('data-id'));
      let line = this.budgetManager.getLine(lineId);
      if (line.getAcquisitionDate() != null) {
        e.value = DatePicker.toLocaleDateString(DatePicker.parseDate(line.getAcquisitionDate()));
      }
      DatePicker.create(e, line.getBudgetAmountsExercise());
    });
    acquisitionDates.change(e => this._onSelectAcquisitionDate(e));
    acquisitionDates.keydown(e => this._onKeyDownInput(e));

    this._lines.find('.nestable-input.decimal').each((i, e) => InputNumeric.createDecimal(e, true));

    $('.disbursementDate.has-errors').removeClass('has-errors');
    var linesWithErrors = this.budgetManager.getLinesWithErrors();

    $.each(linesWithErrors, function (i, e) {
      $('.dd-item[data-id="' + e.id + '"]')
        .find('.disbursementDate')
        .first()
        .addClass('has-errors');
    });

    //Enable tooltips when needed
    if (this._toolTips) {
      this._toolTips.each((i, e) => {
        if (e.hasAttribute('title')) {
          $(e).tooltip('dispose');
        }
      });
      $('.tooltip.show').remove();
    }

    this._toolTips = this._lines.find('[data-toggle="tooltip"]');
    this._toolTips.each((i, e) => {
      if (e.offsetWidth < e.scrollWidth) {
        $(e).tooltip();
      } else {
        e.removeAttribute('title');
      }
    });
    this.pasteExcel();
  }

  /**
   * Event fired after the users click the calendar button
   * @param {Event} event
   */
  _calendarClick(event) {
    let lineId = $(event.target)
      .closest('.dd-item')
      .data('id');
    let line = this.budgetManager.getLine(lineId);

    let modal = new ModalComponent(
      TranslationService.instance.translate('bd-modal-disbur-dates-title'),
      new DisbursementDates(line)
    );
    modal.onClosed.listen(() => {
      this.rebuild();
      if (modal.result != null && modal.result) {
        line.validateAmounts();
        $('.dd-item[data-id="' + line.id + '"]')
          .find('.disbursementDate')
          .first()
          .removeClass('has-errors');
        this._onEditedValue.execute();
      }
    });
  }

  /**
   * Event fired after a user clicks a line
   * @param {Event} e
   */
  _click(e) {
    e.stopPropagation();

    this._lines.removeClass('selected').removeClass('child-selected');

    var line = $(e.currentTarget).parent();
    if (this.selectedLineId == line.data('id') || e.target.tagName.toUpperCase() == 'INPUT') {
      this._selectedLine = null;
    } else if (line.find('.dd-content .table-row').hasClass('disabled')) {
      this._selectedLine = null;;
    } else {
      this._selectedLine = line;
      this._selectedLine.addClass('selected');
      this._selectedLine.parents('.dd-item').addClass('child-selected');
    }

    let saveEnabled = $('#btn-save').attr('disabled') === undefined;
    let lineEnabled = !line
      .find('.dd-content .table-row .t1')
      .first()
      .hasClass('disabled');

      let rowEnabled = !line
      .find('.dd-content .table-row')
      .first()
      .hasClass('disabled');

    if (this._selectedLine && lineEnabled) {
      $('#btn-modal-ask-info').attr('disabled', saveEnabled);
      $('#btn-modal-edit-line').attr('disabled', this.selectedLineId == this.budgetManager.getRootLineId());
      $('#btn-modal-clean-line').attr('disabled', false);
    } else {
      $('#btn-modal-ask-info').attr('disabled', !rowEnabled);
      $('#btn-modal-edit-line').attr('disabled', this._selectedLine === null);
      $('#btn-modal-clean-line').attr('disabled', true);
    }
  }

  /**
   * Event raised when user select an adcquisition date for any line.
   * @param {*} e
   */
  _onSelectAcquisitionDate(e) {
    e.stopPropagation();
    let line = $(e.target).closest('.dd-item');
    let lineId = line.attr('data-id');

    try {
      let line = this.budgetManager.getLine(lineId);
      line.setAcquisitionDate(DatePicker.parseDate(e.target.value).toISOString());
      if (line.getActionId() == -1) line.setActionId(BudgetLinesActionsEnums.Update);
    } catch (error) {
      this.toastService.error('Error editing acquisitionData', error);
      console.error(error);
    } finally {
      this._handleUserTabulation(e);
      this.rebuild();
    }

    this._onEditedValue.execute();
  }

  _onKeyDownInput(e) {
    this._lastKeyEvent = e;
  }

  _handleUserTabulation(e) {
    if (!this._lastKeyEvent) return;
    if (this._lastKeyEvent.keyCode !== 9) return; //Handle only tabs

    let lineId = $(e.target)
      .closest('.dd-item')
      .attr('data-id');
    let selector = $(e.target)
      .closest('.cell')
      .attr('class')
      .split(/\s+/);

    this._onRebuild.once(() => {
      let tabables = this._$element.find("input[tabindex != '-1']:visible:not(:disabled)");
      let currentCell = $('.dd-item[data-id="' + lineId + '"]')
        .find('.' + selector.filter(x => x !== '').join('.'))
        .find('input');

      let index = tabables.index(currentCell);

      let nextElement = tabables.eq(index + (this._lastKeyEvent.shiftKey ? -1 : 1));
      nextElement.focus();
      nextElement.select();

      this._lastKeyEvent = null;
    });
  }

  _onChangeInput(e) {
    e.stopPropagation();

    let lastAmount = isNaN(e.target.defaultValue) ? 0 : e.target.defaultValue;
    let line = $(e.target).closest('.dd-item');
    //let lineId = $(line)[0].attributes[0].value;
    let lineId = line.attr('data-id');

    // Quarter amounts editing...
    if (e.target.id.startsWith('amounts-')) {
      let quarter = e.target.id.slice(-1);
      try {
        let line = this.budgetManager.getLine(lineId);
        let amount = e.target.dataset.rawValue != '' ? parseFloat(e.target.dataset.rawValue) : null;

        if (this._options.isPetition) {
          line.editPetitionAmountQuarter(parseInt(quarter), amount, this._options.userId, lastAmount);
        } else {
          line.editAmountQuarterWithoutRemovingDates(parseInt(quarter), amount, lastAmount);
        }
        if (line.getActionId() == -1) line.setActionId(BudgetLinesActionsEnums.Update);

        e.defaultValue;
      } catch (error) {
        this.toastService.error('Error editing an amount', error);
        console.error(error);
      } finally {
        this._handleUserTabulation(e);

        this._onEditedValue.execute();
        this.rebuild();
      }
    }
  }

  onChangeInputPaste(e) {

    let lastAmount = isNaN(e[0].defaultValue) ? 0 : e[0].defaultValue;
    let line = $(e).closest('.dd-item');
    //let lineId = $(line)[0].attributes[0].value;
    let lineId = line.attr('data-id');

    // Quarter amounts editing...
    if ($(e).attr('id').startsWith('amounts-')) {
      let quarter = $(e).attr('id').slice(-1);
      try {
        let line = this.budgetManager.getLine(lineId);
        let amount = e.data()["rawValue"]
          != '' ? parseFloat(e.data()["rawValue"]) : null;

        if (this._options.isPetition) {
          line.editPetitionAmountQuarter(parseInt(quarter), amount, this._options.userId, lastAmount);
        } else {
          line.editAmountQuarterWithoutRemovingDates(parseInt(quarter), amount, lastAmount);
        }
        if (line.getActionId() == -1) line.setActionId(BudgetLinesActionsEnums.Update);

        e.defaultValue;
      } catch (error) {
        if(!this.errorPaste){
          this.errorPaste = true;
          var errorTitle= TranslationService.instance.translate('exception-title');
          var errorDescription = TranslationService.instance.translate('exception-paste');
          this.toastService.error(errorTitle, errorDescription);
        }
        console.error(error);
      } finally {
        this.handleUserTabulationPaste(e);

        this._onEditedValue.execute();
        this.rebuild();
      }
    }
  }

  handleUserTabulationPaste(e) {
    if (!this._lastKeyEvent) return;
    if (this._lastKeyEvent.keyCode !== 9) return; //Handle only tabs

    let lineId = $(e)
      .closest('.dd-item')
      .attr('data-id');
    let selector = $(e)
      .closest('.cell')
      .attr('class')
      .split(/\s+/);

    this._onRebuild.once(() => {
      let tabables = this._$element.find("input[tabindex != '-1']:visible:not(:disabled)");
      let currentCell = $('.dd-item[data-id="' + lineId + '"]')
        .find('.' + selector.filter(x => x !== '').join('.'))
        .find('input');

      let index = tabables.index(currentCell);

      let nextElement = tabables.eq(index + (this._lastKeyEvent.shiftKey ? -1 : 1));
      nextElement.focus();
      nextElement.select();

      this._lastKeyEvent = null;
    });
  }

  /**
   * Event raised by nestable2 when the user move a line succesfully
   * @param {*} l Main container
   * @param {*} e Element that was moved
   */
  _onLineMoved(l, e) {
    let idLine = e.data('id');
    let idLineParent = e.parents('.dd-item').data('id');
    let newPosition = e.index();

    this.budgetManager.moveBudgetLine(
      idLine,
      idLineParent,
      newPosition,
      this._startDragOriginalPositionLine
    );
    this._onEditedValue.execute();
    this.rebuild();
  }

  /**
   * Event raised by nestable2 when the user start dragging an item
   * @param {*} l Main container
   * @param {*} e Element that was moved
   */
  _onDragStart(l, e) {
    if (this._options.readonly || !this._options.localCurrency) return false;
    //Disable drag for first rootline
    var validLine = !this._rootItem.is(e);

    //Disable drag for groups of the rootline
    this._rootItem
      .children('.dd-list')
      .children()
      .each((index, element) => {
        validLine = validLine && !$(element).is(e);
      });

    if (validLine || (!this._rootItem.is(e) && this._options.isPetition)) {
      this._startDragOriginalPositionLine = e.index();
      this._startDragOriginalParentId = e.parents('.dd-item').data('id');
    }

    return validLine || (!this._rootItem.is(e) && this._options.isPetition);
  }

  /**
   * Event raised by nestable2 when the user stops dragging an item
   * @param {*} l Main container
   * @param {*} e Element that was moved
   * @param {*} p Place where element was moved
   */
  _beforeDragStop(l, e, p) {
    let validPosition = !this._$element.is(p.parent()); //Prevent from moving to root
    validPosition = validPosition && !this._rootItem.is(p.parent()); //Prevent from moving to children of roots
    if (p.closest('.dd-item').length > 0) {
      let node = this.budgetManager.getLine(e.data('id'));
      let parentNode = this.budgetManager.getLine(p.closest('.dd-item').data('id'));
      validPosition =
        validPosition &&
        !(
          (parentNode.hasAmounts() || parentNode.parentsHaveAmounts()) &&
          (node.hasAmounts() || node.childrenHaveAmounts())
        );
    } else {
      validPosition = false;
    }
    //If user moves to a non valid position nestable2 will recreate the elements instead of moving, then we will end without event handlers
    if (!validPosition) {
      setTimeout(() => {
        this._refreshLines();
      }, 1);
    }

    return validPosition;
  }

  /**
   * Function used by nestable2 to draw the items
   * @param {*} item_attrs
   * @param {*} content
   * @param {*} children
   * @param {*} options
   * @param {*} item
   */
  _renderNestedItem(item_attrs, content, children, options, item) {
    var item_attrs_string = $.map(item_attrs, function (value, key) {
      return ' ' + key + '="' + value + '"';
    }).join(' ');

    var html = '<' + options.itemNodeName + item_attrs_string + '>';
    html += '<' + options.contentNodeName + ' class="' + options.contentClass + '">';
    // html += newContent;
    let budgetLine = this.budgetManager.getLine(item.id);
    let lineId = budgetLine.getId();
    let quarters = budgetLine.getAllQuarters(this._options.localCurrency, this._options.unit);
    let previousTotal = budgetLine.getAllPrevious(this._options.localCurrency, this._options.unit);

    var disbursementDatesHtml = null;
    if (budgetLine.hasAmounts()) {
      disbursementDatesHtml = '';
      var budgetLineAmounts = budgetLine.getAmounts().forEach(budgetAmount => {
        disbursementDatesHtml += '<span>' + budgetAmount.getPaymentDateFormatted() + '</span>';
      });
    }

    let disabledInput =
      this._options.readonly ||
        this._options.unit !== UnitsEnums.Unit ||
        !this._options.localCurrency ||
        (!this._options.isPetition && !budgetLine.canStoreBudgetLineAmounts()) ||
        (this._options.isPetition && !budgetLine.canStorePetitionLineAmounts(this._options.userId))
        ? 'disabled'
        : '';
    html += `
    <div class="table-row ${(budgetLine.getRequestedTo() !== this._options.userId &&
        budgetLine.getRequestedToStatus() === 1) ||
        (budgetLine.getRequestedToStatus() === 1 && !this._options.isPetition) ||
        this._options.readonly ||
        this._options.unit !== UnitsEnums.Unit ||
        !this._options.localCurrency
        ? 'disabled'
        : ''
      }"> 
    <div class="cell budget border-right ${Object.keys(budgetLine.getChildrenBudgetLines()).length ? 'title' : ''
      } ${budgetLine.getNodeLevel() != 0 ? 'lvl-' + budgetLine.getNodeLevel() : ''}">
      <div class="wrapper">
      <${options.handleNodeName} class="${options.handleClass}">
      <span class="icon icon-drag"></span>
      </${options.handleNodeName}>
      <span class="description" data-toggle="tooltip" title="${budgetLine.getDescription()}">${budgetLine.getDescription()}</span>
      </div>
    </div>
    <div class="cell totalPreviousYear disabled">
    <input id='amounts-${lineId}-totalPreviousYear' type="text" disabled class="nestable-input decimal" value="${previousTotal.totalPrevious != 0 ? previousTotal.totalPrevious : ''
      }"/>
    </div>
    <div class="cell t1 ${disabledInput}">
    <input id='amounts-${lineId}-q1' type="text" ${disabledInput} class="nestable-input decimal" value="${quarters.q1 != 0 ? quarters.q1 : ''
      }"/>
    </div>
    <div class="cell t2 ${disabledInput}">
      <input id='amounts-${lineId}-q2' type="text" ${disabledInput} class="nestable-input decimal" value="${quarters.q2 != 0 ? quarters.q2 : ''
      }"/>
    </div>
    <div class="cell t3 ${disabledInput}">
    <input id='amounts-${lineId}-q3' type="text" ${disabledInput} class="nestable-input decimal" value="${quarters.q3 != 0 ? quarters.q3 : ''
      }"/>
    </div>
    <div class="cell t4 ${disabledInput}">
    <input id='amounts-${lineId}-q4' type="text" ${disabledInput} class="nestable-input decimal" value="${quarters.q4 != 0 ? quarters.q4 : ''
      }"/>
    </div>
    <div class="cell annual disabled">
    <input id='amounts-${lineId}-annual' type="text" disabled class="nestable-input decimal" value="${quarters.annual != 0 ? quarters.annual : ''
      }"/>
    </div>
    <div class="cell acquisitionDate">
    <input type="text" ${this._options.readonly || !this._options.localCurrency ? 'disabled' : ''
      } class="nestable-input-date acquisition-date" data-id="${budgetLine.getId()}"/>
    </div>  
    <div class="cell disbursementDate">
    <div class="wrapper row">
    ${
      /*!Object.keys(budgetLine.getChildrenBudgetLines()).length &&
      budgetLine.typeId != BudgetLinesTypesEnum.Budget &&*/
      !disabledInput &&
        ((!this._options.isPetition && budgetLine.getRequestedTo() == null) ||
          this._options.isPetition)
        ? this._options.readonly || !this._options.localCurrency
          ? '<span class="icon-calendar disabled"></span>'
          : '<span class="icon-calendar"></span>'
        : ''
      }
              <div class="dates">
              ${disbursementDatesHtml != null ? disbursementDatesHtml : ''}
              </div>
    </div>
    </div>
    <div class="cell requestedTo">
    <div class="wrapper">
    ${budgetLine.getRequestedTo()
        ? '<span class="' +
        PetitionStatusClassesEnum[budgetLine.getRequestedToStatus()] +
        '"></span>'
        : ''
      }
    <span data-toggle="tooltip" class="requested-to-name" title="${budgetLine.getRequestedTo() ? budgetLine.getRequestedTo() : ''
      }">${budgetLine.getRequestedTo() ? budgetLine.getRequestedTo() : ''}</span>
            </div>
    </div>
  </div>`;

    //html += content;
    html += '</' + options.contentNodeName + '>';
    html += children;
    html += '</' + options.itemNodeName + '>';

    return html;
  }
}
export class BudgetTreeDesktopRendererOptions {
  budgetManager = null;
  allowDrag = true;
  unit = UnitsEnums.Unit;
  localCurrency = true;
  readonly = false;
  userId = null;
  isPetition = false;
  selector = null;
  isDirty = false;
}
