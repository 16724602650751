import './createBudget.scss';
var htmlContent = require('./createBudget.html');
import { HttpService } from '../../core/http/http';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { ToastService } from '../../shared/services/toastService';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import { Validator } from '../../core/validator/validator';
import { AuthenticationService } from '../../shared/services/authenticationService';

const renderMarkup = () => htmlContent;

export class CreateBudgetComponent extends BaseModalContent {
  constructor() {
    super(renderMarkup());
    this.toastService = new ToastService();
    this.httpService = new HttpService();
    this.authenticationService = new AuthenticationService();
  }

  onOpened() {
    this.init();
  }

  onLoading(callback) {
    Promise.all([this._loadCompanies(), this._loadCurrencies()]).then(response => {
      callback();
    });
    return true;
  }

  _loadCompanies() {
    return this.httpService
      .get(
        this.httpService.config.loadCompaniesPerUser +
          this.authenticationService.getAuthenticatedUser().login
      )
      .then(response => {
        this._companies = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  _loadInvestmentAreas(idEmpresa) {
    return this.httpService
      .get(
        this.httpService.config.loadInvestmentAreasPerUserAndCompany +
          '?loginUsuario=' +
          this.authenticationService.getAuthenticatedUser().login +
          '&idEmpresa=' +
          idEmpresa +
          '&tieneQueSerAdmin=false'
      )
      .then(response => {
        this._investmentAreas = response;
        if (this._investmentAreas && this._investmentAreas.length > 0) {
          this.investmentAreaSelector.loadData(this._investmentAreas, 'idAreaInversion', 'nombre');
          this.investmentAreaSelector.clearSelection();
          this.investmentAreaSelector.removeRule('disabled');
        } else {
          this.investmentAreaSelector.setRule('disabled', 'disabled');
        }
        this.parentModal.toggleLoadingIcon(false);
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  _loadCurrencies() {
    return this.httpService
      .get(this.httpService.config.loadCurrencies)
      .then(response => {
        this._currencies = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  init() {
    this.buildSelectors();
    this.chkSameStructure = this.parentModal.container.find('#chk-same-structure');
    this.chkTaxes = this.parentModal.container.find('#chk-taxes');
    this.parentModal.container.find('#btn-create-budget').click(() => this.createBudget());
    this.parentModal.container.find('#btn-cancel').click(() => {
      this.parentModal.container.find('.icon-cerrar').click();
    });
  }

  buildSelectors() {
    let years = [];
    for (let year = new Date().getFullYear() + 1; year < new Date().getFullYear() + 26; year++) {
      years.push(year);
    }
    this.yearSelector = new DropDownComponent(document.getElementById('year-selector'), false);
    this.yearSelector.setRules({
      required: true
    });
    this.yearSelector.loadLocal(years);

    this.companySelector = new DropDownComponent(document.getElementById('company-selector'));
    this.companySelector.setRules({
      required: true
    });
    this.companySelector.loadData(this._companies, 'idEmpresa', 'nombre');

    this.investmentAreaSelector = new DropDownComponent(
      document.getElementById('investment-area-selector')
    );
    this.investmentAreaSelector.setRules({
      required: true
    });

    this.investmentAreaSelector.setRule('disabled', 'disabled');

    //Get the currency of euros and add to selector options
    let eurosCurrency = this._currencies.filter(c => c.simbolo === '€')[0];
    let values = [],
      labels = [];
    values.push(eurosCurrency.idTipoMoneda);
    labels.push(eurosCurrency.simbolo + ' - ' + eurosCurrency.nombreLargo);
    this.currencySelector = new DropDownComponent(
      document.getElementById('currency-selector'),
      false
    );
    this.currencySelector.setRules({
      required: true
    });
    this.currencySelector.loadLocal(labels, values);
    //When a company is selected find the currency of the company
    //set as selected and add the option to options.
    this.companySelector.onChanges.listen(company => {
      if (company && company.idMoneda) {
        let companyCurrency = this._currencies.filter(c => c.idTipoMoneda === company.idMoneda)[0];
        values = [eurosCurrency.idTipoMoneda];
        labels = [eurosCurrency.simbolo + ' - ' + eurosCurrency.nombreLargo];
        if (companyCurrency.idTipoMoneda != eurosCurrency.idTipoMoneda) {
          values.push(companyCurrency.idTipoMoneda);
          labels.push(companyCurrency.simbolo + ' - ' + companyCurrency.nombreLargo);
        }
        this.currencySelector.loadLocal(labels, values);
        this.currencySelector.setSelected(companyCurrency.idTipoMoneda, 'id', 'value');
      }

      if (company && company.idEmpresa) {
        this.parentModal.toggleLoadingIcon(true);
        this._loadInvestmentAreas(company.idEmpresa);
      } else {
        this.investmentAreaSelector.clearSelection();
        this.investmentAreaSelector.setRule('disabled', 'disabled');
      }
    });

    this.validator = new Validator(this.parentModal.container);
    this.validator.validate();
  }

  // Tiggered when create button is clicked
  createBudget() {
    if (!this.validator.isValid) {
      return;
    }

    this.parentModal.toggleLoadingIcon(true);

    let body = {
      idMoneda: this.currencySelector.getValue(),
      conImpuestos: this.chkTaxes.is(':checked'),
      areaTrabajo: {
        idEmpresa: this.companySelector.getValue(),
        idAreaInversion: this.investmentAreaSelector.getValue(),
        ejercicio: this.yearSelector.getValue()
      },
      copiaEstructuraAnoAnterior: this.chkSameStructure.is(':checked'),
      usuarioActualizacion: this.authenticationService.getAuthenticatedUser().login
    };

    this.httpService
      .post(this.httpService.config.createNewBudget, body)
      .then(response => {
        this.value = response;
        this.parentModal.close();
      })
      .catch(error => {
        this.value = null;
        this.toastService.exception(error.title);
        console.error(error);
      })
      .finally(() => this.parentModal.toggleLoadingIcon(false));
  }

  get result() {
    return this.value;
  }
}
