export class SessionStorageService {
  constructor() {
    if (SessionStorageService.inst) {
      return SessionStorageService.inst;
    }
    SessionStorageService.inst = this;
    return this;
  }

  getItem(key) {
    return window.sessionStorage.getItem(key);
  }

  hasItem(key) {
    return this.getItem(key) !== null;
  }

  setItem(key, value) {
    window.sessionStorage.setItem(key, value);
  }

  removeItem(key) {
    window.sessionStorage.removeItem(key);
  }

  clearData() {
    window.sessionStorage.clear();
  }
}
