import './concept-mobile.scss';
var htmlContent = require('./concept-mobile.html');
import $ from 'jquery';
import { HttpService } from '../../core/http/http';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { ToastService } from '../../shared/services/toastService';
import { RequestTypeEnum } from '../../config/enums';
import { TranslationService } from '../../core/translation/translation';
import { DatePicker } from '../date-picker/datePicker';
import { InputNumeric } from '../input-numeric/inputNumeric';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import { Validator } from '../../core/validator/validator';

const renderMarkup = () => htmlContent;

export class ConceptMobileComponent extends BaseModalContent {
  constructor(options) {
    super(renderMarkup());
    this._options = options || new ConceptMobileOptions();
    this.toastService = new ToastService();
    this.httpService = new HttpService();
    this.value = null;
  }

  onOpened() {
    this.init();
  }

  init() {
    this._render();
    this.enableButtons();
  }

  _render() {
    this._renderCommonContent();
    if (this._options.requestType == RequestTypeEnum.Investment) {
      this._renderInvestmentConcept();
    } else {
      this._renderDisinvestmentConcept();
    }
  }
  _renderCommonContent() {
    //Set concept order
    $('#content-concept')
      .find('.form-control')
      .val(this._options.data.orden + 1);

    //Set units
    $('#content-units')
      .find('.form-control')
      .val(this._options.data.unidades);
    $('#content-units')
      .find('.form-control')
      .each((i, e) => InputNumeric.createDecimal(e));
    $('#content-units')
      .find('.form-control')
      .change(e => {
        this._options.data.unidades =
          event.target.dataset.rawValue == '' ? null : parseFloat(event.target.dataset.rawValue);
      });

    //Set description
    $('#content-description')
      .find('.form-control')
      .val(this._options.data.descripcion);
    $('#content-description ')
      .find('.form-control')
      .change(e => (this._options.data.descripcion = e.target.value));

    //Set budget item
    let selector = new DropDownComponent(document.getElementById(`budget-item-selector`), false);
    selector.loadData(this._options.budgetItemOptions, 'idLineaPresupuesto', 'ruta');
    selector.onChanges.listen(val => {
      this._options.data.idLineaPresupuesto = val.idLineaPresupuesto;
      this._options.data.partidaPresupuestaria = val.ruta;
    });
    if (this._options.data.idLineaPresupuesto != null) {
      selector.setSelected(this._options.data.idLineaPresupuesto, 'idLineaPresupuesto', 'ruta');
    }
    if (this._options.budgetItemOptions.length == 0) {
      selector.setRule('disabled', 'disabled');
    }
  }
  _renderInvestmentConcept() {
    //Set value
    $('#content-value')
      .find('.form-control')
      .val(this._options.data.conceptoInversion.importeLocal);
    $('#content-value')
      .find('.form-control')
      .each((i, e) => InputNumeric.createDecimal(e));
    $('#content-value')
      .find('.form-control')
      .change(e => {
        this._options.data.conceptoInversion.importeLocal =
          event.target.dataset.rawValue == '' ? null : parseFloat(event.target.dataset.rawValue);
      });

    $('#content-acquisition-date').hide();
    $('#content-contable-value').hide();
    $('#content-disinvestment').hide();
  }
  _renderDisinvestmentConcept() {
    //Set acquisition date
    let date = DatePicker.parseDate(this._options.data.conceptoDesinversion.fechaAdquisicion);
    $('#content-acquisition-date')
      .find('.date')
      .attr('placeholder', TranslationService.instance.translate('common-placeholder-select'));
    if (date instanceof Date && !isNaN(date)) {
      $('#content-acquisition-date')
        .find('.date')
        .each((i, e) => {
          e.value = date.toLocaleDateString('es-ES');
          DatePicker.create(e, date.getFullYear());
        });
    } else {
      $('#content-acquisition-date')
        .find('.date')
        .each((i, e) => {
          DatePicker.create(e);
        });
    }
    $('#content-acquisition-date')
      .find('.date')
      .change(
        e =>
          (this._options.data.conceptoDesinversion.fechaAdquisicion = DatePicker.toIsoString(
            DatePicker.parseDate(e.target.value)
          ))
      );

    //Set value
    $('#content-value')
      .find('.form-control')
      .val(this._options.data.conceptoDesinversion.valorAdquisicionLocal);
    $('#content-value')
      .find('.form-control')
      .each((i, e) => InputNumeric.createDecimal(e));
    $('#content-value')
      .find('.form-control')
      .change(e => {
        this._options.data.conceptoDesinversion.valorAdquisicionLocal =
          event.target.dataset.rawValue == '' ? null : parseFloat(event.target.dataset.rawValue);
      });

    //Set contable value
    $('#content-contable-value')
      .find('.form-control')
      .val(this._options.data.conceptoDesinversion.valorContableLocal);
    $('#content-contable-value')
      .find('.form-control')
      .each((i, e) => InputNumeric.createDecimal(e));
    $('#content-contable-value')
      .find('.form-control')
      .change(e => {
        this._options.data.conceptoDesinversion.valorContableLocal =
          event.target.dataset.rawValue == '' ? null : parseFloat(event.target.dataset.rawValue);
      });

    //Set disinvestment
    $('#content-disinvestment')
      .find('.form-control')
      .val(this._options.data.conceptoDesinversion.desinversionEurosLocal);
    $('#content-disinvestment')
      .find('.form-control')
      .each((i, e) => InputNumeric.createDecimal(e));
    $('#content-disinvestment')
      .find('.form-control')
      .change(e => {
        this._options.data.conceptoDesinversion.desinversionEurosLocal =
          event.target.dataset.rawValue == '' ? null : parseFloat(event.target.dataset.rawValue);
      });
  }

  enableButtons() {
    $('#btn-cancel').click(() => this.close());
    $('#btn-save-concept').click(() => this.save());
    this.validator = new Validator(this.parentModal.container);
    this.validator.validate();
  }

  close() {
    this.parentModal.close();
  }
  save() {
    if (!this.validator.isValid) {
      return;
    }

    this.value = {
      concept: this._options.data
    };
    this.parentModal.close();
  }

  get result() {
    return this.value;
  }
}

export class ConceptMobileOptions {
  data = null;
  requestType = RequestTypeEnum.Investment;
  budgetItemOptions = null;
}
