import { ToastComponent } from '../components/toast/toast';
import { ToastTypes } from '../../config/enums';
import { TranslationService } from '../../core/translation/translation';

export class ToastService {
  constructor() {
    if (ToastService.inst) {
      return ToastService.inst;
    }
    ToastService.inst = this;
    this.toastId = 1;
    return this;
  }

  success(title, message) {
    new ToastComponent(this.toastId, ToastTypes.Success, 'icon-aprobar', title, message);
    this.toastId++;
  }

  error(title, message) {
    new ToastComponent(this.toastId, ToastTypes.Error, 'icon-incidencias', title, message);
    this.toastId++;
  }

  /**
   * Shows the exception translated in the user current language
   * @param {*} exceptionTitle
   */
  exception(exceptionTitle) {
    this.error(
      TranslationService.instance.translate('exception-title'),
      TranslationService.instance.translate('exception-' + exceptionTitle)
    );
  }

  warning(title, message) {
    new ToastComponent(this.toastId, ToastTypes.Warning, 'icon-incidencias', title, message);
    this.toastId++;
  }

  info(title, message) {
    new ToastComponent(this.toastId, ToastTypes.Info, 'icon-peticiones', title, message);
    this.toastId++;
  }
}
