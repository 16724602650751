import './offersReceived.scss';
var htmlContent = require('./offersReceived.html');
import $ from 'jquery';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { HttpService } from '../../core/http/http';
import { Validator } from '../../core/validator/validator';
import { ToastService } from '../../shared/services/toastService';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import { InputNumeric } from '../input-numeric/inputNumeric';
import { TranslationService } from '../../core/translation/translation';
const renderMarkup = () => htmlContent;

export class OffersReceivedComponent extends BaseModalContent {
  constructor(options) {
    super(renderMarkup());
    this._mobileMaxResolution = 768;
    this.value = null;
    this._options = options || new OffersReceivedOptions();
    this._table = null;
    this._selectedLine = null;
    this.screenWidth;
  }

  onCreated() {
    this.init();
  }

  init() {
    this.enableButtons();
    this.validation();
    this.screenWidth = window.innerWidth;
    if (this.screenWidth >= this._mobileMaxResolution) {
      this.buildTableContent(this._options.data, this._options.localCurrency);
    } else {
      this.buildCardsContent(this._options.data, this._options.localCurrency);
    }

    if (this._options.utePercentage == 100) {
      $('.subtitle').hide();
    }

    $(window)
      .off()
      .resize(() => {
        let screenWidth = window.innerWidth;
        if (
          screenWidth >= this._mobileMaxResolution &&
          this.screenWidth < this._mobileMaxResolution
        ) {
          this.screenWidth = window.innerWidth;
          this.buildTableContent(this.getDataFromCards(), this._options.localCurrency);
        } else if (
          screenWidth < this._mobileMaxResolution &&
          this.screenWidth >= this._mobileMaxResolution
        ) {
          this.screenWidth = window.innerWidth;
          this.buildCardsContent(this.getDataFromTable(), this._options.localCurrency);
        }
      });
  }

  buildTableContent(data, localCurrency) {
    this._table = this.parentModal.container.find('.offer-table');
    let rows = this._table.find('.row:not(.header)');
    rows.each((index, element) => {
      let row = $(element);
      row.remove();
    });
    if (data.length > 0) {
      for (const o of data) {
        this.addTableLine(o.nombre, localCurrency == true ? o.valorLocal : o.valor, o.formaPago);
      }
    } else if (!data.readOnly) {
      this.addTableLine();
    }
  }

  buildCardsContent(data, localCurrency) {
    this._cards = this.parentModal.container.find('#cards-container');
    this._cards.html('');
    if (data.length > 0) {
      for (let o of data) {
        let offer = localCurrency == true ? o.valorLocal : o.valor;
        if (offer > 0) this.addCard(o.nombre, offer, o.formaPago);
      }
    }
    this.bindEvents();
  }

  addCard(name, offer, payment) {
    let innerHtml = '';
    innerHtml = innerHtml.concat(`<div class="card">
    <div class="card-row">
      <div class="card-row-element all">
        <span class="title">${name}</span>
        <span class="icon icon-opciones ${this._options.readOnly ? 'hide' : ''}">
          <div id="context-menu" class="context-menu">
          <div class="context-menu-item edit-offer"
          data-name="${name}" data-offer="${offer}" data-payment="${payment}">
              <span class="icon-editar"></span>
              <span class="text">Editar</span>
            </div>
            <div class="context-menu-item delete-offer" 
            data-name="${name}" data-offer="${offer}" data-payment="${payment}">
              <span class="icon-eliminar"></span>
              <span class="text">Eliminar línea</span>
            </div> 
          </div>
        </span>
      </div> 
    </div>
    <div class="card-row">
      <div class="card-row-element half">
        <span class="label">${TranslationService.instance.translate('req-detail-offer')}</span>
        <span id="offer" class="description">${offer}</span>
      </div>
      <div class="card-row-element half">
        <span class="label">${TranslationService.instance.translate(
          'req-detail-offer-payment'
        )}</span>
        <span id="payment" class="description">${payment}</span>
      </div>
    </div>
    </div>`);
    this._cards.append(innerHtml);
  }

  bindEvents() {
    //Floating menu
    this.parentModal.container
      .find('.icon-opciones')
      .off()
      .click(event => {
        this.parentModal.container.find('.context-menu.active').removeClass('active');
        var contextMenuElement = $(event.currentTarget).find('.context-menu')[0];
        contextMenuElement.classList.add('active');
      });
    this.parentModal.container.off().click(event => {
      if (!event.target.classList.contains('icon-opciones')) {
        this.parentModal.container.find('.context-menu.active').removeClass('active');
      }
    });

    this.parentModal.container
      .find('.context-menu-item.edit-offer')
      .off()
      .click(event => this.editOfferMobile(event.currentTarget));
    //Remove offer mobile
    this.parentModal.container
      .find('.context-menu-item.delete-offer')
      .off()
      .click(event => this.deleteOfferMobile(event.currentTarget));
  }

  editOfferMobile(currentTarget) {
    let offer = {
      nombre: currentTarget.getAttribute('data-name'),
      valor: Number(currentTarget.getAttribute('data-offer')),
      valorLocal: Number(currentTarget.getAttribute('data-offer')),
      formaPago: currentTarget.getAttribute('data-payment')
    };
    // call mobile modal
    this.value = {
      action: 'edit-offer',
      offer: offer
    };
    this.parentModal.close();
  }

  deleteOfferMobile(currentTarget) {
    this._options.data = this._options.data.filter(
      e =>
        e.nombre != currentTarget.getAttribute('data-name') &&
        e.offer != Number(currentTarget.getAttribute('data-offer')) &&
        e.formaPago != currentTarget.getAttribute('data-payment')
    );
    this.buildCardsContent(this._options.data, this._options.localCurrency);
  }

  getDataFromTable() {
    let data = [];
    let rows = this._table.find('.row:not(.header)');
    rows.each((index, element) => {
      let row = $(element);
      data.push({
        nombre: row.find('.name').val(),
        valor: Number(row.find('.offer').attr('data-raw-value')),
        valorLocal: Number(row.find('.offer').attr('data-raw-value')),
        formaPago: row.find('.payment').val()
      });
    });
    return data;
  }

  getDataFromCards() {
    let data = [];
    let cards = this._cards.find('.card');
    cards.each((index, element) => {
      let card = $(element);
      data.push({
        nombre: card.find('.title').text(),
        valor: Number(card.find('#offer').text()),
        valorLocal: Number(card.find('#offer').text()),
        formaPago: card.find('#payment').text()
      });
    });
    return data;
  }

  validation() {
    this.validator = new Validator(this.parentModal.container);
    this.validator.onValidationChanges.listen(isValid =>
      this._newOffer.toggleClass('disabled', !isValid)
    );
    this.validator.validate();
  }
  enableButtons() {
    this._cancel = this.parentModal.container.find('.cancel');
    this._save = this.parentModal.container.find('#btn-save-offers');
    this._newOffer = this.parentModal.container.find('.new-offer');
    this._deleteLine = this.parentModal.container.find('.delete-line');

    if (this._options.readOnly) {
      this._cancel.remove();
      this._save.remove();
      this._newOffer.remove();
      this._deleteLine.remove();
    } else {
      this._cancel.click(() => this.cancel());
      this._save.click(() => this.save());
      this._newOffer.click(() => this.newOffer());
      this._deleteLine.click(() => this.removeTableLine());
    }
  }

  newOffer() {
    let screenWidth = window.innerWidth;
    if (screenWidth >= this._mobileMaxResolution) {
      this.addTableLine();
    } else {
      // call mobile modal
      this.value = {
        action: 'edit-offer'
      };
      this.parentModal.close();
    }
  }

  addTableLine(name, offer, payment) {
    let html = `<div class="row">
      <span class="name-container"><input type="text" class="name" required/></span>
      <span class="offer-container"><input type="text" class="offer" required/></span>
      <span class="payment-container"><input type="text" class="payment" required/></span>
    </div>`;

    let temp = $(document.createElement('div'));
    temp.html(html);
    let row = temp.children().first();

    if (this._options.readOnly) {
      temp.find('input').attr('disabled', 'disabled');
    } else {
      row.click(e => this.selectTableLine(e));
    }

    if (name) {
      temp.find('.name').val(name);
    }
    if (offer) {
      temp.find('.offer').val(offer);
    }
    if (payment) {
      temp.find('.payment').val(payment);
    }

    InputNumeric.createDecimal(temp.find('.offer'));
    this._table.append(row);
    this.validator.refreshFields();

    this._table.removeClass('empty');
  }

  selectTableLine(event) {
    if (this._selectedLine && this._selectedLine[0] === event.target) {
      return;
    }
    if (this._selectedLine) {
      this._selectedLine.removeClass('selected');
    } else {
      this._deleteLine.removeClass('disabled');
    }

    this._selectedLine = $(event.target).closest('.row');
    this._selectedLine.addClass('selected');
  }

  removeTableLine() {
    if (!this._selectedLine) {
      return;
    }
    this._selectedLine.remove();
    this._selectedLine = null;
    this._deleteLine.addClass('disabled');

    if (this._table.find('.row:not(.header)').length == 0) {
      this._table.addClass('empty');
    }

    this.validator.refreshFields();
  }

  cancel() {
    this.value = null;
    this.parentModal.close();
  }

  save() {
    if (!this.validator.isValid) {
      return;
    }

    let screenWidth = window.innerWidth;
    this.value = {
      action: 'save',
      offers: {}
    };
    if (screenWidth >= this._mobileMaxResolution) {
      this.value.offers = this.getDataFromTable();
    } else {
      this.value.offers = this.getDataFromCards();
    }
    this.parentModal.close();
  }

  get result() {
    return this.value;
  }
}

export class OffersReceivedOptions {
  readOnly = false;
  localCurrency = true;
  data = null;
}
