import { TranslationService } from '../../../core/translation/translation';

export class List {
  constructor(options) {
    this._options = options || new ListOptions();
    this._init();
  }

  _init() {
    this._list = document.querySelector(this._options.listSelector);
    this._search = document.querySelector(this._options.searchSelector);
    this._textShowing = document.querySelector(this._options.textShowingSelector);
    this._filterableFields = null;

    if (this._search) {
      this._search.setAttribute(
        'placeholder',
        TranslationService.instance.translate('search-placeholder')
      );
      this._search.addEventListener('keyup', () => {
        this.filter(this._search.value);
      });
      this._search.addEventListener('paste', event => {
        this.filter(this._search.value);
      });

      this.filter(this._search.value);
    } else {
      this._filteredText = '';
      this._filteredData = this._options.data;
      this.render();
    }
  }

  updateData(data) {
    this._options.data = data;
    this.filter(this._filteredText);
  }

  _appendItem(item) {
    //Convert to html
    if (typeof item === 'string') {
      let temp = document.createElement('div');
      temp.innerHTML = item;
      item = temp.firstChild;
    }

    this._list.appendChild(item);
  }

  render() {
    this._list.innerHTML = '';

    if (this._filteredData.length == 0 && this._options.onRenderEmpty) {
      let result = this._options.onRenderEmpty();
      this._appendItem(result);
    } else {
      for (const item of this._filteredData) {
        let result = this._options.onRenderRow(item);
        this._appendItem(result);
      }
    }

    if (this._textShowing) {
      this._textShowing.textContent = this._options.textShowingValue
        .replace('{filtered}', this._filteredData.length)
        .replace('{total}', this._options.data.length);
    }

    if (this._options.onRenderFinish != null) this._options.onRenderFinish();
  }

  _getValue(o, expression) {
    let properties = expression.split('.');

    for (const property of properties) {
      if (typeof o[property] === 'undefined' || o[property] == null) {
        return o;
      }

      o = o[property];
    }

    return o;
  }

  _containsText(o, text) {
    let valid = false;

    for (const field of this._filterableFields) {
      let value = this._getValue(o, field);
      if (typeof value !== 'undefined' && value !== null) {
        valid =
          valid ||
          value
            .toString()
            .toLowerCase()
            .indexOf(text) !== -1;
      }
    }

    return valid;
  }

  filter(text) {
    this._filteredText = text.toLowerCase().trim();

    if (
      this._options.data.length <= 0 ||
      this._filteredText.length <= this._options.searchMinLength
    ) {
      this._filteredData = this._options.data;
      this.render();
      return;
    }

    //Generate the fields only once
    if (this._filterableFields == null) {
      this._prepareFilterableFields();
    }

    this._filteredData = [];

    for (const item of this._options.data) {
      if (this._containsText(item, this._filteredText)) {
        this._filteredData.push(item);
      }
    }

    this.render();
  }

  _projectObjectProperties(o) {
    let properties = [];

    for (const property of Object.keys(o)) {
      if (o[property] === Object(o[property])) {
        let childProperties = this._projectObjectProperties(o[property]);
        for (const childProperty of childProperties) {
          properties.push(property + '.' + childProperty);
        }
      } else {
        properties.push(property);
      }
    }

    return properties;
  }
  _prepareFilterableFields() {
    this._filterableFields = this._projectObjectProperties(this._options.data[0]);

    if (this._options.includeFieldsSearch.length > 0) {
      this._filterableFields = this._filterableFields.filter(
        x => this._options.includeFieldsSearch.filter(y => y.startsWith(x)).length > 0
      );
    }

    if (this._options.exlcudeFieldsSearch.length > 0) {
      this._filterableFields = this._filterableFields.filter(
        x => this._options.exlcudeFieldsSearch.filter(y => y.startsWith(x)).length == 0
      );
    }
  }
}
export class ListOptions {
  searchMinLength = 0;
  includeFieldsSearch = [];
  exlcudeFieldsSearch = [];
  listSelector = null;
  searchSelector = null;
  textShowingSelector = null;
  textShowingValue = 'Mostrando {0} de {1} resultados';
  headerRow = null;
  onRenderRow = null;
  onRenderFinish = null;
  onRenderEmpty = null;
  data = [];
}
