import { Component } from './base-component';

/**
 * Base class for the modal's content
 */
export class BaseModalContent extends Component {
  constructor(markup) {
    super(markup);
    this.parentModal = null;
  }

  /**
   * Event fired when modal is create
   */
  onCreating() {}

  /**
   * Event fired when modal is created
   */
  onCreated() {}

  /**
   * Event fired when the modal will load. If the content needs to load something on it's own it will return true and when it's done loading it will call the callback
   * @param {*} callback
   */
  onLoading(callback) {}

  /**
   * Event fired before opening the modal
   */
  onOpening() {}
  /**
   * Event fired after the modal has opened
   */
  onOpened() {}
  /**
   * Event fired before closing the modal
   */
  onClosing() {}
  /**
   * Event fired after the modal has closed
   */
  onClosed() {}
  /**
   * Get the result of the content
   */
  get result() {
    return null;
  }
}
