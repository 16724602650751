import './visualize-aggregated-budgets-report.scss';
import { HttpService } from '../../core/http/http';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { ToastService } from '../../shared/services/toastService';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import { Validator } from '../../core/validator/validator';
import { AuthenticationService } from '../../shared/services/authenticationService';
import { List, ListOptions } from '../../shared/components/list/list';
import { TranslationService } from '../../core/translation/translation';

var htmlContent = require('./visualize-aggregated-budgets-report.html');

const renderMarkup = () => htmlContent;

export class visualizeAggregatedBudgetsReportComponent extends BaseModalContent {
  constructor () {
    super(renderMarkup());
    this.toastService = new ToastService();
    this.httpService = new HttpService();
    this.authenticationService = new AuthenticationService();
    this._toastService = new ToastService();
  }

  onOpened () {
    this.init();
  }

  onLoading (callback) {
    Promise.all([this._loadYears(), this._loadInvestmentAreas()]).then(
      response => {
        callback();
      },
    );
    return true;
  }

  _loadYears () {
    return this.httpService.get(this.httpService.config.loadBudgetsYears + 
      this.authenticationService.getAuthenticatedUser().login
    ).then(response => {
      this._years = response.reverse().map(year => ({id: year, label: year.toString()}));
    }).catch(error => {
      this._toasts.error(error.title, error.message);
      console.error(error);
    });
  }

  _loadInvestmentAreas () {
    return this.httpService.get(this.httpService.config.loadInvestmentAreas).then(response => {
      this._investmentAreas = response;
    }).catch(error => {
      this._toasts.error(error.title, error.message);
      console.error(error);
    });
  }

  buildCompaniesSelector () {
    if (!this.yearSelector.getValue() || !this.investmentAreaSelector.getValue()) return null;

    let login = this.authenticationService.getAuthenticatedUser().login;
    return this.httpService.get(
      this.httpService.config.loadCompaniesPerUserAndInvestmentArea + login +
      '&areaInversion=' + this.investmentAreaSelector.getValue(),
    ).then(companies => {
      this._companies = companies;
      if(!this._companies.length) {
        this.toastService.error(
          TranslationService.instance.translate('exception-title'),
          TranslationService.instance.translate('reports-msg-no-data')
        );
        return;
      }
      
      return this.httpService.get(
        this.httpService.config.loadBudgetsByFilters + login +
        '&IdEstadoAprobacion=' + 3 +
        '&AreaTrabajo.IdAreaInversion=' + this.investmentAreaSelector.getValue() +
        '&AreaTrabajo.Ejercicio=' + this.yearSelector.getValue(),
      );
    }).then(budgets => {

      let label = this.parentModal.container.find('#companies-label');
      if(!this._companies.length) {
        label.addClass('d-none');
      } else {
        label.removeClass('d-none');
      }

      let options = new ListOptions();
      options.listSelector = '#companies-selector';
      options.onRenderRow = function (row) {
        return `<div class="col-4">
                  <label class="checkbox-container">
                    <input type="checkbox" class="companies-selector-option" checked value="${row.idEmpresa}" />
                    <div class="checkbox">
                      <span class="icon-aprobar"></span>
                    </div>
                    <span class="txt ${budgets.find(budget => budget.empresa === row.nombre) ? 'font-weight-bold' : ''}">${row.nombre}</span>
                  </label>
              </div>`;
      };
      this.companiesSelector = new List(options);
      this.companiesSelector.updateData(this._companies);
    }).catch(error => {
      this._toasts.error(error.title, error.message);
      console.error(error);
    });
  }

  init () {
    this.buildSelectors();
    this.chkOnlyApproved = this.parentModal.container.find('#chk-only-approved');
    this.badgeRadio = this.parentModal.container.find('#content-badge-select');
    this.radioTabContainer = this.parentModal.container.find('.radio-tab-container');

    this.parentModal.container.find('#btn-visualize-report').click(() => this.visualizeAggregatedBudgetsReport());
    this.parentModal.container.find('#btn-cancel').click(() => {
      this.value = false;
      this.parentModal.close();
    });
  }

  buildSelectors () {
    this.yearSelector = new DropDownComponent(document.getElementById('year-selector'));
    this.yearSelector.setRules({
      required: true,
    });
    this.yearSelector.loadData(this._years, 'id', 'label');
    const currentYear = new Date().getFullYear();
    if(this._years.some(x => x.id === currentYear)) {
      this.yearSelector.setSelected(currentYear, 'id', 'label');
    }
    this.yearSelector.onChanges.listen(
      _ => this.buildCompaniesSelector(),
    );

    this.investmentAreaSelector = new DropDownComponent(document.getElementById('investment-area-selector'));
    this.investmentAreaSelector.setRules({
      required: true,
    });
    this.investmentAreaSelector.loadData(this._investmentAreas, 'idAreaInversion', 'nombre');
    this.investmentAreaSelector.onChanges.listen(
      _ => this.buildCompaniesSelector(),
    );

    this.validator = new Validator(this.parentModal.container);
    this.validator.validate();
  }

  visualizeAggregatedBudgetsReport () {
    if (!this.validator.isValid) {
      return;
    }

    if(!this._companies.length) {
      this.toastService.error(
        TranslationService.instance.translate('exception-title'),
        TranslationService.instance.translate('reports-msg-no-data')
      );
      return;
    }

    let selectedCompanies = this.parentModal.container.find('.companies-selector-option:checked').map((i, e) => e.value).get();
    if(!selectedCompanies.length){
      this._toastService.error(
        TranslationService.instance.translate('exception-title'),
        TranslationService.instance.translate('common-error-select-one'),
      );
      return;
    }

    this.parentModal.toggleLoadingIcon(true);

    this.httpService.downloadFile(
      this.httpService.config.getAggregatedBudgetsReport +
      '?UsuarioSolicitante=' + this.authenticationService.getAuthenticatedUser().login +
      '&Idioma=' + this.authenticationService.getAuthenticatedUser().idIdioma.substr(0, 2) +
      '&AreaTrabajo.Ejercicio=' + this.yearSelector.getValue() +
      '&AreaTrabajo.IdAreaInversion=' + this.investmentAreaSelector.getValue() +
      '&IdsEmpresas=' + selectedCompanies.join('&IdsEmpresas=')
    ).then(() => {
      this.parentModal.close();
    }).catch(err => {
      this.toastService.exception(err.title);
      console.error(err);
    }).finally(() => this.parentModal.toggleLoadingIcon(false));
  }

  get result () {
    return this.value;
  }
}
