import { Rule } from '../rule';

export class MaxLengthRule extends Rule {
  get name() {
    return 'max-length';
  }

  getErrorMessage(element, lang) {
    return `El campo debe tener una longitud inferior a ${element.attr('max-length')}`;
  }

  isValid(element) {
    let value = element.val() ? element.val().length : 0;
    return value <= parseInt(element.attr('max-length'));
  }
}
