import './filter-request-report.scss';
var htmlContent = require('./filter-request-report.html');
import { HttpService } from '../../core/http/http';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { ToastService } from '../../shared/services/toastService';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import { RequestStatusEnum, InvestmentTypeEnum, RequestTypeEnum } from '../../config/enums';
import { TranslationService } from '../../core/translation/translation';
import { AuthenticationService } from '../../shared/services/authenticationService';

const renderMarkup = () => htmlContent;

export class FilterRequestComponent extends BaseModalContent {
  constructor(options) {
    super(renderMarkup());
    this._options = options || new FilterRequestOptions();
    this.toastService = new ToastService();
    this.httpService = new HttpService();
    this.authenticationService = new AuthenticationService();
  }

  onOpened() {
    this.init();
  }

  onLoading(callback) {
    Promise.all([this._loadYears(), this._loadCompanies(), this._loadInvestmentAreas()]).then(response => {
      callback();
    });
    return true;
  }

  _loadYears () {
    return this.httpService.get(this.httpService.config.loadBudgetsYears +
      this.authenticationService.getAuthenticatedUser().login
    ).then(response => {
      this._years = response.reverse().map(year => ({id: year, label: year.toString()}));
    }).catch(error => {
      this._toasts.error(error.title, error.message);
      console.error(error);
    });
  }

  _loadCompanies() {
    return this.httpService
      .get(this.httpService.config.loadCompanies)
      .then(response => {
        this._companies = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  _loadInvestmentAreas() {
    return this.httpService
      .get(this.httpService.config.loadInvestmentAreas)
      .then(response => {
        this._investmentAreas = response;
      })
      .catch(error => {
        this._toasts.error(error.title, error.message);
        console.error(error);
      });
  }

  init() {
    this.buildSelectors();
    this.setOptionsValues();
    this.parentModal.container.find('#btn-filter-request').click(() => this.filterRequest());
    this.parentModal.container.find('.reset-filters').click(() => {
      this.value = {};
      this.yearSelector.clearSelection();
      this.companySelector.clearSelection();
      this.investmentAreaSelector.clearSelection();
      this.statusSelector.clearSelection();
      this.investmentTypeSelector.clearSelection();
      this.requestTypeSelector.clearSelection();
    });
    this.parentModal.container.find('#btn-cancel').click(() => {
      // this._options = false;
      this.parentModal.close();
    });
  }

  buildSelectors() {
    this.yearSelector = new DropDownComponent(document.getElementById('modal-exercise-selector'));
    this.yearSelector.loadData(this._years, 'id', 'label');

    this.companySelector = new DropDownComponent(document.getElementById('modal-company-selector'));
    this.companySelector.loadData(this._companies, 'idEmpresa', 'nombre');

    this.investmentAreaSelector = new DropDownComponent(
      document.getElementById('modal-investment-area-selector')
    );
    this.investmentAreaSelector.loadData(this._investmentAreas, 'idAreaInversion', 'nombre');

    this.statusSelector = new DropDownComponent(document.getElementById('modal-status-selector'));
    this.statusSelector.loadLocal(
      Object.values(RequestStatusEnum).map(x => TranslationService.instance.translate(x)),
      Object.keys(RequestStatusEnum).map(x => Number(x))
    );

    this.investmentTypeSelector = new DropDownComponent(
      document.getElementById('modal-investment-type-selector')
    );
    this.investmentTypeSelector.loadLocal(
      Object.values(InvestmentTypeEnum).map(x => TranslationService.instance.translate(x)),
      Object.keys(InvestmentTypeEnum).map(x => Number(x))
    );

    this.requestTypeSelector = new DropDownComponent(
      document.getElementById('modal-request-type-selector')
    );
    this.requestTypeSelector.loadLocal(
      Object.keys(RequestTypeEnum).map(x => TranslationService.instance.translate(x)),
      Object.values(RequestTypeEnum).map(x => x)
    );
  }

  setOptionsValues() {
    if (
      typeof this._options.year !== 'undefined' &&
      this._options.year != null &&
      this._options.year != ''
    ) {
      this.yearSelector.setSelected(this._options.year, 'id', 'label');
    }

    if (
      typeof this._options.company !== 'undefined' &&
      this._options.company != null &&
      this._options.company != ''
    ) {
      this.companySelector.setSelected(this._options.company, 'idEmpresa', 'nombre');
    }

    if (
      typeof this._options.investmentArea !== 'undefined' &&
      this._options.investmentArea != null &&
      this._options.investmentArea != ''
    ) {
      this.investmentAreaSelector.setSelected(
        this._options.investmentArea,
        'idAreaInversion',
        'nombre'
      );
    }

    if (
      typeof this._options.status !== 'undefined' &&
      this._options.status != null &&
      this._options.status != ''
    ) {
      this.statusSelector.setSelected(this._options.status, 'id', 'label');
    }

    if (
      typeof this._options.investmentType !== 'undefined' &&
      this._options.investmentType != null &&
      this._options.investmentType != ''
    ) {
      this.investmentTypeSelector.setSelected(this._options.investmentType, 'id', 'label');
    }

    if (
      typeof this._options.requestType !== 'undefined' &&
      this._options.requestType != null &&
      this._options.requestType != ''
    ) {
      this.requestTypeSelector.setSelected(this._options.requestType, 'id', 'label');
    }
  }

  // Tiggered when create button is clicked
  filterRequest() {
    this._options = {
      year: this.yearSelector.getValue(),
      company: this.companySelector.getValue(),
      investmentArea: this.investmentAreaSelector.getValue(),
      status: this.statusSelector.getValue(),
      investmentType: this.investmentTypeSelector.getValue(),
      requestType: this.requestTypeSelector.getValue()
    };
    this.parentModal.close();
  }

  get result() {
    return this._options;
  }
}

export class FilterRequestOptions {
  year = '';
  company = '';
  investmentArea = '';
  status = '';
  investmentType = '';
  requestType = '';
}
