import './home.scss';
var htmlContent = require('./home.html');
import { Component } from '../../shared/components/base-component';
import $ from 'jquery';

const renderMarkup = () => htmlContent;

export class HomePage extends Component {
  constructor() {
    super(renderMarkup());
    $(document).ready(() => {
      $('#btn-toogle-menu').click(() => this._toggleMenu());
    });
  }

  _toggleMenu() {
    $('.sidenav').toggleClass('menuComplete');
  }
}
