import './budget-edit-reorder-line-mobile.scss';
var htmlContent = require('./budget-edit-reorder-line-mobile.html');
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { DatePicker } from '../date-picker/datePicker';
import { TranslationService } from '../../core/translation/translation';

const renderMarkup = () => htmlContent;

export class BudgetEditReorderLineMobile extends BaseModalContent {
  constructor(options) {
    super(renderMarkup());
    this._options = options;
  }

  onOpened() {
    this.init();
  }

  init() {
    this._parentLine = this._options.budgetManager.getLine(this._options.budgetLine);
    this._title = this.parentModal.container.find('.title');
    this._title.text(this.getTitle());
    this._initialOrder = this.getBudgetLinesOrder();
    this._updatedOrder = this._initialOrder.slice();
    this.parentModal.container.find('#btn-save-budget').click(() => this.save());
    this.parentModal.container.find('#btn-cancel').click(() => {
      this._value = null;
      this.parentModal.close();
    });
    this.buildCardsContent();
  }

  getTitle() {
    let title = this._parentLine.getDescription();
    let aLine = this._parentLine.getParentNode();
    while (typeof aLine !== 'undefined' && aLine != null) {
      title = aLine.getDescription() + ' / ' + title;
      aLine = aLine.getParentNode();
    }
    return title;
  }

  getBudgetLinesOrder() {
    let childrenLines = this._parentLine.getChildrenBudgetLines();
    let linesWithOrder = new Array();
    for (let key in childrenLines) {
      linesWithOrder[key] = childrenLines[key].getOrder();
    }
    return linesWithOrder;
  }

  buildCardsContent() {
    let data = this.getOrderedDataFromIds(this._updatedOrder);
    this._cards = this.parentModal.container.find('.cards-container');
    this._cards.html('');
    let it = 0;
    if (data.length > 0) {
      for (let d of data) {
        this.addCard(
          d.id,
          d.description,
          d.annual,
          d.acquisitionDate,
          it == 0,
          it == data.length - 1
        );
        it++;
      }
    }
  }

  getOrderedDataFromIds(lines) {
    let orderAsKey = [];
    for (let key in lines) {
      orderAsKey[lines[key]] = key;
    }

    let ordered = {};
    Object.keys(orderAsKey)
      .sort()
      .forEach(function(key) {
        ordered[key] = orderAsKey[key];
      });

    let data = [];
    for (let key in ordered) {
      let budgetLine = this._options.budgetManager.getLine(ordered[key]);
      let infoLine = {
        id: ordered[key],
        description: budgetLine.getDescription(),
        annual: budgetLine.getAnnualAmounts(this._options.localCurrency),
        acquisitionDate:
          budgetLine.getAcquisitionDate() != null
            ? DatePicker.parseDate(budgetLine.getAcquisitionDate()).toLocaleDateString('es-ES')
            : '-'
      };
      data.push(infoLine);
    }
    return data;
  }

  addCard(id, description, annual, acquisitionDate, isFirst, isLast) {
    let innerHtml = '';
    innerHtml = innerHtml.concat(`<div class="card">
    <div class="card-row">
      <div class="card-row-element all">
        <span class="title">${description}</span>
        <span id="up-${id}" class="icon icon-chevron 
          ${isFirst ? 'disabled' : ''}"></span>
        <span id="down-${id}" class="icon icon-chevron down 
          ${isLast ? 'disabled' : ''}"></span>
      </div>
    </div>
    <div class="card-row">
      <div class="card-row-element half">
        <span class="label">${TranslationService.instance.translate('bd-table-annual')}</span>
        <span id="offer" class="description">${annual}</span>
      </div>
      <div class="card-row-element half">
        <span class="label">${TranslationService.instance.translate(
          'bd-modal-edit-acquisition-date'
        )}</span>
        <span id="payment" class="description">${acquisitionDate}</span>
      </div>
    </div>
    </div>`);

    this._cards.append(innerHtml);
    if (!isFirst)
      this._cards
        .find('#up-' + id)
        .off()
        .click(() => this.moveUp(id));
    if (!isLast)
      this._cards
        .find('#down-' + id)
        .off()
        .click(() => this.moveDown(id));
  }

  moveUp(idLine) {
    let oldOrder = this._updatedOrder[idLine];
    if (oldOrder > 0) {
      let newOrder = oldOrder - 1;
      this._move(this._updatedOrder, newOrder, oldOrder);
      this.buildCardsContent();
    }
  }

  moveDown(idLine) {
    let oldOrder = this._updatedOrder[idLine];
    if (oldOrder < this._updatedOrder.length - 1) {
      let newOrder = oldOrder + 1;
      this._move(this._updatedOrder, newOrder, oldOrder);
      this.buildCardsContent();
    }
  }

  _move(lines, newOrder, oldOrder) {
    if (newOrder != oldOrder) {
      for (let key in lines) {
        if (newOrder > oldOrder && lines[key] > oldOrder && lines[key] <= newOrder) {
          lines[key] -= 1;
        } else if (newOrder < oldOrder && lines[key] < oldOrder && lines[key] >= newOrder) {
          lines[key] += 1;
        } else if (lines[key] == oldOrder) {
          lines[key] = newOrder;
        }
      }
    }
  }

  save() {
    // [temp] tracks the changes while moving
    let temp = this._initialOrder;
    for (let key in this._updatedOrder) {
      if (this._updatedOrder[key] != temp[key]) {
        this._options.budgetManager.moveBudgetLine(
          key,
          this._parentLine.getId(),
          this._updatedOrder[key],
          temp[key]
        );
        this._move(temp, this._updatedOrder[key], temp[key]);
      }
    }
    this._value = true;
    this.parentModal.close();
  }

  get result() {
    return this._value;
  }
}

export class BudgetEditReorderLineMobileOptions {
  budgetManager = null;
  budgetLine = null;
  unit = null;
  localCurrency = null;
}
