import './request-detail-editor.scss';
var htmlContent = require('./request-detail-editor.html');
import $ from '../../core/jquery/jquery-global';
import { Component } from '../../shared/components/base-component';
import { ViewsLoader } from '../../core/views/views-loader';
import { HttpService } from '../../core/http/http';
import { ModalComponent } from '../../shared/components/modal';
import { ToastService } from '../../shared/services/toastService';
import { BudgetStatusEnum, BudgetStatusClassesEnum, RequestTypeEnum } from '../../config/enums';
import { DatePicker } from '../../components/date-picker/datePicker';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import {
  AdditionalCostsComponent,
  AdditionalCostsOptions
} from '../../components/additional-costs/additionalCosts';
import { InputNumeric } from '../../components/input-numeric/inputNumeric';
import { CashFlowComponent, CashFlowOptions } from '../../components/cash-flow/cashFlow';
import {
  GroupSelectorComponent,
  GroupSelectorOptions,
  GroupLine
} from '../../components/group-selector/group-selector';
import { CreateGroupComponent } from '../../components/create-group/createGroup';
import {
  OffersReceivedOptions,
  OffersReceivedComponent
} from '../../components/offers-received/offersReceived';
import { Validator } from '../../core/validator/validator';
import { Router } from '../../core/router/router';
import { Spinner } from '../../shared/components/spinner/spinner';
import {
  InputFileComponent,
  InputFileOptions
} from '../../shared/components/input-file/input-file';
import { TranslationService } from '../../core/translation/translation';
import { QuitWithoutSaveComponent } from '../../components/quit-without-save/quitWithoutSave';
import {
  ConceptMobileOptions,
  ConceptMobileComponent
} from '../../components/concept-mobile/concept-mobile';
import {
  ConceptsMobileOptions,
  ConceptsMobileComponent
} from '../../components/concepts-mobile/concepts-mobile';
import { EditOfferComponent } from '../../components/edit-offer/edit-offer';
import { AuthenticationService } from '../../shared/services/authenticationService';

const renderMarkup = () => htmlContent;

export class RequestDetailEditorPage extends Component {
  constructor(params) {
    super(renderMarkup());
    $(document).ready(() => {
      let selectNav = document.querySelectorAll('.links-item');
      let i = 0;
      selectNav.forEach(e => {
        if (i == 2) {
          $(e).addClass('active');
          i++;
        } else {
          $(e).removeClass('active');
          i++;
        }
      });
      this.httpService = new HttpService();
      this.toastService = new ToastService();
      this.authenticationService = new AuthenticationService();
      this.viewsLoader = new ViewsLoader();
      this._budgetItemsAssociated = [];
      this._budgetJobItem = [];
      this.validator = null;
      this.router = new Router();
      this.workUteSelector = null;
      this._budgetResponse = null;

      this.viewsLoader.loadView(new Spinner('big'), document.getElementById('loader-container'));
      $('#loader-container').removeClass('hidden');

      if (typeof params.id !== 'undefined' && typeof params.data !== 'undefined') {
        // both defined
        this._requestExists = true;
        this._request = params.data;
        this.init();
      } else if (typeof params.id === 'undefined' && typeof params.data !== 'undefined') {
        // only data
        this._requestExists = false;
        this._request = params.data;
        this.addNewConcept();
        Promise.all([this._loadBudget(this._request.idPresupuesto)]).then(response => {
          this.init();
        });
      } else if (typeof params.id !== 'undefined' && typeof params.data === 'undefined') {
        // only id
        this._requestExists = true;
        Promise.all([this._loadRequest(params.id)]).then(response => {
          this.init();
        });
      } else {
        // both undefined
        console.error('Error: id and data can not be undefined');
      }
    });
  }

  init() {
    Promise.all([
      this._loadCurrencies(),
      this._loadWorks(
        this._request.idPresupuesto,
        this.authenticationService.getAuthenticatedUser().login
      )
    ]).then(() => {
      this.router.preventDefault = true;
      this.router.userHasChange.listen(event => this.confirmQuitWithoutSave(event));
      //Destroy listener before leave the component if not is listening multiple times
      this.router.onBeforeNavigation.listen(event => {
        this.router.userHasChange.destroy();
      });
      $(window).on('beforeunload', () => {
        return TranslationService.instance.translate('msgLeavePageWithChages');
      });
      $('#loader-container').addClass('hidden');

      this._additionalCostsDecimal = InputNumeric.createDecimal(
        $('#content-additional-costs .form-control')
      );
      this._totalAmountDecimal = InputNumeric.createDecimal(
        $('#content-total-amount .form-control')
      );

      this.buildPage();
    });
  }

  _loadRequest(requestId) {
    return this.httpService
      .get(
        `${this.httpService.config.loadRequestDetails}/${requestId}/true/${this.authenticationService.getAuthenticatedUser().login
        }`
      )
      .then(response => {
        this._request = response;
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }
  _loadCurrencies() {
    return this.httpService
      .get(this.httpService.config.loadCurrencies)
      .then(response => {
        this._currencies = response;
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }
  _loadWorks(budgetId, userId) {
    return this.httpService
      .get(`${this.httpService.config.loadBudgetWorks}/${budgetId}/${userId}`)
      .then(response => {
        this._works = response;
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }
  _loadBudgetItemsAssociated() {
    return this.httpService
      .get(
        `${this.httpService.config.loadBudgetItemsAsociated}/${this._request.detalleSolicitud.idGrupo
        }/${this.authenticationService.getAuthenticatedUser().login}`
      )
      .then(response => {
        this._budgetItemsAssociated = response;
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }
  _loadBudgetJob() {
    return this.httpService
      .get(
        `${this.httpService.config.loadBudgetJobItem}/${this._request.detalleSolicitud.idGrupo}/${this._request.idTipo
        }/${this.authenticationService.getAuthenticatedUser().login}`
      )
      .then(response => {
        if (typeof response !== 'undefined') {
          this._budgetJobItem = this.importJobJson(response);
        }
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }
  _loadBudget(budgetId) {
    return this.httpService
      .get(
        `${this.httpService.config.loadBudgetDetails}/${budgetId}/${this.authenticationService.getAuthenticatedUser().login
        }`
      )
      .then(response => {
        this._budgetResponse = response;
        this._request.estructuradoEnUte = this._budgetResponse.areaTrabajo.estructuradoEnUte;
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
        location.assign('/#budgets');
      });
  }

  getStatusDescription(request) {
    let translationKey = '';

    if (request.esRevision) {
      switch (request.idEstado) {
        case 2:
          translationKey = 'review';
          break;
        case 3:
          translationKey = 'review-requested';
          break;
        default:
          translationKey = BudgetStatusEnum[request.idEstado];
          break;
      }
    } else {
      translationKey = BudgetStatusEnum[request.idEstado];
    }

    return TranslationService.instance.translate(translationKey);
  }

  importJobJson(apiResponse) {
    let rootLine = new GroupLine(
      apiResponse.idLineaPresupuesto,
      apiResponse.descripcion,
      apiResponse.idTipoLineaPresupuesto,
      0,
      false
    );
    rootLine = this.buildJobChildrenLines(apiResponse.lineasHijas, rootLine, 1);
    return rootLine;
  }

  buildJobChildrenLines(apiChildrenLines, line, level) {
    apiChildrenLines.forEach(apiLine => {
      let childLine = new GroupLine(
        apiLine.idLineaPresupuesto,
        apiLine.descripcion,
        apiLine.idTipoLineaPresupuesto,
        level,
        false
      );
      if (apiLine.lineasHijas.length > 0) {
        childLine = this.buildJobChildrenLines(apiLine.lineasHijas, childLine, level + 1);
      }
      line.childLines.push(childLine);
    });
    return line;
  }

  buildPage() {
    this.buildTitle();
    this.buildHead();
    this.rebuildContent();
    this.enableButtons();
  }

  buildTitle() {
    var innnerHtml = '';
    innnerHtml = innnerHtml.concat(
      TranslationService.instance.translate('req-detail-edit-title') + ': ' + this._request.nombre
    );
    $('#view-title').html(innnerHtml);

    let goBackUrl = '#';
    if (this._requestExists) {
      goBackUrl += 'requests/' + this._request.idSolicitud;
    } else {
      goBackUrl += 'requests/';
    }

    $('.go-back').attr('href', goBackUrl);

    /*$('.go-back').click(() => { if (window.history.length > 1) { window.history.back() } });*/

  }

  buildHead() {
    // State:
    $('.request-status-text').text(this.getStatusDescription(this._request));
    $('.circle-state').addClass(BudgetStatusClassesEnum[this._request.idEstado]);
  }

  rebuildContent() {
    this.buildCommonContent();
    if (this._request.idTipo == RequestTypeEnum.Investment) {
      this.buildInvestmentContent();
    } else {
      this.buildDisinvestmentContent();
    }
  }

  buildCommonContent() {
    $('#request-invest').attr('disabled', 'disabled');
    $('#request-disinvestment').attr('disabled', 'disabled');

    //Set reference
    $('#content-reference')
      .find('.form-control')
      .val(this._request.referencia);
    $('#content-reference')
      .find('.form-control')
      .change(e => (this._request.referencia = e.target.value));

    //Set date
    let date = DatePicker.parseDate(this._request.fecha);
    $('#content-date')
      .find('.date')
      .attr('placeholder', TranslationService.instance.translate('common-placeholder-select'));
    if (date instanceof Date && !isNaN(date)) {
      $('#content-date')
        .find('.date')
        .each((i, e) => {
          e.value = DatePicker.toLocaleDateString(date);
          DatePicker.create(e, date.getFullYear());
        });
    } else {
      DatePicker.create($('#content-date').find('.date'));
    }
    $('#content-date')
      .find('.date')
      .change(e => {
        this._request.fecha = DatePicker.parseDate(e.target.value).toISOString();
        this.restartCashflow();
      });

    //Set name
    $('#content-request')
      .find('.form-control')
      .val(this._request.nombre);
    $('#content-request')
      .find('.form-control')
      .change(e => (this._request.nombre = e.target.value));

    //Set UTE selector
    this.workUteSelector = new DropDownComponent(
      document.getElementById('work-ute-selector'),
      false
    );
    this.workUteSelector.setRules({
      required: true
    });
    this.workUteSelector.loadData(this._works, 'idLineaPresupuesto', 'descripcion');
    if (
      this._request.detalleSolicitud.idGrupo != null &&
      this._request.detalleSolicitud.idGrupo != 0
    ) {
      this.workUteSelector.setSelected(
        this._request.detalleSolicitud.idGrupo,
        'idLineaPresupuesto',
        'descripcion'
      );
    }
    this.workUteSelector.onChanges.listen(value => this.uteSelectorChanged(value));

    if (this._request.ejercicio > new Date().getFullYear()) {
      $('#btn-add-work').addClass('disabled');
    }

    if (!this._request.estructuradoEnUte) {
      let uteStructured = TranslationService.instance.translate(
        'req-detail-lbl-work-ute-notstructured'
      );
      $('#content-work-ute')
        .find('.label-element')
        .text(uteStructured);
      $('#content-ute-percent').hide();
      $('#content-ute-applied').hide();
      $('#content-delivery-pos').hide();
    }

    let uteAppliedId = this._request.detalleSolicitud.uteAplicada
      ? '#request-ute-applied-yes'
      : '#request-ute-applied-no';
    $('#content-ute-applied')
      .find(uteAppliedId)
      .prop('checked', true);

    $('.request-ute-applied').change(value => {
      if ($(value.currentTarget).prop('checked') == true)
        this.changeUteApplied($(value.currentTarget).val() == 'true');
    });

    //Set UTE percentage
    $('#content-ute-percent')
      .find('.form-control')
      .val(this._request.detalleSolicitud.porcentajeUte);

    //Set currency selector
    this._badgeSelector = new DropDownComponent(document.getElementById('badge-selector'), false);
    this._badgeSelector.setRules({
      required: true
    });
    this._badgeSelector.loadData(this._currencies, 'idTipoMoneda', 'nombreCorto');
    if (this._request.idMoneda != null) {
      this._badgeSelector.setSelected(this._request.idMoneda, 'idTipoMoneda', 'nombreCorto');
    }
    this._badgeSelector.onChanges.listen(badge => {
      this._request.idMoneda = badge.idTipoMoneda;
      this._updateCurrencyNames();
    });

    $('#content-amount')
      .find('.form-control')
      .val(this._request.importeLocal);
    this._contentAmountInputNumeric = InputNumeric.createDecimal(
      $('#content-amount').find('.form-control')
    );

    this._totalAmountDecimal.parse(this._request.importeTotalLocal || 0);

    //Set tax
    $('#content-tax')
      .find('.form-control')
      .val(this._request.detalleSolicitud.porcImpuestos);

    $('#content-tax')
      .find('.form-control')
      .change(e => {
        this._request.detalleSolicitud.porcImpuestos = this._getPercentageValue(
          $(e.target).attr('data-raw-value')
        );

        if (
          this._request.detalleSolicitud.porcImpuestos >= 0 ||
          this._request.detalleSolicitud.porcImpuestos <= 100
        ) {
          $('#content-tax')
            .find('.form-control')
            .val(this._request.detalleSolicitud.porcImpuestos);
          this._setTotalAdditionalAmounts();
        }
      });

    //Additional amounts
    this._additionalCostsDecimal.parse(this._calculateAdditionalAmounts());

    //Set delivery term
    $('#content-delivery-term')
      .find('.form-control')
      .val(this._request.detalleSolicitud.numeroPlazoEntrega);

    $('#content-delivery-term')
      .find('.form-control')
      .change(e => {
        let newPeriod = this._getPeriodValue(e.target.value);
        this._request.detalleSolicitud.numeroPlazoEntrega = newPeriod;
        $('#content-delivery-term')
          .find('#delivery-term')
          .val(newPeriod);
        this.restartCashflow();
      });

    let periods = [],
      periodLabels = [];
    periods.push('W');
    periods.push('M');
    periodLabels.push(TranslationService.instance.translate('req-detail-weeks'));
    periodLabels.push(TranslationService.instance.translate('req-detail-months'));

    this.termSelector = new DropDownComponent(document.getElementById('delivery-term-selector'));

    this.termSelector.setRules({
      required: true
    });

    this.termSelector.loadLocal(periodLabels, periods);

    let periodoDefecto =
      this._request.detalleSolicitud.periodoPlazoEntrega !== undefined
        ? this._request.detalleSolicitud.periodoPlazoEntrega
        : 'W';

    this._request.detalleSolicitud.periodoPlazoEntrega = periodoDefecto;

    this.termSelector.setSelected(periodoDefecto, 'id', 'value');

    this.termSelector.onChanges.listen(() => {
      this._request.detalleSolicitud.periodoPlazoEntrega = this.termSelector.currentValue;
      this.restartCashflow();
    });

    //Set delivery position
    $('#content-delivery-pos')
      .find('.form-control')
      .val(this._request.detalleSolicitud.posicionEntrega);
    $('#content-delivery-pos')
      .find('.form-control')
      .change(e => {
        this._request.detalleSolicitud.posicionEntrega = e.target.value;
        this.restartCashflow();
      });

    //Set pay way
    $('#content-pay-way')
      .find('.form-control')
      .val(this._request.detalleSolicitud.formaPago);
    $('#content-pay-way')
      .find('.form-control')
      .change(e => (this._request.detalleSolicitud.formaPago = e.target.value));

    //Set observations
    $('.observations')
      .find('.form-control')
      .val(this._request.detalleSolicitud.observaciones);
    $('.observations')
      .find('.form-control')
      .change(e => (this._request.detalleSolicitud.observaciones = e.target.value));

    let testData = [
      {
        idAdjuntoSolicitud: 0,
        idSolicitud: 0,
        name: 'test.pdf',
        comentarios: 'Esto es un comentario de prueba',
        url: '#'
      }
    ];
    let attachmentOptions = new InputFileOptions();
    attachmentOptions.allowDelete = true;
    attachmentOptions.allowMultipleFiles = true;
    attachmentOptions.existingFiles = this._request.detalleSolicitud.idSolicitudNavigationAdjuntoSolicitud.map(
      e => {
        return {
          name: e.nombre,
          comment: e.comentarios,
          url: e.url,
          idAdjuntoSolicitud: e.idAdjuntoSolicitud
        };
      }
    );
    attachmentOptions.fileLayout = `
    <div class="file">
      <span class="icon-documento"></span>
      <a class="file-text new-file" href="javascript:void()" target="_blank"></a>
      <input class="file-comment form-control">
      <span class="icon-eliminar"></span>
    </div>`;
    attachmentOptions.onRenderedFile = (file, htmlFile) => {
      let fileInput = htmlFile.find('.file-comment');
      fileInput.hide();
      let fileText = htmlFile.find('.file-text');

      fileInput.change(() => {
        file.comment = fileInput.val();
      });

      if (!file.comment) {
        file.comment = '';
      }

      if (file.url) {
        fileText.attr('href', file.url);
        fileText.removeClass('new-file');
      }

      fileInput.val(file.comment);
    };

    if (this._attachments === undefined) {
      this._attachments = new InputFileComponent(attachmentOptions);
    }

    this.viewsLoader.loadView(
      this._attachments,
      document.querySelector('.documentation-container')
    );
    this._updateCurrencyNames();

    //Table concepts load...
    if (
      this._request.detalleSolicitud.idGrupo != null &&
      this._request.detalleSolicitud.idGrupo != 0
    ) {
      this._loadBudgetItemsAssociated();
      this._loadBudgetJob().then(response => this.showConceptTable());
    } else {
      this._budgetItemsAssociated = [];
      this._budgetJobItem = null;
      this.showConceptTable();
    }
  }
  buildInvestmentContent() {
    //Set request type
    $('#content-request-type')
      .find('#request-invest')
      .prop('checked', true);

    //Set provider and hide sell to
    $('#content-provider')
      .find('.form-control')
      .val(this._request.detalleSolicitud.detalleInversion.proveedor);
    $('#content-provider')
      .find('.form-control')
      .change(e => (this._request.detalleSolicitud.detalleInversion.proveedor = e.target.value));
    $('#content-sell-to').hide();
    $('#content-provider').show();

    //Hide received offers
    $('#content-received-offers').hide();

    //Show faker only on mobile
    if ($(window).innerWidth < 768) $('mob-faker-space').show();

    //Set rebuy option
    $('#content-rebuy-option')
      .find('.content-element')
      .text(this._request.detalleSolicitud.detalleInversion.opcionRecompra ? 'Si' : 'No');
    if (this._request.detalleSolicitud.detalleInversion.opcionRecompra) {
      $('#content-rebuy-option')
        .find('#rebuy-yes')
        .prop('checked', true);
    } else {
      $('#content-rebuy-option')
        .find('#rebuy-no')
        .prop('checked', true);
    }
    $('#content-rebuy-option').show();

    //Set rebuy percent
    $('#content-rebuy-percent')
      .find('.form-control')
      .val(this._request.detalleSolicitud.detalleInversion.porcentajeRecompra);
    $('#content-rebuy-percent').show();
    $('#content-rebuy-percent')
      .find('.form-control')
      .change(e => {
        this._request.detalleSolicitud.detalleInversion.porcentajeRecompra = this._getPercentageValue(
          $(e.target).attr('data-raw-value')
        );
        if (
          this._request.detalleSolicitud.detalleInversion.porcentajeRecompra == 0 ||
          this._request.detalleSolicitud.detalleInversion.porcentajeRecompra == 100
        ) {
          $('#content-rebuy-percent')
            .find('.form-control')
            .val(this._request.detalleSolicitud.detalleInversion.porcentajeRecompra);
        }
      });
    $('#content-rebuy-percent')
      .find('.form-control')
      .attr('disabled', !this._request.detalleSolicitud.detalleInversion.opcionRecompra);
  }
  buildDisinvestmentContent() {
    //Set request type
    $('#content-request-type')
      .find('#request-disinvestment')
      .prop('checked', true);

    //Set sell to and hide provider
    $('#content-sell-to')
      .find('.form-control')
      .val(this._request.detalleSolicitud.detalleDesinversion.destinatarioVenta);
    $('#content-sell-to').show();
    $('#content-sell-to')
      .find('.form-control')
      .change(
        e => (this._request.detalleSolicitud.detalleDesinversion.destinatarioVenta = e.target.value)
      );
    $('#content-provider').hide();

    //Show received offers
    $('#content-received-offers').show();

    //Hide faker space
    $('mob-faker-space').hide();

    //Hide rebuy option
    $('#content-rebuy-option').hide();

    //Hide reby percentaje
    $('#content-rebuy-percent').hide();

    $('#totalAmount').change(() => this.restartCashflow());
  }
  _getPercentageValue(value) {
    if (value == '' || parseFloat(value) < 0) {
      return 0;
    } else if (parseFloat(value) >= 100) {
      return 100;
    } else {
      //return value;
      return parseFloat(value);
    }
  }

  _getPeriodValue(value) {
    if (value == '' || parseInt(value) < 0) {
      return 0;
    } else if (parseInt(value) >= 99) {
      return 99;
    } else {
      return parseInt(value);
    }
  }

  switchRebuy(rebuy) {
    if (this._request.detalleSolicitud.detalleInversion.opcionRecompra != rebuy) {
      this._request.detalleSolicitud.detalleInversion.opcionRecompra = rebuy;
      $('#content-rebuy-percent')
        .find('.form-control')
        .attr('disabled', !rebuy);
      $('#content-rebuy-percent')
        .find('.form-control')
        .attr('required', rebuy);
      this.validator.refreshFields();
    }
  }
  switchRequestType(requestType) {
    if (
      requestType == RequestTypeEnum.Investment &&
      this._request.idTipo == RequestTypeEnum.Disinvestment
    ) {
      this._request.idTipo = RequestTypeEnum.Investment;
      this._request.detalleSolicitud.detalleDesinversion = null;
      this._request.detalleSolicitud.concepto = [];
      if (this._request.detalleSolicitud.detalleInversion == null) {
        this._request.detalleSolicitud.detalleInversion = {
          idDetalleSolicitudInversion: null,
          proveedor: null,
          opcionRecompra: false,
          porcentajeRecompra: 0
        };
      }
    }

    if (
      requestType == RequestTypeEnum.Disinvestment &&
      this._request.idTipo == RequestTypeEnum.Investment
    ) {
      this._request.idTipo = RequestTypeEnum.Disinvestment;
      this._request.detalleSolicitud.detalleInversion = null;
      this._request.detalleSolicitud.concepto = [];
      if (this._request.detalleSolicitud.detalleDesinversion == null) {
        this._request.detalleSolicitud.detalleDesinversion = {
          idDetalleSolicitudDesinversion: null,
          destinatarioVenta: null,
          ofertaRecibida: []
        };
      }
    }

    this.rebuildContent();
  }

  applyUteToConcepts() {
    let porcentajeUte = this._request.detalleSolicitud.porcentajeUte;

    this._request.detalleSolicitud.costeAdicional.forEach(c => {
      c.importe = (c.importe / 100) * porcentajeUte;
      c.importeLocal = (c.importeLocal / 100) * porcentajeUte;
    });

    if (this._request.idTipo == RequestTypeEnum.Investment) {
      this._request.detalleSolicitud.concepto.forEach(c => {
        c.conceptoInversion.importe = (c.conceptoInversion.importe / 100) * porcentajeUte;
        c.conceptoInversion.importeLocal = (c.conceptoInversion.importeLocal / 100) * porcentajeUte;
      });
    } else {
      this._request.detalleSolicitud.concepto.forEach(c => {
        c.conceptoDesinversion.desinversionEuros =
          (c.conceptoDesinversion.desinversionEuros / 100) * porcentajeUte;
        c.conceptoDesinversion.desinversionEurosLocal =
          (c.conceptoDesinversion.desinversionEurosLocal / 100) * porcentajeUte;

        c.conceptoDesinversion.valorContable =
          (c.conceptoDesinversion.valorContable / 100) * porcentajeUte;
        c.conceptoDesinversion.valorContableLocal =
          (c.conceptoDesinversion.valorContableLocal / 100) * porcentajeUte;

        c.conceptoDesinversion.valorAdquisicion =
          (c.conceptoDesinversion.valorAdquisicion / 100) * porcentajeUte;
        c.conceptoDesinversion.valorAdquisicionLocal =
          (c.conceptoDesinversion.valorAdquisicionLocal / 100) * porcentajeUte;
      });
    }

    this.showConceptTable();
  }

  removeUteToConcepts() {
    let porcentajeUte = this._request.detalleSolicitud.porcentajeUte;

    this._request.detalleSolicitud.costeAdicional.forEach(c => {
      c.importe = (c.importe * 100) / porcentajeUte;
      c.importeLocal = (c.importeLocal * 100) / porcentajeUte;
    });


    if (this._request.idTipo == RequestTypeEnum.Investment) {
      this._request.detalleSolicitud.concepto.forEach(c => {
        c.conceptoInversion.importe = (c.conceptoInversion.importe * 100) / porcentajeUte;
        c.conceptoInversion.importeLocal = (c.conceptoInversion.importeLocal * 100) / porcentajeUte;
      });
    } else {
      this._request.detalleSolicitud.concepto.forEach(c => {
        c.conceptoDesinversion.desinversionEuros =
          (c.conceptoDesinversion.desinversionEuros * 100) / porcentajeUte;
        c.conceptoDesinversion.desinversionEurosLocal =
          (c.conceptoDesinversion.desinversionEurosLocal * 100) / porcentajeUte;

        c.conceptoDesinversion.valorContable =
          (c.conceptoDesinversion.valorContable * 100) / porcentajeUte;
        c.conceptoDesinversion.valorContableLocal =
          (c.conceptoDesinversion.valorContableLocal * 100) / porcentajeUte;

        c.conceptoDesinversion.valorAdquisicion =
          (c.conceptoDesinversion.valorAdquisicion * 100) / porcentajeUte;
        c.conceptoDesinversion.valorAdquisicionLocal =
          (c.conceptoDesinversion.valorAdquisicionLocal * 100) / porcentajeUte;
      });
    }

    this.showConceptTable();
  }

  changeUteApplied(aplicarUTE) {
    if (this._request.detalleSolicitud.uteAplicada != aplicarUTE) {
      this._request.detalleSolicitud.uteAplicada = aplicarUTE;

      if (aplicarUTE) {
        this.applyUteToConcepts();
      } else {
        this.removeUteToConcepts();
      }

      $('.concept-input').trigger('change');
    }
  }

  uteSelectorChanged(value) {
    $('#request-ute-applied-no').trigger('click');
    $('.budget-item-btn').addClass('disabled');
    if (this._request.detalleSolicitud.idGrupo != value.idLineaPresupuesto) {
      this._request.detalleSolicitud.idGrupo = value.idLineaPresupuesto;
      this._request.detalleSolicitud.grupo = value.descripcion;
      this._request.detalleSolicitud.porcentajeUte = value.porcentajeParticipacion;
      this._request.detalleSolicitud.concepto.forEach(c => {
        c.idLineaPresupuesto = null;
        c.partidaPresupuestaria = null;
      });
      this.rebuildContent();
    }
  }

  enableButtons() {
    $('#btn-toogle-menu').click(() => this._toggleMenu());
    $('#request-invest').click(() => this.switchRequestType(RequestTypeEnum.Investment));
    $('#request-disinvestment').click(() => this.switchRequestType(RequestTypeEnum.Disinvestment));
    $('#rebuy-yes').click(() => this.switchRebuy(true));
    $('#rebuy-no').click(() => this.switchRebuy(false));
    $('#btn-add-cost').click(() => this.addCost());
    $('#btn-save-request').click(() => this.saveRequest());
    $('#btn-delete-concept').addClass('disabled');
    $('#btn-delete-concept').click(e => this.deleteConceptLine(e));
    $('#btn-add-concept').click(e => this.addNewConcept());
    $('#btn-add-work').click(e => this.addWork());
    $('#btn-cashflow').click(e => this.openCashflow());
    $('#btn-received-offers').click(e => this.openReceivedOffers());
    //Mobile
    $('#btn-concepts-mobile').click(() => this._openConceptsMobile());
    //Validate form
    this.validator = new Validator($('.requestdetailseditor-container'));
    this.isValid = true;
    this.validator.onValidationChanges.listen(isValid => {
      this.isValid = isValid;
    });
    this.validator.validate();
  }

  openReceivedOffers(options) {
    if (typeof options === 'undefined') {
      // if not data provided, get it from the api response
      options = new OffersReceivedOptions();
      options.readOnly = false;
      options.data = this._request.detalleSolicitud.detalleDesinversion.ofertaRecibida.slice();
    }

    options.utePercentage = this._request.detalleSolicitud.porcentajeUte;

    // open the received offers modal
    var modal = new ModalComponent(
      TranslationService.instance.translate('req-detail-offer-received-title'),
      new OffersReceivedComponent(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result && modal.result.action == 'save') {
        // save action
        this._request.detalleSolicitud.detalleDesinversion.ofertaRecibida = modal.result.offers;
      } else if (modal.result && modal.result.action == 'edit-offer') {
        // add or edit action (for mobile version)
        let offerEdit = modal.result.offer; // offer data to be edited
        let modalTitle = TranslationService.instance.translate('req-detail-offer-new-title');
        if (offerEdit)
          modalTitle = TranslationService.instance.translate('req-detail-offer-edit-title');
        // open the offer edit modal (for mobile version)
        var editOfferModal = new ModalComponent(modalTitle, new EditOfferComponent(offerEdit));
        editOfferModal.onClosed.listen(() => {
          if (typeof editOfferModal.result !== 'undefined' && editOfferModal.result != false) {
            // if the modal has not been cancelled...
            if (typeof offerEdit !== 'undefined') {
              // ... and we are editing an offer
              let element = options.data.find(
                e =>
                  e.nombre == offerEdit.nombre &&
                  e.valor == offerEdit.valor &&
                  e.formaPago == offerEdit.formaPago
              );
              let pos = options.data.indexOf(element);
              options.data[pos] = editOfferModal.result;
            } else {
              // ... and we are creating a new offer
              // adds the new offer to the data
              options.data.push(editOfferModal.result);
            }
          }
          // Open again the modal with the changes
          this.openReceivedOffers(options);
        });
      }
    });
  }

  restartCashflow() {
    this._request.detalleSolicitud.flujoCaja = [];

    if (
      this._request.fecha !== 'undefined' &&
      this._request.importeTotalLocal !== undefined &&
      this._request.detalleSolicitud.numeroPlazoEntrega !== undefined
    ) {
      let monthsToAdd = 1;
      let daysToAdd = 0;

      if (this._request.detalleSolicitud.periodoPlazoEntrega == 'W') {
        daysToAdd = this._request.detalleSolicitud.numeroPlazoEntrega * 7;
      } else {
        monthsToAdd += this._request.detalleSolicitud.numeroPlazoEntrega;
      }

      let cashFlowDate = DatePicker.parseDate(this._request.fecha);
      cashFlowDate = DatePicker.addMonths(cashFlowDate, monthsToAdd);
      cashFlowDate = DatePicker.addDays(cashFlowDate, daysToAdd);
      let aditionalAmounts = this._calculateAdditionalNoTaxes();

      this._request.detalleSolicitud.flujoCaja.push({
        fecha: cashFlowDate.toISOString(),
        importeLocal: this._request.importeLocal + aditionalAmounts,
        importe: this._request.importeLocal + aditionalAmounts
      });
    }
  }

  openCashflow() {
    let options = new CashFlowOptions();
    options.year = this._request.ejercicio;
    options.readOnly = false;
    options.data = this._request.detalleSolicitud.flujoCaja;
    options.totalAmount = this._totalAmountDecimal;
    var modal = new ModalComponent(
      TranslationService.instance.translate('req-detail-cashflow-title'),
      new CashFlowComponent(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      if (modal.result) {
        this._request.detalleSolicitud.flujoCaja = modal.result;
      }
    });
  }

  openGroupSelector(event) {
    if (!$(event.target.parentElement).hasClass('disabled')) {
      let conceptId = $(event.target)
        .parents('.concept-line')[0]
        .getAttribute('data-id');
      let options = new GroupSelectorOptions();
      options.selectedId = this._request.detalleSolicitud.concepto[conceptId].idLineaPresupuesto;
      options.data = this._budgetJobItem;
      var modal = new ModalComponent(
        TranslationService.instance.translate('req-detail-group-title'),
        new GroupSelectorComponent(options)
      );
      modal.onClosed.listen(() => {
        // if (modal.result == null && this._request.detalleSolicitud.concepto[conceptId].idLineaPresupuesto == null) {
        //   this._request.detalleSolicitud.concepto[conceptId].idLineaPresupuesto = null;
        //   $('#budget-item-text-' + conceptId).text('');
        // }

        if (modal.result) {
          if (modal.result == 'delete') {
            this._request.detalleSolicitud.concepto[conceptId].idLineaPresupuesto = null;
            this.showConceptTable();
            $('#budget-item-text-' + conceptId).text('');
          }

          this._request.detalleSolicitud.concepto[conceptId].idLineaPresupuesto = modal.result.id;
          this._request.detalleSolicitud.concepto[conceptId].partidaPresupuestaria = "/" + modal.result.description;
          let budgetLineName = modal.result.description;

          if (budgetLineName.length >= 85) {
            budgetLineName.slice(0, -3) + '...';
          }

          $('#budget-item-text-' + conceptId).text(budgetLineName);
          this.showConceptTable();
        }
      });
    }
  }

  addWork() {
    if ($('#btn-add-work').hasClass('disabled')) {
      return;
    }
    let textSubtitle = TranslationService.instance.translate('bd-modal-create-group-subtitle-notstructured');
    let modal = new ModalComponent(
      TranslationService.instance.translate('bd-modal-create-group-title'),
      new CreateGroupComponent(null, this._request.estructuradoEnUte, textSubtitle)
    );

    modal.onClosed.listen(() => {
      if (modal.result == null) return;

      let newWork = new Object();
      newWork.idPresupuesto = this._request.idPresupuesto;
      newWork.descripcion = modal.result.name;
      newWork.porcentajeParticipacion = parseInt(modal.result.participation);
      newWork.usuarioActualizacion = this.authenticationService.getAuthenticatedUser().login;

      $('#loader-container').removeClass('hidden');
      this.httpService
        .post(this.httpService.config.addWork, newWork)
        .then(response => {
          this.toastService.success(
            TranslationService.instance.translate('success-title'),
            TranslationService.instance.translate('msg-req-create-ok')
          );

          let responseWork = new Object();
          responseWork.idLineaPresupuesto = response;
          responseWork.idPresupuesto = newWork.idPresupuesto;
          responseWork.porcentajeParticipacion = newWork.porcentajeParticipacion;
          responseWork.orden = 99;
          responseWork.descripcion = newWork.descripcion;
          this._works.push(responseWork);
          this.workUteSelector.loadData(this._works, 'idLineaPresupuesto', 'descripcion');

          this.workUteSelector.setSelected(
            responseWork.idLineaPresupuesto,
            'idLineaPresupuesto',
            'descripcion'
          );

          $('#btn-add-work').addClass('disabled');
          $('#loader-container').addClass('hidden');
        })
        .catch(error => {
          this.toastService.exception(error.title);
          console.error(error);
          $('#loader-container').addClass('hidden');
        });
    });
  }

  addNewConcept() {
    let concept = {
      idConcepto: null,
      idDetalleSolicitud: this._request.detalleSolicitud.idDetalleSolicitud,
      idLineaPresupuesto: null,
      partidaPresupuestaria: null,
      orden: this._request.detalleSolicitud.concepto.length,
      unidades: 0,
      descripcion: ''
    };
    if (this._request.idTipo == RequestTypeEnum.Investment) {
      concept.conceptoInversion = {
        idConceptoInversion: null,
        importe: null,
        importeLocal: null,
        excedido: false
      };
    } else {
      concept.conceptoDesinversion = {
        idConceptoDesinversion: null,
        fechaAdquisicion: null,
        valorAdquisicion: null,
        valorContable: null,
        desinversionEuros: null,
        valorAdquisicionLocal: null,
        valorContableLocal: null,
        desinversionEurosLocal: null
      };
    }
    this._request.detalleSolicitud.concepto.push(concept);
    this.showConceptTable();
  }
  deleteConceptLine(event) {
    this._request.detalleSolicitud.concepto = this._request.detalleSolicitud.concepto.filter(
      c => !c.isSelected
    );
    this._request.detalleSolicitud.concepto.forEach(c => (c.isSelected = false));
    this.showConceptTable();
    $('#btn-delete-concept').addClass('disabled');

    let query =
      this._request.idTipo == RequestTypeEnum.Disinvestment ? '.disinvestment' : '.totalValue';
    this._calculateTotalImport(query);
    this.restartCashflow();
  }

  _calculateAdditionalAmounts() {
    let aditionalAmounts = 0;
    this._request.detalleSolicitud.costeAdicional.forEach(aditionalAmount => {
      if (aditionalAmount.conImpuestos) {
        aditionalAmounts += aditionalAmount.importeLocal;
      } else {
        let taxAmount =
          (aditionalAmount.importeLocal * this._request.detalleSolicitud.porcImpuestos) / 100;
        aditionalAmounts += aditionalAmount.importeLocal + taxAmount;
      }
    });
    return aditionalAmounts;
  }

  _calculateAdditionalNoTaxes() {
    let aditionalAmounts = 0;
    this._request.detalleSolicitud.costeAdicional.forEach(aditionalAmount => {
      if (!aditionalAmount.conImpuestos) {
        aditionalAmounts += aditionalAmount.importeLocal;
      } else {
        let taxAmount =
          (aditionalAmount.importeLocal * this._request.detalleSolicitud.porcImpuestos) / 100;
        aditionalAmounts += aditionalAmount.importeLocal - taxAmount;
      }
    });
    return aditionalAmounts;
  }

  _setTotalAdditionalAmounts() {
    let aditionalAmounts = this._calculateAdditionalAmounts();

    this._additionalCostsDecimal.parse(aditionalAmounts);

    this._setTotalAmount(aditionalAmounts);
    this.restartCashflow();
  }
  _setTotalAmount(aditionalAmounts) {
    let taxAmount =
      (this._request.importeLocal * this._request.detalleSolicitud.porcImpuestos) / 100;

    let desinversion = this._request.detalleSolicitud.detalleInversion != null ? 1 : -1;
    let totalAmount = this._request.importeLocal + taxAmount + (aditionalAmounts * desinversion);
    this._totalAmountDecimal.parse(totalAmount);
    this._request.importeTotalLocal = totalAmount;
  }

  addCost() {
    let options = new AdditionalCostsOptions();
    options.readOnly = false;
    options.data = this._request.detalleSolicitud.costeAdicional;
    options.localCurrency = true;
    var modal = new ModalComponent(
      TranslationService.instance.translate('req-detail-lbl-additional-costs'),
      new AdditionalCostsComponent(options)
    );
    modal.onClosed.listen(() => {
      if (modal.result == null) return;
      if (modal.result) {
        this._request.detalleSolicitud.costeAdicional = modal.result;

        // Updates the input field
        this._setTotalAdditionalAmounts();
      }
    });
  }

  showConceptTable() {
    if (this._request.idTipo == RequestTypeEnum.Investment) {
      this._buildInvestmentConceptTable();
    } else {
      this._buildDisinvestmentConceptTable();
    }
    $('.concept-line').click(e => this._selectLine(e));
    this._enableTableInputs();
    //Refresh validator
    if (this.validator != null) this.validator.refreshFields();
  }

  _buildInvestmentConceptTable() {
    let requestConceptsHtml = '';
    $('.table-head').addClass("table-investment");
    $('.table-head .acquisitionDate').hide();
    $('.table-head .contableValue').hide();
    $('.table-head .disinvestment').hide();
    this._request.detalleSolicitud.concepto.forEach((petition, index) => {
      requestConceptsHtml += `<div class="table-row table-investment concept-line ${petition.isSelected ? 'selected' : ''
        }" data-id="${index}">
        <div class="cell center concept">${index + 1}</div>
        <div class="cell center units">
        <input type="text" required 
        class="concept-input decimal" value="${petition.unidades ? petition.unidades : ''
        }" data-field="unidades"/>
        </div>
        <div class="cell description">
        <input type="text" required max-length="500"
        class="concept-input" value='${petition.descripcion ? petition.descripcion : ''
        }' data-field="descripcion"/>
        </div>
        <div class="cell not-apply acquisitionDate">
        </div>
        <div class="cell totalValue">
        <input type="text" required
        class="concept-input decimal is-not-nullable" value="${!isNaN(petition.conceptoInversion.importeLocal)
          ? petition.conceptoInversion.importeLocal
          : ''
        }" data-field="conceptoInversion.importeLocal"/>
        </div>
        <div class="cell not-apply contableValue"></div>
        <div class="cell not-apply disinvestment"></div>
        <div class="cell not-apply budgetAmount">
        <input type="text" disabled
        class="concept-input decimal" value="${petition.importePresupuestado}"/>
        </div>
        <div class="cell not-apply approvedAmount">
        <input type="text" disabled
        class="concept-input decimal" value="${petition.importeAprobado}"/>
        </div>
        <div class="cell budgetItem concept-table-selector" id="budget-item-selector-${index}">
        <span class="budget-item-description" id="budget-item-text-${index}"></span>
        <div class="form-control-append btn budget-item-btn" id="btn-add-group-${index}"><span class="icon icon-abrir add-group"></span></div>
        </div>
        <div class="cell planArea no-center-vertical disabled">
        ${petition.idLineaPresupuesto
          ? '<div class="plan center"><span>' +
          TranslationService.instance.translate('onplan') +
          '</span></div>'
          : '<div class="plan no center"><span>' +
          TranslationService.instance.translate('notonplan') +
          '</span></div>'
        }</div>
      </div>`;
    });

    if (requestConceptsHtml.length == 0) {
      requestConceptsHtml = `<div class="table-row all">
        <span>${TranslationService.instance.translate('common-msg-no-elements')}</span>
      </div>`;
    }
    $('#concepts-table-content').html(requestConceptsHtml);
    $('.icon-abrir').click(event => this.openGroupSelector(event));
  }
  _buildDisinvestmentConceptTable() {
    let requestConceptsHtml = '';

    this._request.detalleSolicitud.concepto.forEach((petition, index) => {
      requestConceptsHtml += `<div class="table-row concept-line ${petition.isSelected ? 'selected' : ''
        }" data-id="${index}">
        <div class="cell concept center">${index + 1}</div>
        <div class="cell units center">
          <input type="text" required
          class="concept-input decimal" value="${petition.unidades ? petition.unidades : ''
        }" data-field="unidades"/>
        </div>
        <div class="cell description">
          <input type="text" required max-length="500"
          class="concept-input" value="${petition.descripcion ? petition.descripcion : ''
        }" data-field="descripcion"/>
        </div>
        <div class="cell acquisitionDate">
        <input type="text" class="concept-input-date concept-acquisition-date" data-id="${index}"/>
        </div>
        <div class="cell totalValue">
        <input type="text" required
        class="concept-input decimal is-not-nullable" value="${!isNaN(petition.conceptoDesinversion.valorAdquisicionLocal)
          ? petition.conceptoDesinversion.valorAdquisicionLocal
          : ''
        }" data-field="conceptoDesinversion.valorAdquisicionLocal"/>
        </div>
        <div class="cell contableValue">
        <input type="text" required
        class="concept-input decimal is-not-nullable" value="${!isNaN(petition.conceptoDesinversion.valorContableLocal)
          ? petition.conceptoDesinversion.valorContableLocal
          : ''
        }" data-field="conceptoDesinversion.valorContableLocal"/>
        </div>
        <div class="cell disinvestment">
        <input type="text" required
        class="concept-input decimal" value="${!isNaN(petition.conceptoDesinversion.desinversionEurosLocal)
          ? petition.conceptoDesinversion.desinversionEurosLocal
          : ''
        }" data-field="conceptoDesinversion.desinversionEurosLocal"/>
        </div>
        <div class="cell not-apply budgetAmount">
        <input type="text" disabled
        class="concept-input decimal" value="0"/>
        </div>
        <div class="cell not-apply approvedAmount">
        <input type="text" disabled
        class="concept-input decimal" value="0"/>
        </div>
        <div class="cell budgetItem concept-table-selector" id="budget-item-selector-${index}">
        <span class="budget-item-description" id="budget-item-text-${index}"></span>
        <div class="form-control-append btn budget-item-btn" id="btn-add-group-${index}"><span class="icon icon-abrir add-group"></span></div>
        </div>
        <div class="cell planArea no-center-vertical disabled">
        ${petition.idLineaPresupuesto
          ? '<div class="plan center"><span>' +
          TranslationService.instance.translate('onplan') +
          '</span></div>'
          : '<div class="plan no center"><span>' +
          TranslationService.instance.translate('notonplan') +
          '</span></div>'
        }</div>
      </div>`;
    });

    if (requestConceptsHtml.length == 0) {
      requestConceptsHtml = `<div class="table-row all">
        <span>${TranslationService.instance.translate('common-msg-no-elements')}</span>
      </div>`;
    }
    $('#concepts-table-content').html(requestConceptsHtml);
    $('.icon-abrir').click(event => this.openGroupSelector(event));
  }
  _selectLine(event) {
    this._request.detalleSolicitud.concepto.forEach(c => (c.isSelected = false));
    this._request.detalleSolicitud.concepto[
      parseInt(event.currentTarget.getAttribute('data-id'))
    ].isSelected = true;
    $('.table-row').removeClass('selected');
    event.currentTarget.classList.add('selected');
    $('#btn-delete-concept').removeClass('disabled');
  }

  _calculateTotalImport(query) {
    let contents = $('#concepts-table-content').find('.table-row');
    let total = 0;

    contents.find(query + ' .concept-input').each((i, e) => {
      let val = $(e).attr('data-raw-value');
      if (val) {
        total += parseFloat(val);
      }
    });

    this._contentAmountInputNumeric.parse(total);
    this._request.importeLocal = total;
    this._setTotalAdditionalAmounts();
  }

  _enableTableInputs() {
    //Inputs
    let contents = $('#concepts-table-content').find('.table-row');
    let inputs = contents.find('input.concept-input');
    inputs.off();
    inputs.change(e => this._onChangeInput(e));

    let query =
      this._request.idTipo == RequestTypeEnum.Disinvestment ? '.disinvestment' : '.totalValue';
    let imports = contents.find(query + ' .concept-input');
    imports.change(() => {
      this._calculateTotalImport(query);
      this.restartCashflow();
    }); //No need to set off because it's implicitly set in the previous statement

    //Enable decimals
    contents.find('.concept-input.decimal').each((i, e) => InputNumeric.createDecimal(e));
    $('#content-tax')
      .find('.tax-input.decimal')
      .each((i, e) => InputNumeric.createDecimal(e));

    $('#content-rebuy-percent')
      .find('.rebuy-input.decimal')
      .each((i, e) => InputNumeric.createDecimal(e));

    this._request.detalleSolicitud.concepto.forEach((concept, index) => {
      if (concept.idLineaPresupuesto != null) {
        this._request.detalleSolicitud.concepto[index].idLineaPresupuesto =
          concept.idLineaPresupuesto;
        let budgetLinePath = $(concept.partidaPresupuestaria.split('/'));
        let budgetLineName = budgetLinePath[budgetLinePath.length - 1];

        if (budgetLineName.length >= 85) {
          budgetLineName.slice(0, -3) + '...';
        }

        $('#budget-item-text-' + index).text(budgetLineName);
      }
    });

    //Datepickers --> only aply to conceptoDesinversion
    let acquisitionDates = contents.find('.concept-acquisition-date');
    acquisitionDates.each((i, e) => {
      let position = parseInt(e.getAttribute('data-id'));
      let concept = this._request.detalleSolicitud.concepto[position];
      if (concept.conceptoDesinversion.fechaAdquisicion) {
        e.value = DatePicker.toLocaleDateString(
          DatePicker.parseDate(concept.conceptoDesinversion.fechaAdquisicion)
        );
      }
      DatePicker.createWithoutMinDate(e, this._request.ejercicio);
    });
    acquisitionDates.change(e => this._onSelectAcquisitionDate(e));
  }
  _onChangeInput(event) {
    let value, field, position;
    if (event.target.classList.contains('decimal')) {
      value =
        event.target.dataset.rawValue == '' ? null : parseFloat(event.target.dataset.rawValue);

      if (value == null && event.target.classList.contains('is-not-nullable')) {
        value = 0;
        event.target.value = 0;
        event.target.dataset.rawValue = 0;
      }
    } else {
      value = event.target.value;
    }
    field = event.target.getAttribute('data-field').split('.');
    position = parseInt(
      $(event.target)
        .closest('.table-row')
        .attr('data-id')
    );
    if (field.length > 1) {
      this._request.detalleSolicitud.concepto[position][field[0]][field[1]] = value;
    } else {
      this._request.detalleSolicitud.concepto[position][field[0]] = value;
    }
  }
  _onSelectAcquisitionDate(event) {
    event.stopPropagation();
    let position = parseInt(
      $(event.target)
        .closest('.table-row')
        .attr('data-id')
    );

    if (event.target.value != '') {
      this._request.detalleSolicitud.concepto[
        position
      ].conceptoDesinversion.fechaAdquisicion = DatePicker.parseDate(
        event.target.value
      ).toISOString();
    } else {
      this._request.detalleSolicitud.concepto[position].conceptoDesinversion.fechaAdquisicion = '';
    }
  }

  saveRequest() {
    if (!this.validator.isValid) {
      return;
    }
    $('#loader-container').removeClass('hidden');

    this._request.usuarioActualizacion = this.authenticationService.getAuthenticatedUser().login;

    this._setPostPutFields();

    if (this._requestExists) {
      this._updateExistingRequest();
    } else {
      this._createNewRequest();
    }
  }
  _createNewRequest() {
    this.httpService
      .post(this.httpService.config.createRequest, this._prepareBody(this._request))
      .then(response => {
        this.toastService.success(
          TranslationService.instance.translate('success-title'),
          TranslationService.instance.translate('msg-req-create-ok')
        );
        $(window).off('beforeunload');
        this.router.preventDefault = false;
        this.router.cancelNavigation = false;
        Router.inst.navigate(`requests/${response}`);
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
        $('#loader-container').addClass('hidden');
      });
  }
  _prepareBody(body) {
    let formData = HttpService.objectToFormData(body);

    for (const file of this._attachments.files.filter(x => x.isNewFile)) {
      formData.append('nuevosAdjuntos', file);
    }

    return formData;
  }
  _updateExistingRequest() {
    this.httpService
      .put(this.httpService.config.updateRequest, this._prepareBody(this._request))
      .then(response => {
        this.toastService.success(
          TranslationService.instance.translate('success-title'),
          TranslationService.instance.translate('msg-req-save-ok')
        );
        $(window).off('beforeunload');
        this.router.preventDefault = false;
        this.router.cancelNavigation = false;
        Router.inst.navigate(`requests/${this._request.idSolicitud}`);
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
        $('#loader-container').addClass('hidden');
      });
  }
  _updateCurrencyNames() {
    $('.currency-name-display').text(this._badgeSelector.getLabel());
  }
  _setPostPutFields() {
    //This method is nedeed now cause the form is edited over the local currency fields but on the post
    //and put methods there is no 'local' field. Is needed to map the value of the form to the correct field.

    //Map amount
    this._request.importe = this._request.importeTotalLocal;

    //Map taxes
    this._request.detalleSolicitud.impuestos =
      (this._request.importeLocal * this._request.detalleSolicitud.porcImpuestos) / 100;

    //Map additional amounts
    this._request.detalleSolicitud.costeAdicional.forEach(cost => {
      cost.importe = cost.importeLocal;
    });

    //Map cashflow amounts
    this._request.detalleSolicitud.flujoCaja.forEach(cost => {
        cost.importe = cost.importeLocal;
    });

    //Map offers
    if(this._request.detalleSolicitud.detalleDesinversion != null){
    this._request.detalleSolicitud.detalleDesinversion.ofertaRecibida.forEach( offer => {
      offer.valor = offer.valorLocal;
    })}

    //Map concepts
    this._request.detalleSolicitud.concepto.forEach(concept => {
      if (this._request.idTipo == RequestTypeEnum.Investment) {
        concept.conceptoInversion.importe = concept.conceptoInversion.importeLocal;
      } else {
        concept.conceptoDesinversion.valorAdquisicion =
          concept.conceptoDesinversion.valorAdquisicionLocal;
        concept.conceptoDesinversion.valorContable =
          concept.conceptoDesinversion.valorContableLocal;
        concept.conceptoDesinversion.desinversionEuros =
          concept.conceptoDesinversion.desinversionEurosLocal;
      }
    });

    this._request.detalleSolicitud.adjuntos = [];
    for (const file of this._attachments.files) {
      this._request.detalleSolicitud.adjuntos.push({
        nombre: file.name,
        comentario: file.comment
      });
    }

    this._request.listadoAdjuntosBorrar = [];
    for (const deletedFile of this._attachments.deletedFiles) {
      this._request.listadoAdjuntosBorrar.push(deletedFile.idAdjuntoSolicitud);
    }
  }

  _toggleMenu() {
    $('.sidenav').toggleClass('menuComplete');
  }

  /* Open modal asking the user for confirmation before leave the page when a change has been done
   * @param {*} event
   */
  confirmQuitWithoutSave(event) {
    this.router.cancelNavigation = true;
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');
    let desiredLocation = location.hash;
    //Assign current location to loction of this budget details
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
      if (this._requestExists) location.assign('#requests/' + this.params.id + '/edit');
      else location.assign('#requests/create');
    } else {
      location.assign(event.oldURL.substr(event.oldURL.indexOf('#')));
    }
    var modal = new ModalComponent(
      TranslationService.instance.translate('common-wout-continue'),
      new QuitWithoutSaveComponent(this.isValid)
    );
    modal.onClosed.listen(() => {
      if (modal.result != null && modal.result.save) {
        $(window).off('beforeunload');
        this.router.preventDefault = false;
        this.router.cancelNavigation = false;
        this.saveRequest();
      } else if (modal.result != null && modal.result.continue) {
        //continue to target
        $(window).off('beforeunload');
        this.router.preventDefault = false;
        this.router.cancelNavigation = false;
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
          location.assign(desiredLocation);
        } else {
          location.assign(event.newURL.substr(event.newURL.indexOf('#')) + ' ');
        }
      }
    });
  }
  _openConceptsMobile(tempConcepts) {
    let options = new ConceptsMobileOptions();
    options.requestType = this._request.idTipo;
    options.unitDiv = 1;
    options.readonly = false;
    options.localCurrency = true;
    options.data = tempConcepts || this._request.detalleSolicitud.concepto.slice();
    var modalListConcepts = new ModalComponent(
      TranslationService.instance.translate('req-detail-concepts-title'),
      new ConceptsMobileComponent(options)
    );
    modalListConcepts.onClosed.listen(() => {
      if (modalListConcepts.result == null) return;
      // modal.result = {
      //   action: 'accion realizada',
      //   concept: 'concepto a editar',
      //   conceptPosition: 'posicion del concepto a editar',
      //   concepts: 'lista actual del conceptos'
      // };
      if (modalListConcepts.result.action == 'save-concepts') {
        //Guardar -> meto al objeto
        this._request.detalleSolicitud.concepto = modalListConcepts.result.concepts;
      } else if (modalListConcepts.result.action == 'new-concept') {
        //Nuevo concepto -> llamar al modal con un concepto vacío
        let newConceptOptions = new ConceptMobileOptions();
        newConceptOptions.data = this._generateNewConcept(modalListConcepts.result.concepts);
        newConceptOptions.requestType = this._request.idTipo;
        newConceptOptions.budgetItemOptions = this._budgetItemsAssociated;
        var modalNewConcept = new ModalComponent(
          TranslationService.instance.translate('req-detail-new-concept'),
          new ConceptMobileComponent(newConceptOptions)
        );
        modalNewConcept.onClosed.listen(() => {
          // modalNewConcept.result = {
          //   concept: 'concepto creado',
          // };
          if (modalNewConcept.result != null && modalNewConcept.result.concept != null) {
            modalListConcepts.result.concepts.push(modalNewConcept.result.concept);
          }
          this._openConceptsMobile(modalListConcepts.result.concepts);
        });
      } else if (modalListConcepts.result.action == 'edit-concept') {
        //Edición de un concepto -> editar el concepto seleccionado
        let editConceptOptions = new ConceptMobileOptions();
        editConceptOptions.data = modalListConcepts.result.concept;
        editConceptOptions.requestType = this._request.idTipo;
        editConceptOptions.budgetItemOptions = this._budgetItemsAssociated;
        var modalEditConcept = new ModalComponent(
          TranslationService.instance.translate('req-det-edit-concept'),
          new ConceptMobileComponent(editConceptOptions)
        );
        modalEditConcept.onClosed.listen(() => {
          // modalEditConcept.result = {
          //   concept: 'concepto editado',
          // };
          if (modalEditConcept.result != null && modalEditConcept.result.concept != null) {
            modalListConcepts.result.concepts[modalListConcepts.result.conceptPosition] =
              modalEditConcept.result.concept;
          }
          this._openConceptsMobile(modalListConcepts.result.concepts);
        });
      }
    });
  }
  _generateNewConcept(concepts) {
    let concept = {
      idConcepto: null,
      idDetalleSolicitud: this._request.detalleSolicitud.idDetalleSolicitud,
      idLineaPresupuesto: null,
      partidaPresupuestaria: null,
      orden: concepts.length,
      unidades: 0,
      descripcion: ''
    };
    if (this._request.idTipo == RequestTypeEnum.Investment) {
      concept.conceptoInversion = {
        idConceptoInversion: null,
        importe: 0,
        importeLocal: 0,
        excedido: false
      };
    } else {
      concept.conceptoDesinversion = {
        idConceptoDesinversion: null,
        fechaAdquisicion: null,
        valorAdquisicion: 0,
        valorContable: 0,
        desinversionEuros: 0,
        valorAdquisicionLocal: 0,
        valorContableLocal: 0,
        desinversionEurosLocal: 0
      };
    }
    return concept;
  }
}
