import './card-balance-budget.scss';
import { TranslationService } from '../../../core/translation/translation';
import { DatePicker } from '../../../components/date-picker/datePicker';
import { UnitsEnums } from '../../../config/enums';
import { InputNumeric } from '../../../components/input-numeric/inputNumeric';
let cardTemplate = require('./card-balance-budget.html');

export class CardBalanceBudget {
  static get template() {
    return TranslationService.instance.translateHtml(cardTemplate);
  }

  constructor(options) {
    this._options = options;
  }

  render(card, data, breadcrumbs) {
    card.querySelector('.title').textContent = data.description;

    let localCurrency = this._options.localCurrency;
    let values = {
      budget: localCurrency ? data.localBudget : data.budget,
      approvedBalance: localCurrency ? data.localApprovedRequests : data.approvedRequests,
      pendingBalance: localCurrency ? data.localPendingRequests : data.pendingRequests,
      balance: localCurrency ? data.localBalance : data.balance,
      predictedBalance: localCurrency ? data.localPredictedBalance : data.predictedBalance
    };

    let divisor = 1;
    switch (this._options.unit) {
      case UnitsEnums.Unit:
        divisor = 1;
        break;
      case UnitsEnums.Thousand:
        divisor = 1000;
        break;
      case UnitsEnums.Millions:
        divisor = 1000000;
        break;
    }
    for (let key in values) {
      values[key] = values[key] / divisor;
    }

    card.querySelector('.budget').textContent = values.budget
      ? InputNumeric.formatWithoutComponent(values.budget)
      : '-';
    card.querySelector('.approved-balance').textContent = values.approvedBalance
      ? InputNumeric.formatWithoutComponent(values.approvedBalance)
      : '-';
    card.querySelector('.pending-balance').textContent = values.pendingBalance
      ? InputNumeric.formatWithoutComponent(values.pendingBalance)
      : '-';
    card.querySelector('.balance').textContent = values.balance
      ? InputNumeric.formatWithoutComponent(values.balance)
      : '-';
    card.querySelector('.balance-provided').textContent = values.predictedBalance
      ? InputNumeric.formatWithoutComponent(values.predictedBalance)
      : '-';

    let translateKey = 'bd-';
    switch (breadcrumbs.length) {
      case 0:
        translateKey += 'work';
        break;
      case 1:
        translateKey += 'group';
        break;
      default:
        translateKey += 'line';
        break;
    }
    if (data.childLines && data.childLines.length > 0) {
      translateKey += data.childLines.length > 1 ? 's' : '';
      card.querySelector('.children-text').textContent = `${
        data.childLines.length
      } ${TranslationService.instance.translate(translateKey)}`;
    }
  }
}

export class CardBalanceBudgetOptions {
  localCurrency = null;
  unit = null;
}
