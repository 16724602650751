import './budgetAskInfo.scss';
var htmlContent = require('./budgetAskInfo.html');
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { HttpService } from '../../core/http/http';
import { ToastService } from '../../shared/services/toastService';
import { Validator } from '../../core/validator/validator';
import { DropDownComponent } from '../../shared/components/drop-down/dropDown';
import { AuthenticationService } from '../../shared/services/authenticationService';

const renderMarkup = () => htmlContent;

export class BudgetAskInfoComponent extends BaseModalContent {
  constructor(lines, breadcums) {
    super(renderMarkup());
    this.httpService = new HttpService();
    this.toastService = new ToastService();
    this.authenticationService = new AuthenticationService();
    this.lines = lines;
    this.value = null;
    this.breadcums = breadcums;
    this.petitionObj = {};
  }

  onOpened() {
    this.init();
  }

  init() {
    //Load users
    this.buildUsersSelector();
    this.parentModal.container.find('#btn-cancel').click(() => this.cancel());
    this.parentModal.container.find('#btn-create-petition').click(() => this.sendRequest());
    // Build breadcums
    this.parentModal.container.find('.breadcums').text(this.breadcums);
    // Implement validation
    this.validator = new Validator(this.parentModal.container);
    this.validator.validate();
  }

  buildUsersSelector() {
    let userSelector = new DropDownComponent(document.getElementById('user-selctor'), false);
    userSelector.setRules({
      required: true
    });
    userSelector.loadRemote(this.httpService.config.loadUsers, 'idUsuario', 'nombre');
    userSelector.onChanges.listen(lineObj => {
      this.petitionObj.idUsuarioResponsable = lineObj.idUsuario;
    });
  }

  buildBreadcums() {
    let breadcums = '';
    this.lines.forEach(line => {
      breadcums = breadcums.length == 0 ? line.description : line.description + '/' + breadcums;
    });
  }

  fillRequestedLines() {
    let linesIds = [];
    this.lines.forEach(line => {
      linesIds.push(line.id);
    });
    return linesIds;
  }

  cancel() {
    this.value = null;
    this.parentModal.close();
  }

  sendRequest() {
    if (!this.validator.isValid) {
      return;
    }
    this.parentModal.toggleLoadingIcon(true);

    this.petitionObj.idUsuarioRemitente = this.authenticationService.getAuthenticatedUser().idUsuario;
    this.petitionObj.mensaje = this.parentModal.container.find('#msgTextArea').val();
    this.petitionObj.usuarioActualizacion = this.authenticationService.getAuthenticatedUser().login;
    this.petitionObj.idLineasAsociadas = this.fillRequestedLines();

    this.httpService
      .post(this.httpService.config.createPetition, this.petitionObj)
      .then(response => {
        this.value = true;
        this.parentModal.close();
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      })
      .finally(() => {
        this.parentModal.toggleLoadingIcon(false);
      });
  }

  get result() {
    return this.value;
  }
}
