import './petitions.scss';
var htmlContent = require('./petitions.html');
import { Component } from '../../shared/components/base-component';
import $ from 'jquery';
import { HttpService } from '../../core/http/http';
import { ToastService } from '../../shared/services/toastService';
import { DatePicker } from '../../components/date-picker/datePicker';
import { PetitionStatusDotsEnum, PetitionStatusTextEnum } from '../../config/enums';
import { ViewsLoader } from '../../core/views/views-loader';
import { Spinner } from '../../shared/components/spinner/spinner';
import { TranslationService } from '../../core/translation/translation';
import { AuthenticationService } from '../../shared/services/authenticationService';
import { ModalComponent } from '../../shared/components/modal';
import { PetitionStatusEnum } from '../../config/enums';
import { GenericConfirmComponent } from '../../components/generic-confirm/generic-confirm';

const renderMarkup = () => htmlContent;

export class PetitionsPage extends Component {
  constructor() {
    super(renderMarkup());
    $(document).ready(() => {
      let selectNav = document.querySelectorAll('.links-item');
      let i = 0;
      selectNav.forEach(e => {
        if (i == 1) {
          $(e).addClass('active');
          i++;
        } else {
          $(e).removeClass('active');
          i++;
        }
      });
      $('#btn-toogle-menu').click(() => this._toggleMenu());
      this.httpService = new HttpService();
      this.toastService = new ToastService();
      this.authenticationService = new AuthenticationService();
      this.viewsLoader = new ViewsLoader();
      this.loadPetitions();

      this.getNumberOfPetitions();
      setInterval(() => {
        this.getNumberOfPetitions();
      }, 60000);
    });
  }

  loadPetitions() {
    $('#loader-container').removeClass('hidden');
    this.viewsLoader.loadView(new Spinner('big'), document.getElementById('loader-container'));
    this.httpService
      .get(
        this.httpService.config.loadPetitions +
          this.authenticationService.getAuthenticatedUser().login
      )
      .then(response => {
        $('#loader-container').addClass('hidden');

        var screenWidth = window.innerWidth;
        if (screenWidth >= 768) {
          this.buildPetitionsTable(response);
        } else {
          this.buildPetitionsCard(response);
        }

        $(window).resize(() => {
          screenWidth = window.innerWidth;
          if (screenWidth >= 768) {
            this.buildPetitionsTable(response);
          } else {
            this.buildPetitionsCard(response);
          }
        });
      })
      .catch(error => {
        this.toastService.exception(error.title);
        console.error(error);
      });
  }

  buildPetitionsTable(petitionsResponse) {
    let receivedPetHtml = '',
      donePetHtml = '';

    petitionsResponse.peticionesRecibidas.forEach(petition => {
      let requiredDate = DatePicker.toLocaleDateString(
        DatePicker.localTimeFromUTC(new Date(petition.fechaSolicitud)),
        true
      );

      let answerDate =
        petition.fechaRespuesta != null
          ? DatePicker.toLocaleDateString(
              DatePicker.localTimeFromUTC(new Date(petition.fechaRespuesta)),
              true
            )
          : '';

      receivedPetHtml += `<div class="table-row">
        <div class="cell center open"><a href="#petitions/${
          petition.idPeticion
        }" class="icon icon-abrir" title="${TranslationService.instance.translate('common-table-open')}"></a></div>
        <div class="cell budget">${TranslationService.instance.translate('common-exercises') +
          ' ' +
          petition.presupuesto}</div>
        <div class="cell requiredBy">${petition.solicitante}</div>
        <div class="cell requiredDate">${requiredDate}</div>
        <div class="cell replyDate">${answerDate}</div>
        <div class="cell status">
          <div class="flex-row">
            <div class="circle-state ${PetitionStatusDotsEnum[petition.idEstado]}"></div>
            <span>
            ${TranslationService.instance.translate(
              PetitionStatusTextEnum[petition.idEstado]
            )}</span>
          </div>
        </div>
        <div class="cell center icon">
          <span class="icon icon-eliminar  ${petition.idEstado == 1 ? 'disabled' : ''}"" data-id="${
        petition.idPeticion
      }"></span>
        </div>
      </div>`;
    });

    petitionsResponse.peticionesRealizadas.forEach(petition => {
      let requiredDate = DatePicker.toLocaleDateString(
        DatePicker.localTimeFromUTC(new Date(petition.fechaSolicitud)),
        true
      );

      let answerDate =
        petition.fechaRespuesta != null
          ? DatePicker.toLocaleDateString(
              DatePicker.localTimeFromUTC(new Date(petition.fechaRespuesta)),
              true
            )
          : '';

      donePetHtml += `<div class="table-row">
      <div class="cell center open"><a href="#petitions/${
        petition.idPeticion
      }" class="icon icon-abrir" title="${TranslationService.instance.translate('common-table-open')}"></a></div>
      <div class="cell budget">${TranslationService.instance.translate('common-exercises') +
        ' ' +
        petition.presupuesto}</div>
      <div class="cell requiredBy">${petition.destinatario}</div>
      <div class="cell requiredDate">${requiredDate}</div>
      <div class="cell replyDate">${
        petition.fechaRespuesta != null
          ? answerDate
          : '<div class="button primary-button cancel-petition" data-id="' +
            petition.idPeticion +
            '">' +
            TranslationService.instance.translate('petitions-cancel-petition') +
            '</div>'
      }</div>
      <div class="cell status">
        <div class="flex-row">
          <div class="circle-state ${PetitionStatusDotsEnum[petition.idEstado]}"></div>
          <span>${TranslationService.instance.translate(
            PetitionStatusTextEnum[petition.idEstado]
          )}</span>
        </div>
      </div>
      <div class="cell center icon">
        <span class="icon icon-eliminar ${petition.idEstado == 1 ? 'disabled' : ''}" data-id="${
        petition.idPeticion
      }"></span>
      </div>
    </div>`;
    });

    if (receivedPetHtml.length == 0) {
      receivedPetHtml = `<div class="table-row all">
        <span>${TranslationService.instance.translate('petitions-empty-received-petitions')}</span>
      </div>`;
    }
    if (donePetHtml.length == 0) {
      donePetHtml = `<div class="table-row all">
        <span>${TranslationService.instance.translate('petitions-empty-send-petitions')}</span>
      </div>`;
    }

    $('#petitions-received').html(receivedPetHtml);
    $('#petitions-done').html(donePetHtml);
    $('.icon-eliminar').click(event => this.deletePetition(event.target));
    $('.cancel-petition').click(event => this.cancelPetition(event.target));
  }

  buildPetitionsCard(petitionsResponse) {
    //this.buildReceived(petitionsResponse.peticionesRecibidas);
    $('#received').click(() => this.buildReceived(petitionsResponse.peticionesRecibidas));
    $('#perform').click(() => this.buildPerform(petitionsResponse.peticionesRealizadas));
    if ($('#received').hasClass('active')) {
      this.buildReceived(petitionsResponse.peticionesRecibidas);
    } else {
      this.buildPerform(petitionsResponse.peticionesRealizadas);
    }
  }
  buildReceived(petitions) {
    let htmlTemplate = $('#cards-container');
    let listCards = `<div id="listReceived">`;

    if (petitions.length != 0) {
      petitions.forEach(petition => {
        listCards = listCards.concat(
          `<div class="card">
          <div class="card-row">
            <div class="card-row-element all">
              <span class="title with-link">${TranslationService.instance.translate(
                'common-exercises'
              ) + petition.presupuesto}</span>
              <a href="/#budgets/${petition.idPeticion}" class="icon-abrir" title="${TranslationService.instance.translate('common-table-open')}"></a>
            </div>
          </div>
          <div class="card-row">
            <div class="card-row-element all">
            <div class="label">${TranslationService.instance.translate(
              'petitions-table-applicant'
            )}</div>
            <div class="description">${petition.solicitante}</div>
            </div>
          </div>
          <div class="card-row">  
            <div class="card-row-element half">
              <div class="label">${TranslationService.instance.translate(
                'petitions-table-date-applicant'
              )}</div>
              <div class="description">
                ${DatePicker.toLocaleDateString(DatePicker.parseDate(petition.fechaSolicitud))}
              </div>
            </div>
  
            <div class="card-row-element half">
              <div class="label">${TranslationService.instance.translate(
                'petitions-table-date-reply'
              )}</div>
              <div class="description">${
                petition.fechaRespuesta != null
                  ? DatePicker.toLocaleDateString(DatePicker.parseDate(petition.fechaRespuesta))
                  : ''
              }
              </div>
            </div>
          </div>  
          <div class="card-row">
            <div class="card-row-element half">
              <div class="label">${TranslationService.instance.translate(
                'budgets-table-state-budget'
              )}</div>
              <div class="description">
                <div class="circle-state ${PetitionStatusDotsEnum[petition.idEstado]}"></div>
                <span>${TranslationService.instance.translate(
                  PetitionStatusTextEnum[petition.idEstado]
                )}</span>
              </div>
            </div>
            <div class="card-row-element half right down">
              <span class="icon icon-eliminar  ${
                petition.idEstado == 1 ? 'disabled' : ''
              }"" data-id="${petition.idPeticion}">
              </span>
            </div>
          </div>
        </div>`
        );
      });
    } else {
      listCards = listCards.concat(
        `<div class="empty">
          <span class="msg">${TranslationService.instance.translate(
            'petitions-empty-received-petitions'
          )}</span>
        </div>`
      );
    }

    listCards = listCards.concat(`</div>`);

    if ($('#perform').hasClass('active')) {
      $('#perform').removeClass('active');
      $('#received').addClass('active');
    }

    if ($('#listReceived').length == 0) {
      htmlTemplate.append(listCards);
    }

    $('#listPerform').remove();
    $('.icon-eliminar').click(event => this.deletePetition(event.target));
    $('.cancel-petition').click(event => this.cancelPetition(event.target));
  }

  buildPerform(petitions) {
    let htmlTemplate = $('#cards-container');
    let listCards = `<div id="listPerform">`;

    if (petitions.length != 0) {
      petitions.forEach(petition => {
        listCards = listCards.concat(
          `<div class="card">
          <div class="card-row">
            <div class="card-row-element all">
              <span class="title with-link">${petition.presupuesto}</p> <a href="/#petitions/${
            petition.idPeticion
          }" class="icon-abrir" title="${TranslationService.instance.translate('common-table-open')}"></a>
            </div>
          </div>
          <div class="card-row">
            <div class="card-row-element all">
            <div class="label">${TranslationService.instance.translate(
              'petitions-table-applicant'
            )}</div>
            <div class="description">${petition.solicitante}</div>
            </div>
          </div>
          <div class="card-row">  
            <div class="card-row-element half">
              <div class="label">${TranslationService.instance.translate(
                'petitions-table-date-applicant'
              )}</div>
              <div class="description">
                ${DatePicker.toLocaleDateString(DatePicker.parseDate(petition.fechaSolicitud))}
              </div>
            </div>
  
            <div class="card-row-element half">
              <div class="label">${TranslationService.instance.translate(
                'petitions-table-date-reply'
              )}</div>
              <div class="description">${
                petition.fechaRespuesta != null
                  ? DatePicker.toLocaleDateString(DatePicker.parseDate(petition.fechaRespuesta))
                  : '<div class="button primary-button cancel-petition" data-id="' +
                    petition.idPeticion +
                    '">' +
                    TranslationService.instance.translate('petitions-cancel-petition') +
                    '</div>'
              }
              </div>
            </div>
          </div>  
          <div class="card-row">
            <div class="card-row-element half">
              <div class="label">${TranslationService.instance.translate(
                'budgets-table-state-budget'
              )}</div>
              <div class="description">
                <div class="circle-state ${PetitionStatusDotsEnum[petition.idEstado]}"></div>
                <span>${TranslationService.instance.translate(
                  PetitionStatusTextEnum[petition.idEstado]
                )}</span>
              </div>
            </div>
            <div class="card-row-element half right down">
              <span class="icon icon-eliminar  ${
                petition.idEstado == 1 ? 'disabled' : ''
              }"" data-id="${petition.idPeticion}">
              </span>
            </div>
          </div>
        </div>`
        );
      });
    } else {
      listCards = listCards.concat(
        `<div class="empty">
          <span class="msg">${TranslationService.instance.translate(
            'petitions-empty-send-petitions'
          )}</span>
        </div>`
      );
    }

    listCards = listCards.concat(`</div>`);

    if ($('#received').hasClass('active')) {
      $('#received').removeClass('active');
      $('#perform').addClass('active');
    }

    if ($('#listPerform').length == 0) {
      htmlTemplate.append(listCards);
    }

    $('#listReceived').remove();
    $('.icon-eliminar').click(event => this.deletePetition(event.target));
    $('.cancel-petition').click(event => this.cancelPetition(event.target));
  }

  deletePetition(element) {
    var modal = new ModalComponent(
      TranslationService.instance.translate('petitions-remove-petition'),
      new GenericConfirmComponent(
        TranslationService.instance.translate('petitions-remove-petition-subtitle')
      )
    );
    modal.onClosed.listen(() => {
      if (modal.result && modal.result.confirm === true) {
        $('#loader-container').removeClass('hidden');

        let body = {
          usuarioSolicitante: this.authenticationService.getAuthenticatedUser().login,
          idPeticion: parseInt(element.getAttribute('data-id'))
        };
        this.httpService
          .delWithBody(this.httpService.config.deletePetition, body)
          .then(response => {
            this.toastService.success(
              TranslationService.instance.translate('success-title'),
              TranslationService.instance.translate('msg-pet-del-ok')
            );
            this.loadPetitions();
          })
          .catch(error => {
            this.toastService.exception(error.title);
            console.error(error);
            $('#loader-container').addClass('hidden');
          });
      }
    });
  }

  cancelPetition(element) {
    var modal = new ModalComponent(
      TranslationService.instance.translate('petitions-cancel-petition'),
      new GenericConfirmComponent(
        TranslationService.instance.translate('petitions-cancel-petition-subtitle')
      )
    );
    modal.onClosed.listen(() => {
      if (modal.result && modal.result.confirm === true) {
        $('#loader-container').removeClass('hidden');

        let body = {
          usuarioSolicitante: this.authenticationService.getAuthenticatedUser().login,
          idPeticion: parseInt(element.getAttribute('data-id'))
        };
        this.httpService
          .post(this.httpService.config.cancelPetition, body)
          .then(response => {
            this.toastService.success(
              TranslationService.instance.translate('success-title'),
              TranslationService.instance.translate('msg-pet-cancel-ok')
            );
            this.loadPetitions();
          })
          .catch(error => {
            this.toastService.exception(error.title);
            console.error(error);
            $('#loader-container').addClass('hidden');
          });
      }
    });
  }

  getNumberOfPetitions() {
    this.httpService
      .get(
        this.httpService.config.loadPetitionsNumber +
          '/' +
          this.authenticationService.getAuthenticatedUser().login
      )
      .then(response => {
        if (response === 0) {
          $('#petitions-numberPage').hide();
        } else {
          $('#petitions-numberPage').show();
          $('#petitions-numberPage').text(response);
        }
      })
      .catch(error => {
        this.toastService.error(error.title, error.message);
      });
  }
  _toggleMenu() {
    $('.sidenav').toggleClass('menuComplete');
  }
}
