var htmlContent = require('./404.html');
import { Component } from '../../shared/components/base-component';

const renderMarkup = () => htmlContent;

export class Err404Page extends Component {
  constructor() {
    super(renderMarkup());
    // Page logic
  }
}
