import './modalyesno.scss';
var htmlContent = require('./modalyesno.html');
import $ from 'jquery';
import { BaseModalContent } from '../../shared/components/base-modal-content';
import { Validator } from '../../core/validator/validator';
import { ToastService } from '../../shared/services/toastService';
import { InputNumeric } from '../input-numeric/inputNumeric';

const renderMarkup = () => htmlContent;

export class ModalYesNoComponent extends BaseModalContent {
  constructor(options) {
    super(renderMarkup());
    this.value = null;
    this._options = options || new ModalYesNoOptions();
  }

  onCreated() {
    this.init();
  }

  init() {
    this.enableButtons();
  }


  enableButtons() {
    this._cancel = this.parentModal.container.find('.cancel');
    this._save = this.parentModal.container.find('.save');
 
      this._cancel.click(() => this.cancel());
      this._save.click(() => this.save());
 
  }

  cancel() {
    this.value = null;
    this.parentModal.close();
  }

  save() {
    this.value = true;
    this.parentModal.close();
  }

  get result() {
    return this.value;
  }
}

export class ModalYesNoOptions {
  readOnly = false;
  data = [];
  localCurrency = true;
}
